import * as API from 'aws-amplify/api'
import { fetchAuthSession } from 'aws-amplify/auth'
export type RestMethod = 'post' | 'get' | 'del' | 'skip'
export type RestAPIInput = {
  body?: object
  path: string
  method: RestMethod
  query?: Record<string, string | string[] | number>
  responseType?: 'arraybuffer' | 'blob' | 'document' | 'json' | 'text'
}

export async function restAPIRequest<T>({ body = {}, path, method, query, responseType = 'json' }: RestAPIInput): Promise<T> {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  }
  try {
    const { idToken } = (await fetchAuthSession()).tokens ?? {}
    headers.Authorization = `Bearer ${idToken?.toString()}`
  } catch (error) {
    console.warn('Failed to get idToken', error)
  }
  let res: T
  try {
    res = (
      await API[method]({
        apiName: 'RestAPI',
        path: `/api/${path}`,
        options: {
          headers,
          body: body as any,
          queryParams: query as any,
        },
      }).response
    ).body[responseType || 'json']()

    // console.log(`${method} ${path} got response`, res)
    return res
  } catch (error: any) {
    let finalError = error
    if (error?.response?.data?.error) {
      throw new Error(error.response.data.error)
    }
    if (typeof error?.response?.body === 'string') {
      try {
        const body = JSON.parse(error.response.body)
        if (body.error) {
          finalError = new Error(body.error)
        }
      } catch (e) {
        console.error('Failed to parse error response', error.response)
      }
    }
    throw finalError
  }
}
