import { Flex, Button, View } from '@aws-amplify/ui-react'
import { FunctionComponent, useState } from 'react'
import ProfileSearch from '../components/ProfileSearch'
import UserCompanyGroups from '../components/UserCompanyLists'
import GlassCard from '../figma-components/GlassCard'
import '../styles/dashboard.css'
import '../styles/opportunity.css'
import '../styles/sidenav.css'
import '../styles/table.css'
import ListInfoPage from './ListInfoPage'

type ListsPageProps = {
  isStreamlined?: boolean
  handleCustomSubmit?: Function
}

const ListsPage: FunctionComponent<ListsPageProps> = ({ isStreamlined = false, handleCustomSubmit }) => {
  const [currentPage, setCurrentPage] = useState('viewLists')

  const getCurrentPage = (page: string) => {
    switch (page) {
      case 'manageLists':
        return <UserCompanyGroups handlePageUpdate={() => setCurrentPage('viewLists')} />
      case 'viewLists':
        return <ListInfoPage handlePageUpdate={() => setCurrentPage('manageLists')} />
      default:
        return <ListInfoPage handlePageUpdate={() => setCurrentPage('manageLists')} />
    }
  }

  return (
    <Flex direction='column' paddingTop='4px'>
      <ProfileSearch enableNavigate={true} />
      <GlassCard
        header={currentPage === 'viewLists' ? 'Saved Lists' : 'Create New List'}
        level={0}
        headerButtons={
          <Button variation='primary' size='small' onClick={() => setCurrentPage(currentPage === 'viewLists' ? 'manageLists' : 'viewLists')}>
            {currentPage === 'viewLists' ? '+ New List' : 'View Lists'}
          </Button>
        }
      >
        <View marginTop='1rem'>{getCurrentPage(currentPage)}</View>
      </GlassCard>
    </Flex>
  )
}

export default ListsPage
