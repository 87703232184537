import { Badge, Button, Flex, Text } from '@aws-amplify/ui-react'
import { FunctionComponent } from 'react'

type CompPickerProps = {
  compSet: string[]
  onCompClick?: Function
  onEditClick: Function
}
export const CompPicker: FunctionComponent<CompPickerProps> = ({ compSet, onCompClick = () => {}, onEditClick }) => {
  return (
    <Flex direction='row' alignItems='center' wrap='wrap'>
      <Text>Selected: </Text>
      {compSet.map((i) => {
        return (
          <Badge
            key={i}
            onClick={() => {
              onCompClick(i)
            }}
          >
            {i}
          </Badge>
        )
      })}
      <Button size='small' variation='link' onClick={() => onEditClick()}>
        edit
      </Button>
    </Flex>
  )
}
