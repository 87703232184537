/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function CkInfoOutline(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="20px"
      height="20px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 20, height: 20 }}
      paths={[
        {
          d: "M9.88249 6.58333C10.3427 6.58333 10.7158 6.21024 10.7158 5.75C10.7158 5.28976 10.3427 4.91667 9.88249 4.91667C9.42225 4.91667 9.04915 5.28976 9.04915 5.75C9.04915 6.21024 9.42225 6.58333 9.88249 6.58333Z",
          fill: "rgba(48,53,69,1)",
          fillRule: "nonzero",
          style: {
            transform:
              "translate(calc(50% - 9.92px - 0px), calc(50% - 9.92px - 0px))",
          },
        },
        {
          d: "M10.716 8.25L10.716 14.9167L9.04932 14.9167L9.04932 8.25L10.716 8.25Z",
          fill: "rgba(48,53,69,1)",
          fillRule: "nonzero",
          style: {
            transform:
              "translate(calc(50% - 9.92px - 0px), calc(50% - 9.92px - 0px))",
          },
        },
        {
          d: "M9.91667 0C4.43984 0 0 4.43984 0 9.91667C0 15.3935 4.43984 19.8333 9.91667 19.8333C15.3935 19.8333 19.8333 15.3935 19.8333 9.91667C19.8333 4.43984 15.3935 0 9.91667 0ZM1.5 9.91667C1.5 5.26827 5.26827 1.5 9.91667 1.5C14.5651 1.5 18.3333 5.26827 18.3333 9.91667C18.3333 14.5651 14.5651 18.3333 9.91667 18.3333C5.26827 18.3333 1.5 14.5651 1.5 9.91667Z",
          fill: "rgba(48,53,69,1)",
          fillRule: "evenodd",
          style: {
            transform:
              "translate(calc(50% - 9.92px - 0px), calc(50% - 9.92px - 0px))",
          },
        },
      ]}
      {...getOverrideProps(overrides, "CkInfoOutline")}
      {...rest}
    ></Icon>
  );
}
