import '../styles/dashboard.css'
import '../styles/opportunity.css'
import '../styles/table.css'

import { GraphQLQuery } from '@aws-amplify/api'
import { graphqlOperation } from '@aws-amplify/api-graphql'
import { Button, Flex, Heading, Loader, Text, TextField, View } from '@aws-amplify/ui-react'
import { useMutation } from '@tanstack/react-query'
import MDEditor from '@uiw/react-md-editor'
import { generateClient } from 'aws-amplify/api'
import { FunctionComponent, useState } from 'react'
import { useParams } from 'react-router-dom'
import { UpdateCompanyInfoV3Input, UpdateCompanyInfoV3Mutation } from '../API'
import CaseStudiesReview from '../components/CaseStudiesReview'
import CompanyOverview from '../components/CompanyOverview'
import MarketInfoReview from '../components/MarketInfoReview'
import ProductsReview from '../components/ProductsReview'
import MissingDataCard from '../components/missingDataCard'
import { useAmplifyUser } from '../contexts/amplifyUserContext'
import GlassCard from '../figma-components/GlassCard'
import { updateCompanyInfoV3 } from '../graphql/mutations'
import { useCompanyInfoV2 } from '../hooks/useCompanyInfoV2'
import { restAPIRequest } from '../services/restApiRequest'
import { PROFILE_UPDATE, getRunningTasks } from '../services/tasks'
import { convertToHtml, removeNonGraphqlKeys } from '../utils/utils'

const API = generateClient()

// TODO: combine with keys variable above
const pages = [
  { name: 'overview', displayName: 'Company Overview' },
  { name: 'products', displayName: 'Product Detail' },
  { name: 'customerCaseStudies', displayName: 'Customer Case Studies' },
  { name: 'market', displayName: 'Masrket Summary' },

  { name: 'investment', displayName: 'Investment Highlights & Risks' },
]

type Params = {}

const COMPANY_PROFILE = 'Admin Review'

const ProfileReviewPage: FunctionComponent<Params> = () => {
  let params = useParams()
  const targetCompanyName = params.targetCompany
  const [companyInfoV3, companyInfoV3Loading] = useCompanyInfoV2(targetCompanyName)
  const [currentPage, setCurrentPage] = useState<string>('overview')
  const amplifyUser = useAmplifyUser()
  const orgName = amplifyUser?.org
  const isDemo = orgName?.toLowerCase() === 'demo'
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [cbLink, setCbLink] = useState<string>(companyInfoV3?.cb_organizationPermalink || '')
  const [cyberLink, setCyberLink] = useState<string>(companyInfoV3?.cyberdbId || '')
  const [saving, setSaving] = useState<boolean>(false)
  const [ai_investmentHighlights, setAii] = useState<string>(companyInfoV3?.ai_investmentHighlights || '')
  const save = async (updates: Record<string, any>) => {
    if (!companyInfoV3?.companyName || !companyInfoV3?._version) return console.error('missing company info')
    const update: UpdateCompanyInfoV3Input = {
      ...updates,
      companyName: companyInfoV3?.companyName,
      _version: companyInfoV3?._version,
    }

    await API.graphql(graphqlOperation(updateCompanyInfoV3, { input: removeNonGraphqlKeys(update) }))
  }

  const sendApprove = useMutation({
    mutationFn: async (isApproved: boolean) => {
      return save({ isApproved })
    },
  })

  if (!targetCompanyName) {
    return (
      <Flex direction='column'>
        <Text>Not A valid company "{targetCompanyName}". Be sure you are at the right URL</Text>
      </Flex>
    )
  }

  if (!companyInfoV3) {
    return (
      <MissingDataCard>
        <Text>We do not have "{targetCompanyName}" in our database. Search for it from Company Profiles</Text>
      </MissingDataCard>
    )
  }

  if (companyInfoV3Loading) {
    return (
      <MissingDataCard>
        <Text>Loading</Text>
        <Loader />
      </MissingDataCard>
    )
  }

  // TODO: refactor into a dynamic, smaller switch statement
  const getCurrentPage = (page: string) => {
    switch (page) {
      case 'products':
        return (
          <ProductsReview
            products={companyInfoV3.products || []}
            isEditing={isEditing}
            onSave={async (products) => {
              const productsAdded = products.filter((p) => !companyInfoV3.products?.find((p2) => p2.name === p.name)) || []
              const productsThatChangedTaxonomies = products.filter((p) => {
                const oldProduct = companyInfoV3.products?.find((p2) => p2.name === p.name)
                if (!oldProduct) return false
                return JSON.stringify(oldProduct.taxonomiesV2) !== JSON.stringify(p.taxonomiesV2)
              })

              const changedProducts = [...productsAdded, ...productsThatChangedTaxonomies]
              for (const p of changedProducts) {
                const description = p.description || p.shortDescription
                if (!description || !p.featuresV2 || !p.taxonomiesV2?.length) continue
                await restAPIRequest({
                  path: `companyProfile/${companyInfoV3?.companyName}/products/addProduct`,
                  body: {
                    productName: p.name,
                    description,
                    features: p.featuresV2,
                    taxonomies: p.taxonomiesV2,
                    companyLabel: companyInfoV3.companyLabel,
                  },
                  method: 'post',
                })
              }
              const productsRemoved = companyInfoV3.products?.filter((p) => !products.find((p2) => p2.name === p.name)) || []
              for (const p of productsRemoved) {
                await restAPIRequest({
                  path: `companyProfile/${companyInfoV3?.companyName}/products/removeProduct`,
                  body: {
                    productName: p.name,
                    companyLabel: companyInfoV3.companyLabel,
                  },
                  method: 'post',
                })
              }
              // TODO: say user modified products
              await save({ products })
            }}
          />
        )

      case 'market':
        return (
          <MarketInfoReview
            companyName={companyInfoV3.companyName}
            onSave={(marketRisks, marketDrivers) => save({ marketRisks, marketDrivers })}
            isEditing={isEditing}
            marketRisks={companyInfoV3.marketRisks || []}
            marketDrivers={companyInfoV3.marketDrivers || []}
            marketNames={companyInfoV3.products?.map((p) => p.markets || []).flat() || []}
          />
        )

      case 'customerCaseStudies':
        return (
          <CaseStudiesReview
            caseStudies={companyInfoV3.caseStudies || []}
            isEditing={isEditing}
            onSave={async function (caseStudies) {
              await save({ caseStudies })
            }}
          />
        )

      case 'investment':
        const ai_i = ai_investmentHighlights || companyInfoV3?.ai_investmentHighlights
        return (
          <GlassCard header='Investment Highlights & Risks'>
            <Flex direction='column'>
              {convertToHtml(ai_i)}
              {isEditing && (
                <Flex>
                  <Button
                    isLoading={saving}
                    onClick={() => {
                      setSaving(true)
                      save({ ai_investmentHighlights })
                        .catch((e) => console.error('save error', e))
                        .finally(() => setSaving(false))
                    }}
                  >
                    Save
                  </Button>
                  <MDEditor
                    preview='edit'
                    value={ai_i || 'N/A'}
                    onChange={(v) => {
                      setAii(v || '')
                    }}
                  />
                </Flex>
              )}
            </Flex>
          </GlassCard>
        )
      case 'overview':
      default:
        return (
          <Flex direction='column'>
            <Flex>
              <TextField label='Crunchbase name' value={cbLink || companyInfoV3?.cb_organizationPermalink || ''} onChange={(e) => setCbLink(e.target.value)} />
              <Button
                onClick={async () => {
                  await restAPIRequest({
                    path: `companyProfile/${companyInfoV3?.companyName}/updateCrunchbase`,
                    body: {
                      cb_organizationPermalink: cbLink || companyInfoV3?.cb_organizationPermalink,
                    },
                    method: 'post',
                  })
                }}
              >
                update crunchbase
              </Button>
              <TextField label='Cyber name' value={cyberLink || companyInfoV3?.cyberdbId || ''} onChange={(e) => setCyberLink(e.target.value)} />
              <Button
                onClick={async () => {
                  const update: UpdateCompanyInfoV3Input = {
                    companyName: companyInfoV3.companyName,
                    cyberdbId: cyberLink,
                    _version: companyInfoV3._version,
                  }
                  const input = removeNonGraphqlKeys(update)
                  try {
                    await API.graphql<GraphQLQuery<UpdateCompanyInfoV3Mutation>>(graphqlOperation(updateCompanyInfoV3, { input }))
                  } catch (error: any) {
                    alert(`error updating cyber: ${error.message}`)
                  }
                }}
              >
                update cyber
              </Button>
              <Button
                isLoading={!!getRunningTasks(PROFILE_UPDATE, companyInfoV3.tasks, { timeout: 1000 * 60 * 5 })}
                onClick={async () => {
                  await restAPIRequest({
                    path: `companyProfile/${companyInfoV3?.companyName}/updateAi`,
                    body: {
                      cb_organizationPermalink: cbLink || companyInfoV3?.cb_organizationPermalink,
                      force: true,
                    },
                    method: 'post',
                  })
                }}
              >
                Run AI
              </Button>
            </Flex>
            <CompanyOverview companyInfo={companyInfoV3} />
          </Flex>
        )
    }
  }

  return (
    <Flex direction='column'>
      <Heading level={3}>
        {COMPANY_PROFILE} — {targetCompanyName}
      </Heading>
      {isDemo ? (
        <>
          <Button
            isLoading={sendApprove.isLoading}
            onClick={() => {
              sendApprove.mutate(!companyInfoV3?.isApproved)
            }}
            variation='destructive'
          >
            {companyInfoV3?.isApproved ? 'DE-LIST' : 'APPROVE'}
          </Button>
          <Button onClick={() => setIsEditing(!isEditing)}>{isEditing ? 'STOP EDITING' : 'EDIT'}</Button>
        </>
      ) : null}
      <View className='dashboard'>
        <View className='dashboardButtons sticky tutorial-dash-bar'>
          {pages.map((page) => {
            return (
              <Button key={page.name} size='small' className={`dashboardButton ${currentPage === page.name ? 'active' : ''}`} onClick={() => setCurrentPage(page.name)}>
                {page.displayName}
              </Button>
            )
          })}
        </View>
        {getCurrentPage(currentPage)}
      </View>
    </Flex>
  )
}
export default ProfileReviewPage
