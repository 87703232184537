import { Button, Card, CheckboxField, Flex, Text } from '@aws-amplify/ui-react'
import { FunctionComponent, useState } from 'react'
import { File } from '../models'
import { CloseIcon } from '../ui-components'

interface SelectableDocListViewProps {
  docs: File[]
  selectedDocs: string[]
  deleteClicked: (s3Key: string) => void
  updateSelectedDocs?: (s3Keys: string[]) => void
  maxHeight?: string
  isDisabled?: boolean
}

const SelectableDocListView: FunctionComponent<SelectableDocListViewProps> = ({ docs, selectedDocs, deleteClicked, updateSelectedDocs = () => {}, maxHeight, isDisabled }) => {
  const [selectedS3Keys, setSelected] = useState<string[]>(selectedDocs || [])

  // async function downloadClicked() {
  //   if (!selectedDoc?.s3Key) return alert('Please select a document first.')
  //   const signedURL = await Storage.get(selectedDoc.s3Key, { level: 'private' })
  //   const link = document.createElement('a')
  //   link.href = signedURL
  //   link.target = '_blank'
  //   link.style.display = 'none'
  //   link.setAttribute('download', selectedDoc.name || 'document')
  //   document.body.appendChild(link)
  //   link.click()
  //   document.body.removeChild(link)
  // }

  const docCard = (doc: File) => (
    <Card key={doc.s3Key} className='documentCard'>
      <Flex justifyContent='space-between'>
        <CheckboxField
          isDisabled={isDisabled}
          checked={selectedS3Keys.includes(doc.s3Key)}
          onChange={(e) => {
            const filtered = selectedS3Keys.filter((d) => d !== doc.s3Key)
            if (e.target.checked) {
              const update = [...filtered, doc.s3Key]
              setSelected(update)
              updateSelectedDocs(update)
            } else {
              setSelected(filtered)
              updateSelectedDocs(filtered)
            }
          }}
          label={
            <Flex direction='column' gap='0px' marginLeft='1rem'>
              <Text fontWeight='bold' fontSize='14px'>
                {doc.name}
              </Text>
              <Text fontSize='14px'>Date uploaded: {new Date(doc.dateUploaded).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</Text>
            </Flex>
          }
          name={doc.s3Key}
          value='yes'
        />
        <Button
          isDisabled={isDisabled}
          size='small'
          variation='link'
          alignSelf='center'
          onClick={() => {
            deleteClicked(doc.s3Key)
          }}
        >
          <CloseIcon width='0.75rem' className='actionButton' />
        </Button>
      </Flex>
    </Card>
  )
  return (
    <Flex direction='column' maxHeight={maxHeight} overflow='scroll'>
      {docs.map((f) => docCard(f))}
    </Flex>
  )
}

export default SelectableDocListView
