/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function ChatIcon(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="20px"
      height="20px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 20, height: 20 }}
      paths={[
        {
          d: "M12.6751 10.3544C13.9467 9.22103 14.6667 7.71042 14.6667 6.14281C14.6667 4.47683 13.8868 2.91876 12.4706 1.75536C11.0923 0.623452 9.2679 0 7.33326 0C5.39861 0 3.57421 0.623452 2.1961 1.75544C0.779909 2.91876 0 4.47683 0 6.14281C0 7.80879 0.779909 9.36686 2.1961 10.5303C3.57421 11.6623 5.39861 12.2857 7.33326 12.2857C7.59105 12.2857 7.85042 12.2743 8.10762 12.2517L8.54704 12.6325C10.0608 13.9443 11.9968 14.6664 13.9999 14.6665L14.6665 14.6665L14.6665 13.2475L14.4713 13.0523C13.701 12.2798 13.0907 11.363 12.6751 10.3544ZM9.42028 11.6249L8.53858 10.8607L8.24458 10.8967C7.94221 10.9337 7.63788 10.9522 7.33326 10.9523C4.02496 10.9523 1.33332 8.79474 1.33332 6.14281C1.33332 3.49088 4.02496 1.33332 7.33326 1.33332C10.6416 1.33332 13.3332 3.49084 13.3332 6.14281C13.3332 7.46405 12.674 8.69753 11.4771 9.61602L11.0932 9.91065L11.2703 10.4121C11.6334 11.4362 12.1671 12.3916 12.8487 13.2378C11.5793 13.0259 10.3929 12.4678 9.42028 11.6249Z",
          fill: "rgba(48,53,69,1)",
          fillRule: "nonzero",
          style: { transform: "translate(26.67%, 26.67%)" },
        },
        {
          d: "M1.84102 12.5614C2.46507 11.7714 2.95504 10.8843 3.29134 9.93544L3.46717 9.43648L3.08355 9.14207C1.9549 8.276 1.33332 7.11359 1.33332 5.86898C1.33332 3.36801 3.87533 1.33332 6.99993 1.33332C9.29857 1.33332 11.2818 2.43456 12.1697 4.01246C12.3346 4.00446 12.5002 3.99996 12.6665 3.99996C13.0006 3.99996 13.3316 4.01617 13.6596 4.04858C13.3165 3.15963 12.7206 2.34814 11.9013 1.67523C10.5862 0.594952 8.84574 0 6.99993 0C5.15411 0 3.41363 0.594952 2.09848 1.67523C0.745242 2.78685 0 4.2762 0 5.86898C0 7.36063 0.681326 8.79791 1.8854 9.87906C1.49246 10.8247 0.918305 11.6843 0.19529 12.4094L0 12.6047L0 13.9999L0.66666 13.9999C1.89944 13.9997 3.11527 13.7126 4.21791 13.1613C4.097 12.703 4.02616 12.233 4.00663 11.7595C3.33737 12.1517 2.60433 12.4231 1.84102 12.5614Z",
          fill: "rgba(48,53,69,1)",
          fillRule: "nonzero",
          style: { transform: "translate(0%, 0%)" },
        },
      ]}
      {...getOverrideProps(overrides, "ChatIcon")}
      {...rest}
    ></Icon>
  );
}
