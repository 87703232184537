import { CompanyGroups } from './models'

export const SAFE_INPUT_CLASS = 'safe-input'
export const PRIVATE_INPUT_CLASS = 'private-input'
export type eString = string | undefined | null

export type Option = {
  readonly value: string
  readonly label: string
}

export type CreateWorkbookRes = {
  workbooks: WorkbookInfo
}

export type CreateGoogleSheetsRes = {
  googleSheetIds: GoogleSheetInfo
}

export type CreateGoogleSheetRes = {
  googleSheetId: string
}

export type PublicCompsRes = {
  comps: CompanyGroups[]
}

export type OpportunityRes = {
  tableauProjectId?: string
  workbooksInfo: WorkbookInfo
  marketComps: string[]
}

export type TransactionModelRes = {
  transactionModelGoogleSheetId: string
}

export type GoogleSheetInfo = {
  [x: string]: string
}

export type CyberPostRes = {
  workbooksInfo: WorkbookInfo
  googleSheetIds: GoogleSheetInfo
}

export type WorkbookInfo = {
  [x: string]: {
    workbook: Workbook
  }
}

export type GetGoogleSheetRes = {
  [x: string]: {
    range: string
    values: string[][]
  }
}

export type Workbook = {
  project: Owner
  owner: Owner
  tags: Tags
  views: Views
  dataAccelerationConfig: DataAccelerationConfig
  id: string
  name: string
  description: string
  contentUrl: string
  webpageURL: string
  showTabs: string
  size: string
  createdAt: Date
  updatedAt: Date
  encryptExtracts: string
  defaultViewID: string
}

export type DataAccelerationConfig = {
  accelerationEnabled: boolean
}

export type Owner = {
  id: string
  name: string
}

export type Tags = {}

export type Views = {
  view: View[]
}

export type View = {
  tags: Tags
  id: string
  name: string
  contentUrl: string
  createdAt: Date
  updatedAt: Date
  viewUrlName: string
}

export type ExistingCommon = {
  type: 'SHARE_HOLDER_TYPE_COMMON'
}

export type ExistingOption = {
  type: 'SHARE_HOLDER_TYPE_OPTION'
  strikePrice: number
  interestRate: number
  convertTo?: {
    shareholderName: string
    group: string
  }
}
export type ProformaData = {
  detailData: ProformaCapTableRow[]
  summaryData: ProformaCapTableRow[]
}
export type ProformaCapTableRow = {
  shareholderName?: string
  group?: string
  SecurityType: string
  NumberOfShares?: number
  PercentOfTotalFullyDilutedShares?: number
  AmountContributed?: number
  NumberOfSharesAddedOrLost?: number
  AmountContributedOrSold?: number
  PostSecurityType?: string
  PostNumberOfShares?: number
  PostPercentOfTotalFullyDilutedShares?: number
  PostAmountContributedNetOfSold?: number
  PostEntryPricePerShare?: number
  PostStrikePrice?: number | null
}

export type ExistingWarrant = Omit<ExistingOption, 'type'> & { type: 'SHARE_HOLDER_TYPE_WARRANT' }
export type DividendTypes = 'DIVIDEND_NON_CUMULATIVE' | 'DIVIDEND_CUMULATIVE' | 'DIVIDEND_NOT_DECLARED' | 'DIVIDEND_NA'
export type ShareholderTypes = 'SHARE_HOLDER_TYPE_WARRANT' | 'SHARE_HOLDER_TYPE_SERIES' | 'SHARE_HOLDER_TYPE_OPTION' | 'SHARE_HOLDER_TYPE_COMMON'
export type ParticipationTypes =
  | 'PARTICIPATION_RIGHTS_NON_PARTICIPATING'
  | 'PARTICIPATION_RIGHTS_PARTICIPATING_FULL'
  | 'PARTICIPATION_RIGHTS_PARTICIPATING_WITH_CAP'
  | 'PARTICIPATION_RIGHTS_NA'

export type PrimaryInvestmentInputSeries = PrimaryInvestmentBaseInput & {
  liquidationMultiple: number
  participationType: ParticipationTypes
  dividendType: DividendTypes
  interestRate: number
  conversionRatio: number
  pricePerShare?: number
}

export type PrimaryInvestmentInputOption = PrimaryInvestmentBaseInput & {
  strikePrice: number
  interestRate: number
}

export type PrimaryInvestmentInputWarrant = PrimaryInvestmentBaseInput & {
  strikePrice: number
  interestRate: number
}

export type PrimaryInvestmentBaseInput = {
  type: ShareholderTypes
  group: string
  shareholderName: string
  exitDate: Date
  investmentDate: Date
  totalContribution: number
  capOnParticipation: number
}

export type ConvertibleNoteInput = {
  group: string
  shareholderName: string
  valuation: number
  convertDate: Date
  maturityDate: Date
  amountInvested: number
  investmentDate: Date
  dividendType: DividendTypes
  interestRate: number
  convertTo: Omit<PrimaryInvestmentBaseInput, 'shareholderName' | 'group' | 'shareCount' | 'pricePerShare' | 'investmentDate' | 'totalContribution'>
}

export type PrimaryInvestmentInput = PrimaryInvestmentInputSeries | PrimaryInvestmentInputOption | PrimaryInvestmentInputWarrant

export type ExistingPreferred = {
  type: 'SHARE_HOLDER_TYPE_SERIES'
  pricePerShare: number
  conversionRatio: number
  liquidationMultiple: number
  participationType: ParticipationTypes
  capOnParticipation?: number
  dividendType: DividendTypes
  interestRate?: number
}

export type ExistingShareHolder = (ExistingCommon | ExistingWarrant | ExistingPreferred | ExistingOption) & {
  exitDate: Date
  group: string
  shareholderName: string
  shareCount: number
  investmentDate: Date
}

export type ShareHolderType = {
  type: ShareholderTypes
  group: string
  shareholderName: string
  shareCount: number
  pricePerShare: number
  conversionRatio: number
  liquidationMultiple: number
  strikePrice: number | null
  seniority: number | null
  participationType: ParticipationTypes
  capOnParticipation: number
  exitDate: Date
  investmentDate: Date
  dividendType: DividendTypes
  interestRate: number
  amountInvested?: number
}

export type CapTableType = ShareHolderType[]

export type CapTableRes = {
  pricePerShare: number
  capTable: CapTableType
  proformaTableData: ProformaData
}

export const PRIMARY_INVESTMENT_KEY = 'primaryInvestment'
export const CONVERTIBLE_NOTE_KEY = 'CONVERTIBLE_NOTE_KEY'
export const SECONDARY_INVESTMENT_KEY = 'secondaryInvestment'
export const NEW_INVESTOR_KEY = 'newInvestor'
export const MONITORING_KEY = 'monitoring'
export const LOAN_KEY = 'loans'

export type AutocompleteOption = {
  id: string
  label: string
  img?: string
  shortDescription: string
  isNew?: boolean
}

export type DocumentMetadata = {
  source?: string
  type?: string
  date?: string
  loc?: {
    pageNumber?: number
    lines?: {
      from: number
      to: number
    }
  }
}

export type DocumentQuery = {
  pageContent: string
  metadata: DocumentMetadata
  score?: number
}

export class Error404 extends Error {}
