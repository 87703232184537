import { FunctionComponent } from 'react'
import { Flex, Image, Loader } from '@aws-amplify/ui-react'
import { useOpportunity } from '../contexts/opportunityContext'
import { useCompanyInfoV2 } from '../hooks/useCompanyInfoV2'

const OpportunityName: FunctionComponent = () => {
  const opportunity = useOpportunity()
  const [companyInfo, loading] = useCompanyInfoV2(opportunity?.name)

  return (
    <Flex className='opportunityName'>
      {
        loading && <Loader />
      }
      {
        !loading && companyInfo &&
        <Flex gap='0.9rem'>
          {companyInfo.iconUrl && <Image alt='company image' src={companyInfo.iconUrl} maxHeight={20} />}
          {companyInfo.companyLabel || companyInfo.companyName}
        </Flex>
      }
    </Flex>
  )
}

export default OpportunityName
