import { Table, TableBody, TableCell, TableHead, TableRow } from '@aws-amplify/ui-react'
import { FunctionComponent } from 'react'
import { currencyFormatterShort } from '../utils/utils'

type CapitalRaiseData = {
  announceDate?: Date
  name?: string
  fundingType?: string
  leadInvestors?: string
  amountRaised?: number
  preMoneyValuation?: number
}
type CapitalRaiseTrxTableProps = {
  data: CapitalRaiseData[]
}

const CapitalRaiseTrxTable: FunctionComponent<CapitalRaiseTrxTableProps> = ({ data }) => {
  const tableBody = data.map((d) => {
    return (
      <TableRow key={`${d.name} ${d?.announceDate}`}>
        <TableCell>{d?.announceDate?.toLocaleString('en-US', { year: 'numeric', month: 'long' }) || 'N/A'}</TableCell>
        <TableCell>{d?.name}</TableCell>
        <TableCell>{d?.fundingType || 'Unknown'}</TableCell>
        <TableCell>{d?.leadInvestors || 'Unknown'}</TableCell>
        <TableCell>{currencyFormatterShort(d.amountRaised) || ''}</TableCell>
        <TableCell>{currencyFormatterShort(d.preMoneyValuation) || ''}</TableCell>
      </TableRow>
    )
  })
  return (
    <Table caption='' highlightOnHover={true} variation='striped' size='small'>
      <TableHead>
        <TableRow>
          <TableCell as='th'>Year Announced</TableCell>
          <TableCell as='th'>Org Name</TableCell>
          <TableCell as='th'>Funding Type</TableCell>
          <TableCell as='th'>Lead Investors</TableCell>
          <TableCell as='th'>Amount Raised</TableCell>
          <TableCell as='th'>($) Pre-Money Valuation</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{tableBody}</TableBody>
    </Table>
  )
}

export default CapitalRaiseTrxTable
