/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function AiOutlineTag(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="24px"
      height="24px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 24, height: 24 }}
      paths={[
        {
          d: "M19.9693 8.7375L19.2756 1.41094C19.2404 1.03125 18.9381 0.73125 18.5584 0.69375L11.2318 0L11.2224 0C11.1474 0 11.0889 0.0234375 11.0443 0.0679688L0.0685415 11.0438C0.0468141 11.0654 0.0295763 11.0912 0.0178151 11.1195C0.00605377 11.1479 0 11.1783 0 11.209C0 11.2397 0.00605377 11.2701 0.0178151 11.2984C0.0295763 11.3268 0.0468141 11.3525 0.0685415 11.3742L8.5951 19.9008C8.63964 19.9453 8.69823 19.9688 8.76151 19.9688C8.82479 19.9688 8.88339 19.9453 8.92792 19.9008L19.9037 8.925C19.9506 8.87578 19.974 8.80781 19.9693 8.7375L19.9693 8.7375ZM8.75917 17.5477L2.42167 11.2102L11.7896 1.84219L17.5787 2.39062L18.1271 8.17969L8.75917 17.5477ZM13.9224 3.98438C12.7857 3.98438 11.8599 4.91016 11.8599 6.04688C11.8599 7.18359 12.7857 8.10938 13.9224 8.10938C15.0592 8.10938 15.9849 7.18359 15.9849 6.04688C15.9849 4.91016 15.0592 3.98438 13.9224 3.98438ZM13.9224 6.79688C13.5076 6.79688 13.1724 6.46172 13.1724 6.04688C13.1724 5.63203 13.5076 5.29688 13.9224 5.29688C14.3373 5.29688 14.6724 5.63203 14.6724 6.04688C14.6724 6.46172 14.3373 6.79688 13.9224 6.79688Z",
          fill: "rgba(61,67,87,1)",
          fillRule: "nonzero",
          style: { transform: "translate(8.4%, 8.4%)" },
        },
      ]}
      {...getOverrideProps(overrides, "AiOutlineTag")}
      {...rest}
    ></Icon>
  );
}
