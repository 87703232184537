import { FunctionComponent } from 'react'
import MyDocumentsView from '../figma-components/MyDocumentsView'
import { Opportunity } from '../models'
import { deleteFileFromOpportunity } from '../services/fileStorage'
import { restAPIRequest } from '../services/restApiRequest'
import { sendSlack } from '../services/slack'
import { OPPORTUNITY_OVERRIDE_UPDATE, getRunningTasks } from '../services/tasks'
import { removeDuplicateValues } from '../utils/utils'

interface MyDocumentsProps {
  opportunity: Opportunity
  docTag: string
  [x: string]: any
}

const MyDocuments: FunctionComponent<MyDocumentsProps> = ({ opportunity, docTag }) => {
  const opportunityId = opportunity?.id
  const currentTask = getRunningTasks(OPPORTUNITY_OVERRIDE_UPDATE, opportunity.tasks)
  const currentArgs = JSON.parse(currentTask?.args || '{}')
  const overrideTaskRunning = !!currentTask
  const documents = currentArgs?.files || opportunity.files || []
  const selectedDocs = documents.filter((d) => (d?.tags || []).includes(docTag || 'n/a')).map((d) => d.s3Key)
  const updateSelected = async (s3Keys) => {
    if (!opportunityId) return console.error('No opportunityId')
    const type = s3Keys.length > 0 ? 'override' : null
    const out = Opportunity.copyOf(opportunity, (updated) => {
      if (!updated.overrides) updated.overrides = {}
      updated.overrides.sourceTypes = {
        ...(updated.overrides?.sourceTypes || {}),
        [docTag]: type,
      }
      updated.files = (updated.files || []).map((f) => {
        if (s3Keys.includes(f.s3Key)) {
          f.tags = removeDuplicateValues([...(f.tags || []), docTag])
        }
        if (s3Keys.length < 1) {
          f.tags = (f.tags || []).filter((t) => t !== docTag)
        }
        return f
      })
    })
    const body = {
      files: out.files,
      sourceTypes: out.overrides?.sourceTypes,
    }
    try {
      await restAPIRequest({
        method: 'post',
        path: `deals/${opportunityId}/update`,
        body,
      })
    } catch (e: any) {
      console.error(e)
      alert('We hit a snag updating the page. Please try again later.')
      sendSlack(`ERROR: POST: deals/${opportunityId}/update message: ${e?.message} body: ${JSON.stringify(body, null, 2)})}`)
    }
  }

  return (
    <MyDocumentsView
      selectedDocs={selectedDocs}
      sending={overrideTaskRunning}
      updateClicked={(s3Keys) => updateSelected(s3Keys)}
      deleteClicked={(s3Key) => deleteFileFromOpportunity(opportunity, s3Key)}
      docs={documents}
      flex={1}
      level={3}
    />
  )
}

export default MyDocuments
