import { Flex, Text, Image } from '@aws-amplify/ui-react'
import { FunctionComponent } from 'react'
import ProfileSearch from '../components/ProfileSearch'
import GlassCard from '../figma-components/GlassCard'

import '../styles/view.css'

interface SearchPageProps {}

const SearchPage: FunctionComponent<SearchPageProps> = () => {
  return (
    <GlassCard header='' className='homePageSearchCard'>
      <Flex direction='column' gap='2rem' className='homePageSearch'>
        <Flex direction='column' alignItems='center' gap='1rem'>
          <Image src='/logoSemiColoredBlack.svg' alt='logo-colored' width='16rem' />
          <Text>We leverage AI to help you research companies and execute deals more quickly</Text>
        </Flex>
        <ProfileSearch enableNavigate={true} placeholder='Search...' />
      </Flex>
    </GlassCard>
  )
}

export default SearchPage
