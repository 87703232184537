import { useQueries } from '@tanstack/react-query'
import { ProductDBTable, queryConfigTable } from './useConfigTable'

type ListMarketRes = {
  targetMarketName: string
  data: ProductDBTable[] | undefined
  isLoading: boolean
  error: Error | undefined
}

export function useMarketMapList(targetMarketNames: string[]): ListMarketRes[] {
  targetMarketNames.sort((a, b) => a.localeCompare(b))
  const q = useQueries({
    queries: targetMarketNames.map((id) => {
      return {
        enabled: targetMarketNames.length > 0,
        queryKey: ['marketName', id],
        queryFn: queryConfigTable('product_db', {
          key: 'markets',
          op: 'like',
          value: `%${id}%`,
        }),
        staleTime: 1000 * 60 * 60 * 24,
      }
    }),
  })

  return q.map((res, i) => ({
    targetMarketName: targetMarketNames[i],
    data: res.data,
    isLoading: res.isLoading,
    error: res.error as Error | undefined,
  }))
}
