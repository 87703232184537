import { Alert, Flex, Loader, Text } from '@aws-amplify/ui-react'
import { ChangeEventHandler, useState } from 'react'
import * as XLSX from 'xlsx'
import { CellValues } from '../sheets'
import '../styles/file.css'

type InputUploadProps = {
  onUpload: (x: CellValues[][]) => void
}

const ExcelUpload = ({ onUpload }: InputUploadProps) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const onFileChange: ChangeEventHandler<HTMLInputElement> = async (e) => {
    var file = e.target?.files?.[0]
    if (!file) {
      return alert('select a file')
    }
    if (loading) return alert('already uploading')
    setLoading(true)
    var reader = new FileReader()
    reader.onload = async function (e) {
      var data = e?.target?.result
      if (!data) {
        return alert('invalid file, could not read')
      }

      var workbook = XLSX.read(data, {
        cellDates: true,
      })
      try {
        const aoa = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1, blankrows: false }) as CellValues[][]
        onUpload(aoa)
      } catch {
        setError('We could not read the file. Please make sure it is in the correct format.')
      } finally {
        setLoading(false)
      }
    }
    reader.readAsArrayBuffer(file)
  }

  const uploadInput = () => {
    if (loading) {
      return (
        <Flex>
          <Loader />
          <Text>Loading File ...</Text>
        </Flex>
      )
    }
    return <input type='file' accept='.xlsx, .xls' onChange={onFileChange} />
  }

  return (
    <Flex direction='column'>
      {error && <Alert variation='error'>{error}</Alert>}
      <Flex alignItems='center'>{uploadInput()}</Flex>
    </Flex>
  )
}

export default ExcelUpload
