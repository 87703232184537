import { Card, Flex } from '@aws-amplify/ui-react'
import { FunctionComponent, ReactNode } from 'react'

type MissingDataCardProps = {
  children: ReactNode
  alignItems?: string
  justifyContent?: string
}
const MissingDataCard: FunctionComponent<MissingDataCardProps> = ({ children, alignItems = 'center', justifyContent = 'center' }) => {
  return (
    <Flex justifyContent={justifyContent} alignItems={alignItems} height='40%'>
      <Card variation='elevated' textAlign='center'>
        {children}
      </Card>
    </Flex>
  )
}
export default MissingDataCard
