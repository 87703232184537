/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getOpportunity = /* GraphQL */ `query GetOpportunity($id: ID!) {
  getOpportunity(id: $id) {
    id
    orgName
    name
    transactionName
    transactionTypes
    uploadDate
    marketMapCompSet
    marketMapSelectedMetrics
    publicCompsCompSetV2 {
      name
      groupName
      __typename
    }
    owner
    transactionAcquisitionCompSet
    transactionCapitalRaiseCompSet
    transactionModelsV1
    competitiveLandscapeDashboards {
      url
      name
      __typename
    }
    capTableV1
    totalEquityRaised
    lastPreMoneyValuation
    latestDealDate
    files {
      name
      s3Key
      group
      dateUploaded
      vectorDbId
      docType
      tags
      __typename
    }
    financialData {
      public_company_2020_revenue
      public_company_2021_revenue
      public_company_2022_revenue
      public_company_2023_analyst_revenue_estimates
      public_company_ltm_revenue_m
      public_company_ntm_revenue_m
      public_company_arr_m
      public_company_ga_percent
      public_company_gross_margin
      public_company_gross_profit
      public_company_ebitda
      public_company_ebitda_percent
      public_company_ltm_ebitda
      public_company_ltm_ebitda_margin
      public_company_ntm_ebitda
      public_company_ltm_fcf
      public_company_ltm_fcf_percent
      public_company_rd_percent
      public_company_sm_percent
      public_company_total_cash
      public_company_ltm_revenue_growth
      public_company_ntm_revenue_growth
      public_company_arr_growth
      public_company_arr_per_customer_k
      public_company_gross_retention
      public_company_net_dollar_retention
      public_company_ltm_free_cash_flow_growth_percent
      public_company_growth_persistence
      public_company_net_new_arr
      public_company_net_new_arr_growth_percent
      public_company_arr_employee_k
      public_company_annualized_opex_employee
      public_company_burn_multiple
      public_company_ltm_magic_number
      public_company_operating_income_percent
      public_company_rule_of_40_ltm_fcf
      public_company_rule_of_40_op_in_margin
      public_company_rule_of_40_quarter_fcf
      public_company_sales_and_marketing_yield
      public_company_implied_5yr_ltv_cac
      __typename
    }
    financialDataV2 {
      type
      year
      quarter
      month
      totalRevenue
      totalRevGrowthPercent
      totalARR
      arrGrowthPercent
      totalGrossProfit
      grossMarginPercent
      totalEBITDA
      eBITDAMarginPercent
      totalFCF
      fcfMarginPercent
      gnaAsPercentOfRev
      snmAsPercentOfRev
      rndAsPercentOfRev
      grossRetentionPercent
      netRetentionPercent
      salesEfficiencyMultiple
      netNewARR
      netNewARRGrowthPercent
      arrPerEmployee
      annualizedOpexPerEmployee
      burnMultiple
      operatingIncomePercent
      ruleOf40LTMFCF
      ruleOf40LTMEbitda
      salesAndMarketingYield
      implied5yrLTVPerCAC
      __typename
    }
    overrides {
      sourceTypes {
        overview
        products
        customerDetail
        marketSummary
        competitiveLandscape
        investmentDetails
        __typename
      }
      products {
        name
        description
        shortDescription
        type
        featuresV2
        features
        useCases
        revenueModel
        deliveryModel
        targetCustomerProfile
        sources
        markets
        taxonomiesV2
        mcKinseyMarketCategory
        classification
        objectStateJson
        __typename
      }
      productRanks
      caseStudies {
        customerName
        backgroundOnProblem
        howProductHelpedCustomer
        howCustomerJustifiedROI
        sourceLink
        __typename
      }
      marketRisks {
        summary
        details
        mitigation
        __typename
      }
      marketDrivers {
        summary
        details
        __typename
      }
      objectStateJson
      __typename
    }
    tasks {
      id
      name
      status
      args
      amplifyUserId
      orgName
      error
      startedAt
      finishedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOpportunityQueryVariables,
  APITypes.GetOpportunityQuery
>;
export const listOpportunities = /* GraphQL */ `query ListOpportunities(
  $filter: ModelOpportunityFilterInput
  $limit: Int
  $nextToken: String
) {
  listOpportunities(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      orgName
      name
      transactionName
      transactionTypes
      uploadDate
      marketMapCompSet
      marketMapSelectedMetrics
      publicCompsCompSetV2 {
        name
        groupName
        __typename
      }
      owner
      transactionAcquisitionCompSet
      transactionCapitalRaiseCompSet
      transactionModelsV1
      competitiveLandscapeDashboards {
        url
        name
        __typename
      }
      capTableV1
      totalEquityRaised
      lastPreMoneyValuation
      latestDealDate
      files {
        name
        s3Key
        group
        dateUploaded
        vectorDbId
        docType
        tags
        __typename
      }
      financialData {
        public_company_2020_revenue
        public_company_2021_revenue
        public_company_2022_revenue
        public_company_2023_analyst_revenue_estimates
        public_company_ltm_revenue_m
        public_company_ntm_revenue_m
        public_company_arr_m
        public_company_ga_percent
        public_company_gross_margin
        public_company_gross_profit
        public_company_ebitda
        public_company_ebitda_percent
        public_company_ltm_ebitda
        public_company_ltm_ebitda_margin
        public_company_ntm_ebitda
        public_company_ltm_fcf
        public_company_ltm_fcf_percent
        public_company_rd_percent
        public_company_sm_percent
        public_company_total_cash
        public_company_ltm_revenue_growth
        public_company_ntm_revenue_growth
        public_company_arr_growth
        public_company_arr_per_customer_k
        public_company_gross_retention
        public_company_net_dollar_retention
        public_company_ltm_free_cash_flow_growth_percent
        public_company_growth_persistence
        public_company_net_new_arr
        public_company_net_new_arr_growth_percent
        public_company_arr_employee_k
        public_company_annualized_opex_employee
        public_company_burn_multiple
        public_company_ltm_magic_number
        public_company_operating_income_percent
        public_company_rule_of_40_ltm_fcf
        public_company_rule_of_40_op_in_margin
        public_company_rule_of_40_quarter_fcf
        public_company_sales_and_marketing_yield
        public_company_implied_5yr_ltv_cac
        __typename
      }
      financialDataV2 {
        type
        year
        quarter
        month
        totalRevenue
        totalRevGrowthPercent
        totalARR
        arrGrowthPercent
        totalGrossProfit
        grossMarginPercent
        totalEBITDA
        eBITDAMarginPercent
        totalFCF
        fcfMarginPercent
        gnaAsPercentOfRev
        snmAsPercentOfRev
        rndAsPercentOfRev
        grossRetentionPercent
        netRetentionPercent
        salesEfficiencyMultiple
        netNewARR
        netNewARRGrowthPercent
        arrPerEmployee
        annualizedOpexPerEmployee
        burnMultiple
        operatingIncomePercent
        ruleOf40LTMFCF
        ruleOf40LTMEbitda
        salesAndMarketingYield
        implied5yrLTVPerCAC
        __typename
      }
      overrides {
        sourceTypes {
          overview
          products
          customerDetail
          marketSummary
          competitiveLandscape
          investmentDetails
          __typename
        }
        products {
          name
          description
          shortDescription
          type
          featuresV2
          features
          useCases
          revenueModel
          deliveryModel
          targetCustomerProfile
          sources
          markets
          taxonomiesV2
          mcKinseyMarketCategory
          classification
          objectStateJson
          __typename
        }
        productRanks
        caseStudies {
          customerName
          backgroundOnProblem
          howProductHelpedCustomer
          howCustomerJustifiedROI
          sourceLink
          __typename
        }
        marketRisks {
          summary
          details
          mitigation
          __typename
        }
        marketDrivers {
          summary
          details
          __typename
        }
        objectStateJson
        __typename
      }
      tasks {
        id
        name
        status
        args
        amplifyUserId
        orgName
        error
        startedAt
        finishedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOpportunitiesQueryVariables,
  APITypes.ListOpportunitiesQuery
>;
export const syncOpportunities = /* GraphQL */ `query SyncOpportunities(
  $filter: ModelOpportunityFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncOpportunities(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      orgName
      name
      transactionName
      transactionTypes
      uploadDate
      marketMapCompSet
      marketMapSelectedMetrics
      publicCompsCompSetV2 {
        name
        groupName
        __typename
      }
      owner
      transactionAcquisitionCompSet
      transactionCapitalRaiseCompSet
      transactionModelsV1
      competitiveLandscapeDashboards {
        url
        name
        __typename
      }
      capTableV1
      totalEquityRaised
      lastPreMoneyValuation
      latestDealDate
      files {
        name
        s3Key
        group
        dateUploaded
        vectorDbId
        docType
        tags
        __typename
      }
      financialData {
        public_company_2020_revenue
        public_company_2021_revenue
        public_company_2022_revenue
        public_company_2023_analyst_revenue_estimates
        public_company_ltm_revenue_m
        public_company_ntm_revenue_m
        public_company_arr_m
        public_company_ga_percent
        public_company_gross_margin
        public_company_gross_profit
        public_company_ebitda
        public_company_ebitda_percent
        public_company_ltm_ebitda
        public_company_ltm_ebitda_margin
        public_company_ntm_ebitda
        public_company_ltm_fcf
        public_company_ltm_fcf_percent
        public_company_rd_percent
        public_company_sm_percent
        public_company_total_cash
        public_company_ltm_revenue_growth
        public_company_ntm_revenue_growth
        public_company_arr_growth
        public_company_arr_per_customer_k
        public_company_gross_retention
        public_company_net_dollar_retention
        public_company_ltm_free_cash_flow_growth_percent
        public_company_growth_persistence
        public_company_net_new_arr
        public_company_net_new_arr_growth_percent
        public_company_arr_employee_k
        public_company_annualized_opex_employee
        public_company_burn_multiple
        public_company_ltm_magic_number
        public_company_operating_income_percent
        public_company_rule_of_40_ltm_fcf
        public_company_rule_of_40_op_in_margin
        public_company_rule_of_40_quarter_fcf
        public_company_sales_and_marketing_yield
        public_company_implied_5yr_ltv_cac
        __typename
      }
      financialDataV2 {
        type
        year
        quarter
        month
        totalRevenue
        totalRevGrowthPercent
        totalARR
        arrGrowthPercent
        totalGrossProfit
        grossMarginPercent
        totalEBITDA
        eBITDAMarginPercent
        totalFCF
        fcfMarginPercent
        gnaAsPercentOfRev
        snmAsPercentOfRev
        rndAsPercentOfRev
        grossRetentionPercent
        netRetentionPercent
        salesEfficiencyMultiple
        netNewARR
        netNewARRGrowthPercent
        arrPerEmployee
        annualizedOpexPerEmployee
        burnMultiple
        operatingIncomePercent
        ruleOf40LTMFCF
        ruleOf40LTMEbitda
        salesAndMarketingYield
        implied5yrLTVPerCAC
        __typename
      }
      overrides {
        sourceTypes {
          overview
          products
          customerDetail
          marketSummary
          competitiveLandscape
          investmentDetails
          __typename
        }
        products {
          name
          description
          shortDescription
          type
          featuresV2
          features
          useCases
          revenueModel
          deliveryModel
          targetCustomerProfile
          sources
          markets
          taxonomiesV2
          mcKinseyMarketCategory
          classification
          objectStateJson
          __typename
        }
        productRanks
        caseStudies {
          customerName
          backgroundOnProblem
          howProductHelpedCustomer
          howCustomerJustifiedROI
          sourceLink
          __typename
        }
        marketRisks {
          summary
          details
          mitigation
          __typename
        }
        marketDrivers {
          summary
          details
          __typename
        }
        objectStateJson
        __typename
      }
      tasks {
        id
        name
        status
        args
        amplifyUserId
        orgName
        error
        startedAt
        finishedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncOpportunitiesQueryVariables,
  APITypes.SyncOpportunitiesQuery
>;
export const getUser = /* GraphQL */ `query GetUser($amplifyUserId: String!) {
  getUser(amplifyUserId: $amplifyUserId) {
    amplifyUserId
    owner
    groups {
      name
      groupName
      __typename
    }
    listInfo {
      headers
      data {
        name
        jsonData
        __typename
      }
      __typename
    }
    websiteChatsV2 {
      target
      chat {
        role
        content
        timestamp
        sources
        __typename
      }
      __typename
    }
    docChats {
      target
      id
      selectedDocs
      chat {
        role
        content
        timestamp
        sources
        __typename
      }
      __typename
    }
    marketMapSelectedMetrics
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $amplifyUserId: String
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUsers(
    amplifyUserId: $amplifyUserId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      amplifyUserId
      owner
      groups {
        name
        groupName
        __typename
      }
      listInfo {
        headers
        data {
          name
          jsonData
          __typename
        }
        __typename
      }
      websiteChatsV2 {
        target
        chat {
          role
          content
          timestamp
          sources
          __typename
        }
        __typename
      }
      docChats {
        target
        id
        selectedDocs
        chat {
          role
          content
          timestamp
          sources
          __typename
        }
        __typename
      }
      marketMapSelectedMetrics
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const syncUsers = /* GraphQL */ `query SyncUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUsers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      amplifyUserId
      owner
      groups {
        name
        groupName
        __typename
      }
      listInfo {
        headers
        data {
          name
          jsonData
          __typename
        }
        __typename
      }
      websiteChatsV2 {
        target
        chat {
          role
          content
          timestamp
          sources
          __typename
        }
        __typename
      }
      docChats {
        target
        id
        selectedDocs
        chat {
          role
          content
          timestamp
          sources
          __typename
        }
        __typename
      }
      marketMapSelectedMetrics
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncUsersQueryVariables, APITypes.SyncUsersQuery>;
export const getOrg = /* GraphQL */ `query GetOrg($orgName: String!) {
  getOrg(orgName: $orgName) {
    orgName
    owner
    dashboards {
      url
      companyName
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetOrgQueryVariables, APITypes.GetOrgQuery>;
export const listOrgs = /* GraphQL */ `query ListOrgs(
  $orgName: String
  $filter: ModelOrgFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listOrgs(
    orgName: $orgName
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      orgName
      owner
      dashboards {
        url
        companyName
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListOrgsQueryVariables, APITypes.ListOrgsQuery>;
export const syncOrgs = /* GraphQL */ `query SyncOrgs(
  $filter: ModelOrgFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncOrgs(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      orgName
      owner
      dashboards {
        url
        companyName
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncOrgsQueryVariables, APITypes.SyncOrgsQuery>;
export const getCompanyInfoV3 = /* GraphQL */ `query GetCompanyInfoV3($companyName: String!) {
  getCompanyInfoV3(companyName: $companyName) {
    companyName
    companyLabel
    master_market_map_company_name
    isApproved
    linkedinUrl
    iconUrl
    headquartersLocation
    headquartersRegion
    ticker
    fullTimeEmployeeCount
    fullTimeEmployeeCountGrowth6m
    fullTimeEmployeeCountGrowth1y
    fullTimeEmployeeCountGrowth2y
    companyType
    marketComps
    ai_public_comps {
      name
      groupName
      __typename
    }
    cb_organizationPermalink
    cb_acquiredByLabel
    cb_acquiredByPermalink
    cb_acquisitionStatus
    cb_acquisitionTerms
    cb_acquisitionType
    cb_description
    cb_fullDescription
    cb_numberOfEmployees
    cb_fteGrowth
    cb_top5Investors
    cb_website
    cb_foundedDate
    cb_totalFundingAmount
    cb_lastFundingDate
    cb_estimatedRevenueRange
    cb_numFundingRounds
    cb_lastFundingAmount
    cb_lastFundingType
    cb_lastEquityValuation
    cb_lastEquityFundingType
    cyberdb_revenues
    cyberdbId
    ai_goToMarketChannels
    ai_investmentHighlights
    publicFinancialData {
      yearlyData {
        year
        evRevenue
        evEbitda
        revenue
        ebitda
        analystRevenueEstimates
        __typename
      }
      enterpriseValue
      price
      ruleOf40OpInMargin
      smPercent
      salesAndMarketingYield
      implied5yrLtvCac
      close52WeeksAgo
      close52WeekHigh
      close52WeekLow
      arr
      lastYearArr
      ntmRevenueGrowth
      ltmRevenue
      ltmEbitda
      ltmRevenuePrevious
      ltmEbitdaMargin
      netNewArrGrowthPercent
      netDollarRetention
      grossRetention
      grossMargin
      __typename
    }
    products {
      name
      description
      shortDescription
      type
      featuresV2
      features
      useCases
      revenueModel
      deliveryModel
      targetCustomerProfile
      sources
      markets
      taxonomiesV2
      mcKinseyMarketCategory
      classification
      objectStateJson
      __typename
    }
    productRanks
    caseStudies {
      customerName
      backgroundOnProblem
      howProductHelpedCustomer
      howCustomerJustifiedROI
      sourceLink
      __typename
    }
    marketRisks {
      summary
      details
      mitigation
      __typename
    }
    marketDrivers {
      summary
      details
      __typename
    }
    objectStateJson
    tasks {
      id
      name
      status
      args
      amplifyUserId
      orgName
      error
      startedAt
      finishedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCompanyInfoV3QueryVariables,
  APITypes.GetCompanyInfoV3Query
>;
export const listCompanyInfoV3s = /* GraphQL */ `query ListCompanyInfoV3s(
  $companyName: String
  $filter: ModelCompanyInfoV3FilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCompanyInfoV3s(
    companyName: $companyName
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      companyName
      companyLabel
      master_market_map_company_name
      isApproved
      linkedinUrl
      iconUrl
      headquartersLocation
      headquartersRegion
      ticker
      fullTimeEmployeeCount
      fullTimeEmployeeCountGrowth6m
      fullTimeEmployeeCountGrowth1y
      fullTimeEmployeeCountGrowth2y
      companyType
      marketComps
      ai_public_comps {
        name
        groupName
        __typename
      }
      cb_organizationPermalink
      cb_acquiredByLabel
      cb_acquiredByPermalink
      cb_acquisitionStatus
      cb_acquisitionTerms
      cb_acquisitionType
      cb_description
      cb_fullDescription
      cb_numberOfEmployees
      cb_fteGrowth
      cb_top5Investors
      cb_website
      cb_foundedDate
      cb_totalFundingAmount
      cb_lastFundingDate
      cb_estimatedRevenueRange
      cb_numFundingRounds
      cb_lastFundingAmount
      cb_lastFundingType
      cb_lastEquityValuation
      cb_lastEquityFundingType
      cyberdb_revenues
      cyberdbId
      ai_goToMarketChannels
      ai_investmentHighlights
      publicFinancialData {
        yearlyData {
          year
          evRevenue
          evEbitda
          revenue
          ebitda
          analystRevenueEstimates
          __typename
        }
        enterpriseValue
        price
        ruleOf40OpInMargin
        smPercent
        salesAndMarketingYield
        implied5yrLtvCac
        close52WeeksAgo
        close52WeekHigh
        close52WeekLow
        arr
        lastYearArr
        ntmRevenueGrowth
        ltmRevenue
        ltmEbitda
        ltmRevenuePrevious
        ltmEbitdaMargin
        netNewArrGrowthPercent
        netDollarRetention
        grossRetention
        grossMargin
        __typename
      }
      products {
        name
        description
        shortDescription
        type
        featuresV2
        features
        useCases
        revenueModel
        deliveryModel
        targetCustomerProfile
        sources
        markets
        taxonomiesV2
        mcKinseyMarketCategory
        classification
        objectStateJson
        __typename
      }
      productRanks
      caseStudies {
        customerName
        backgroundOnProblem
        howProductHelpedCustomer
        howCustomerJustifiedROI
        sourceLink
        __typename
      }
      marketRisks {
        summary
        details
        mitigation
        __typename
      }
      marketDrivers {
        summary
        details
        __typename
      }
      objectStateJson
      tasks {
        id
        name
        status
        args
        amplifyUserId
        orgName
        error
        startedAt
        finishedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCompanyInfoV3sQueryVariables,
  APITypes.ListCompanyInfoV3sQuery
>;
export const syncCompanyInfoV3s = /* GraphQL */ `query SyncCompanyInfoV3s(
  $filter: ModelCompanyInfoV3FilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCompanyInfoV3s(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      companyName
      companyLabel
      master_market_map_company_name
      isApproved
      linkedinUrl
      iconUrl
      headquartersLocation
      headquartersRegion
      ticker
      fullTimeEmployeeCount
      fullTimeEmployeeCountGrowth6m
      fullTimeEmployeeCountGrowth1y
      fullTimeEmployeeCountGrowth2y
      companyType
      marketComps
      ai_public_comps {
        name
        groupName
        __typename
      }
      cb_organizationPermalink
      cb_acquiredByLabel
      cb_acquiredByPermalink
      cb_acquisitionStatus
      cb_acquisitionTerms
      cb_acquisitionType
      cb_description
      cb_fullDescription
      cb_numberOfEmployees
      cb_fteGrowth
      cb_top5Investors
      cb_website
      cb_foundedDate
      cb_totalFundingAmount
      cb_lastFundingDate
      cb_estimatedRevenueRange
      cb_numFundingRounds
      cb_lastFundingAmount
      cb_lastFundingType
      cb_lastEquityValuation
      cb_lastEquityFundingType
      cyberdb_revenues
      cyberdbId
      ai_goToMarketChannels
      ai_investmentHighlights
      publicFinancialData {
        yearlyData {
          year
          evRevenue
          evEbitda
          revenue
          ebitda
          analystRevenueEstimates
          __typename
        }
        enterpriseValue
        price
        ruleOf40OpInMargin
        smPercent
        salesAndMarketingYield
        implied5yrLtvCac
        close52WeeksAgo
        close52WeekHigh
        close52WeekLow
        arr
        lastYearArr
        ntmRevenueGrowth
        ltmRevenue
        ltmEbitda
        ltmRevenuePrevious
        ltmEbitdaMargin
        netNewArrGrowthPercent
        netDollarRetention
        grossRetention
        grossMargin
        __typename
      }
      products {
        name
        description
        shortDescription
        type
        featuresV2
        features
        useCases
        revenueModel
        deliveryModel
        targetCustomerProfile
        sources
        markets
        taxonomiesV2
        mcKinseyMarketCategory
        classification
        objectStateJson
        __typename
      }
      productRanks
      caseStudies {
        customerName
        backgroundOnProblem
        howProductHelpedCustomer
        howCustomerJustifiedROI
        sourceLink
        __typename
      }
      marketRisks {
        summary
        details
        mitigation
        __typename
      }
      marketDrivers {
        summary
        details
        __typename
      }
      objectStateJson
      tasks {
        id
        name
        status
        args
        amplifyUserId
        orgName
        error
        startedAt
        finishedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCompanyInfoV3sQueryVariables,
  APITypes.SyncCompanyInfoV3sQuery
>;
export const getMarketInfoV1 = /* GraphQL */ `query GetMarketInfoV1($marketName: String!) {
  getMarketInfoV1(marketName: $marketName) {
    marketName
    description
    drivers
    restraints
    opportunities
    challenge
    cagr
    fromYear
    toYear
    fromValue
    toValue
    publishedDate
    sources
    objectStateJson
    tasks {
      id
      name
      status
      args
      amplifyUserId
      orgName
      error
      startedAt
      finishedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMarketInfoV1QueryVariables,
  APITypes.GetMarketInfoV1Query
>;
export const listMarketInfoV1s = /* GraphQL */ `query ListMarketInfoV1s(
  $marketName: String
  $filter: ModelMarketInfoV1FilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listMarketInfoV1s(
    marketName: $marketName
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      marketName
      description
      drivers
      restraints
      opportunities
      challenge
      cagr
      fromYear
      toYear
      fromValue
      toValue
      publishedDate
      sources
      objectStateJson
      tasks {
        id
        name
        status
        args
        amplifyUserId
        orgName
        error
        startedAt
        finishedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMarketInfoV1sQueryVariables,
  APITypes.ListMarketInfoV1sQuery
>;
export const syncMarketInfoV1s = /* GraphQL */ `query SyncMarketInfoV1s(
  $filter: ModelMarketInfoV1FilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMarketInfoV1s(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      marketName
      description
      drivers
      restraints
      opportunities
      challenge
      cagr
      fromYear
      toYear
      fromValue
      toValue
      publishedDate
      sources
      objectStateJson
      tasks {
        id
        name
        status
        args
        amplifyUserId
        orgName
        error
        startedAt
        finishedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMarketInfoV1sQueryVariables,
  APITypes.SyncMarketInfoV1sQuery
>;
