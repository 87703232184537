import { Flex, Grid, Text, Link } from '@aws-amplify/ui-react'
import { FunctionComponent } from 'react'
import ReactMarkdown from 'react-markdown'
import { CaseStudy } from '../models'
import GlassCard from '../figma-components/GlassCard'

interface Props {
  caseStudies: CaseStudy[]
}

const CustomerDetail: FunctionComponent<Props> = ({ caseStudies }) => {
  if (!caseStudies.length) {
    return (
      <GlassCard header='Not available'>
        <Text>No case studies available</Text>
      </GlassCard>
    )
  }

  const study = (study: CaseStudy) => (
    <GlassCard level={1} header={study.customerName || 'N/A'}>
      <Grid templateColumns='1fr 1fr' templateRows='1fr 1fr' gap='1rem'>
        <GlassCard level={2} header='Background' className='customerCard overlayCard'>
          <ReactMarkdown>{study.backgroundOnProblem || 'N/A'}</ReactMarkdown>
        </GlassCard>
        <GlassCard level={2} header='Benefits' className='customerCard overlayCard'>
          <ReactMarkdown>{study.howProductHelpedCustomer || 'N/A'}</ReactMarkdown>
        </GlassCard>
        <GlassCard level={2} header='ROI' className='customerCard overlayCard'>
          <ReactMarkdown>{study.howCustomerJustifiedROI || 'N/A'}</ReactMarkdown>
        </GlassCard>
        <GlassCard level={2} header='Source' className='customerCard overlayCard'>
          {study.sourceLink ? (
            <Link isExternal={true} href={study.sourceLink}>
              {study.sourceLink}
            </Link>
          ) : (
            'N/A'
          )}
        </GlassCard>
      </Grid>
    </GlassCard>
  )
  return (
    <Flex direction='column' gap='1.5rem'>
      { caseStudies.map((i) => study(i)) }
    </Flex>
  )
}
export default CustomerDetail
