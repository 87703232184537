import { Button, Flex } from '@aws-amplify/ui-react'
import { FunctionComponent, useMemo, useState } from 'react'

import { ColumnDef } from '@tanstack/react-table'
import '../styles/comps.css'
import InputTable from './InputTable'
import ProfileSearch from './ProfileSearch'
import { Option } from '../types'

const SelectCol = [
  {
    header: 'Name',
    accessorKey: 'label',
    meta: {
      type: 'viewOnlyString',
    },
  },
  {
    header: '',
    accessorKey: 'delete',
    enableSorting: false,
    enableGrouping: false,
    meta: {
      type: 'deleteButton',
    },
  },
]

type ProfileSearchMultiProps = {
  initSelected: string[] | Option[]
  isSending: boolean
  placeHolder: string
  saveButtonText?: string
  submit: (items: Option[], extraData?: Record<string, Record<string, string>>) => void
  extraCol?: ColumnDef<Option, Option>
  initExtraData?: Record<string, Record<string, string>>
}

function normalizeOptions(opts: string[] | Option[], selectedOptions: Option[]): Option[] {
  let out = opts

  if (typeof out[0] === 'string') {
    out = opts.map((v) => {
      return {
        value: v,
        label: v,
      }
    }) as Option[]
  }
  out = out as Option[]
  return out.filter((opt) => {
    return !selectedOptions.find((c) => {
      return c.value === opt.value
    })
  })
}

const ProfileSearchMulti: FunctionComponent<ProfileSearchMultiProps> = ({ initSelected, initExtraData, isSending, submit, extraCol, placeHolder, saveButtonText }) => {
  const cols = useMemo(() => {
    return [...SelectCol, ...(extraCol ? [extraCol] : [])]
  }, [extraCol])
  const [selectedOptions, setSelectedOptions] = useState<Option[]>(normalizeOptions(initSelected, []))
  const [extraData, setExtraData] = useState<Record<string, Record<string, string>>>(initExtraData || {})

  const data = selectedOptions.map((opt) => {
    return {
      ...opt,
      ...extraData[opt.value],
    }
  })

  const removeOption = (opt: Option) => {
    setSelectedOptions((opts) => {
      return [
        ...opts.filter((c) => {
          return opt.value !== c.value
        }),
      ]
    })
  }

  return (
    <Flex direction='column'>
      <Flex alignItems='flex-end'>
        <ProfileSearch
          setSelected={(e) => {
            if (!e) return
            setSelectedOptions([{ value: e.id, label: e.label }, ...selectedOptions])
          }}
          placeholder={placeHolder}
        />
        <Button isLoading={isSending} variation='primary' size='small' margin='0' className='save' onClick={() => submit(selectedOptions, extraData)}>
          {isSending ? 'Sending' : saveButtonText || 'Select Comp Set'}
        </Button>
      </Flex>
      <InputTable
        data={data}
        columns={cols}
        updateData={(rowIndex, colIndex, value) => {
          const item = selectedOptions[rowIndex]

          setExtraData({
            ...extraData,
            [item.value]: {
              ...(extraData[item.value] || {}),
              [colIndex]: value as string,
            },
          })
        }}
        deleteRow={(rowIndex) => {
          const item = selectedOptions[rowIndex]
          removeOption(item)
        }}
      />
    </Flex>
  )
}

export default ProfileSearchMulti
