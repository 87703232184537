import { useQuery } from '@tanstack/react-query'
import { restAPIRequest } from '../services/restApiRequest'
import { ExistingShareHolder } from '../types'

export type AITransactionInfoTable = {
  playbook_transaction_name: string
  description: string
}

export type AIInfoTable = {
  is_smb: boolean
  is_mid_market: boolean
  is_enterprise: boolean
  market_size_est: number
  num_direct_competitors: number
  market_growth_est: number
  forecasted_cagr: string
  market_size: string
  customer_use_cases: string
  customer_studies: string
  acquisition_rationale: string
  investment_highlights: string
  transactionscomps: string
  transactions_comps: string
  level_of_fragmentation: string
  num_direct_competitors_range: string
  customer_base_category: string
  customer_satisfaction: string
  customer_review_count: string
  customer_reviews: string
  short_description: string
  name: string
  public_comps: string
  description: string
  products: string
  market_description: string
  growth_drivers: string
  market_risks: string
  go_to_market_channels: string
}

export type CapitalRaiseTransactionTable = {
  playbook_transaction_name: string
  announced_date: Date
  lead_investors: string
  money_raised_currency_in_usd: number
  pre_money_valuation_currency_in_usd: number
  transaction_name: string
  transaction_name_url: string
  organization_location: string
  organization_name: string
  organization_name_url: string
  funding_type: string
  money_raised: number
  money_raised_currency: string
  organization_industries: string
  funding_stage: string
  investor_names: string
  number_of_investors: number
  number_of_partner_investors: number
  pre_money_valuation: number
  pre_money_valuation_currency: string
}

export type AcquisitionTransactionTable = {
  playbook_transaction_name: string
  announced_date: Date
  acquiree_name?: string
  acquisition_type?: string
  acquirer_name?: string
  acquisition_terms?: string
  price_currency_in_usd?: number
}

export type ConfigTableStates<T> = {
  get: T
}

export type PublicCompsCurrentTable = {
  public_company_last_filing: string
  row: number
  bucketing?: string
  master_market_map_company_name: string
  // Financial
  public_company_2020_revenue?: number
  public_company_2021_revenue?: number
  public_company_2022_revenue?: number
  public_company_2023_analyst_revenue_estimates?: number
  public_company_2024_analyst_revenue_estimates?: number
  public_company_ltm_revenue_m?: number
  public_company_arr_m?: number
  public_company_ga_percent?: number
  public_company_gross_margin?: number
  public_company_gross_profit?: number
  public_company_ebitda?: number
  public_company_ebitda_percent?: number
  public_company_ltm_ebitda?: number
  public_company_ltm_ebitda_margin?: number
  public_company_ltm_fcf?: number
  public_company_ltm_fcf_percent?: number
  public_company_rd_percent?: number
  public_company_sm_percent?: number
  public_company_total_cash?: number
  // Growth
  public_company_ltm_revenue_growth?: number
  public_company_ntm_revenue_growth?: number
  public_company_arr_growth?: number
  public_company_arr_per_customer_k?: number
  public_company_gross_retention?: number
  public_company_net_dollar_retention?: number
  public_company_ltm_free_cash_flow_growth_percent?: number
  public_company_growth_persistence?: number
  public_company_net_new_arr?: number
  public_company_net_new_arr_growth_percent?: number
  // Efficiency
  public_company_arr_employee_k?: number
  public_company_annualized_opex_employee?: number
  public_company_burn_multiple?: number
  public_company_ltm_magic_number?: number
  public_company_operating_income_percent?: number
  public_company_rule_of_40_ltm_fcf?: number
  public_company_rule_of_40_op_in_margin?: number
  public_company_rule_of_40_quarter_fcf?: number
  public_company_sales_and_marketing_yield?: number
  public_company_implied_5yr_ltv_cac?: number
  // Extra
  public_company_enterprise_value?: number
  public_company_percent_change_52_weeks?: number
  public_company_percent_of_52_week_high?: number
  public_company_percent_of_52_week_low?: number
  public_company_price: number
  close_52_weeks_ago: number
  close_52_week_high: number
  close_52_week_low: number
  ebitda_2023: number
  ebitda_2024: number
}

export type ProductDBTable = {
  company_name: string
  company_label: string
  product_name: string
  short_description: string
  markets: string
  taxonomies: string
}

export type ProductEmbeddingTable = {
  metadata: {
    companyName: string
    companyLabel: string
    productName: string
    description: string
    taxonomies: string[]
  }
}

export type PublicCompsYearlyData = {
  year: number
  evRevenue: number
  evEbitda: number
  revenue: number
  ebitda: number
  analystRevenueEstimates: number
}

export type PublicCompsTable = {
  ticker: string
  name: string
  company_name?: string | null
  yearly_data?: PublicCompsYearlyData[] | null
  enterprise_value?: number | null
  price?: number | null
  rule_of_40_op_in_margin?: number | null
  sm_percent?: number | null
  sales_and_marketing_yield?: number | null
  implied_5yr_ltv_cac?: number | null
  close_52_weeks_ago?: number | null
  close_52_week_high?: number | null
  close_52_week_low?: number | null
  arr?: number | null
  last_year_arr?: number | null
  ntm_revenue_growth?: number | null
  ltm_revenue?: number | null
  ltm_ebitda?: number | null
  ltm_revenue_previous?: number | null
  ltm_ebitda_margin?: number | null
  net_new_arr_growth_percent?: number | null
  net_dollar_retention?: number | null
  gross_retention?: number | null
  gross_margin?: number | null
  gross_profit?: number | null
  ev_ntm_revenue?: number | null
  ev_ntm_gross_profit?: number | null
}
type PublicCapTablesTable = {
  company_name: string
  cap_table_json: ExistingShareHolder[]
  fte?: number
  comp_market_size?: string
  competitive_products?: string
}

type TableTypeMap = {
  cyber_transactions_acquisition: AcquisitionTransactionTable
  cyber_transaction_capitalraise: CapitalRaiseTransactionTable
  ai_transaction_info: AITransactionInfoTable
  product_db: ProductDBTable
  product_embeddings: ProductEmbeddingTable
  public_comps: PublicCompsTable
  public_cap_tables: PublicCapTablesTable
}

type TableName = keyof TableTypeMap

type TableType<T extends TableName> = TableTypeMap[T]

type Sort = {
  sortKey?: string
  sortOrder?: 'asc' | 'desc'
}

type Limit = {
  limit?: number
}

type Queries = { value: string | string[]; select?: string[] } & Sort & Limit & ({ findKey: string } | { op: string; key: string } | { op: string; json: string })
export function useConfigTable<T extends TableName>(
  tableName: T,
  query?: Queries,
  enabled: boolean = true
): [TableType<T>[], ConfigTableStates<boolean>, ConfigTableStates<Error | undefined>] {
  const { data, isLoading, error } = useQuery({
    queryKey: ['configTable', tableName, query],
    enabled,
    queryFn: queryConfigTable(tableName, query),
  })

  return [
    data || [],
    {
      get: isLoading,
    },
    {
      get: error as Error | undefined,
    },
  ]
}

export function queryConfigTable<T extends TableName>(tableName: T, query?: Queries) {
  return async () =>
    restAPIRequest<TableType<T>[]>({
      path: `get-table/${tableName}`,
      method: 'get',
      query,
    })
}
