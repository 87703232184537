import { FunctionComponent, useState } from 'react'
import { CartesianGrid, Legend, Tooltip, Line, LineChart, Rectangle, ReferenceArea, ResponsiveContainer, Label, XAxis, YAxis, Dot } from 'recharts'
import { currencyFormatterLong, currencyFormatterShort, multipleFormatter, percentFormatter } from '../utils/utils'

export type TrxModelChartData = {
  xAxis: number
  yAxis: Record<string, number>
}
export const CHART_Y_TYPE_CURRENCY = 'currency'
export const CHART_Y_TYPE_MULTIPLE = 'multiple'
export const CHART_Y_TYPE_PERCENT = 'percent'

type TransactionModelChartParams = {
  data: TrxModelChartData[]
  chartYType: 'currency' | 'multiple' | 'percent'
  lowExit: number
  highExit: number
  interval: number
}

const CustomizedDot = (props) => {
  const { index } = props
  if (index % props.interval !== 0) return null
  return <Dot {...props} />
}

const EquityChart: FunctionComponent<TransactionModelChartParams> = ({ data, chartYType, lowExit, highExit, interval }) => {
  data.sort((a, b) => (a.xAxis > b.xAxis ? 1 : -1)) // sort data by exit values ASC
  const yAxis = Object.keys(data?.[0]?.yAxis || {})
  yAxis.sort((a, b) => {
    if (a.toLowerCase().includes('common')) return 1
    if (a.toLowerCase().includes('option')) return 1
    if (a.toLowerCase().includes('warrant')) return 1
    return -1
  })

  const [barProps, setBarProps] = useState(
    yAxis.reduce(
      (a, key) => {
        a[key] = false
        return a
      },
      { hover: null } as Record<string, boolean | string | null>
    )
  )

  const handleLegendMouseEnter = (e: { dataKey: string }) => {
    if (!barProps[e.dataKey]) {
      setBarProps({ ...barProps, hover: e.dataKey })
    }
  }

  const handleLegendMouseLeave = () => {
    setBarProps({ ...barProps, hover: null })
  }

  const selectBar = (e: any) => {
    setBarProps({
      ...barProps,
      [e.dataKey]: !barProps[e.dataKey],
      hover: null,
    })
  }

  if (!data[0]) return null
  const values = data.map((r) => {
    return {
      xAxis: r.xAxis,
      Returns: r.yAxis.Playbook,
      ...r.yAxis,
    }
  })
  values.sort((a, b) => a.xAxis - b.xAxis)
  let formatter = currencyFormatterShort
  if (chartYType === CHART_Y_TYPE_MULTIPLE) formatter = multipleFormatter
  if (chartYType === CHART_Y_TYPE_PERCENT) formatter = percentFormatter
  const headerName = 'Returns'
  return (
    <ResponsiveContainer width='100%' height='100%'>
      <LineChart
        width={300}
        height={300}
        data={values}
        margin={{
          top: 5,
          right: 5,
          left: 40,
          bottom: 30,
        }}
      >
        <CartesianGrid strokeDasharray='1 5' />
        <XAxis interval={interval - 1} dataKey='xAxis' type='category' allowDataOverflow={true} tickFormatter={currencyFormatterShort}>
          <Label position='insideBottom' offset={-20} style={{ textAnchor: 'middle', fontSize: '120%', fontWeight: '500', fill: '#595D6D' }} value='Valuation' />
        </XAxis>
        <YAxis width={50} domain={['auto', 'auto']} type='number' tickFormatter={formatter}>
          <Label angle={270} position='insideLeft' offset={-20} style={{ textAnchor: 'middle', fontSize: '120%', fontWeight: '500', fill: '#595D6D' }} value='Returns' />
        </YAxis>
        <Tooltip formatter={formatter as any} labelFormatter={currencyFormatterLong} />
        {/* <Legend onClick={selectBar} onMouseOver={handleLegendMouseEnter} onMouseOut={handleLegendMouseLeave} /> */}
        <Line type='linear' dataKey={headerName} stroke={'#1C4E98'} strokeWidth={3} activeDot={{ r: 8 }} dot={<CustomizedDot interval={interval} />} />
        <ReferenceArea ifOverflow='visible' x1={values[0].xAxis} x2={lowExit} fillOpacity={0.2} fill='#BF4040' shape={<Rectangle />} />
        <ReferenceArea ifOverflow='visible' x1={lowExit} x2={highExit} fillOpacity={0.2} fill='#1C4E98' shape={<Rectangle />} />
        <ReferenceArea ifOverflow='visible' x1={highExit} x2={values[values.length - 1].xAxis} fillOpacity={0.2} fill='#43A854' shape={<Rectangle />} />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default EquityChart
