/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function AdminIcon(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="20px"
      height="20px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 20, height: 20 }}
      paths={[
        {
          d: "M8.54238 12.5183L5.8619 10.7644L6.89395 8.87238C7.14287 8.39994 7.27328 7.8741 7.27396 7.3401L7.27396 4.34068C7.27603 3.49731 7.03177 2.67168 6.57116 1.9652C6.11055 1.25871 5.45366 0.702116 4.68113 0.363758C3.9086 0.0253996 3.05408 -0.0799913 2.2225 0.0605268C1.39091 0.201045 0.61846 0.581355 0 1.15476L0.90599 2.13277C1.33453 1.73655 1.86937 1.47398 2.44495 1.37726C3.02052 1.28053 3.61181 1.35387 4.14632 1.58826C4.68084 1.82265 5.13533 2.20792 5.4541 2.69683C5.77286 3.18574 5.94204 3.75703 5.94089 4.34068L5.94089 7.3401C5.94076 7.65104 5.86627 7.95745 5.72364 8.23376L4.10245 11.206L7.81248 13.6336C8.05738 13.7939 8.25835 14.0128 8.39711 14.2705C8.53587 14.5282 8.60804 14.8165 8.60704 15.1092L8.60704 17.3382L5.60762 17.3382L5.60762 18.6712L9.94011 18.6712L9.94011 15.1092C9.94145 14.595 9.8143 14.0887 9.57019 13.6363C9.32609 13.1838 8.97278 12.7995 8.54238 12.5183Z",
          fill: "rgba(66,71,77,1)",
          fillRule: "nonzero",
          style: { transform: "translate(50.3%, 0%)" },
        },
        {
          d: "M12.6034 12.5157L9.92299 10.7618L10.955 8.86978C11.2039 8.39734 11.3343 7.87149 11.335 7.33749L11.335 4.33807C11.3341 3.18839 10.8773 2.086 10.0647 1.27273C9.25203 0.45947 8.14999 0.00175379 7.00031 0C4.61256 -8.88006e-16 2.67002 1.94608 2.67002 4.33807L2.67002 7.33749C2.66748 7.87125 2.79646 8.39742 3.04557 8.86948L4.08321 10.7718L1.41835 12.5157C0.988438 12.7964 0.635441 13.1801 0.391392 13.6319C0.147344 14.0836 0.01997 14.5892 0.0208293 15.1026L0 18.6686L14.0012 18.6686L14.0012 15.1066C14.0025 14.5924 13.8753 14.0861 13.6312 13.6336C13.3871 13.1812 13.0338 12.7969 12.6034 12.5157ZM12.6681 17.3355L1.34082 17.3355L1.35374 15.1066C1.35273 14.8139 1.42489 14.5256 1.56365 14.2679C1.70241 14.0102 1.90338 13.7912 2.14829 13.631L5.84266 11.2136L4.21585 8.23115C4.07482 7.95444 4.00188 7.64806 4.0031 7.33749L4.0031 4.33807C4.0031 3.54258 4.31911 2.77967 4.88161 2.21717C5.44411 1.65467 6.20702 1.33866 7.00251 1.33866C7.79801 1.33866 8.56092 1.65467 9.12342 2.21717C9.68592 2.77967 10.0019 3.54258 10.0019 4.33807L10.0019 7.33749C10.0018 7.64844 9.92731 7.95485 9.78468 8.23115L8.1635 11.2034L11.8735 13.631C12.1184 13.7913 12.3194 14.0102 12.4582 14.2679C12.5969 14.5256 12.6691 14.8139 12.6681 15.1066L12.6681 17.3355Z",
          fill: "rgba(66,71,77,1)",
          fillRule: "nonzero",
          style: { transform: "translate(0%, 6.68%)" },
        },
      ]}
      {...getOverrideProps(overrides, "AdminIcon")}
      {...rest}
    ></Icon>
  );
}
