/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function FiUpload(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="24px"
      height="24px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 24, height: 24 }}
      paths={[
        {
          d: "M1 0C1.55228 0 2 0.447715 2 1L2 5C2 5.26522 2.10536 5.51957 2.29289 5.70711C2.48043 5.89464 2.73478 6 3 6L17 6C17.2652 6 17.5196 5.89464 17.7071 5.70711C17.8946 5.51957 18 5.26522 18 5L18 1C18 0.447715 18.4477 0 19 0C19.5523 0 20 0.447715 20 1L20 5C20 5.79565 19.6839 6.55871 19.1213 7.12132C18.5587 7.68393 17.7957 8 17 8L3 8C2.20435 8 1.44129 7.68393 0.87868 7.12132C0.31607 6.55871 0 5.79565 0 5L0 1C0 0.447715 0.447715 0 1 0Z",
          fill: "rgba(61,67,87,1)",
          fillRule: "evenodd",
          style: { transform: "translate(8.33%, 58.33%)" },
        },
        {
          d: "M5.29289 0.292893C5.68342 -0.0976311 6.31658 -0.0976311 6.70711 0.292893L11.7071 5.29289C12.0976 5.68342 12.0976 6.31658 11.7071 6.70711C11.3166 7.09763 10.6834 7.09763 10.2929 6.70711L6 2.41421L1.70711 6.70711C1.31658 7.09763 0.683417 7.09763 0.292893 6.70711C-0.0976311 6.31658 -0.0976311 5.68342 0.292893 5.29289L5.29289 0.292893Z",
          fill: "rgba(61,67,87,1)",
          fillRule: "evenodd",
          style: { transform: "translate(25%, 8.33%)" },
        },
        {
          d: "M1 0C1.55228 0 2 0.447715 2 1L2 13C2 13.5523 1.55228 14 1 14C0.447715 14 0 13.5523 0 13L0 1C0 0.447715 0.447715 0 1 0Z",
          fill: "rgba(61,67,87,1)",
          fillRule: "evenodd",
          style: { transform: "translate(45.83%, 8.33%)" },
        },
      ]}
      {...getOverrideProps(overrides, "FiUpload")}
      {...rest}
    ></Icon>
  );
}
