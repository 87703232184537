import { Card, Loader, Text } from '@aws-amplify/ui-react'
import React from 'react'

type LoadingWaiterProps = {
  isLoading: boolean
  error?: Error
  children: React.ReactNode
}

const LoadingWaiter: React.FC<LoadingWaiterProps> = ({ isLoading, error, children }) => {
  if (isLoading) {
    return <Loader />
  }

  if (error) {
    return (
      <Card>
        <Text> We encountered an error: {error.message}. If this continues us by clicking chat icon in the bottom right or email us at support@playbookmna.com </Text>
      </Card>
    )
  }

  return children
}

export default LoadingWaiter
