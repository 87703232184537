import { Flex, SelectField, Text } from '@aws-amplify/ui-react'
import { FunctionComponent } from 'react'
import CurrencyInput from './CurrencyInput'
import NumberInput from './NumberInput'
import PercentInput from './PercentInput'

export type LoanDetails = {
  shareholderName: string
  group: string
  loanAmount: number
  termLength: number
  interestRate: number
  repaymentType: 'INTEREST_ONLY_BULLET_PRINCIPAL'
  interestOnlyLength?: number
  interestPaymentFrequency: 'PAYMENT_FREQUENCY_MONTHLY' | 'PAYMENT_FREQUENCY_QUARTERLY'
  warrantPercent: number
}

interface LoanDetailsInputProps {
  dispatch(out: Partial<LoanDetails>): void
  state?: LoanDetails
}

const LoanDetailsInput: FunctionComponent<LoanDetailsInputProps> = ({ state, dispatch }) => {
  if (!state) return <Text>Error loading load information, please refresh and try again</Text>
  return (
    <Flex padding='15px' alignItems='baseline' direction='column'>
      <CurrencyInput label='Loan Amount' value={state.loanAmount} onValueChange={(v) => dispatch({ loanAmount: v })} />
      <NumberInput label='Term Length' value={state.termLength} onValueChange={(v) => dispatch({ termLength: Number(v) })} />
      <PercentInput label='Interest Rate' value={state.interestRate} onValueChange={(v) => dispatch({ interestRate: v })} />
      <SelectField
        label='Repayment Type'
        value={state.repaymentType}
        size='small'
        onChange={(e) => {
          dispatch({ repaymentType: e.target.value as LoanDetails['repaymentType'] })
        }}
      >
        <option value='INTEREST_ONLY_BULLET_PRINCIPAL'>Interest only + Bullet Principle</option>
      </SelectField>
      <NumberInput
        label='If Interest Only, How many Months'
        value={state.interestOnlyLength || state.termLength || 0}
        onValueChange={(v) => dispatch({ interestOnlyLength: Number(v) })}
      />
      <SelectField
        label='Interest Payment Frequency'
        value={state.interestPaymentFrequency}
        size='small'
        onChange={(e) => {
          dispatch({ interestPaymentFrequency: e.target.value as LoanDetails['interestPaymentFrequency'] })
        }}
      >
        <option value='PAYMENT_FREQUENCY_MONTHLY'>Monthly</option>
        <option value='PAYMENT_FREQUENCY_QUARTERLY'>Quarterly</option>
      </SelectField>
      <PercentInput label='Total Warrant Purchase Amount' value={state.warrantPercent} onValueChange={(v) => dispatch({ warrantPercent: v })} />
    </Flex>
  )
}

export default LoanDetailsInput
