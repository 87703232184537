/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function Trash(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    { overrides: { Trash: {} }, variantValues: { state: "Default" } },
    {
      overrides: {
        Trash: {
          paths: [
            {
              d: "M0 1C0 0.447715 0.447715 0 1 0L19 0C19.5523 0 20 0.447715 20 1C20 1.55228 19.5523 2 19 2L1 2C0.447715 2 0 1.55228 0 1Z",
              fill: "rgba(191,64,64,1)",
              fillRule: "evenodd",
              style: { transform: "translate(8.33%, 20.83%)" },
            },
            {
              d: "M6 2C5.73478 2 5.48043 2.10536 5.29289 2.29289C5.10536 2.48043 5 2.73478 5 3L5 5C5 5.55228 4.55228 6 4 6C3.44772 6 3 5.55228 3 5L3 3C3 2.20435 3.31607 1.44129 3.87868 0.87868C4.44129 0.31607 5.20435 0 6 0L10 0C10.7956 0 11.5587 0.31607 12.1213 0.87868C12.6839 1.44129 13 2.20435 13 3L13 5C13 5.55228 12.5523 6 12 6C11.4477 6 11 5.55228 11 5L11 3C11 2.73478 10.8946 2.48043 10.7071 2.29289C10.5196 2.10536 10.2652 2 10 2L6 2ZM1 4C1.55228 4 2 4.44772 2 5L2 19C2 19.2652 2.10536 19.5196 2.29289 19.7071C2.48043 19.8946 2.73478 20 3 20L13 20C13.2652 20 13.5196 19.8946 13.7071 19.7071C13.8946 19.5196 14 19.2652 14 19L14 5C14 4.44772 14.4477 4 15 4C15.5523 4 16 4.44772 16 5L16 19C16 19.7957 15.6839 20.5587 15.1213 21.1213C14.5587 21.6839 13.7957 22 13 22L3 22C2.20435 22 1.44129 21.6839 0.87868 21.1213C0.31607 20.5587 0 19.7957 0 19L0 5C0 4.44772 0.447715 4 1 4Z",
              fill: "rgba(191,64,64,1)",
              fillRule: "evenodd",
              style: { transform: "translate(16.67%, 4.17%)" },
            },
            {
              d: "M1 0C1.55228 0 2 0.447715 2 1L2 7C2 7.55228 1.55228 8 1 8C0.447715 8 0 7.55228 0 7L0 1C0 0.447715 0.447715 0 1 0Z",
              fill: "rgba(191,64,64,1)",
              fillRule: "evenodd",
              style: { transform: "translate(37.5%, 41.67%)" },
            },
            {
              d: "M1 0C1.55228 0 2 0.447715 2 1L2 7C2 7.55228 1.55228 8 1 8C0.447715 8 0 7.55228 0 7L0 1C0 0.447715 0.447715 0 1 0Z",
              fill: "rgba(191,64,64,1)",
              fillRule: "evenodd",
              style: { transform: "translate(54.17%, 41.67%)" },
            },
          ],
        },
      },
      variantValues: { state: "Hover" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Icon
      width="24px"
      height="24px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 24, height: 24 }}
      paths={[
        {
          d: "M0 1C0 0.447715 0.447715 0 1 0L19 0C19.5523 0 20 0.447715 20 1C20 1.55228 19.5523 2 19 2L1 2C0.447715 2 0 1.55228 0 1Z",
          fill: "rgba(0,0,0,1)",
          fillRule: "evenodd",
          style: { transform: "translate(8.33%, 20.83%)" },
        },
        {
          d: "M6 2C5.73478 2 5.48043 2.10536 5.29289 2.29289C5.10536 2.48043 5 2.73478 5 3L5 5C5 5.55228 4.55228 6 4 6C3.44772 6 3 5.55228 3 5L3 3C3 2.20435 3.31607 1.44129 3.87868 0.87868C4.44129 0.31607 5.20435 0 6 0L10 0C10.7956 0 11.5587 0.31607 12.1213 0.87868C12.6839 1.44129 13 2.20435 13 3L13 5C13 5.55228 12.5523 6 12 6C11.4477 6 11 5.55228 11 5L11 3C11 2.73478 10.8946 2.48043 10.7071 2.29289C10.5196 2.10536 10.2652 2 10 2L6 2ZM1 4C1.55228 4 2 4.44772 2 5L2 19C2 19.2652 2.10536 19.5196 2.29289 19.7071C2.48043 19.8946 2.73478 20 3 20L13 20C13.2652 20 13.5196 19.8946 13.7071 19.7071C13.8946 19.5196 14 19.2652 14 19L14 5C14 4.44772 14.4477 4 15 4C15.5523 4 16 4.44772 16 5L16 19C16 19.7957 15.6839 20.5587 15.1213 21.1213C14.5587 21.6839 13.7957 22 13 22L3 22C2.20435 22 1.44129 21.6839 0.87868 21.1213C0.31607 20.5587 0 19.7957 0 19L0 5C0 4.44772 0.447715 4 1 4Z",
          fill: "rgba(0,0,0,1)",
          fillRule: "evenodd",
          style: { transform: "translate(16.67%, 4.17%)" },
        },
        {
          d: "M1 0C1.55228 0 2 0.447715 2 1L2 7C2 7.55228 1.55228 8 1 8C0.447715 8 0 7.55228 0 7L0 1C0 0.447715 0.447715 0 1 0Z",
          fill: "rgba(0,0,0,1)",
          fillRule: "evenodd",
          style: { transform: "translate(37.5%, 41.67%)" },
        },
        {
          d: "M1 0C1.55228 0 2 0.447715 2 1L2 7C2 7.55228 1.55228 8 1 8C0.447715 8 0 7.55228 0 7L0 1C0 0.447715 0.447715 0 1 0Z",
          fill: "rgba(0,0,0,1)",
          fillRule: "evenodd",
          style: { transform: "translate(54.17%, 41.67%)" },
        },
      ]}
      {...getOverrideProps(overrides, "Trash")}
      {...rest}
    ></Icon>
  );
}
