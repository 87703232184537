import { GraphQLQuery, generateClient } from '@aws-amplify/api'
import { graphqlOperation } from '@aws-amplify/api-graphql'
import { DataStore } from '@aws-amplify/datastore'
import { useEffect, useState } from 'react'
import { ListOpportunitiesQuery, ListOpportunitiesQueryVariables } from '../API'
import { listOpportunities } from '../graphql/queries'
import { Opportunity } from '../models'

const API = generateClient()

export function useOpportunities(): [Opportunity[], boolean] {
  const [opportunities, setOpportunities] = useState<Opportunity[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  useEffect(() => {
    const sub = DataStore.observeQuery(Opportunity).subscribe(async ({ items, isSynced }) => {
      if (isSynced || items.length) {
        setIsLoading(false)
        setOpportunities(items)
      }
      const validatedItems: Opportunity[] = []
      const args: ListOpportunitiesQueryVariables = {
        limit: 100,
        nextToken: null,
      }

      do {
        try {
          const out = await API.graphql<GraphQLQuery<ListOpportunitiesQuery>>(graphqlOperation(listOpportunities, args))
          const filteredItems =
            out.data?.listOpportunities?.items?.map((i) => {
              // @ts-ignore
              delete i?.createdAt
              // @ts-ignore
              delete i?.updatedAt
              return new Opportunity(i as any)
            }) || []
          validatedItems.push(...filteredItems)
          args.nextToken = out.data?.listOpportunities?.nextToken || null
        } catch (error) {
          console.error('ListOpportunitiesQuery', error)
        }
      } while (args.nextToken !== null)

      setIsLoading(false)
      setOpportunities(items)
    })

    return () => {
      return sub.unsubscribe()
    }
  }, [])

  return [opportunities, isLoading]
}
