export const TRX_EXPORT = 'PLAYBOOK:TRX_EXPORT'
export const SOURCES = 'PLAYBOOK:SHOW_SOURCES'
export const NEW_PRODUCTS = 'PLAYBOOK:SHOW_PRODUCTS'
export const FULL_CRUNCHBASE = 'PLAYBOOK:FULL_CRUNCHBASE'
export const CB_LIST_EXCEL_IMPORT = 'PLAYBOOK:CB_LIST_EXCEL_IMPORT'
export const PB_CAP_TABLE_INPUT = 'PLAYBOOK:PB_CAP_TABLE_INPUT'

export function enabledForOrg(groups: string[] | undefined, feature: string) {
  if (!groups?.length) return false
  if (!feature) return false
  switch (feature) {
    case TRX_EXPORT:
      return matchArrays(groups, ['Demo', TRX_EXPORT])
    case SOURCES:
      return matchArrays(groups, ['Demo', SOURCES])
    case NEW_PRODUCTS:
      return matchArrays(groups, ['Demo', NEW_PRODUCTS])
    case CB_LIST_EXCEL_IMPORT:
      return matchArrays(groups, ['Demo', CB_LIST_EXCEL_IMPORT])
    case PB_CAP_TABLE_INPUT:
      return matchArrays(groups, ['Demo', PB_CAP_TABLE_INPUT])
    case FULL_CRUNCHBASE:
      return true
    default:
      return false
  }
}

export function matchArrays(groups: string[], targetGroups: string[]) {
  if (!groups?.length || !targetGroups?.length) return false
  return groups.some((item) => targetGroups.includes(item))
}
