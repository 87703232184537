import { FunctionComponent, useState } from 'react'
import CompanyHeader from '../components/CompanyHeader'
import { SelectOpportunityText } from '../components/SelectOpportunityText'
import { useOpportunity } from '../contexts/opportunityContext'
import GlassCard from '../figma-components/GlassCard'
import { VALUATION } from '../sitemap'
import PublicComps from './PublicComps'
import TransactionComps from './TransactionComps'

interface ValuationPageProps {}

const PUBLIC_COMPS_PAGE = 'public'
const TRX_A = 'trx_a'
const TRX_CR = 'trx_cr'
const pages = [
  { name: PUBLIC_COMPS_PAGE, displayName: 'Public Comps' },
  // { name: TRX_A, displayName: 'Trx. Comps - Acquisition' },
  // { name: TRX_CR, displayName: 'Trx. Comps - Capital Raise' },
  // { name: 'valuation', displayName: 'Valuation' },
]
const ValuationPage: FunctionComponent<ValuationPageProps> = () => {
  const [currentPage, setCurrentPage] = useState<string>(PUBLIC_COMPS_PAGE)
  const opportunity = useOpportunity()
  if (!opportunity) return <SelectOpportunityText />

  // TODO: refactor into a dynamic, smaller switch statement
  const getCurrentPage = (page: string) => {
    switch (page) {
      case PUBLIC_COMPS_PAGE:
        return <PublicComps opportunity={opportunity} />
      case TRX_A:
        return <TransactionComps type='acquisition' />
      case TRX_CR:
        return <TransactionComps type='capitalraise' />
    }
  }
  return (
    <>
      <CompanyHeader />
      <GlassCard header={VALUATION} level={0}>
        {/* <Tabs className='lightmodeTabs'>
          {pages.map((page) => {
            return <TabItem title={page.displayName} onClick={() => setCurrentPage(page.name)} />
          })}
        </Tabs> */}
        {getCurrentPage(currentPage)}
      </GlassCard>
    </>
  )
}
export default ValuationPage
