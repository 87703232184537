import { Flex, Loader, Table, TableBody, TableCell, TableHead, TableRow, Text, TextAreaField } from '@aws-amplify/ui-react'
import { FunctionComponent, useState } from 'react'
import { useCompanyInfoV2 } from '../hooks/useCompanyInfoV2'
import { useConfigTable } from '../hooks/useConfigTable'
import { restAPIRequest } from '../services/restApiRequest'
import { ExistingShareHolder } from '../types'
import { parseRestError } from '../utils/utils'
import NumberInput from './NumberInput'

interface PublicCapTableUploaderProps {
  opportunityName?: string
  onUpload: (cp: ExistingShareHolder[]) => void
  onError: (err: string) => void
}
type ParseCapTableRes = {
  existingCapTable: ExistingShareHolder[]
}

const isAdmin = window.location.href.includes('admin')
const PublicCapTableUploader: FunctionComponent<PublicCapTableUploaderProps> = ({ opportunityName = '', onUpload, onError }) => {
  const [companyInfo, companyInfoLoading] = useCompanyInfoV2(opportunityName)
  const [fte, setFte] = useState(0)
  const [capTable, capTableLoading, capTableError] = useConfigTable(
    'public_cap_tables',
    {
      findKey: 'company_name',
      value: opportunityName,
    },
    !!opportunityName
  )

  const [capTables, capTablesLoading, capTablesError] = useConfigTable('public_cap_tables')

  const fteInit = capTable?.[0]?.fte || 0
  const hasCapTable = capTable?.[0]?.cap_table_json?.length > 0

  let inputs = <Loader />
  if (!companyInfoLoading && companyInfo) {
    inputs = (
      <Flex>
        {hasCapTable ? <Text>Has Cap Table</Text> : <Text>Needs Cap Table</Text>}
        <TextAreaField
          label='Pitchbook Cap Table input'
          placeholder='Paste Pitchbook Cap Table here. Starting from "Cap Table History" to the end of the section.'
          onChange={async (e) => {
            const text = e.target.value
            try {
              const { existingCapTable } = await restAPIRequest<ParseCapTableRes>({
                path: 'ai/parsePitchbookCapTable',
                method: 'post',
                body: {
                  text,
                  exitDate: new Date(),
                  crunchbaseName: companyInfo.cb_organizationPermalink,
                },
              })
              onUpload(existingCapTable)
              restAPIRequest({
                path: `public/capTable/${companyInfo.companyName}`,
                method: 'post',
                body: { capTable: existingCapTable },
              }).catch((error) => console.error('Error saving cap table', parseRestError(error, 'UNKNOWN')))
            } catch (error: any) {
              onError(error.message)
            }
          }}
        />
        <NumberInput
          isDisabled={!hasCapTable}
          label={'fte'}
          value={fte || fteInit}
          onValueChange={(v) => {
            restAPIRequest({
              path: `public/capTable/${companyInfo.companyName}`,
              method: 'post',
              body: { fte: v },
            }).catch((error) => alert('Error saving fte: ' + parseRestError(error, 'UNKNOWN')))
            setFte(v)
          }}
        />
      </Flex>
    )
  } else if (companyInfo && !companyInfo?.cb_organizationPermalink) {
    inputs = <Text>We do not have crunchbase info for this company. Cannot use Pitchbook Cap Table.</Text>
  } else if (!companyInfoLoading) {
    inputs = <Text>Select Company</Text>
  }

  const existingCapTables = (
    <>
      <Text>Existing Cap Tables: </Text>
      <Table caption='existing cap tables' title='existing cap tables' highlightOnHover={true} variation='striped' size='small'>
        <TableHead>
          <TableRow>
            <TableCell as='th'>Org Name</TableCell>
            <TableCell as='th'>FTE</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {capTables &&
            capTables.map((m) => {
              return (
                <TableRow key={m.company_name}>
                  <TableCell>{m.company_name}</TableCell>
                  <TableCell>{m.fte}</TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
    </>
  )
  return (
    <Flex direction='column'>
      {inputs}
      {isAdmin && existingCapTables}
    </Flex>
  )
}

export default PublicCapTableUploader
