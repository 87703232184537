/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function FilesOpenIcon(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="20px"
      height="16.22px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 20, height: 16 }}
      paths={[
        {
          d: "M19.7867 5.92946C19.693 5.80875 19.573 5.71115 19.4357 5.64415C19.2984 5.57716 19.1475 5.54257 18.9948 5.54304L16.2236 5.54304L16.2236 3.34891C16.2233 3.09241 16.1212 2.8465 15.9398 2.66513C15.7585 2.48376 15.5126 2.38174 15.2561 2.38146L9.55035 2.38146L8.80638 0.595271C8.73319 0.418784 8.60924 0.268001 8.45026 0.162028C8.29128 0.0560553 8.10442 -0.000335724 7.91335 1.50371e-06L0.967459 1.50371e-06C0.710965 0.000300636 0.465061 0.102325 0.283693 0.283694C0.102324 0.465063 0.000299132 0.710966 0 0.967461L0 16.2236L17.6114 16.2236L19.9694 6.79139C20.0069 6.64331 20.01 6.4886 19.9783 6.33917C19.9466 6.18974 19.881 6.04957 19.7867 5.92946ZM1.33957 14.8841L1.33957 1.33957L7.66528 1.33957L8.6574 3.72102L14.8841 3.72102L14.8841 5.54304L5.0898 5.54304C4.86566 5.54275 4.64787 5.61753 4.47118 5.75546C4.2945 5.89339 4.16909 6.08652 4.11498 6.30404L1.97012 14.8841L1.33957 14.8841ZM16.5655 14.8841L3.35092 14.8841L5.35127 6.8826L18.5659 6.8826L16.5655 14.8841Z",
          fill: "rgba(48,53,69,1)",
          fillRule: "nonzero",
          style: { transform: "translate(0%, 0%)" },
        },
      ]}
      {...getOverrideProps(overrides, "FilesOpenIcon")}
      {...rest}
    ></Icon>
  );
}
