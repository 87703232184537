import { Flex, ScrollView, Table, TableBody, TableCell, TableHead, TableRow } from '@aws-amplify/ui-react'
import { Table as TanTable, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { FunctionComponent, useMemo } from 'react'
import GlassCard from './figma-components/GlassCard'
import { ProformaCapTableRow, ProformaData } from './types'
import { percentFormatter } from './utils/utils'
import { fuzzyFilter } from './components/InputTable'

type CapTableProps = {
  proformaTableData?: ProformaData
}

export const capTableColumns = [
  {
    header: 'Pre-Money',
    columns: [
      {
        header: 'Shareholder Name',
        accessorKey: 'shareholderName',
      },
      {
        header: 'Shareholder Grouping',
        accessorKey: 'group',
      },
      {
        header: 'Security Type',
        accessorKey: 'SecurityType',
      },
      {
        header: '# of Shares',
        accessorKey: 'NumberOfShares',
      },
      {
        header: '% of Total Fully Diluted Shares',
        accessorKey: 'PercentOfTotalFullyDilutedShares',
      },
      {
        header: '$ Contributed',
        accessorKey: 'AmountContributed',
      },
    ],
  },
  // TODO: remove hacky solution for group header dividers
  {
    header: ' ',
    accessorKey: 'None1',
    columns: [],
  },
  {
    header: 'Adjustments',
    columns: [
      {
        header: '# of Shares Added / (Lost)',
        accessorKey: 'NumberOfSharesAddedOrLost',
      },
      {
        header: '$ Contributed / (Sold)',
        accessorKey: 'AmountContributedOrSold',
      },
    ],
  },
  // TODO: remove hacky solution for group header dividers
  {
    header: ' ',
    accessorKey: 'None2',
    columns: [],
  },
  {
    header: 'Pro Forma',
    columns: [
      { header: 'Security Type', accessorKey: 'PostSecurityType' },
      { header: '# of Shares', accessorKey: 'PostNumberOfShares' },
      { header: '% of Total Fully Diluted Shares', accessorKey: 'PostPercentOfTotalFullyDilutedShares' },
      { header: '$ Contributed - Net of Sold', accessorKey: 'PostAmountContributedNetOfSold' },
      { header: 'Entry Price Per Share', accessorKey: 'PostEntryPricePerShare' },
      { header: 'Strike Price', accessorKey: 'PostStrikePrice' },
    ],
  },
]

function int(num: number | undefined | null) {
  if (!num) return
  return (Math.round(num * 100) / 100).toLocaleString()
}

function $(num: number | undefined | null) {
  if (!num) return
  return '$' + int(num)
}

function percent(num: number | undefined | null) {
  if (!num) return
  return percentFormatter(num)
}

const StringTypes = {
  PARTICIPATION_RIGHTS_NON_PARTICIPATING: 'Convertible Preferred',
  PARTICIPATION_RIGHTS_PARTICIPATING_FULL: 'Participating Preferred',
  PARTICIPATION_RIGHTS_PARTICIPATING_WITH_CAP: 'Participating w/ Cap',
  PARTICIPATION_RIGHTS_NA: 'Convertible Preferred',
  DIVIDEND_NON_CUMULATIVE: 'Non-Cumulative',
  DIVIDEND_CUMULATIVE: 'Cumulative',
  DIVIDEND_NOT_DECLARED: 'Not Declared',
  DIVIDEND_NA: 'N/A',
  SHARE_HOLDER_TYPE_WARRANT: 'Warrant',
  SHARE_HOLDER_TYPE_SERIES: 'Series',
  SHARE_HOLDER_TYPE_COMMON: 'Common',
  SHARE_HOLDER_TYPE_OPTION: 'Option',
}

const CapTable: FunctionComponent<CapTableProps> = ({ proformaTableData }) => {
  const { detailData, summaryData } = useMemo(() => {
    return {
      detailData: formatProformaTableData(proformaTableData?.detailData || []),
      summaryData: formatProformaTableData(proformaTableData?.summaryData || []),
    }
  }, [JSON.stringify(proformaTableData || {})])

  const detailCapTableView = useReactTable({
    data: detailData,
    columns: capTableColumns,
    getCoreRowModel: getCoreRowModel(),
    filterFns: {
      fuzzy: fuzzyFilter,
    },
  })

  const summaryCapTableView = useReactTable({
    data: summaryData,
    columns: capTableColumns,
    getCoreRowModel: getCoreRowModel(),
    filterFns: {
      fuzzy: fuzzyFilter,
    },
  })

  return (
    <Flex direction='column'>
      {renderTable('Detailed Shareholder View', detailCapTableView)}
      {renderTable('Summary View', summaryCapTableView)}
    </Flex>
  )
}

function renderTable<T>(title: string, data: TanTable<T>) {
  return (
    <GlassCard header={title} initIsExpanded={true} className='overlayCard'>
      <ScrollView width='100%'>
        <Table size='small' className='capTable'>
          <TableHead>
            {data.getHeaderGroups().map((headerGroup, index) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header, subIndex) => (
                  <TableCell
                    as='th'
                    key={header.id}
                    colSpan={header.colSpan}
                    minWidth={`${header.id.includes('None') ? 0 : 12}rem`}
                    className={`tableHeader ${index === 0 ? 'groupTableHeader' : ''} ${header.colSpan === 1 ? 'groupTableHeaderDivider' : 'groupTableHeaderContainer'} ${
                      header.id.includes('None') ? 'groupTableHeaderNone' : ''
                    } ${index === 1 && subIndex === 0 ? 'sticky' : ''}`}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {data.getRowModel().rows.map((row) => {
              return (
                <TableRow key={row.id}>
                  {row.getVisibleCells().map((cell, index) => {
                    return (
                      <TableCell key={cell.id} className={`tableCell ${cell.id.includes('None') ? 'cellNone' : ''} ${index === 0 ? 'sticky' : ''}`}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </ScrollView>
    </GlassCard>
  )
}

export function formatProformaTableData(data: ProformaCapTableRow[]) {
  const top: ProformaCapTableRow[] = []
  const bottom: ProformaCapTableRow[] = []
  const middle: ProformaCapTableRow[] = []
  data.forEach((row) => {
    const d: any = {
      ...row,
      SecurityType: StringTypes[row.SecurityType],
      PostSecurityType: StringTypes[row.PostSecurityType || ''] || 'N/A',
      NumberOfShares: int(row.NumberOfShares) || 'N/A',
      PercentOfTotalFullyDilutedShares: percent(row.PercentOfTotalFullyDilutedShares) || 'N/A',
      AmountContributed: $(row.AmountContributed) || 'N/A',
      NumberOfSharesAddedOrLost: int(row.NumberOfSharesAddedOrLost) || 'N/A',
      AmountContributedOrSold: $(row.AmountContributedOrSold) || 'N/A',
      PostNumberOfShares: int(row.PostNumberOfShares) || 'N/A',
      PostPercentOfTotalFullyDilutedShares: percent(row.PostPercentOfTotalFullyDilutedShares) || 'N/A',
      PostAmountContributedNetOfSold: $(row.PostAmountContributedNetOfSold) || 'N/A',
      PostEntryPricePerShare: $(row.PostEntryPricePerShare) || 'N/A',
      PostStrikePrice: $(row.PostStrikePrice) || 'N/A',
    }
    if (
      [StringTypes.PARTICIPATION_RIGHTS_NON_PARTICIPATING, StringTypes.PARTICIPATION_RIGHTS_PARTICIPATING_FULL, StringTypes.PARTICIPATION_RIGHTS_PARTICIPATING_WITH_CAP].includes(
        row.SecurityType
      )
    ) {
      top.push(d)
    } else if ([StringTypes.SHARE_HOLDER_TYPE_COMMON].includes(row.SecurityType)) {
      middle.push(d)
    } else {
      bottom.push(d)
    }
  })

  const out = top.concat(middle).concat(bottom)
  out.push({
    shareholderName: 'Total',
    group: '',
    SecurityType: '',
    NumberOfShares: int(data.reduce((acc, row) => acc + (row.NumberOfShares || 0), 0)),
    PercentOfTotalFullyDilutedShares: percent(data.reduce((acc, row) => acc + (row.PercentOfTotalFullyDilutedShares || 0), 0)),
    PostPercentOfTotalFullyDilutedShares: percent(data.reduce((acc, row) => acc + (row.PostPercentOfTotalFullyDilutedShares || 0), 0)),
    AmountContributed: $(data.reduce((acc, row) => acc + (row.AmountContributed || 0), 0)),
    NumberOfSharesAddedOrLost: int(data.reduce((acc, row) => acc + (row.NumberOfSharesAddedOrLost || 0), 0)),
    AmountContributedOrSold: $(data.reduce((acc, row) => acc + (row.AmountContributedOrSold || 0), 0)),
    PostNumberOfShares: int(data.reduce((acc, row) => acc + (row.PostNumberOfShares || 0), 0)),
    PostAmountContributedNetOfSold: $(data.reduce((acc, row) => acc + (row.PostAmountContributedNetOfSold || 0), 0)),
  } as any)
  return out
}

export function updateProformaNames(data: ProformaCapTableRow[]) {
  return data.map((row) => {
    return {
      ...row,
      SecurityType: StringTypes[row.SecurityType],
      PostSecurityType: StringTypes[row.PostSecurityType || ''] || 'N/A',
    }
  })
}

export default CapTable
