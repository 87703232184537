import { TextField } from '@aws-amplify/ui-react'
import { FunctionComponent } from 'react'

interface DateInputProps {
  label: string
  value: Date
  labelHidden?: boolean
  className?: string
  onChange: (value: Date) => void
}

const DateInput: FunctionComponent<DateInputProps> = ({ label, labelHidden = false, className = '', value, onChange }) => {
  return (
    <TextField
      label={label}
      labelHidden={labelHidden}
      type='date'
      size='small'
      className={className}
      value={value.toISOString().split('T')[0]}
      onChange={(e) => {
        onChange(new Date(e.target.value))
      }}
    />
  )
}

export default DateInput
