import { FunctionComponent } from 'react'
import ExcelUpload from './ExcelUpload'

interface CrunchbaseNameExcelInputProps {
  onUpload: (cbIds: string[]) => void
}

const CrunchbaseNameExcelInput: FunctionComponent<CrunchbaseNameExcelInputProps> = ({ onUpload }) => {
  return (
    <ExcelUpload
      onUpload={(data) => {
        if (data.length === 0) return alert('No names found in file')
        const names = data
          .map((i, index) => {
            // @ts-ignore
            if (!i?.[0]?.includes('crunchbase.com')) {
              alert(`Row ${index} is not a crunchbase URL, got "${i[0]}". All cells in Col A need to be crunchbase URLs like https://www.crunchbase.com/organization/fornetix`)
              throw new Error('bad data')
            }
            return (i[0] as string)?.split('/').pop()
          })
          .filter((i) => i)
        if (names.length === 0) return alert('No names found in file')
        onUpload(names as string[])
      }}
    />
  )
}

export default CrunchbaseNameExcelInput
