import { Button, Flex, Text } from '@aws-amplify/ui-react'
import { FunctionComponent, useState } from 'react'
import GlassCard from '../figma-components/GlassCard'
import { CaseStudy } from '../models'
import CustomerDetail from './CustomerDetail'
import { Editable } from './Editable'

interface Props {
  caseStudies: CaseStudy[]
  isEditing: boolean
  onSave: (caseStudies: CaseStudy[]) => Promise<void>
}

const CaseStudiesReview: FunctionComponent<Props> = ({ caseStudies: initCaseStudies, isEditing, onSave }) => {
  const [caseStudies, setCaseStudies] = useState<CaseStudy[]>(initCaseStudies)
  const [saving, setSaving] = useState<boolean>(false)
  function section(label: string, key: string, caseStudy: CaseStudy, isLink: boolean = false) {
    return (
      <GlassCard level={2} header={label} width='50%' className='customerCard'>
        <Editable
          value={caseStudy[key]}
          onChange={(v) => {
            const newCaseStudies = [...caseStudies]
            const index = newCaseStudies.findIndex((cs) => cs.customerName === caseStudy.customerName)
            newCaseStudies[index][key] = v
            setCaseStudies(newCaseStudies)
          }}
          isEditing={isEditing}
          isLink={isLink}
        />
      </GlassCard>
    )
  }

  const infos = caseStudies ? (
    caseStudies.map((caseStudy, i) => {
      return (
        <GlassCard
          key={i}
          header={`Study Number ${i}`}
          headerIcon={
            <Button
              isLoading={saving}
              variation='destructive'
              onClick={() => {
                const newCaseStudies = [...caseStudies]
                newCaseStudies.splice(i, 1)
                setCaseStudies(newCaseStudies)
              }}
            >
              Delete
            </Button>
          }
        >
          <Flex direction='column'>
            {section('Name', 'customerName', caseStudy)}
            <Flex direction='row'>
              {section('Background', 'backgroundOnProblem', caseStudy)}
              {section('Success', 'howProductHelpedCustomer', caseStudy)}
            </Flex>
            <Flex direction='row'>
              {section('ROI', 'howCustomerJustifiedROI', caseStudy)}
              {section('Source Link', 'sourceLink', caseStudy, true)}
            </Flex>
          </Flex>
        </GlassCard>
      )
    })
  ) : (
    <Text>N/A</Text>
  )

  if (!isEditing) {
    return <CustomerDetail caseStudies={caseStudies} />
  }

  return (
    <Flex direction='column'>
      <Button
        isLoading={saving}
        variation='primary'
        onClick={() => {
          setSaving(true)
          return onSave(caseStudies)
            .catch((e) => console.error('onSave error', e))
            .finally(() => setSaving(false))
        }}
      >
        SAVE
      </Button>
      {infos}
      <Button
        isLoading={saving}
        onClick={() => {
          setCaseStudies([
            ...caseStudies,
            {
              customerName: '',
              backgroundOnProblem: '',
              howProductHelpedCustomer: '',
              howCustomerJustifiedROI: '',
              sourceLink: '',
            },
          ])
        }}
      >
        Add new case study
      </Button>
    </Flex>
  )
}

export default CaseStudiesReview
