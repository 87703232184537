/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function IoSparklesSharp(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="24px"
      height="24px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 24, height: 24 }}
      paths={[
        {
          d: "M9.75 24L7.29469 17.4553L0.75 15L7.29469 12.5447L9.75 6L12.2053 12.5447L18.75 15L12.2053 17.4553L9.75 24ZM4.125 8.25L3.02016 5.22984L0 4.125L3.02016 3.02016L4.125 0L5.22984 3.02016L8.25 4.125L5.22984 5.22984L4.125 8.25ZM18.75 12L17.2917 8.20828L13.5 6.75L17.2917 5.29172L18.75 1.5L20.2083 5.29172L24 6.75L20.2083 8.20828L18.75 12Z",
          fill: "rgba(48,53,69,1)",
          fillRule: "nonzero",
          style: { transform: "translate(0%, 0%)" },
        },
      ]}
      {...getOverrideProps(overrides, "IoSparklesSharp")}
      {...rest}
    ></Icon>
  );
}
