import { Flex, View } from '@aws-amplify/ui-react'
import { FunctionComponent } from 'react'
import GlassCard from '../figma-components/GlassCard'
import TransactionModelChart, { CHART_Y_TYPE_CURRENCY, CHART_Y_TYPE_MULTIPLE, CHART_Y_TYPE_PERCENT } from './TransactionModelChart'

interface WaterfallChartsProps {
  calcs: any
  flex?: number
}

const WaterfallCharts: FunctionComponent<WaterfallChartsProps> = ({ calcs, flex }) => {
  const grossProceedsChartValues = getGrossProceedsChartValues(calcs)
  const grossProceedsPerShareChartValues = getGrossProceedsPerShareChartValues(calcs)
  const moicChartValues = getMoicChartValues(calcs)
  const irrChartValues = getIrrChartValues(calcs)

  return (
    <GlassCard header='' className='transactionModelOutput' flex={flex}>
      <Flex direction='column'>
        <GlassCard header='Gross Proceeds' className='overlayCard'>
          <View width='100%' className='summaryTileContent summaryTileChart'>
            <TransactionModelChart data={grossProceedsChartValues} chartYType={CHART_Y_TYPE_CURRENCY} />
          </View>
        </GlassCard>
        <GlassCard header='Gross Proceeds per Share' className='overlayCard'>
          <View width='100%' className='summaryTileContent summaryTileChart'>
            <TransactionModelChart data={grossProceedsPerShareChartValues} chartYType={CHART_Y_TYPE_CURRENCY} />
          </View>
        </GlassCard>
        <GlassCard header='Money Over Invested Capital (MOIC)' className='overlayCard'>
          <View width='100%' className='summaryTileContent summaryTileChart'>
            <TransactionModelChart data={moicChartValues} chartYType={CHART_Y_TYPE_MULTIPLE} />
          </View>
        </GlassCard>
        <GlassCard header='Internal Rate of Return (IRR)' className='overlayCard'>
          <View width='100%' className='summaryTileContent summaryTileChart'>
            <TransactionModelChart data={irrChartValues} chartYType={CHART_Y_TYPE_PERCENT} />
          </View>
        </GlassCard>
      </Flex>
    </GlassCard>
  )
}

export function getGrossProceedsChartValues(calcs) {
  return calcs.map(({ salePrice, groupCalcs }) => {
    const yAxis = groupCalcs.reduce((p, { group, proceeds }) => {
      p[group] = proceeds
      return p
    }, {} as Record<string, number>)

    return {
      xAxis: salePrice,
      yAxis,
    }
  })
}

export function getGrossProceedsPerShareChartValues(calcs) {
  return calcs.map(({ salePrice, groupCalcs }) => {
    const yAxis = groupCalcs.reduce((p, { group, proceedsPerShare }) => {
      p[group] = proceedsPerShare
      return p
    }, {} as Record<string, number>)

    return {
      xAxis: salePrice,
      yAxis,
    }
  })
}

export function getMoicChartValues(calcs) {
  return calcs.map(({ salePrice, groupCalcs }) => {
    return {
      xAxis: salePrice,
      yAxis: groupCalcs.reduce((p, c) => {
        if (c.group.toLowerCase().includes('common')) return p
        if (c.group.toLowerCase().includes('option')) return p
        if (c.group.toLowerCase().includes('warrent')) return p
        p[c.group] = c.momReturns || 0
        return p
      }, {} as Record<string, number>),
    }
  })
}
export function getIrrChartValues(calcs) {
  return calcs.map(({ salePrice, groupCalcs }) => {
    return {
      xAxis: salePrice,
      yAxis: groupCalcs.reduce((p, c) => {
        if (c.group.toLowerCase().includes('common')) return p
        if (c.group.toLowerCase().includes('option')) return p
        if (c.group.toLowerCase().includes('warrent')) return p
        p[c.group] = c.irr || 0
        return p
      }, {} as Record<string, number>),
    }
  })
}

export default WaterfallCharts
