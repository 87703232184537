import { FunctionComponent, useEffect, useState } from 'react'
import { Text } from '@aws-amplify/ui-react'
import DashboardListView from './DashboardListView'

type DashboardListProps = {
  dashboardUrls: string[] | undefined
}

const DashboardList: FunctionComponent<DashboardListProps> = ({ dashboardUrls, ...props }) => {
  const [currentDashboardUrl, setCurrentDashboard] = useState<string>()
  useEffect(() => {
    if (dashboardUrls && !currentDashboardUrl) {
      setCurrentDashboard(dashboardUrls[0])
    }
  }, [currentDashboardUrl, dashboardUrls])

  if (!dashboardUrls) return <Text>Waiting for dashboards</Text>
  if (dashboardUrls.length < 1) return <Text>No dashboards available</Text>
  if (!currentDashboardUrl) return <Text>Loading current dashboard</Text>

  return <DashboardListView dashboardUrls={dashboardUrls} currentDashboardUrl={currentDashboardUrl} setCurrentDashboard={setCurrentDashboard} />
}

export default DashboardList
