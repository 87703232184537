import { Flex } from '@aws-amplify/ui-react'
import { FunctionComponent } from 'react'
import TransactionModelInput from '../TransactionModelInput'
import { SelectOpportunityText } from '../components/SelectOpportunityText'
import { useOpportunity } from '../contexts/opportunityContext'

const TransactionModel: FunctionComponent = () => {
  const opportunity = useOpportunity()
  if (!opportunity) {
    return <SelectOpportunityText />
  }

  return (
    <Flex direction='column' gap='0'>
      <TransactionModelInput />
    </Flex>
  )
}

export default TransactionModel
