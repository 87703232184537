import { Button, ScrollView } from '@aws-amplify/ui-react'
import { CellChange, Column, DefaultCellTypes, NumberCell, ReactGrid, Row } from '@silevis/reactgrid'
import '@silevis/reactgrid/styles.css'
import { FunctionComponent, useState } from 'react'
import { useLocation } from 'react-router-dom'
import GlassCard from '../figma-components/GlassCard'
import { FinancialDataV2 } from '../models'
import '../styles/dashboard.css'
import '../styles/grid.scss'
import '../styles/table.css'
import { CurrencyCell, CurrencyCellTemplate } from './CurrencyCell'
import { HeaderCell, HeaderCellTemplate } from './HeaderCell'
import { HorizontalChevronCell, HorizontalChevronCellTemplate } from './HorizontalChevronCell'
import { MultipleCellTemplate } from './MultipleCell'
import { PercentCell, PercentCellTemplate } from './PercentCell'
import CompanyHeader from './CompanyHeader'

const nonEditable = (cell: RowCells): RowCells => ({
  ...cell,
  nonEditable: true,
})

const getColumns = (): (Column & { headerName?: string })[] => {
  const currentYear = new Date().getFullYear()
  const columns: (Column & { headerName?: string })[] = [{ columnId: 'headers', width: 250, headerName: 'Period' }]

  for (let i = currentYear - 5; i <= currentYear + 5; i++) {
    columns.push({ columnId: `${i}-Q1`, headerName: `Q1 ${i}` })
    columns.push({ columnId: `${i}-Q2`, headerName: `Q2 ${i}` })
    columns.push({ columnId: `${i}-Q3`, headerName: `Q3 ${i}` })
    columns.push({ columnId: `${i}-Q4`, headerName: `Q4 ${i}` })
    columns.push({ columnId: `${i}`, headerName: `CY ${i}` })
  }

  columns.push({ columnId: 'ltm', headerName: 'LTM' })
  columns.push({ columnId: 'ntm', headerName: 'NTM' })

  return columns
}

function headerRow(cols): Row<HeaderCell> {
  return {
    rowId: 'header',
    cells: cols.map((col) => {
      const { columnId, headerName } = col
      const text = headerName
      const hasChildren = columnId.length === 4
      const isYear = columnId.length === 4
      const isExpanded = cols.filter((col) => col.columnId.includes(columnId)).length > 1
      return { type: 'horizontalChevron', columnId: columnId, text, hasChildren, isExpanded, className: isYear ? 'year' : '' }
    }),
    height: 38,
  }
}

const keyToHeader = {
  totalRevenue: { displayName: 'Total Revenue ($)', type: 'currencyCell', className: 'cell' },
  totalRevGrowthPercent: { displayName: 'Total Rev Growth (%)', type: 'percentCell', className: 'cell' },
  totalARR: { displayName: 'Total ARR ($)', type: 'currencyCell', className: 'cell' },
  arrGrowthPercent: { displayName: 'ARR Growth (%)', type: 'percentCell', className: 'cell' },
  totalGrossProfit: { displayName: 'Total Gross Profit ($)', type: 'currencyCell', className: 'cell' },
  grossMarginPercent: { displayName: 'Gross Margin (%)', type: 'percentCell', className: 'cell' },
  totalEBITDA: { displayName: 'Total EBITDA ($)', type: 'currencyCell', className: 'cell' },
  eBITDAMarginPercent: { displayName: 'EBITDA Margin (%)', type: 'percentCell', className: 'cell' },
  totalFCF: { displayName: 'Total FCF ($)', type: 'currencyCell', className: 'cell' },
  fcfMarginPercent: { displayName: 'FCF Margin (%)', type: 'percentCell', className: 'cell' },
  gnaAsPercentOfRev: { displayName: 'G&A as % of Rev', type: 'percentCell', className: 'cell' },
  snmAsPercentOfRev: { displayName: 'S&M as % of Rev', type: 'percentCell', className: 'cell' },
  rndAsPercentOfRev: { displayName: 'R&D as % of Rev', type: 'percentCell', className: 'cell' },
  grossRetentionPercent: { displayName: 'Gross Retention %', type: 'percentCell', className: 'cell' },
  netRetentionPercent: { displayName: 'Net Retention %', type: 'percentCell', className: 'cell' },
  salesEfficiencyMultiple: { displayName: 'Sales Efficiency (0.0x)', type: 'multipleCell', className: 'cell' },
  netNewARR: { displayName: 'Net New ARR ($)', type: 'currencyCell', className: 'cell' },
  netNewARRGrowthPercent: { displayName: 'Net New ARR Growth %', type: 'percentCell', className: 'cell' },
  arrPerEmployee: { displayName: 'ARR $ / Employee', type: 'currencyCell', className: 'cell' },
  annualizedOpexPerEmployee: { displayName: 'Annualized Opex $ / Employee', type: 'currencyCell', className: 'cell' },
  burnMultiple: { displayName: 'Burn Multiple (0.0x)', type: 'multipleCell', className: 'cell' },
  operatingIncomePercent: { displayName: 'Operating Income %', type: 'percentCell', className: 'cell' },
  ruleOf40LTMFCF: { displayName: 'Rule of 40 (LTM FCF)', type: 'percentCell', className: 'cell' },
  ruleOf40LTMEbitda: { displayName: 'Rule of 40 (LTM EBITDA)', type: 'percentCell', className: 'cell' },
  salesAndMarketingYield: { displayName: 'Sales and Marketing Yield', type: 'percentCell', className: 'cell' },
  implied5yrLTVPerCAC: { displayName: 'Implied 5yr LTV/CAC', type: 'multipleCell', className: 'cell' },
}

type RowCells = NumberCell | HorizontalChevronCell | HeaderCell | CurrencyCell | DefaultCellTypes | PercentCell

function getRowFor(data: FinancialDataV2[], colIds: string[], key: string): Row<RowCells> {
  const details = keyToHeader[key]
  const headerName = details.displayName || 'N/A'
  return {
    rowId: key,
    cells: [
      ...colIds.map<RowCells>((colId, idx) => {
        if (idx === 0) return nonEditable({ type: 'headerCell', text: headerName })
        const item = findDataForColId(colId, data)
        let value = item?.[key] || ''
        return { type: details.type, value, className: details.className || 'cell', nanToZero: true }
      }),
    ],
    height: 38,
  }
}

function createHeaderRow(name: string, colIds: string[]): Row<RowCells> {
  return {
    rowId: `header-${name}`,
    cells: [
      ...colIds.map<RowCells>((colId, idx) => {
        const text = idx === 0 ? name : ''
        return nonEditable({ type: 'headerCell', text, className: 'header-section' })
      }),
    ],
    height: 50,
  }
}

const getRows = (data: FinancialDataV2[], cols: Column[]): Row<RowCells>[] => {
  const colIds = cols.map((col) => col.columnId.toString())
  return [
    headerRow(cols),
    createHeaderRow('Financials', colIds),
    getRowFor(data, colIds, 'totalRevenue'),
    getRowFor(data, colIds, 'totalRevGrowthPercent'),
    getRowFor(data, colIds, 'totalARR'),
    getRowFor(data, colIds, 'arrGrowthPercent'),
    getRowFor(data, colIds, 'totalGrossProfit'),
    getRowFor(data, colIds, 'grossMarginPercent'),
    getRowFor(data, colIds, 'totalEBITDA'),
    getRowFor(data, colIds, 'eBITDAMarginPercent'),
    getRowFor(data, colIds, 'totalFCF'),
    getRowFor(data, colIds, 'fcfMarginPercent'),
    createHeaderRow('Operating Expense Metrics', colIds),
    getRowFor(data, colIds, 'gnaAsPercentOfRev'),
    getRowFor(data, colIds, 'snmAsPercentOfRev'),
    getRowFor(data, colIds, 'rndAsPercentOfRev'),
    createHeaderRow('Growth Efficiency Metrics', colIds),
    getRowFor(data, colIds, 'grossRetentionPercent'),
    getRowFor(data, colIds, 'netRetentionPercent'),
    getRowFor(data, colIds, 'salesEfficiencyMultiple'),
    getRowFor(data, colIds, 'netNewARR'),
    getRowFor(data, colIds, 'netNewARRGrowthPercent'),
    createHeaderRow('Efficiency Metrics', colIds),
    getRowFor(data, colIds, 'arrPerEmployee'),
    getRowFor(data, colIds, 'annualizedOpexPerEmployee'),
    getRowFor(data, colIds, 'burnMultiple'),
    getRowFor(data, colIds, 'operatingIncomePercent'),
    getRowFor(data, colIds, 'ruleOf40LTMFCF'),
    getRowFor(data, colIds, 'ruleOf40LTMEbitda'),
    getRowFor(data, colIds, 'salesAndMarketingYield'),
    getRowFor(data, colIds, 'implied5yrLTVPerCAC'),
  ]
}

function findDataForColId(colId: string, data: FinancialDataV2[]) {
  const type = getTypeFromColId(colId)
  if (type.includes('tm')) return data.find((item) => item.type === type)
  const [year, quarter] = getYearQuarter(colId)
  const item = data.find((item) => item.year === year && (item.quarter || undefined) === quarter)
  return item
}

function getYearQuarter(colId: string): [number, number | undefined] {
  const [year, quarter] = colId.split('-')
  const outQuarter = quarter ? parseInt(quarter[1]) : undefined
  return [parseInt(year), outQuarter]
}

function getTypeFromColId(id: string) {
  if (id.includes('tm')) return id
  if (id.includes('Q')) return 'quarterly'
  return 'yearly'
}

type FinancialInputProps = {
  submit: (data: FinancialDataV2[]) => Promise<void>
  initState: FinancialDataV2[]
}

const FinancialInput: FunctionComponent<FinancialInputProps> = ({ submit, initState }) => {
  const [data, setData] = useState<FinancialDataV2[]>([...initState])
  const [sending, setSending] = useState<boolean>(false)
  const [columns, setCols] = useState<Column[]>(getColumns().filter((predicate) => !predicate.columnId.toString().includes('-')))
  const rows = getRows(data, columns)
  const location = useLocation()

  const handleChanges = (changes: CellChange<RowCells>[]) => {
    let updateData = [...data]

    changes.forEach((change) => {
      const fieldName = change.rowId
      const colId = change.columnId.toString()
      const oldItem = findDataForColId(colId, updateData)
      if (change.type === 'horizontalChevron') {
        const search = `${change.newCell.columnId.toString()}-`
        if (change.newCell.isExpanded) {
          const colsToAdd = getColumns().filter((col) => col.columnId.toString().includes(search))
          // remove header col
          const newCols = [...columns.slice(1), ...colsToAdd]
          // sort by name without header col. this addes new cols in the correct order after the year
          newCols.sort((a, b) => a.columnId.toString().localeCompare(b.columnId.toString()))
          // add header col back
          setCols([columns[0], ...newCols])
        } else {
          setCols(columns.filter((col) => !col.columnId.toString().includes(search)))
        }
        return
      }
      let newValue = (change as CellChange<any>).newCell.value
      if (change.type === 'percentCell') {
        newValue = newValue / 100
      }
      if (!oldItem) {
        const [year, quarter] = getYearQuarter(colId)
        updateData.push({
          type: getTypeFromColId(colId),
          year: year,
          quarter: quarter,
          [fieldName]: newValue,
        })
      } else {
        updateData = updateData.map((item) => {
          if (item === oldItem) {
            return {
              ...item,
              [fieldName]: newValue,
            }
          }
          return item
        })
      }
    })
    setData(updateData)
  }

  return (
    <>
      {location.pathname.includes('financials') && <CompanyHeader />}
      <GlassCard
        header='Financials'
        level={0}
        headerButtons={
          <Button
            isLoading={sending}
            loadingText='Saving...'
            variation='primary'
            size='small'
            className='save'
            onClick={() => {
              setSending(true)
              submit(data)
                .catch((e) => console.error('submit error', e))
                .finally(() => setSending(false))
            }}
          >
            Save changes
          </Button>
        }
      >
        <ScrollView className='financialsGrid'>
          <ReactGrid
            rows={rows}
            columns={columns}
            onCellsChanged={handleChanges as any}
            customCellTemplates={{
              horizontalChevron: new HorizontalChevronCellTemplate(),
              currencyCell: new CurrencyCellTemplate(),
              multipleCell: new MultipleCellTemplate(),
              percentCell: new PercentCellTemplate(),
              headerCell: new HeaderCellTemplate(),
            }}
            enableRangeSelection
            enableFillHandle
            stickyLeftColumns={1}
          />
        </ScrollView>
      </GlassCard>
    </>
  )
}

export default FinancialInput
