import { FunctionComponent } from 'react'
import InputTable from '../components/InputTable'
import { ParticipatedInvestments } from '../hooks/useInvestments'
import '../styles/companyOverview.css'
import GlassCard from './GlassCard'

interface CompanyOverviewProps {
  investments?: ParticipatedInvestments[]
  isEditable: boolean
  setUpdate: (update: Record<string, string>) => void
}

const Cols = [
  {
    header: 'Company Name',
    accessorKey: `companyLabel`,
    meta: {
      type: 'link',
      valueToLink: (i: string, row) => `/profiles/${row.companyName}`,
    },
  },
  {
    header: 'Tier',
    accessorKey: 'tier',
    meta: {
      type: 'viewOnlyString',
    },
  },
  {
    header: 'Coverage',
    accessorKey: 'coverage',
    meta: {
      type: 'viewOnlyString',
    },
  },
  {
    header: 'Point Person',
    accessorKey: 'pointPerson',
    meta: {
      type: 'viewOnlyString',
    },
  },
  {
    header: 'Next Raise Date',
    accessorKey: 'nextRaiseDate',
    meta: {
      type: 'viewOnlyDate',
    },
  },
  {
    header: 'Next Touchpoint',
    accessorKey: 'nextTouchpoint',
    meta: {
      type: 'viewOnlyDate',
    },
  },
]

const InvestmentsView: FunctionComponent<CompanyOverviewProps> = ({ investments, isEditable, setUpdate }) => {
  if (!investments || !investments.length) return null
  const data = investments.map((i) => ({
    companyLabel: i.organization_identifier.value,
    companyName: i.organization_identifier.permalink,
    tier: i.relationship?.Tier || '',
    coverage: i.relationship?.Coverage || '-',
    pointPerson: i.relationship?.['Point Person'] || '-',
    nextRaiseDate: i.relationship?.['Next Raise Date'] || '',
    nextTouchpoint: i.relationship?.['Next Touchpoint'] || '',
  }))

  return (
    <GlassCard header='Investments' className='overlayCard'>
      <InputTable data={data} columns={Cols} paginate enableGrouping={true} />
    </GlassCard>
  )
}

export default InvestmentsView
