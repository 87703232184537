import { Button, Divider, Flex, Text } from '@aws-amplify/ui-react'
import { FunctionComponent, useState } from 'react'
import { getGroupNames, useSetUser, useUser } from '../contexts/userContext'
import { uniqValue } from '../utils/utils'
import UserCompanyList from './UserCompanyList'

const INIT_COMP_NAME = 'New Company'

type UserCompanyGroupsType = {
  handlePageUpdate: () => void
}

const UserCompanyGroups: FunctionComponent<UserCompanyGroupsType> = ({ handlePageUpdate }) => {
  const user = useUser()
  const setUser = useSetUser()
  const [loadingNewGroup, setLoading] = useState(false)

  const groups = user?.groups ?? []
  const groupNames = getGroupNames(user)
  return (
    <Flex direction='column'>
      <Flex alignItems='flex-start'>
        <Button
          variation='primary'
          size='small'
          margin='0'
          isLoading={loadingNewGroup}
          onClick={async () => {
            setLoading(true)
            const groupName = uniqValue('New List', groupNames)

            await setUser({
              groups: [
                {
                  name: INIT_COMP_NAME,
                  groupName,
                },
                ...groups,
              ],
            })
              .catch((e) => console.error('setUser error', e))
              .finally(() => setLoading(false))
          }}
        >
          + Add List
        </Button>
      </Flex>
      <Divider />
      {groupNames.map((groupName, index) => {
        const groupList = groups.filter((g) => g.groupName === groupName && g.name !== INIT_COMP_NAME).map((g) => g.name)
        return (
          <Flex direction='column' key={'FlexUserCompanyGroup' + groupName}>
            <UserCompanyList
              key={'UserCompanyGroup' + groupName}
              initListName={groupName}
              initList={groupList}
              submit={async (g) => {
                const oldGroups = groups.filter((g) => g.groupName !== groupName)
                const newGroups = oldGroups.concat(g)
                if (g.length === 0) return alert('Please select at least one company to save list.')
                await setUser({ groups: newGroups }).then(() => handlePageUpdate())
              }}
              deleteGroup={async () => {
                const oldGroups = groups.filter((g) => g.groupName !== groupName)
                await setUser({ groups: oldGroups })
              }}
            />
          </Flex>
        )
      })}
      {groupNames.length !== 0 ? null : (
        <Text>
          Click <strong>+ Create List</strong> to create a list of companies to use throughout the app.
        </Text>
      )}
    </Flex>
  )
}

export default UserCompanyGroups
