import { useQuery } from '@tanstack/react-query'
import { restAPIRequest } from '../services/restApiRequest'
import { defaultRetry } from '../utils/utils'

type Relationship = {
  'VC Firm Name': string
  Coverage: string
  'Point Person': string
  Tier: string
}

type RelationshipProps = {
  firmNames: string[]
  enabled?: boolean
}

export function useRelationships({ firmNames, enabled = true }: RelationshipProps): [Relationship[] | undefined, boolean, Error | undefined] {
  const { data, isLoading, error } = useQuery({
    queryKey: ['relationships', firmNames.join(',')],
    enabled,
    queryFn: queryConfigTable(firmNames),
    retry: defaultRetry,
  })

  return [data, isLoading, error as Error | undefined]
}

export function queryConfigTable(firmNames: string[]): () => Promise<Relationship[]> {
  return async () =>
    restAPIRequest<Relationship[]>({
      path: `relationships`,
      method: 'post',
      body: {
        firmNames,
      },
    })
}
