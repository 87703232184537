import { DataStore } from '@aws-amplify/datastore'
import { Flex, Loader } from '@aws-amplify/ui-react'
import { FunctionComponent, useState } from 'react'
import AutoCompleteOptionSelect from '../components/AutoCompleteOptionSelect'
import CapitalRaiseTrxTable from '../components/CapitalRaiseTrxTable'
import { CompPicker } from '../components/CompPicker'
import { SelectOpportunityText } from '../components/SelectOpportunityText'
import { useOpportunity } from '../contexts/opportunityContext'
import { AcquisitionTransactionTable, CapitalRaiseTransactionTable, useConfigTable } from '../hooks/useConfigTable'
import { Opportunity } from '../models'
import AcquisitionTrxTable from '../components/AcquisitionTrxTable'
import { Option } from '../types'

type TransactionCompsProps = {
  type: 'acquisition' | 'capitalraise'
}
const TransactionComps: FunctionComponent<TransactionCompsProps> = ({ type }) => {
  const opportunity = useOpportunity()
  const setKey = type === 'acquisition' ? 'transactionAcquisitionCompSet' : 'transactionCapitalRaiseCompSet'
  const comps = opportunity?.[setKey] || []
  const [isSending, setIsSending] = useState<boolean>(false)
  const [adding, setAdding] = useState<boolean>(comps.length === 0)
  const tableName = type === 'acquisition' ? 'cyber_transactions_acquisition' : 'cyber_transaction_capitalraise'
  const [transactions, loadingTransaction] = useConfigTable(tableName)
  const chartData = transactions
    .filter((f) => comps.includes(f.playbook_transaction_name))
    .map((trx) => {
      if (type === 'acquisition') {
        const info = trx as AcquisitionTransactionTable
        return {
          announceDate: info.announced_date && new Date(info.announced_date),
          name: info.acquiree_name,
          type: info.acquisition_type,
          leadInvestor: info.acquirer_name,
          acquisitionTerms: info.acquisition_terms,
          price: info.price_currency_in_usd,
        }
      }
      const info = trx as CapitalRaiseTransactionTable
      return {
        announceDate: info.announced_date && new Date(info.announced_date),
        name: info.organization_name,
        fundingType: info.funding_type,
        leadInvestors: info.lead_investors,
        amountRaised: info.money_raised_currency_in_usd,
        preMoneyValuation: info.pre_money_valuation_currency_in_usd,
      }
    })

  if (!opportunity) {
    return <SelectOpportunityText />
  }

  if (loadingTransaction.get) {
    return <Loader />
  }

  async function sendComps(selectedComps: string[]) {
    if (!opportunity) throw new Error('no opportunity')
    try {
      await DataStore.save(
        Opportunity.copyOf(opportunity, (updated) => {
          Object.assign(updated, {
            [setKey]: selectedComps,
          })
        })
      )
    } catch (error: any) {
      console.error('transactionCompSet: Error saving to datastore', error)
    }
  }

  const submit = (opts: Option[]) => {
    if (isSending) return
    setIsSending(true)
    const selectedComps = opts.map((o) => o.value)
    sendComps(selectedComps)
      .catch((err) => {
        alert(err)
      })
      .finally(() => {
        setIsSending(false)
        setAdding(false)
      })
  }

  if (!adding && comps?.length) {
    return (
      <Flex direction='column'>
        <CompPicker
          compSet={comps}
          onEditClick={() => {
            setAdding(true)
          }}
        />
        {type === 'acquisition' ? <AcquisitionTrxTable data={chartData} /> : <CapitalRaiseTrxTable data={chartData} />}
      </Flex>
    )
  }

  const options = transactions.map((trx) => {
    return {
      value: trx.playbook_transaction_name,
      label: trx.playbook_transaction_name,
    }
  })
  const initSelected =
    comps.map((x) => {
      return {
        value: x,
        label: x,
      }
    }) || []

  return (
    <Flex direction='column'>
      <AutoCompleteOptionSelect
        placeHolder='Select comp set'
        suggestedOptions={[]}
        otherOptions={options}
        optionsLoading={loadingTransaction.get}
        isSending={isSending}
        submit={submit}
        initSelected={initSelected}
      />
    </Flex>
  )
}

export default TransactionComps
