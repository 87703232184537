/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function CkChevronUp(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="24px"
      height="24px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 24, height: 24 }}
      paths={[
        {
          d: "M7 0L0 6.47773L1.645 8L7 3.05533L12.355 8L14 6.47773L7 0Z",
          fill: "rgba(61,67,87,1)",
          fillRule: "nonzero",
          style: {
            transform:
              "translate(calc(50% - 7px - 0px), calc(50% - 4px - 0px))",
          },
        },
      ]}
      {...getOverrideProps(overrides, "CkChevronUp")}
      {...rest}
    ></Icon>
  );
}
