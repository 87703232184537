import { Flex, Text } from '@aws-amplify/ui-react'
import MissingDataCard from './missingDataCard'

export const SelectOpportunityText = () => {
  return (
    <MissingDataCard>
      <Flex direction='column'>
        <Text>Select an existing deal or create a new deal for a company.</Text>
      </Flex>
    </MissingDataCard>
  )
}
