import { Flex, Loader, Text } from '@aws-amplify/ui-react'
import { useQuery } from '@tanstack/react-query'
import { DataStore } from 'aws-amplify/datastore'
import { FunctionComponent } from 'react'
import MissingDataCard from '../components/missingDataCard'
import { useUser } from '../contexts/userContext'
import ChatView, { QuickPrompts } from '../figma-components/ChatView'
import GlassCard from '../figma-components/GlassCard'
import { User } from '../models'
import { restAPIRequest } from '../services/restApiRequest'
import { DELIM, parseRestError } from '../utils/utils'

export type ChatRes = {
  msg: string
  sources: string[]
}
interface WebsiteChatProps {
  targetCompany: string
  websiteSources?: string[]
}
const WebsiteChat: FunctionComponent<WebsiteChatProps> = ({ targetCompany, websiteSources = [] }) => {
  const user = useUser()
  const targetInfo = (user?.websiteChatsV2 || []).find((c) => {
    return c.target === targetCompany
  }) || {
    target: targetCompany,
    chat: [],
  }

  const currentChat = targetInfo?.chat || []
  const { isLoading, error } = useQuery<ChatRes, any>({
    enabled: !!targetCompany,
    queryKey: ['ai/chatSite', targetCompany],
    retryDelay: 60000,
    retry: (failureCount, error) => {
      if (error?.response?.status === 405) {
        return false
      }
      if (failureCount > 1) {
        return false
      }
      return true
    },
    staleTime: 1000 * 60 * 60 * 24,
    queryFn: async () =>
      restAPIRequest<ChatRes>({
        method: 'post',
        path: 'ai/chatSite',
        body: {
          targetCompany,
          question: DELIM,
        },
      }),
  })

  if (!user) {
    return (
      <GlassCard header='oops!'>
        <Text> We ran into an issue loading chat. Please logout and try again </Text>
      </GlassCard>
    )
  }

  const generateResponse = async (question: string) => {
    if (!question) return alert('Please enter a question')

    try {
      const sent = Math.floor(Date.now() / 1000)
      const response = await restAPIRequest<ChatRes>({
        method: 'post',
        path: 'ai/chatSite',
        body: {
          targetCompany,
          question,
          history: currentChat,
        },
      })
      if (!response.msg) {
        alert('Sorry we had issues getting your response. Please rephrase the question or try again.')
        console.error('empty response')
        throw new Error('empty response')
      }

      const chat = [
        ...currentChat,
        {
          role: 'user',
          content: question,
          timestamp: sent,
        },
        {
          role: 'assistant',
          content: response.msg,
          timestamp: Math.floor(Date.now() / 1000),
          sources: response.sources,
        },
      ]

      await DataStore.save(
        User.copyOf(user, (updated) => {
          const existing = updated?.websiteChatsV2?.filter((c) => c.target !== targetCompany) || []
          Object.assign(updated, {
            websiteChatsV2: [...existing, { ...targetInfo, chat }],
          })
        })
      )
    } catch (error: any) {
      console.error('error getting chat', parseRestError(error))
      alert('Sorry we had issues getting your response. Please rephrase the question or try again.')
      throw error
    }
  }

  const clear = async () => {
    if (!user) return
    await DataStore.save(
      User.copyOf(user, (updated) => {
        Object.assign(updated, {
          websiteChatsV2: updated?.websiteChatsV2?.map((c) => {
            if (c.target === targetCompany) return { ...c, chat: [] }

            return c
          }),
        })
      })
    )
  }

  const quickPrompts: QuickPrompts[] = [
    {
      label: "What is the company's mission?",
      prompt: "You are an expert investor. What is the company's mission?",
    },
    {
      label: 'Key products?',
      prompt: `You are an expert investor. What are the key products offered by ${targetCompany}?`,
    },
    {
      label: 'Competitive advantages?',
      prompt: `You are an expert investor. What are the key competitive advantages of ${targetCompany}?`,
    },
  ]

  if (isLoading) {
    return (
      <MissingDataCard>
        <Flex>
          <Loader />
          <Text>Loading Website Data</Text>
        </Flex>
      </MissingDataCard>
    )
  }

  const placeholder = error ? `Please start a deal to chat with ${targetCompany} website` : `Ask Playbook AI anything about ${targetCompany}...`
  return (
    <ChatView
      header='Playbook AI'
      quickPrompts={quickPrompts}
      disabled={error}
      enabled={true}
      placeholder={placeholder}
      history={currentChat}
      postQuestion={generateResponse}
      clearClicked={clear}
      websiteSources={websiteSources}
    />
  )
}

export default WebsiteChat
