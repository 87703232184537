import { Button, Flex, TextField } from '@aws-amplify/ui-react'
import { FunctionComponent, useState } from 'react'
import { useAmplifyUser } from '../contexts/amplifyUserContext'
import { CompanyGroups } from '../models'
import { CB_LIST_EXCEL_IMPORT, enabledForOrg } from '../services/featureFlags'
import { Option } from '../types'
import { removeDuplicateValues } from '../utils/utils'
import CrunchbaseNameExcelInput from './CrunchbaseNameExcelInput'
import ProfileSearchMulti from './ProfileSearchMulti'

type UserCompanyGroupProps = {
  initListName: string
  initList: string[]
  submit: (g: CompanyGroups[]) => Promise<void>
  deleteGroup: () => Promise<void>
}
const UserCompanyList: FunctionComponent<UserCompanyGroupProps> = ({ initListName, initList, submit, deleteGroup }) => {
  const [listName, setListName] = useState<string>(initListName)
  const [sending, setSending] = useState<boolean>(false)
  const [deleting, setDeleting] = useState<boolean>(false)
  const amplifyUser = useAmplifyUser()
  const orgGroups = amplifyUser?.groups
  const excelImportEnabled = enabledForOrg(orgGroups, CB_LIST_EXCEL_IMPORT)
  return (
    <Flex direction='column'>
      <Flex>
        <TextField
          label={'listName'}
          defaultValue={initListName}
          variation='quiet'
          size='small'
          labelHidden
          placeholder='Enter list name'
          onChange={(e) => {
            setListName(e.target.value)
          }}
        />
      </Flex>
      {excelImportEnabled && (
        <CrunchbaseNameExcelInput
          onUpload={async (data) => {
            try {
              setSending(true)
              // for (const name of data) {
              //   await restAPIRequest({
              //     path: `companyProfile/${name}/updateAi`,
              //     body: {
              //       cb_organizationPermalink: name,
              //       wait: true,
              //       skipAi: true,
              //     },
              //     method: 'post',
              //   })
              // }
              const update = removeDuplicateValues(data.concat(initList)).map((d) => {
                return {
                  name: d,
                  groupName: listName,
                }
              })

              await submit(update)
            } catch (error) {
              console.error('submit error', error)
            } finally {
              setSending(false)
            }
          }}
        />
      )}
      <ProfileSearchMulti
        initSelected={initList}
        isSending={sending}
        placeHolder={'Select companies to add to list...'}
        saveButtonText='Save List'
        submit={function (items: Option[]): void {
          const out = items.map((item) => {
            return {
              name: item.value,
              groupName: listName,
            }
          })
          setSending(true)
          submit(out)
            .catch((e) => console.error('submit error', e))
            .finally(() => setSending(false))
        }}
      />

      <Button
        isLoading={deleting}
        variation='destructive'
        size='small'
        alignSelf='flex-end'
        onClick={() => {
          setDeleting(true)
          deleteGroup()
            .catch((e) => console.error('deleteGroup error', e))
            .finally(() => setDeleting(false))
        }}
      >
        Delete List
      </Button>
    </Flex>
  )
}

export default UserCompanyList
