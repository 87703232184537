import { Button, Flex, Heading, Text } from '@aws-amplify/ui-react'
import { DataStore } from 'aws-amplify/datastore'
import { FunctionComponent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CompanyHeader from '../components/CompanyHeader'
import FileUploader from '../components/FileUploader'
import { SelectOpportunityText } from '../components/SelectOpportunityText'
import { useOpportunity } from '../contexts/opportunityContext'
import { useUser } from '../contexts/userContext'
import GlassCard from '../figma-components/GlassCard'
import SelectableDocListView from '../figma-components/SelectableDocListView'
import { User } from '../models'
import { deleteFileFromOpportunity } from '../services/fileStorage'
import { DOC_CHAT, getPathTo } from '../sitemap'
import { ChatIcon } from '../ui-components'

const DocListPage: FunctionComponent = () => {
  const opportunity = useOpportunity()
  const user = useUser()
  const documents = opportunity?.files || []
  const [selectedS3Keys, setSelected] = useState<string[]>([])
  const navigate = useNavigate()

  if (!opportunity) return <SelectOpportunityText />
  const chatsForOppy = user?.docChats?.filter((c) => c.target === opportunity.id && c.chat?.length) || []
  const createDocChat = async () => {
    if (!user) {
      return <Text>Login again</Text>
    }
    if (!selectedS3Keys.length) {
      return alert('Please select at least one document to chat with')
    }
    const id = crypto.randomUUID()
    await DataStore.save(
      User.copyOf(user, (updated) => {
        Object.assign(updated, {
          docChats: [
            ...(updated.docChats || []),
            {
              id,
              target: opportunity.id,
              selectedDocs: selectedS3Keys,
              chat: [],
            },
          ],
        })
      })
    )

    navigate(
      getPathTo(DOC_CHAT, {
        chatId: id,
        opportunityId: opportunity.id,
      })
    )
  }

  const selectExistingDocs = () => {
    if (!documents.length) return
    return (
      <GlassCard header='Select existing file(s) to chat with:' level={2}>
        <SelectableDocListView
          docs={documents}
          selectedDocs={[]}
          deleteClicked={(s3Key) => {
            deleteFileFromOpportunity(opportunity, s3Key)
          }}
          updateSelectedDocs={setSelected}
          maxHeight='500px'
        />
      </GlassCard>
    )
  }

  const selectExistingChat = () => {
    if (!documents.length) return
    if (!chatsForOppy.length) return
    return (
      <GlassCard header='Return to chat:' level={2}>
        {chatsForOppy.map((chat) => {
          const docsNames = chat.selectedDocs.map((doc) => {
            return documents.find((d) => d.s3Key === doc)?.name
          })
          const lastChat = chat?.chat?.[chat?.chat?.length - 1]?.timestamp || new Date()
          const msg = `Docs: ${docsNames.join('; ')} - Date: ${new Date(lastChat).toLocaleString()}`
          return (
            <Flex key={chat.id} direction='column'>
              <Button
                onClick={() => {
                  navigate(
                    getPathTo(DOC_CHAT, {
                      chatId: chat.id,
                      opportunityId: opportunity.id,
                    })
                  )
                }}
              >
                {msg}
              </Button>
            </Flex>
          )
        })}
      </GlassCard>
    )
  }

  return (
    <>
      <CompanyHeader />
      <GlassCard header='Document AI' level={0}>
        <GlassCard header='Upload file' level={2}>
          <FileUploader dropzoneEnabled={true} />
        </GlassCard>
        {documents.length > 0 && (
          <Heading alignSelf='center' level={3}>
            OR
          </Heading>
        )}
        {selectExistingDocs()}
        {selectExistingChat()}
        <Button variation='primary' size='small' className='aiButton' alignSelf='center' onClick={createDocChat}>
          <ChatIcon />
          <Text>Start Chat</Text>
        </Button>
      </GlassCard>
    </>
  )
}

export default DocListPage
