import { Flex, Heading, SelectField, Table, TableBody, TableCell, TableHead, TableRow } from '@aws-amplify/ui-react'
import { FunctionComponent, useState } from 'react'
import { useOpportunity } from '../contexts/opportunityContext'
import GlassCard from '../figma-components/GlassCard'
import { useCompanyInfoV2 } from '../hooks/useCompanyInfoV2'
import { useConfigTable } from '../hooks/useConfigTable'
import { alphaCompare, m, mean, median, str, parseDecimal } from '../utils/utils'

type TransactionModelMultiplesProps = {
  preMoneyEquityValuation: number
}

const currentYear = new Date().getFullYear()

const MEAN = 'mean'
const MEDIAN = 'median'
const LOW = 'low'
const HIGH = 'high'

const EV_LTM_REVENUE = 'EV / LTM Revenue'
const EV_LTM_ARR = 'EV / LTM ARR'
const EV_LTM_GROSS_PROFIT = 'EV / LTM Gross Profit'
const EV_LTM_EBITDA = 'EV / LTM EBITDA'

const EV_NTM_REVENUE = 'EV / NTM Revenue'
const EV_NTM_ARR = 'EV / NTM ARR'
const EV_NTM_GROSS_PROFIT = 'EV / NTM Gross Profit'
const EV_NTM_EBITDA = 'EV / NTM EBITDA'

const EV_PREV_TWO_YEARS_REVENUE = `EV / ${currentYear - 2} Revenue`
const EV_PREV_TWO_YEARS_ARR = `EV / ${currentYear - 2} ARR`
const EV_PREV_TWO_YEARS_GROSS_PROFIT = `EV / ${currentYear - 2} Gross Profit`
const EV_PREV_TWO_YEARS_EBITDA = `EV / ${currentYear - 2} EBITDA`

const EV_PREV_YEAR_REVENUE = `EV / ${currentYear - 1} Revenue`
const EV_PREV_YEAR_ARR = `EV / ${currentYear - 1} ARR`
const EV_PREV_YEAR_GROSS_PROFIT = `EV / ${currentYear - 1} Gross Profit`
const EV_PREV_YEAR_EBITDA = `EV / ${currentYear - 1} EBITDA`

const EV_CURRENT_YEAR_REVENUE = `EV / ${currentYear} Revenue`
const EV_CURRENT_YEAR_ARR = `EV / ${currentYear} ARR`
const EV_CURRENT_YEAR_GROSS_PROFIT = `EV / ${currentYear} Gross Profit`
const EV_CURRENT_YEAR_EBITDA = `EV / ${currentYear} EBITDA`

const EV_NEXT_YEAR_REVENUE = `EV / ${currentYear + 1} Revenue`
const EV_NEXT_YEAR_ARR = `EV / ${currentYear + 1} ARR`
const EV_NEXT_YEAR_GROSS_PROFIT = `EV / ${currentYear + 1} Gross Profit`
const EV_NEXT_YEAR_EBITDA = `EV / ${currentYear + 1} EBITDA`

const EV_NEXT_TWO_YEARS_REVENUE = `EV / ${currentYear + 2} Revenue`
const EV_NEXT_TWO_YEARS_ARR = `EV / ${currentYear + 2} ARR`
const EV_NEXT_TWO_YEARS_GROSS_PROFIT = `EV / ${currentYear + 2} Gross Profit`
const EV_NEXT_TWO_YEARS_EBITDA = `EV / ${currentYear + 2} EBITDA`

const TransactionModelMultiples: FunctionComponent<TransactionModelMultiplesProps> = ({ preMoneyEquityValuation }) => {
  const [bucket, setBucket] = useState<string>('All')
  const opportunity = useOpportunity()
  const [companyInfo, loading] = useCompanyInfoV2(opportunity?.name)
  const [compsFinancialData, { get: compsLoading }] = useConfigTable('public_comps')
  const compsSet = opportunity?.publicCompsCompSetV2 || []
  const compsGroupNames = ['All'].concat(Array.from(new Set(compsSet.map((c) => c.groupName))))
  const dealFinancialData = opportunity?.financialDataV2 || []

  const multiples = {
    [EV_LTM_REVENUE]: 'N/A',
    [EV_LTM_ARR]: 'N/A',
    [EV_LTM_GROSS_PROFIT]: 'N/A',
    [EV_LTM_EBITDA]: 'N/A',
    [EV_NTM_REVENUE]: 'N/A',
    [EV_NTM_ARR]: 'N/A',
    [EV_NTM_GROSS_PROFIT]: 'N/A',
    [EV_NTM_EBITDA]: 'N/A',
    [EV_PREV_TWO_YEARS_REVENUE]: 'N/A',
    [EV_PREV_TWO_YEARS_ARR]: 'N/A',
    [EV_PREV_TWO_YEARS_GROSS_PROFIT]: 'N/A',
    [EV_PREV_TWO_YEARS_EBITDA]: 'N/A',
    [EV_PREV_YEAR_REVENUE]: 'N/A',
    [EV_PREV_YEAR_ARR]: 'N/A',
    [EV_PREV_YEAR_GROSS_PROFIT]: 'N/A',
    [EV_PREV_YEAR_EBITDA]: 'N/A',
    [EV_CURRENT_YEAR_REVENUE]: 'N/A',
    [EV_CURRENT_YEAR_ARR]: 'N/A',
    [EV_CURRENT_YEAR_GROSS_PROFIT]: 'N/A',
    [EV_CURRENT_YEAR_EBITDA]: 'N/A',
    [EV_NEXT_YEAR_REVENUE]: 'N/A',
    [EV_NEXT_YEAR_ARR]: 'N/A',
    [EV_NEXT_YEAR_GROSS_PROFIT]: 'N/A',
    [EV_NEXT_YEAR_EBITDA]: 'N/A',
    [EV_NEXT_TWO_YEARS_REVENUE]: 'N/A',
    [EV_NEXT_TWO_YEARS_ARR]: 'N/A',
    [EV_NEXT_TWO_YEARS_GROSS_PROFIT]: 'N/A',
    [EV_NEXT_TWO_YEARS_EBITDA]: 'N/A',
  }

  const compsMultiples = {
    [EV_LTM_REVENUE]: [] as string[],
    [EV_LTM_ARR]: [] as string[],
    [EV_LTM_GROSS_PROFIT]: [] as string[],
    [EV_LTM_EBITDA]: [] as string[],
    [EV_NTM_REVENUE]: [] as string[],
    [EV_NTM_ARR]: [] as string[],
    [EV_NTM_GROSS_PROFIT]: [] as string[],
    [EV_NTM_EBITDA]: [] as string[],
    [EV_PREV_TWO_YEARS_REVENUE]: [] as string[],
    [EV_PREV_TWO_YEARS_ARR]: [] as string[],
    [EV_PREV_TWO_YEARS_GROSS_PROFIT]: [] as string[],
    [EV_PREV_TWO_YEARS_EBITDA]: [] as string[],
    [EV_PREV_YEAR_REVENUE]: [] as string[],
    [EV_PREV_YEAR_ARR]: [] as string[],
    [EV_PREV_YEAR_GROSS_PROFIT]: [] as string[],
    [EV_PREV_YEAR_EBITDA]: [] as string[],
    [EV_CURRENT_YEAR_REVENUE]: [] as string[],
    [EV_CURRENT_YEAR_ARR]: [] as string[],
    [EV_CURRENT_YEAR_GROSS_PROFIT]: [] as string[],
    [EV_CURRENT_YEAR_EBITDA]: [] as string[],
    [EV_NEXT_YEAR_REVENUE]: [] as string[],
    [EV_NEXT_YEAR_ARR]: [] as string[],
    [EV_NEXT_YEAR_GROSS_PROFIT]: [] as string[],
    [EV_NEXT_YEAR_EBITDA]: [] as string[],
    [EV_NEXT_TWO_YEARS_REVENUE]: [] as string[],
    [EV_NEXT_TWO_YEARS_ARR]: [] as string[],
    [EV_NEXT_TWO_YEARS_GROSS_PROFIT]: [] as string[],
    [EV_NEXT_TWO_YEARS_EBITDA]: [] as string[],
  }

  const getMultiple = (numerator: number | undefined | null, denominator: number | undefined | null): string => {
    if (!numerator || !denominator) return 'N/A'
    return m(numerator / denominator)
  }

  const getMultiples = (): void => {
    if (dealFinancialData.length === 0) return

    dealFinancialData.forEach((metric) => {
      switch (metric.type) {
        case 'ltm':
          multiples[EV_LTM_REVENUE] = getMultiple(preMoneyEquityValuation, metric.totalRevenue)
          multiples[EV_LTM_ARR] = getMultiple(preMoneyEquityValuation, metric.totalARR)
          multiples[EV_LTM_GROSS_PROFIT] = getMultiple(preMoneyEquityValuation, metric.totalGrossProfit)
          multiples[EV_LTM_EBITDA] = getMultiple(preMoneyEquityValuation, metric.totalEBITDA)
          break
        case 'ntm':
          multiples[EV_NTM_REVENUE] = getMultiple(preMoneyEquityValuation, metric.totalRevenue)
          multiples[EV_NTM_ARR] = getMultiple(preMoneyEquityValuation, metric.totalARR)
          multiples[EV_NTM_GROSS_PROFIT] = getMultiple(preMoneyEquityValuation, metric.totalGrossProfit)
          multiples[EV_NTM_EBITDA] = getMultiple(preMoneyEquityValuation, metric.totalEBITDA)
          break
        case 'yearly':
          switch (metric.year) {
            case currentYear - 2:
              multiples[EV_PREV_TWO_YEARS_REVENUE] = getMultiple(preMoneyEquityValuation, metric.totalRevenue)
              multiples[EV_PREV_TWO_YEARS_ARR] = getMultiple(preMoneyEquityValuation, metric.totalARR)
              multiples[EV_PREV_TWO_YEARS_GROSS_PROFIT] = getMultiple(preMoneyEquityValuation, metric.totalGrossProfit)
              multiples[EV_PREV_TWO_YEARS_EBITDA] = getMultiple(preMoneyEquityValuation, metric.totalEBITDA)
              break
            case currentYear - 1:
              multiples[EV_PREV_YEAR_REVENUE] = getMultiple(preMoneyEquityValuation, metric.totalRevenue)
              multiples[EV_PREV_YEAR_ARR] = getMultiple(preMoneyEquityValuation, metric.totalARR)
              multiples[EV_PREV_YEAR_GROSS_PROFIT] = getMultiple(preMoneyEquityValuation, metric.totalGrossProfit)
              multiples[EV_PREV_YEAR_EBITDA] = getMultiple(preMoneyEquityValuation, metric.totalEBITDA)
              break
            case currentYear:
              multiples[EV_CURRENT_YEAR_REVENUE] = getMultiple(preMoneyEquityValuation, metric.totalRevenue)
              multiples[EV_CURRENT_YEAR_ARR] = getMultiple(preMoneyEquityValuation, metric.totalARR)
              multiples[EV_CURRENT_YEAR_GROSS_PROFIT] = getMultiple(preMoneyEquityValuation, metric.totalGrossProfit)
              multiples[EV_CURRENT_YEAR_EBITDA] = getMultiple(preMoneyEquityValuation, metric.totalEBITDA)
              break
            case currentYear + 1:
              multiples[EV_NEXT_YEAR_REVENUE] = getMultiple(preMoneyEquityValuation, metric.totalRevenue)
              multiples[EV_NEXT_YEAR_ARR] = getMultiple(preMoneyEquityValuation, metric.totalARR)
              multiples[EV_NEXT_YEAR_GROSS_PROFIT] = getMultiple(preMoneyEquityValuation, metric.totalGrossProfit)
              multiples[EV_NEXT_YEAR_EBITDA] = getMultiple(preMoneyEquityValuation, metric.totalEBITDA)
              break
            case currentYear + 2:
              multiples[EV_NEXT_TWO_YEARS_REVENUE] = getMultiple(preMoneyEquityValuation, metric.totalRevenue)
              multiples[EV_NEXT_TWO_YEARS_ARR] = getMultiple(preMoneyEquityValuation, metric.totalARR)
              multiples[EV_NEXT_TWO_YEARS_GROSS_PROFIT] = getMultiple(preMoneyEquityValuation, metric.totalGrossProfit)
              multiples[EV_NEXT_TWO_YEARS_EBITDA] = getMultiple(preMoneyEquityValuation, metric.totalEBITDA)
              break
          }
          break
      }
    })
  }

  const getCompsMultiple = (multiples: string[], method: string): string => {
    if (multiples.length === 0) return 'N/A'
    const multiplesInt = multiples.map((m) => parseDecimal(m))
    switch (method) {
      case MEAN:
        return m(mean(multiplesInt))
      case MEDIAN:
        return m(median(multiplesInt))
      case LOW:
        return m(Math.min(...multiplesInt))
      case HIGH:
        return m(Math.max(...multiplesInt))
      default:
        return 'N/A'
    }
  }

  const getCompsMultiples = (): void => {
    if (compsLoading) return

    // filter to selected comps
    let selectedCompsFinancialData = compsFinancialData
    let selectedCompsNames = compsSet.map((c) => c.name)
    if (bucket !== 'All') {
      selectedCompsNames = compsSet.filter((c) => c.groupName === bucket).map((c) => c.name)
    }
    selectedCompsFinancialData = compsFinancialData.filter((profile) => selectedCompsNames.find((name) => alphaCompare(profile.name, name)))

    // calculate multiples for selected comps
    selectedCompsFinancialData.forEach((comp) => {
      if (comp?.enterprise_value && comp?.ltm_revenue) compsMultiples[EV_LTM_REVENUE].push(str(comp?.enterprise_value / comp?.ltm_revenue))
      if (comp?.enterprise_value && comp?.ltm_ebitda) compsMultiples[EV_LTM_EBITDA].push(str(comp?.enterprise_value / comp?.ltm_ebitda))

      let yearlyData = comp?.yearly_data?.find((y) => y.year === currentYear - 2)
      if (comp?.enterprise_value && yearlyData && yearlyData.revenue) compsMultiples[EV_PREV_TWO_YEARS_REVENUE].push(str(comp?.enterprise_value / yearlyData.revenue))
      if (comp?.enterprise_value && yearlyData && yearlyData.ebitda) compsMultiples[EV_PREV_TWO_YEARS_EBITDA].push(str(comp?.enterprise_value / yearlyData.ebitda))

      yearlyData = comp?.yearly_data?.find((y) => y.year === currentYear - 1)
      if (comp?.enterprise_value && yearlyData && yearlyData.revenue) compsMultiples[EV_PREV_YEAR_REVENUE].push(str(comp?.enterprise_value / yearlyData.revenue))
      if (comp?.enterprise_value && yearlyData && yearlyData.ebitda) compsMultiples[EV_PREV_YEAR_EBITDA].push(str(comp?.enterprise_value / yearlyData.ebitda))

      yearlyData = comp?.yearly_data?.find((y) => y.year === currentYear)
      if (comp?.enterprise_value && yearlyData && yearlyData.revenue) compsMultiples[EV_CURRENT_YEAR_REVENUE].push(str(comp?.enterprise_value / yearlyData.revenue))
      if (comp?.enterprise_value && yearlyData && yearlyData.ebitda) compsMultiples[EV_CURRENT_YEAR_EBITDA].push(str(comp?.enterprise_value / yearlyData.ebitda))

      yearlyData = comp?.yearly_data?.find((y) => y.year === currentYear + 1)
      if (comp?.enterprise_value && yearlyData && yearlyData.revenue) compsMultiples[EV_NEXT_YEAR_REVENUE].push(str(comp?.enterprise_value / yearlyData.revenue))
      if (comp?.enterprise_value && yearlyData && yearlyData.ebitda) compsMultiples[EV_NEXT_YEAR_EBITDA].push(str(comp?.enterprise_value / yearlyData.ebitda))

      yearlyData = comp?.yearly_data?.find((y) => y.year === currentYear - 1)
      if (comp?.enterprise_value && yearlyData && yearlyData.revenue) compsMultiples[EV_NEXT_TWO_YEARS_REVENUE].push(str(comp?.enterprise_value / yearlyData.revenue))
      if (comp?.enterprise_value && yearlyData && yearlyData.ebitda) compsMultiples[EV_NEXT_TWO_YEARS_EBITDA].push(str(comp?.enterprise_value / yearlyData.ebitda))
    })
  }

  // initiate state
  getMultiples()
  getCompsMultiples()

  return (
    <GlassCard header='' className='overlayCard overflow' style={{ gap: 0, width: '100%', maxHeight: '22rem' }}>
      <Flex position='sticky' top='-1rem' padding='0.5rem 0' backgroundColor='#FFF'>
        <Heading level={4}>Multiples</Heading>
        <SelectField label='Comps Bucket' size='small' marginLeft='auto' value={bucket} options={compsGroupNames} onChange={(e) => setBucket(e.target.value)} />
      </Flex>
      <Table size='small'>
        <TableHead position='sticky' top='60px'>
          <TableRow>
            <TableCell as='th' colSpan={1} className='withBorderNone' maxWidth='6rem' minWidth='4rem' backgroundColor='#FFF'></TableCell>
            <TableCell as='th' colSpan={1} className='groupTableHeaderContainer groupTableHeader' maxWidth='6rem' minWidth='4rem'>
              {!loading && (companyInfo?.companyLabel || companyInfo?.companyName)}
            </TableCell>
            <TableCell as='th' colSpan={4} className='groupTableHeaderContainer groupTableHeader' maxWidth='6rem' minWidth='4rem'>
              Comps
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell as='th' colSpan={1} className='withBorderNone' maxWidth='6rem' minWidth='4rem' backgroundColor='#FFF' />
            <TableCell as='th' colSpan={1} className='groupTableHeader' maxWidth='6rem' minWidth='4rem'>
              Target
            </TableCell>
            <TableCell as='th' colSpan={1} className='groupTableHeader' maxWidth='6rem' minWidth='4rem'>
              Mean
            </TableCell>
            <TableCell as='th' colSpan={1} className='groupTableHeader' maxWidth='6rem' minWidth='4rem'>
              Median
            </TableCell>
            <TableCell as='th' colSpan={1} className='groupTableHeader' maxWidth='6rem' minWidth='4rem'>
              Low
            </TableCell>
            <TableCell as='th' colSpan={1} className='groupTableHeader' maxWidth='6rem' minWidth='4rem'>
              High
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell colSpan={1} className='withBorderNone' minWidth='8rem' />
            <TableCell colSpan={5} className='groupTableHeader' minWidth='8rem' style={{ borderTop: '2px solid black' }}>
              Revenue
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='tableCell' fontWeight='500'>
              {EV_LTM_REVENUE}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {multiples[EV_LTM_REVENUE]}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_LTM_REVENUE], MEAN)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_LTM_REVENUE], MEDIAN)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_LTM_REVENUE], LOW)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_LTM_REVENUE], HIGH)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='tableCell' fontWeight='500'>
              {EV_NTM_REVENUE}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {multiples[EV_NTM_REVENUE]}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='tableCell' fontWeight='500'>
              {EV_PREV_TWO_YEARS_REVENUE}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {multiples[EV_PREV_TWO_YEARS_REVENUE]}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_PREV_TWO_YEARS_REVENUE], MEAN)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_PREV_TWO_YEARS_REVENUE], MEDIAN)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_PREV_TWO_YEARS_REVENUE], LOW)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_PREV_TWO_YEARS_REVENUE], HIGH)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='tableCell' fontWeight='500'>
              {EV_PREV_YEAR_REVENUE}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {multiples[EV_PREV_YEAR_REVENUE]}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_PREV_YEAR_REVENUE], MEAN)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_PREV_YEAR_REVENUE], MEDIAN)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_PREV_YEAR_REVENUE], LOW)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_PREV_YEAR_REVENUE], HIGH)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='tableCell' fontWeight='500'>
              {EV_CURRENT_YEAR_REVENUE}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {multiples[EV_CURRENT_YEAR_REVENUE]}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_CURRENT_YEAR_REVENUE], MEAN)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_CURRENT_YEAR_REVENUE], MEDIAN)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_CURRENT_YEAR_REVENUE], LOW)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_CURRENT_YEAR_REVENUE], HIGH)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='tableCell' fontWeight='500'>
              {EV_NEXT_YEAR_REVENUE}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {multiples[EV_NEXT_YEAR_REVENUE]}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_NEXT_YEAR_REVENUE], MEAN)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_NEXT_YEAR_REVENUE], MEDIAN)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_NEXT_YEAR_REVENUE], LOW)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_NEXT_YEAR_REVENUE], HIGH)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='tableCell' fontWeight='500'>
              {EV_NEXT_TWO_YEARS_REVENUE}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {multiples[EV_NEXT_TWO_YEARS_REVENUE]}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_NEXT_TWO_YEARS_REVENUE], MEAN)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_NEXT_TWO_YEARS_REVENUE], MEDIAN)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_NEXT_TWO_YEARS_REVENUE], LOW)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_NEXT_TWO_YEARS_REVENUE], HIGH)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={1} className='withBorderNone' minWidth='8rem' />
            <TableCell colSpan={5} className='groupTableHeader' minWidth='8rem'>
              ARR
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='tableCell' fontWeight='500'>
              {EV_LTM_ARR}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {multiples[EV_LTM_ARR]}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='tableCell' fontWeight='500'>
              {EV_NTM_ARR}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {multiples[EV_NTM_ARR]}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='tableCell' fontWeight='500'>
              {EV_PREV_TWO_YEARS_ARR}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {multiples[EV_PREV_TWO_YEARS_ARR]}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='tableCell' fontWeight='500'>
              {EV_PREV_YEAR_ARR}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {multiples[EV_PREV_YEAR_ARR]}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='tableCell' fontWeight='500'>
              {EV_CURRENT_YEAR_ARR}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {multiples[EV_CURRENT_YEAR_ARR]}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='tableCell' fontWeight='500'>
              {EV_NEXT_YEAR_ARR}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {multiples[EV_NEXT_YEAR_ARR]}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='tableCell' fontWeight='500'>
              {EV_NEXT_TWO_YEARS_ARR}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {multiples[EV_NEXT_TWO_YEARS_ARR]}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={1} className='withBorderNone' minWidth='8rem' />
            <TableCell colSpan={5} className='groupTableHeader' minWidth='8rem'>
              Gross Profit
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='tableCell' fontWeight='500'>
              {EV_LTM_GROSS_PROFIT}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {multiples[EV_LTM_GROSS_PROFIT]}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='tableCell' fontWeight='500'>
              {EV_NTM_GROSS_PROFIT}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {multiples[EV_NTM_GROSS_PROFIT]}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='tableCell' fontWeight='500'>
              {EV_PREV_TWO_YEARS_GROSS_PROFIT}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {multiples[EV_PREV_TWO_YEARS_GROSS_PROFIT]}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='tableCell' fontWeight='500'>
              {EV_PREV_YEAR_GROSS_PROFIT}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {multiples[EV_PREV_YEAR_GROSS_PROFIT]}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='tableCell' fontWeight='500'>
              {EV_CURRENT_YEAR_GROSS_PROFIT}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {multiples[EV_CURRENT_YEAR_GROSS_PROFIT]}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='tableCell' fontWeight='500'>
              {EV_NEXT_YEAR_GROSS_PROFIT}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {multiples[EV_NEXT_YEAR_GROSS_PROFIT]}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='tableCell' fontWeight='500'>
              {EV_NEXT_TWO_YEARS_GROSS_PROFIT}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {multiples[EV_NEXT_TWO_YEARS_GROSS_PROFIT]}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              N/A
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={1} className='withBorderNone' minWidth='8rem' />
            <TableCell colSpan={5} className='groupTableHeader' minWidth='8rem'>
              EBITDA
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='tableCell' fontWeight='500'>
              {EV_LTM_EBITDA}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {multiples[EV_LTM_EBITDA]}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_LTM_EBITDA], MEAN)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_LTM_EBITDA], MEDIAN)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_LTM_EBITDA], LOW)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_LTM_EBITDA], HIGH)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='tableCell' fontWeight='500'>
              {EV_NTM_EBITDA}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {multiples[EV_NTM_EBITDA]}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_NTM_EBITDA], MEAN)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_NTM_EBITDA], MEDIAN)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_NTM_EBITDA], LOW)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_NTM_EBITDA], HIGH)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='tableCell' fontWeight='500'>
              {EV_PREV_TWO_YEARS_EBITDA}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {multiples[EV_PREV_TWO_YEARS_EBITDA]}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_PREV_TWO_YEARS_EBITDA], MEAN)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_PREV_TWO_YEARS_EBITDA], MEDIAN)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_PREV_TWO_YEARS_EBITDA], LOW)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_PREV_TWO_YEARS_EBITDA], HIGH)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='tableCell' fontWeight='500'>
              {EV_PREV_YEAR_EBITDA}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {multiples[EV_PREV_YEAR_EBITDA]}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_PREV_YEAR_EBITDA], MEAN)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_PREV_YEAR_EBITDA], MEDIAN)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_PREV_YEAR_EBITDA], LOW)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_PREV_YEAR_EBITDA], HIGH)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='tableCell' fontWeight='500'>
              {EV_CURRENT_YEAR_EBITDA}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {multiples[EV_CURRENT_YEAR_EBITDA]}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_CURRENT_YEAR_EBITDA], MEAN)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_CURRENT_YEAR_EBITDA], MEDIAN)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_CURRENT_YEAR_EBITDA], LOW)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_CURRENT_YEAR_EBITDA], HIGH)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='tableCell' fontWeight='500'>
              {EV_NEXT_YEAR_EBITDA}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {multiples[EV_NEXT_YEAR_EBITDA]}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_NEXT_YEAR_EBITDA], MEAN)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_NEXT_YEAR_EBITDA], MEDIAN)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_NEXT_YEAR_EBITDA], LOW)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_NEXT_YEAR_EBITDA], HIGH)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='tableCell' fontWeight='500'>
              {EV_NEXT_TWO_YEARS_EBITDA}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {multiples[EV_NEXT_TWO_YEARS_EBITDA]}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_NEXT_TWO_YEARS_EBITDA], MEAN)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_NEXT_TWO_YEARS_EBITDA], MEDIAN)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_NEXT_TWO_YEARS_EBITDA], LOW)}
            </TableCell>
            <TableCell className='tableCell' textAlign='center'>
              {getCompsMultiple(compsMultiples[EV_NEXT_TWO_YEARS_EBITDA], HIGH)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </GlassCard>
  )
}
export default TransactionModelMultiples
