import React, { createContext, FunctionComponent, PropsWithChildren, useContext } from 'react'
import { useOpportunityState } from '../hooks/useOpportunity'
import { Opportunity } from '../models'

export const OpportunityContext = createContext<Opportunity | undefined>(undefined)
export const OpportunitySetContext = createContext<Function>(() => {})

export const OpportunityProvider: FunctionComponent<PropsWithChildren> = ({ children, ...props }) => {
  const [opportunity, setOpp] = useOpportunityState()
  return (
    <OpportunityContext.Provider {...props} value={opportunity}>
      <OpportunitySetContext.Provider {...props} value={setOpp}>
        {children}
      </OpportunitySetContext.Provider>
    </OpportunityContext.Provider>
  )
}

export const useOpportunity = (): Opportunity | undefined => useContext(OpportunityContext)
export const useSetOpportunity = (): Function => useContext(OpportunitySetContext)
