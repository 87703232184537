import { Flex, Text, TextField, Image } from '@aws-amplify/ui-react'
import { FunctionComponent } from 'react'
import { uniqValue } from '../utils/utils'
import CurrencyInput from './CurrencyInput'

export type ValueAdjustmentType = {
  name: string
  amount: number
  comment: string
}

type ValueAdjustmentProps = {
  values: ValueAdjustmentType[]
  onChange: (valueAdjustment: ValueAdjustmentType[]) => void
}

const ValueAdjustment: FunctionComponent<ValueAdjustmentProps> = ({ values, onChange }) => {
  return (
    <>
      <Flex alignItems='center'>
        <Flex className='assumptionLabel'>
          <Text className='amplify-label assumptionLabel'>Adjustments</Text>
          <Image src='/images/CkAdd.svg' alt='add' className='imageButton' onClick={() => {
            const update = [
              ...values,
              {
                name: uniqValue(
                  'New Adjustment',
                  values.map((v) => v.name)
                  ),
                  amount: 0,
                  comment: '',
                },
              ]
              onChange(update)
          }} />
        </Flex>
      </Flex>
      <Flex direction='column' gap='0.5rem'>
        {values.map((valueAdjustment, index) => {
          return (
            <Flex key={index} className='subAssumptionContainer'>
              <Flex direction='column' gap='0' width='15rem'>
                <Text className='assumptionSubLabel'>Name</Text>
                <TextField
                  label='Name'
                  labelHidden={true}
                  type='text'
                  size='small'
                  className='assumptionValue'
                  value={valueAdjustment.name}
                  onChange={(e) => {
                    const value = e.target.value
                    const newValue = values.map((v, i) => {
                      if (index === i) {
                        v.name = value
                      }
                      return v
                    })
                    onChange(newValue)
                  }}
                />
              </Flex>
              <Flex direction='column' gap='0' width='15rem'>
                <Text className='assumptionSubLabel'>Amount</Text>
                <CurrencyInput
                  label='Amount'
                  labelHidden
                  className='assumptionValue'
                  value={valueAdjustment.amount}
                  onValueChange={(value) => {
                    const newValue = values.map((v, i) => {
                      if (index === i) {
                        v.amount = value
                      }
                      return v
                    })
                    onChange(newValue)
                  }}
                />
              </Flex>
              <Image
                src='/images/CkClose.svg'
                alt='remove'
                className='imageButton'
                minWidth='1.5rem'
                alignSelf='flex-end'
                marginLeft='-0.5rem'
                marginBottom='0.25rem'
                onClick={() => {
                  const newValue = values.filter((_, i) => index !== i)
                  onChange(newValue)
                }}
              />
            </Flex>
          )
        })}
      </Flex>
    </>
  )
}

export default ValueAdjustment
