/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function ValuationCompsIcon(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="20px"
      height="19.33px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 20, height: 19 }}
      paths={[
        {
          d: "M3.79082 15.5417C2.36194 14.1111 1.5037 12.2085 1.3769 10.1905C1.25009 8.1725 1.86342 6.17749 3.10198 4.57924C4.34053 2.98099 6.11933 1.88916 8.10513 1.5083C10.0909 1.12744 12.1475 1.48368 13.8894 2.51029L14.876 1.52379C12.8686 0.239551 10.4575 -0.257922 8.1057 0.126883C5.75391 0.511688 3.62713 1.75165 2.1337 3.60871C0.640263 5.46577 -0.114572 7.80903 0.0141139 10.1886C0.1428 12.5682 1.14594 14.8164 2.83093 16.5016C4.38667 18.0563 6.42581 19.0345 8.61209 19.2747C10.7984 19.5149 13.0013 19.0029 14.8575 17.8231L13.8686 16.8342C10.6858 18.6998 6.5183 18.2692 3.79082 15.5417Z",
          fill: "rgba(48,53,69,1)",
          fillRule: "nonzero",
          style: { transform: "translate(0%, 0%)" },
        },
        {
          d: "M10.5077 11.7426C11.0273 10.4888 11.2947 9.14491 11.2947 7.78773C11.2947 6.43055 11.0273 5.08667 10.5077 3.83287C10.4702 3.74226 10.4311 3.65254 10.3912 3.56333C9.87812 2.41418 9.15833 1.36904 8.26765 0.479947L7.78788 0L7.3078 0.479947L0 7.78771L7.32307 15.1108L7.78788 15.5755L8.26765 15.0955C9.14352 14.2211 9.85419 13.1956 10.3654 12.0685C10.4144 11.9607 10.4622 11.8523 10.5077 11.7426ZM7.76917 1.93833C9.16803 3.56616 9.9372 5.64142 9.9372 7.78773C9.9372 9.93404 9.16803 12.0093 7.76917 13.6371L1.91975 7.78771L7.76917 1.93833Z",
          fill: "rgba(48,53,69,1)",
          fillRule: "nonzero",
          style: { transform: "translate(43.53%, 9.72%)" },
        },
      ]}
      {...getOverrideProps(overrides, "ValuationCompsIcon")}
      {...rest}
    ></Icon>
  );
}
