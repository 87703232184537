import { Button, Flex, Text } from '@aws-amplify/ui-react'
import { FunctionComponent, useState } from 'react'
import { useAmplifyUser } from '../contexts/amplifyUserContext'
import GlassCard from '../figma-components/GlassCard'
import { sendSlack } from '../services/slack'
import { FilesOpenIcon } from '../ui-components'
import { DELIM } from '../utils/utils'

export const FEATURE_REQUESTS = {
  marketInfo: {
    name: 'Market Data',
  },
  investmentDetails: {
    name: 'Investment Details',
  },
  companyProfile: {
    name: 'Company Profile',
  },
  crm: {
    name: 'CRM',
  },
  publicCapTable: {
    name: 'Equity Calculator',
  },
}

interface FeatureRequestProps {
  featureName: keyof typeof FEATURE_REQUESTS
  featureArg?: string
  info?: Record<string, string>
  requestedMsg?: string
  requestButtonMsg?: string
  requestMsg?: string
  className?: string
  header?: string
}

const FeatureRequest: FunctionComponent<FeatureRequestProps> = ({ featureName, featureArg, info = {}, requestedMsg, requestButtonMsg, requestMsg, className = '', header }) => {
  const fInfo = FEATURE_REQUESTS[featureName]
  const storageKey = featureArg ? `${fInfo.name}${DELIM}${featureArg}` : fInfo.name
  const amplifyUser = useAmplifyUser()
  const [hasRequested, setIsRequested] = useState(!!window.localStorage.getItem(storageKey))
  const email = amplifyUser?.email

  const rdMsg = requestedMsg || 'Data has been requested.'
  const rButtonMsg = requestButtonMsg || `Request ${fInfo.name}`
  const rMsg = requestMsg || `Looks like the details on this tab aren't ready yet. Request below and we'll collect this information for you within 2 hours.`
  const mainHeader = header || 'Request company information?'
  return (
    <GlassCard header={mainHeader} className={className}>
      {hasRequested ? (
        <Text>{rdMsg}</Text>
      ) : (
        <Flex direction='column'>
          <Text>{rMsg}</Text>
          <Button
            variation='primary'
            size='small'
            alignSelf='flex-start'
            className='save'
            onClick={async () => {
              sendSlack(
                `${fInfo.name} requested by ${email}. ${Object.keys(info)
                  .map((k) => `${k}: ${info[k]}`)
                  .join(', ')}`
              )
              window.localStorage.setItem(storageKey, 'true')
              setIsRequested(true)
            }}
          >
            <FilesOpenIcon />
            {rButtonMsg}
          </Button>
        </Flex>
      )}
    </GlassCard>
  )
}

export default FeatureRequest
