import { Flex, Heading, Loader, Text } from '@aws-amplify/ui-react'
import { FunctionComponent } from 'react'
import { useRelationships } from '../hooks/useRelationships'
import '../styles/companyOverview.css'
import GlassCard from './GlassCard'

interface RelationshipProps {
  keyInvestors: string[]
}

const RelationshipsView: FunctionComponent<RelationshipProps> = ({ keyInvestors }) => {
  const [relationships, isLoading, error] = useRelationships({ firmNames: keyInvestors, enabled: keyInvestors.length > 0 })
  if (isLoading) return <Loader />
  if (error || !relationships || !relationships.length) return null
  return (
    <GlassCard header='Relationships' className='overlayCard relationshipsCard' style={{padding: '1rem'}}>
      <Flex direction='column' maxHeight='20rem' className='relationshipsCardContent'>
        {relationships.map((item, i) => {
          return (
            <GlassCard
              key={i}
              header={item['VC Firm Name']}
              level={2}
              className='marketSummaryCard overlayCard'
            >
              <Flex direction='column' gap='0.25rem'>
                <Heading className='marketSummaryHeading'>Coverage</Heading>
                <Text className='marketSummaryMetric'>{item.Coverage || '-'}</Text>
                <Heading className='marketSummaryHeading' marginTop='0.5rem'>Point Person</Heading>
                <Text className='marketSummaryMetric'>{item['Point Person'] || '-'}</Text>
                <Heading className='marketSummaryHeading' marginTop='0.5rem'>Tier</Heading>
                <Text className='marketSummaryMetric'>{item.Tier || '-'}</Text>
              </Flex>
            </GlassCard>
          )
        })}
      </Flex>
    </GlassCard>
  )
}

export default RelationshipsView
