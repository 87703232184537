/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateOpportunity = /* GraphQL */ `subscription OnCreateOpportunity(
  $filter: ModelSubscriptionOpportunityFilterInput
  $owner: String
) {
  onCreateOpportunity(filter: $filter, owner: $owner) {
    id
    orgName
    name
    transactionName
    transactionTypes
    uploadDate
    marketMapCompSet
    marketMapSelectedMetrics
    publicCompsCompSetV2 {
      name
      groupName
      __typename
    }
    owner
    transactionAcquisitionCompSet
    transactionCapitalRaiseCompSet
    transactionModelsV1
    competitiveLandscapeDashboards {
      url
      name
      __typename
    }
    capTableV1
    totalEquityRaised
    lastPreMoneyValuation
    latestDealDate
    files {
      name
      s3Key
      group
      dateUploaded
      vectorDbId
      docType
      tags
      __typename
    }
    financialData {
      public_company_2020_revenue
      public_company_2021_revenue
      public_company_2022_revenue
      public_company_2023_analyst_revenue_estimates
      public_company_ltm_revenue_m
      public_company_ntm_revenue_m
      public_company_arr_m
      public_company_ga_percent
      public_company_gross_margin
      public_company_gross_profit
      public_company_ebitda
      public_company_ebitda_percent
      public_company_ltm_ebitda
      public_company_ltm_ebitda_margin
      public_company_ntm_ebitda
      public_company_ltm_fcf
      public_company_ltm_fcf_percent
      public_company_rd_percent
      public_company_sm_percent
      public_company_total_cash
      public_company_ltm_revenue_growth
      public_company_ntm_revenue_growth
      public_company_arr_growth
      public_company_arr_per_customer_k
      public_company_gross_retention
      public_company_net_dollar_retention
      public_company_ltm_free_cash_flow_growth_percent
      public_company_growth_persistence
      public_company_net_new_arr
      public_company_net_new_arr_growth_percent
      public_company_arr_employee_k
      public_company_annualized_opex_employee
      public_company_burn_multiple
      public_company_ltm_magic_number
      public_company_operating_income_percent
      public_company_rule_of_40_ltm_fcf
      public_company_rule_of_40_op_in_margin
      public_company_rule_of_40_quarter_fcf
      public_company_sales_and_marketing_yield
      public_company_implied_5yr_ltv_cac
      __typename
    }
    financialDataV2 {
      type
      year
      quarter
      month
      totalRevenue
      totalRevGrowthPercent
      totalARR
      arrGrowthPercent
      totalGrossProfit
      grossMarginPercent
      totalEBITDA
      eBITDAMarginPercent
      totalFCF
      fcfMarginPercent
      gnaAsPercentOfRev
      snmAsPercentOfRev
      rndAsPercentOfRev
      grossRetentionPercent
      netRetentionPercent
      salesEfficiencyMultiple
      netNewARR
      netNewARRGrowthPercent
      arrPerEmployee
      annualizedOpexPerEmployee
      burnMultiple
      operatingIncomePercent
      ruleOf40LTMFCF
      ruleOf40LTMEbitda
      salesAndMarketingYield
      implied5yrLTVPerCAC
      __typename
    }
    overrides {
      sourceTypes {
        overview
        products
        customerDetail
        marketSummary
        competitiveLandscape
        investmentDetails
        __typename
      }
      products {
        name
        description
        shortDescription
        type
        featuresV2
        features
        useCases
        revenueModel
        deliveryModel
        targetCustomerProfile
        sources
        markets
        taxonomiesV2
        mcKinseyMarketCategory
        classification
        objectStateJson
        __typename
      }
      productRanks
      caseStudies {
        customerName
        backgroundOnProblem
        howProductHelpedCustomer
        howCustomerJustifiedROI
        sourceLink
        __typename
      }
      marketRisks {
        summary
        details
        mitigation
        __typename
      }
      marketDrivers {
        summary
        details
        __typename
      }
      objectStateJson
      __typename
    }
    tasks {
      id
      name
      status
      args
      amplifyUserId
      orgName
      error
      startedAt
      finishedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateOpportunitySubscriptionVariables,
  APITypes.OnCreateOpportunitySubscription
>;
export const onUpdateOpportunity = /* GraphQL */ `subscription OnUpdateOpportunity(
  $filter: ModelSubscriptionOpportunityFilterInput
  $owner: String
) {
  onUpdateOpportunity(filter: $filter, owner: $owner) {
    id
    orgName
    name
    transactionName
    transactionTypes
    uploadDate
    marketMapCompSet
    marketMapSelectedMetrics
    publicCompsCompSetV2 {
      name
      groupName
      __typename
    }
    owner
    transactionAcquisitionCompSet
    transactionCapitalRaiseCompSet
    transactionModelsV1
    competitiveLandscapeDashboards {
      url
      name
      __typename
    }
    capTableV1
    totalEquityRaised
    lastPreMoneyValuation
    latestDealDate
    files {
      name
      s3Key
      group
      dateUploaded
      vectorDbId
      docType
      tags
      __typename
    }
    financialData {
      public_company_2020_revenue
      public_company_2021_revenue
      public_company_2022_revenue
      public_company_2023_analyst_revenue_estimates
      public_company_ltm_revenue_m
      public_company_ntm_revenue_m
      public_company_arr_m
      public_company_ga_percent
      public_company_gross_margin
      public_company_gross_profit
      public_company_ebitda
      public_company_ebitda_percent
      public_company_ltm_ebitda
      public_company_ltm_ebitda_margin
      public_company_ntm_ebitda
      public_company_ltm_fcf
      public_company_ltm_fcf_percent
      public_company_rd_percent
      public_company_sm_percent
      public_company_total_cash
      public_company_ltm_revenue_growth
      public_company_ntm_revenue_growth
      public_company_arr_growth
      public_company_arr_per_customer_k
      public_company_gross_retention
      public_company_net_dollar_retention
      public_company_ltm_free_cash_flow_growth_percent
      public_company_growth_persistence
      public_company_net_new_arr
      public_company_net_new_arr_growth_percent
      public_company_arr_employee_k
      public_company_annualized_opex_employee
      public_company_burn_multiple
      public_company_ltm_magic_number
      public_company_operating_income_percent
      public_company_rule_of_40_ltm_fcf
      public_company_rule_of_40_op_in_margin
      public_company_rule_of_40_quarter_fcf
      public_company_sales_and_marketing_yield
      public_company_implied_5yr_ltv_cac
      __typename
    }
    financialDataV2 {
      type
      year
      quarter
      month
      totalRevenue
      totalRevGrowthPercent
      totalARR
      arrGrowthPercent
      totalGrossProfit
      grossMarginPercent
      totalEBITDA
      eBITDAMarginPercent
      totalFCF
      fcfMarginPercent
      gnaAsPercentOfRev
      snmAsPercentOfRev
      rndAsPercentOfRev
      grossRetentionPercent
      netRetentionPercent
      salesEfficiencyMultiple
      netNewARR
      netNewARRGrowthPercent
      arrPerEmployee
      annualizedOpexPerEmployee
      burnMultiple
      operatingIncomePercent
      ruleOf40LTMFCF
      ruleOf40LTMEbitda
      salesAndMarketingYield
      implied5yrLTVPerCAC
      __typename
    }
    overrides {
      sourceTypes {
        overview
        products
        customerDetail
        marketSummary
        competitiveLandscape
        investmentDetails
        __typename
      }
      products {
        name
        description
        shortDescription
        type
        featuresV2
        features
        useCases
        revenueModel
        deliveryModel
        targetCustomerProfile
        sources
        markets
        taxonomiesV2
        mcKinseyMarketCategory
        classification
        objectStateJson
        __typename
      }
      productRanks
      caseStudies {
        customerName
        backgroundOnProblem
        howProductHelpedCustomer
        howCustomerJustifiedROI
        sourceLink
        __typename
      }
      marketRisks {
        summary
        details
        mitigation
        __typename
      }
      marketDrivers {
        summary
        details
        __typename
      }
      objectStateJson
      __typename
    }
    tasks {
      id
      name
      status
      args
      amplifyUserId
      orgName
      error
      startedAt
      finishedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateOpportunitySubscriptionVariables,
  APITypes.OnUpdateOpportunitySubscription
>;
export const onDeleteOpportunity = /* GraphQL */ `subscription OnDeleteOpportunity(
  $filter: ModelSubscriptionOpportunityFilterInput
  $owner: String
) {
  onDeleteOpportunity(filter: $filter, owner: $owner) {
    id
    orgName
    name
    transactionName
    transactionTypes
    uploadDate
    marketMapCompSet
    marketMapSelectedMetrics
    publicCompsCompSetV2 {
      name
      groupName
      __typename
    }
    owner
    transactionAcquisitionCompSet
    transactionCapitalRaiseCompSet
    transactionModelsV1
    competitiveLandscapeDashboards {
      url
      name
      __typename
    }
    capTableV1
    totalEquityRaised
    lastPreMoneyValuation
    latestDealDate
    files {
      name
      s3Key
      group
      dateUploaded
      vectorDbId
      docType
      tags
      __typename
    }
    financialData {
      public_company_2020_revenue
      public_company_2021_revenue
      public_company_2022_revenue
      public_company_2023_analyst_revenue_estimates
      public_company_ltm_revenue_m
      public_company_ntm_revenue_m
      public_company_arr_m
      public_company_ga_percent
      public_company_gross_margin
      public_company_gross_profit
      public_company_ebitda
      public_company_ebitda_percent
      public_company_ltm_ebitda
      public_company_ltm_ebitda_margin
      public_company_ntm_ebitda
      public_company_ltm_fcf
      public_company_ltm_fcf_percent
      public_company_rd_percent
      public_company_sm_percent
      public_company_total_cash
      public_company_ltm_revenue_growth
      public_company_ntm_revenue_growth
      public_company_arr_growth
      public_company_arr_per_customer_k
      public_company_gross_retention
      public_company_net_dollar_retention
      public_company_ltm_free_cash_flow_growth_percent
      public_company_growth_persistence
      public_company_net_new_arr
      public_company_net_new_arr_growth_percent
      public_company_arr_employee_k
      public_company_annualized_opex_employee
      public_company_burn_multiple
      public_company_ltm_magic_number
      public_company_operating_income_percent
      public_company_rule_of_40_ltm_fcf
      public_company_rule_of_40_op_in_margin
      public_company_rule_of_40_quarter_fcf
      public_company_sales_and_marketing_yield
      public_company_implied_5yr_ltv_cac
      __typename
    }
    financialDataV2 {
      type
      year
      quarter
      month
      totalRevenue
      totalRevGrowthPercent
      totalARR
      arrGrowthPercent
      totalGrossProfit
      grossMarginPercent
      totalEBITDA
      eBITDAMarginPercent
      totalFCF
      fcfMarginPercent
      gnaAsPercentOfRev
      snmAsPercentOfRev
      rndAsPercentOfRev
      grossRetentionPercent
      netRetentionPercent
      salesEfficiencyMultiple
      netNewARR
      netNewARRGrowthPercent
      arrPerEmployee
      annualizedOpexPerEmployee
      burnMultiple
      operatingIncomePercent
      ruleOf40LTMFCF
      ruleOf40LTMEbitda
      salesAndMarketingYield
      implied5yrLTVPerCAC
      __typename
    }
    overrides {
      sourceTypes {
        overview
        products
        customerDetail
        marketSummary
        competitiveLandscape
        investmentDetails
        __typename
      }
      products {
        name
        description
        shortDescription
        type
        featuresV2
        features
        useCases
        revenueModel
        deliveryModel
        targetCustomerProfile
        sources
        markets
        taxonomiesV2
        mcKinseyMarketCategory
        classification
        objectStateJson
        __typename
      }
      productRanks
      caseStudies {
        customerName
        backgroundOnProblem
        howProductHelpedCustomer
        howCustomerJustifiedROI
        sourceLink
        __typename
      }
      marketRisks {
        summary
        details
        mitigation
        __typename
      }
      marketDrivers {
        summary
        details
        __typename
      }
      objectStateJson
      __typename
    }
    tasks {
      id
      name
      status
      args
      amplifyUserId
      orgName
      error
      startedAt
      finishedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteOpportunitySubscriptionVariables,
  APITypes.OnDeleteOpportunitySubscription
>;
export const onCreateUser = /* GraphQL */ `subscription OnCreateUser(
  $filter: ModelSubscriptionUserFilterInput
  $owner: String
) {
  onCreateUser(filter: $filter, owner: $owner) {
    amplifyUserId
    owner
    groups {
      name
      groupName
      __typename
    }
    listInfo {
      headers
      data {
        name
        jsonData
        __typename
      }
      __typename
    }
    websiteChatsV2 {
      target
      chat {
        role
        content
        timestamp
        sources
        __typename
      }
      __typename
    }
    docChats {
      target
      id
      selectedDocs
      chat {
        role
        content
        timestamp
        sources
        __typename
      }
      __typename
    }
    marketMapSelectedMetrics
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserSubscriptionVariables,
  APITypes.OnCreateUserSubscription
>;
export const onUpdateUser = /* GraphQL */ `subscription OnUpdateUser(
  $filter: ModelSubscriptionUserFilterInput
  $owner: String
) {
  onUpdateUser(filter: $filter, owner: $owner) {
    amplifyUserId
    owner
    groups {
      name
      groupName
      __typename
    }
    listInfo {
      headers
      data {
        name
        jsonData
        __typename
      }
      __typename
    }
    websiteChatsV2 {
      target
      chat {
        role
        content
        timestamp
        sources
        __typename
      }
      __typename
    }
    docChats {
      target
      id
      selectedDocs
      chat {
        role
        content
        timestamp
        sources
        __typename
      }
      __typename
    }
    marketMapSelectedMetrics
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserSubscriptionVariables,
  APITypes.OnUpdateUserSubscription
>;
export const onDeleteUser = /* GraphQL */ `subscription OnDeleteUser(
  $filter: ModelSubscriptionUserFilterInput
  $owner: String
) {
  onDeleteUser(filter: $filter, owner: $owner) {
    amplifyUserId
    owner
    groups {
      name
      groupName
      __typename
    }
    listInfo {
      headers
      data {
        name
        jsonData
        __typename
      }
      __typename
    }
    websiteChatsV2 {
      target
      chat {
        role
        content
        timestamp
        sources
        __typename
      }
      __typename
    }
    docChats {
      target
      id
      selectedDocs
      chat {
        role
        content
        timestamp
        sources
        __typename
      }
      __typename
    }
    marketMapSelectedMetrics
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserSubscriptionVariables,
  APITypes.OnDeleteUserSubscription
>;
export const onCreateOrg = /* GraphQL */ `subscription OnCreateOrg(
  $filter: ModelSubscriptionOrgFilterInput
  $owner: String
) {
  onCreateOrg(filter: $filter, owner: $owner) {
    orgName
    owner
    dashboards {
      url
      companyName
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateOrgSubscriptionVariables,
  APITypes.OnCreateOrgSubscription
>;
export const onUpdateOrg = /* GraphQL */ `subscription OnUpdateOrg(
  $filter: ModelSubscriptionOrgFilterInput
  $owner: String
) {
  onUpdateOrg(filter: $filter, owner: $owner) {
    orgName
    owner
    dashboards {
      url
      companyName
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateOrgSubscriptionVariables,
  APITypes.OnUpdateOrgSubscription
>;
export const onDeleteOrg = /* GraphQL */ `subscription OnDeleteOrg(
  $filter: ModelSubscriptionOrgFilterInput
  $owner: String
) {
  onDeleteOrg(filter: $filter, owner: $owner) {
    orgName
    owner
    dashboards {
      url
      companyName
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteOrgSubscriptionVariables,
  APITypes.OnDeleteOrgSubscription
>;
export const onCreateCompanyInfoV3 = /* GraphQL */ `subscription OnCreateCompanyInfoV3(
  $filter: ModelSubscriptionCompanyInfoV3FilterInput
) {
  onCreateCompanyInfoV3(filter: $filter) {
    companyName
    companyLabel
    master_market_map_company_name
    isApproved
    linkedinUrl
    iconUrl
    headquartersLocation
    headquartersRegion
    ticker
    fullTimeEmployeeCount
    fullTimeEmployeeCountGrowth6m
    fullTimeEmployeeCountGrowth1y
    fullTimeEmployeeCountGrowth2y
    companyType
    marketComps
    ai_public_comps {
      name
      groupName
      __typename
    }
    cb_organizationPermalink
    cb_acquiredByLabel
    cb_acquiredByPermalink
    cb_acquisitionStatus
    cb_acquisitionTerms
    cb_acquisitionType
    cb_description
    cb_fullDescription
    cb_numberOfEmployees
    cb_fteGrowth
    cb_top5Investors
    cb_website
    cb_foundedDate
    cb_totalFundingAmount
    cb_lastFundingDate
    cb_estimatedRevenueRange
    cb_numFundingRounds
    cb_lastFundingAmount
    cb_lastFundingType
    cb_lastEquityValuation
    cb_lastEquityFundingType
    cyberdb_revenues
    cyberdbId
    ai_goToMarketChannels
    ai_investmentHighlights
    publicFinancialData {
      yearlyData {
        year
        evRevenue
        evEbitda
        revenue
        ebitda
        analystRevenueEstimates
        __typename
      }
      enterpriseValue
      price
      ruleOf40OpInMargin
      smPercent
      salesAndMarketingYield
      implied5yrLtvCac
      close52WeeksAgo
      close52WeekHigh
      close52WeekLow
      arr
      lastYearArr
      ntmRevenueGrowth
      ltmRevenue
      ltmEbitda
      ltmRevenuePrevious
      ltmEbitdaMargin
      netNewArrGrowthPercent
      netDollarRetention
      grossRetention
      grossMargin
      __typename
    }
    products {
      name
      description
      shortDescription
      type
      featuresV2
      features
      useCases
      revenueModel
      deliveryModel
      targetCustomerProfile
      sources
      markets
      taxonomiesV2
      mcKinseyMarketCategory
      classification
      objectStateJson
      __typename
    }
    productRanks
    caseStudies {
      customerName
      backgroundOnProblem
      howProductHelpedCustomer
      howCustomerJustifiedROI
      sourceLink
      __typename
    }
    marketRisks {
      summary
      details
      mitigation
      __typename
    }
    marketDrivers {
      summary
      details
      __typename
    }
    objectStateJson
    tasks {
      id
      name
      status
      args
      amplifyUserId
      orgName
      error
      startedAt
      finishedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCompanyInfoV3SubscriptionVariables,
  APITypes.OnCreateCompanyInfoV3Subscription
>;
export const onUpdateCompanyInfoV3 = /* GraphQL */ `subscription OnUpdateCompanyInfoV3(
  $filter: ModelSubscriptionCompanyInfoV3FilterInput
) {
  onUpdateCompanyInfoV3(filter: $filter) {
    companyName
    companyLabel
    master_market_map_company_name
    isApproved
    linkedinUrl
    iconUrl
    headquartersLocation
    headquartersRegion
    ticker
    fullTimeEmployeeCount
    fullTimeEmployeeCountGrowth6m
    fullTimeEmployeeCountGrowth1y
    fullTimeEmployeeCountGrowth2y
    companyType
    marketComps
    ai_public_comps {
      name
      groupName
      __typename
    }
    cb_organizationPermalink
    cb_acquiredByLabel
    cb_acquiredByPermalink
    cb_acquisitionStatus
    cb_acquisitionTerms
    cb_acquisitionType
    cb_description
    cb_fullDescription
    cb_numberOfEmployees
    cb_fteGrowth
    cb_top5Investors
    cb_website
    cb_foundedDate
    cb_totalFundingAmount
    cb_lastFundingDate
    cb_estimatedRevenueRange
    cb_numFundingRounds
    cb_lastFundingAmount
    cb_lastFundingType
    cb_lastEquityValuation
    cb_lastEquityFundingType
    cyberdb_revenues
    cyberdbId
    ai_goToMarketChannels
    ai_investmentHighlights
    publicFinancialData {
      yearlyData {
        year
        evRevenue
        evEbitda
        revenue
        ebitda
        analystRevenueEstimates
        __typename
      }
      enterpriseValue
      price
      ruleOf40OpInMargin
      smPercent
      salesAndMarketingYield
      implied5yrLtvCac
      close52WeeksAgo
      close52WeekHigh
      close52WeekLow
      arr
      lastYearArr
      ntmRevenueGrowth
      ltmRevenue
      ltmEbitda
      ltmRevenuePrevious
      ltmEbitdaMargin
      netNewArrGrowthPercent
      netDollarRetention
      grossRetention
      grossMargin
      __typename
    }
    products {
      name
      description
      shortDescription
      type
      featuresV2
      features
      useCases
      revenueModel
      deliveryModel
      targetCustomerProfile
      sources
      markets
      taxonomiesV2
      mcKinseyMarketCategory
      classification
      objectStateJson
      __typename
    }
    productRanks
    caseStudies {
      customerName
      backgroundOnProblem
      howProductHelpedCustomer
      howCustomerJustifiedROI
      sourceLink
      __typename
    }
    marketRisks {
      summary
      details
      mitigation
      __typename
    }
    marketDrivers {
      summary
      details
      __typename
    }
    objectStateJson
    tasks {
      id
      name
      status
      args
      amplifyUserId
      orgName
      error
      startedAt
      finishedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCompanyInfoV3SubscriptionVariables,
  APITypes.OnUpdateCompanyInfoV3Subscription
>;
export const onDeleteCompanyInfoV3 = /* GraphQL */ `subscription OnDeleteCompanyInfoV3(
  $filter: ModelSubscriptionCompanyInfoV3FilterInput
) {
  onDeleteCompanyInfoV3(filter: $filter) {
    companyName
    companyLabel
    master_market_map_company_name
    isApproved
    linkedinUrl
    iconUrl
    headquartersLocation
    headquartersRegion
    ticker
    fullTimeEmployeeCount
    fullTimeEmployeeCountGrowth6m
    fullTimeEmployeeCountGrowth1y
    fullTimeEmployeeCountGrowth2y
    companyType
    marketComps
    ai_public_comps {
      name
      groupName
      __typename
    }
    cb_organizationPermalink
    cb_acquiredByLabel
    cb_acquiredByPermalink
    cb_acquisitionStatus
    cb_acquisitionTerms
    cb_acquisitionType
    cb_description
    cb_fullDescription
    cb_numberOfEmployees
    cb_fteGrowth
    cb_top5Investors
    cb_website
    cb_foundedDate
    cb_totalFundingAmount
    cb_lastFundingDate
    cb_estimatedRevenueRange
    cb_numFundingRounds
    cb_lastFundingAmount
    cb_lastFundingType
    cb_lastEquityValuation
    cb_lastEquityFundingType
    cyberdb_revenues
    cyberdbId
    ai_goToMarketChannels
    ai_investmentHighlights
    publicFinancialData {
      yearlyData {
        year
        evRevenue
        evEbitda
        revenue
        ebitda
        analystRevenueEstimates
        __typename
      }
      enterpriseValue
      price
      ruleOf40OpInMargin
      smPercent
      salesAndMarketingYield
      implied5yrLtvCac
      close52WeeksAgo
      close52WeekHigh
      close52WeekLow
      arr
      lastYearArr
      ntmRevenueGrowth
      ltmRevenue
      ltmEbitda
      ltmRevenuePrevious
      ltmEbitdaMargin
      netNewArrGrowthPercent
      netDollarRetention
      grossRetention
      grossMargin
      __typename
    }
    products {
      name
      description
      shortDescription
      type
      featuresV2
      features
      useCases
      revenueModel
      deliveryModel
      targetCustomerProfile
      sources
      markets
      taxonomiesV2
      mcKinseyMarketCategory
      classification
      objectStateJson
      __typename
    }
    productRanks
    caseStudies {
      customerName
      backgroundOnProblem
      howProductHelpedCustomer
      howCustomerJustifiedROI
      sourceLink
      __typename
    }
    marketRisks {
      summary
      details
      mitigation
      __typename
    }
    marketDrivers {
      summary
      details
      __typename
    }
    objectStateJson
    tasks {
      id
      name
      status
      args
      amplifyUserId
      orgName
      error
      startedAt
      finishedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCompanyInfoV3SubscriptionVariables,
  APITypes.OnDeleteCompanyInfoV3Subscription
>;
export const onCreateMarketInfoV1 = /* GraphQL */ `subscription OnCreateMarketInfoV1(
  $filter: ModelSubscriptionMarketInfoV1FilterInput
) {
  onCreateMarketInfoV1(filter: $filter) {
    marketName
    description
    drivers
    restraints
    opportunities
    challenge
    cagr
    fromYear
    toYear
    fromValue
    toValue
    publishedDate
    sources
    objectStateJson
    tasks {
      id
      name
      status
      args
      amplifyUserId
      orgName
      error
      startedAt
      finishedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMarketInfoV1SubscriptionVariables,
  APITypes.OnCreateMarketInfoV1Subscription
>;
export const onUpdateMarketInfoV1 = /* GraphQL */ `subscription OnUpdateMarketInfoV1(
  $filter: ModelSubscriptionMarketInfoV1FilterInput
) {
  onUpdateMarketInfoV1(filter: $filter) {
    marketName
    description
    drivers
    restraints
    opportunities
    challenge
    cagr
    fromYear
    toYear
    fromValue
    toValue
    publishedDate
    sources
    objectStateJson
    tasks {
      id
      name
      status
      args
      amplifyUserId
      orgName
      error
      startedAt
      finishedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMarketInfoV1SubscriptionVariables,
  APITypes.OnUpdateMarketInfoV1Subscription
>;
export const onDeleteMarketInfoV1 = /* GraphQL */ `subscription OnDeleteMarketInfoV1(
  $filter: ModelSubscriptionMarketInfoV1FilterInput
) {
  onDeleteMarketInfoV1(filter: $filter) {
    marketName
    description
    drivers
    restraints
    opportunities
    challenge
    cagr
    fromYear
    toYear
    fromValue
    toValue
    publishedDate
    sources
    objectStateJson
    tasks {
      id
      name
      status
      args
      amplifyUserId
      orgName
      error
      startedAt
      finishedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMarketInfoV1SubscriptionVariables,
  APITypes.OnDeleteMarketInfoV1Subscription
>;
