import { useQuery } from '@tanstack/react-query'
import { restAPIRequest } from '../services/restApiRequest'
import { defaultRetry } from '../utils/utils'

type CompanyRelationship = {
  'Company Name': string
  Coverage: string
  'Point Person': string
  Tier: string
  'Next Raise Date': string
  'Next Touchpoint': string
}

type UseInvestmentsProps = {
  companyName: string
  enabled?: boolean
}

interface identifier {
  uuid: string
  permalink: string
  value: string
  image_id: string
  entity_def_id: string
}

interface currency {
  value: number
  currency: string
  value_usd: number
}

export type ParticipatedInvestments = {
  announced_on: string
  created_at: string
  entity_def_id: string
  funding_round_identifier: identifier
  funding_round_investment_type: string
  funding_round_money_raised: currency
  identifier: identifier
  investor_identifier: identifier
  investor_stage: string[]
  investor_type: string[]
  isLeadInvestor: boolean
  money_invested: currency
  name: string
  organization_diversity_spotlights: identifier[]
  organization_identifier: identifier
  partner_identifiers: identifier[]
  permalink: string
  updated_at: string
  uuid: string
  relationship?: CompanyRelationship
}

export function useInvestments({ companyName, enabled = true }: UseInvestmentsProps): [ParticipatedInvestments[], boolean, Error | undefined] {
  const { data, isLoading, error } = useQuery({
    queryKey: ['companyProfile/investments', companyName],
    enabled: !!companyName && enabled,
    queryFn: queryInvestments(companyName),
    retry: defaultRetry,
  })

  return [data?.investments || [], isLoading, error as Error | undefined]
}

export function queryInvestments(companyName: string) {
  return async () =>
    restAPIRequest<{ investments: ParticipatedInvestments[] }>({
      path: `companyProfile/${companyName}/investments`,
      method: 'get',
    })
}
