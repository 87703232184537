import { Button, CheckboxField, Flex, Image, Link, SelectField, Table, TableBody, TableCell, TableHead, TableRow, Text, TextField } from '@aws-amplify/ui-react'
import {
  Column,
  ColumnDef,
  FilterFn,
  RowData,
  SortingFn,
  Table as TableCore,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  sortingFns,
  useReactTable,
} from '@tanstack/react-table'
import { InputHTMLAttributes, useEffect, useMemo, useState } from 'react'
import Markdown from 'react-markdown'
import { NumericFormat } from 'react-number-format'
import { AiOutlineTag, MarketMapIcon } from '../ui-components'
import { currencyFormatterLong, fDate, p, parseCurrency } from '../utils/utils'
import IconList from './IconList'
import NumberInput from './NumberInput'

import { RankingInfo, compareItems, rankItem } from '@tanstack/match-sorter-utils'
import { InitialTableState } from '@tanstack/react-table'

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy?: FilterFn<unknown>
  }
  interface FilterMeta {
    itemRank?: RankingInfo
  }
}

declare module '@tanstack/react-table' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends RowData> {
    updateData?: (rowIndex: number, columnId: string, value: TData) => void
    deleteRow?: (rowIndex: number) => void
    getOptions?: (type: string) => { label: string; value: string }[]
    getErrors?: (index: number, id: string, value: string | number, original: TData) => { errorMessage: string; hasError: boolean }
    getCell?: GetCell<TData>
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    type: string
    optionType?: string
    props?: Object
    valueToLink?: (value: TValue, row: TData) => string
  }
}

export type GetCellProps<T> = { value: string | number; colName: string; originalRow: T }
export type GetCell<T> = (info: GetCellProps<T>) => React.ReactNode

type InputTableProps<T> = {
  data: T[]
  columns: ColumnDef<T, any>[]
  updateData?: (rowIndex: number, columnId: string, value: unknown) => void
  deleteRow?: (rowIndex: number) => void
  getOptions?: (type: string) => { label: string; value: string }[]
  getErrors?: (index: number, id: string, value: string | number, original: T) => { errorMessage: string; hasError: boolean }
  getCell?: GetCell<T>
  paginate?: boolean
  sticky?: boolean
  enableGrouping?: boolean
  initState?: InitialTableState
}

function InputTable<T>({
  data,
  columns,
  updateData,
  deleteRow,
  getOptions,
  getErrors,
  getCell,
  paginate = false,
  sticky = false,
  enableGrouping = false,
  initState = {},
}: InputTableProps<T>) {
  let tableWithDelete: boolean = false
  const table = useReactTable<T>({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    columnResizeMode: 'onChange',
    enableGrouping,
    initialState: {
      pagination: {
        pageSize: 200,
        pageIndex: 0,
      },
      ...initState,
    },
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: paginate ? getPaginationRowModel() : undefined,
    defaultColumn: {
      cell: ({
        getValue,
        row: { index, original },
        column: {
          id,
          columnDef: { meta },
        },
      }) => {
        const value = getValue<string | number | boolean>()
        const onValueChange = (v: string | number | boolean | undefined) => {
          table.options.meta?.updateData?.(index, id, v)
        }
        const { errorMessage, hasError } = table.options.meta?.getErrors?.(index, id, value, original) || { errorMessage: '', hasError: false }
        const cell = table.options.meta?.getCell?.({ value, originalRow: original, colName: id })
        if (cell) return cell

        if (meta?.type === 'deleteButton') {
          tableWithDelete = true
          return <Image src='/images/CkClose.svg' alt='remove' className='imageButton' minWidth='1.5rem' onClick={() => table.options.meta?.deleteRow?.(index)} />
        } else if (meta?.type === 'viewOnlyString') {
          return <Text>{value}</Text>
        } else if (meta?.type === 'link') {
          if (!value) return <Text>N/A</Text>
          return <Link href={meta?.valueToLink ? meta?.valueToLink(value, original) : `${value}`}>{(value as string).replace('playbook-', '')}</Link>
        } else if (meta?.type === 'viewOnlyMarkdown') {
          return <Markdown>{value as string}</Markdown>
        } else if (meta?.type === 'taxonomies' || meta?.type === 'markets') {
          const buckets = String(value).split(',')
          const icon = meta?.type === 'taxonomies' ? <AiOutlineTag /> : <MarketMapIcon />
          return <IconList list={buckets} icon={icon} />
        } else if (meta?.type === 'inputString') {
          return (
            <TextField size='small' label={id} labelHidden type='text' minWidth='12rem' value={value as string} onChange={(e) => onValueChange(e.target.value)} {...meta?.props} />
          )
        } else if (meta?.type === 'inputCheck') {
          return <CheckboxField size='small' label={id} name={id} labelHidden value='yes' checked={value as boolean} onChange={(e) => onValueChange(e.target.checked)} />
        } else if (meta?.type === 'viewOnlyNumber') {
          return <Text children={value.toLocaleString()} {...meta?.props} />
        } else if (meta?.type === 'viewOnlyPercentage') {
          return <Text {...meta?.props}> {p(value as number)} </Text>
        } else if (meta?.type === 'viewOnlyMoney') {
          return <Text children={value ? currencyFormatterLong(value) : 'N/A'} {...meta?.props} />
        } else if (meta?.type === 'inputMoney') {
          return (
            <NumericFormat
              label={id}
              labelHidden
              type='text'
              inputMode='numeric'
              pattern='\d*'
              size='small'
              minWidth='12rem'
              prefix={'$'}
              decimalScale={2}
              fixedDecimalScale
              thousandSeparator=','
              customInput={TextField}
              value={value as number}
              errorMessage={errorMessage}
              hasError={hasError}
              valueIsNumericString={true}
              onValueChange={(v) => {
                const value = parseCurrency(v.value)
                if (isNaN(value)) return onValueChange(undefined)

                onValueChange(value)
              }}
              {...meta?.props}
            />
          )
        } else if (meta?.type === 'inputNumber') {
          return (
            <NumberInput
              label={id}
              labelHidden
              value={value as number}
              onValueChange={(v) => {
                onValueChange(v)
              }}
            />
          )
        } else if (meta?.type === 'inputPercentage') {
          return (
            <NumericFormat
              label={id}
              labelHidden
              type='text'
              inputMode='numeric'
              pattern='\d*'
              size='small'
              minWidth='5rem'
              decimalScale={2}
              fixedDecimalScale
              suffix='%'
              customInput={TextField}
              valueIsNumericString={true}
              value={parseFloat(`${value}`) * 100}
              onValueChange={(v) => {
                const value = parseCurrency(v.value)
                if (isNaN(value)) return
                onValueChange(value / 100)
              }}
              {...meta?.props}
            />
          )
        } else if (meta?.type === 'inputMultiple') {
          return (
            <NumericFormat
              label={id}
              labelHidden
              type='text'
              inputMode='numeric'
              pattern='\d*'
              size='small'
              minWidth='5rem'
              thousandSeparator=','
              decimalScale={2}
              fixedDecimalScale
              suffix='x'
              customInput={TextField}
              valueIsNumericString={true}
              value={value as string}
              onValueChange={(v) => {
                const value = parseCurrency(v.value)
                if (isNaN(value)) return
                onValueChange(value)
              }}
              {...meta?.props}
            />
          )
        } else if (meta?.type === 'select') {
          const type = meta?.optionType
          if (!type) throw new Error('option type not given')
          if (!table.options.meta?.getOptions) throw new Error('getOptions not given')
          return (
            <SelectField size='small' minWidth='12rem' value={`${value}`} onChange={(e) => onValueChange(e.target.value)} label={type} labelHidden {...meta?.props}>
              {table.options.meta.getOptions(type).map((option) => (
                <option key={type + option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </SelectField>
          )
        } else if (meta?.type === 'date') {
          return <TextField size='small' label={id} labelHidden type='date' value={fDate(value as any)} onChange={(e) => onValueChange(e.target.value)} {...meta?.props} />
        } else if (meta?.type === 'viewOnlyDate') {
          if (!value) return <Text>-</Text>
          return <Text>{new Date(value as string).toLocaleDateString('en-EN')}</Text>
        }

        throw new Error(`unknown type: id=${id}`)
      },
    },
    meta: {
      updateData,
      deleteRow,
      getOptions,
      getErrors,
      getCell,
    },
  })
  return (
    <Flex direction={'column'}>
      <Table size='small' className={`primaryInvestmentTable ${tableWithDelete ? 'tableWithDelete' : ''}`}>
        <TableHead>
          {table.getHeaderGroups().map((headerGroup, index) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header, subIndex) => {
                if (header.isPlaceholder) return null
                return (
                  <TableCell
                    key={header.id}
                    colSpan={header.colSpan}
                    className={`groupTableHeader ${header.colSpan > 1 && 'groupTableHeaderContainer'} ${
                      (index === 1 && subIndex === 0 && sticky) || (subIndex === 0 && header.colSpan === 1 && sticky) ? 'sticky' : ''
                    }`}
                    textAlign={`${header.colSpan > 1 ? 'center' : 'left'}`}
                    width={header.colSpan > 1 ? `${15 * header.colSpan}rem` : '15rem'}
                  >
                    <Flex>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {enableGrouping && header.column.getCanGroup() ? (
                        // If the header can be grouped, let's add a toggle
                        <Image
                          src={header.column.getIsGrouped() ? '/ungroup.svg' : '/group.svg'}
                          alt='Group By'
                          width={20}
                          height={20}
                          onClick={header.column.getToggleGroupingHandler()}
                          style={{
                            cursor: 'pointer',
                          }}
                        />
                      ) : null}
                      {header.column.getCanSort() ? (
                        <div
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {{
                            asc: <Image src='/upArrow.svg' alt='Sort ASC' width={20} height={20} />,
                            desc: <Image src='/downArrow.svg' alt='Sort DESC' width={20} height={20} />,
                          }[header.column.getIsSorted() as string] ?? <Image src='/upDownArrow.svg' alt='Sort' width={20} height={20} />}
                        </div>
                      ) : null}
                    </Flex>
                    {header.column.getCanFilter() ? (
                      <div>
                        <Filter column={header.column} table={table} />
                      </div>
                    ) : null}
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {table.getRowModel().rows.map((row) => {
            return (
              <TableRow key={row.id}>
                {row.getVisibleCells().map((cell, index) => {
                  return (
                    <TableCell key={cell.id} className={`tableCell ${index === 0 && sticky && 'sticky'}`}>
                      {cell.getIsGrouped() ? (
                        // If it's a grouped cell, add an expander and row count
                        <Link
                          {...{
                            onClick: row.getToggleExpandedHandler(),
                            style: {
                              cursor: row.getCanExpand() ? 'pointer' : 'normal',
                            },
                          }}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}({row.subRows.length})
                          {row.getIsExpanded() ? (
                            <Image src='/upArrow.svg' alt='Sort ASC' width={20} height={20} style={{ marginLeft: '0.5rem', verticalAlign: 'middle' }} />
                          ) : (
                            <Image src='/downArrow.svg' alt='Sort DESC' width={20} height={20} style={{ marginLeft: '0.5rem', verticalAlign: 'middle' }} />
                          )}
                        </Link>
                      ) : cell.getIsAggregated() ? (
                        // If the cell is aggregated, use the Aggregated
                        // renderer for cell
                        flexRender(cell.column.columnDef.aggregatedCell ?? cell.column.columnDef.cell, cell.getContext())
                      ) : cell.getIsPlaceholder() ? null : ( // For cells with repeated values, render null
                        // Otherwise, just render the regular cell
                        flexRender(cell.column.columnDef.cell, cell.getContext())
                      )}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      {paginate && table.getState().pagination.pageSize < data.length && (
        <Button
          onClick={() => {
            table.setPageSize(table.getState().pagination.pageSize + 200)
          }}
        >
          Load More
        </Button>
      )}
    </Flex>
  )
}

export const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({
    itemRank,
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

export const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0

  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(rowA.columnFiltersMeta[columnId]?.itemRank!, rowB.columnFiltersMeta[columnId]?.itemRank!)
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir
}

function Filter({ column, table }: { column: Column<any, unknown>; table: TableCore<any> }) {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id)

  const columnFilterValue = column.getFilterValue()

  const sortedUniqueValues = useMemo(() => (typeof firstValue === 'number' ? [] : Array.from(column.getFacetedUniqueValues().keys()).sort()), [column.getFacetedUniqueValues()])

  return typeof firstValue === 'number' ? (
    <div>
      <div className='flex space-x-2'>
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[0] ?? ''}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [value, old?.[1]])}
          placeholder={`Min ${column.getFacetedMinMaxValues()?.[0] ? `(${column.getFacetedMinMaxValues()?.[0]})` : ''}`}
          className='amplify-input amplify-input--small'
          style={{ width: '48%', marginRight: '0.2rem', background: '#FFF' }}
        />
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[1] ?? ''}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [old?.[0], value])}
          placeholder={`Max ${column.getFacetedMinMaxValues()?.[1] ? `(${column.getFacetedMinMaxValues()?.[1]})` : ''}`}
          className='amplify-input amplify-input--small'
          style={{ width: '48%', background: '#FFF' }}
        />
      </div>
      <div className='h-1' />
    </div>
  ) : (
    <>
      <datalist id={column.id + 'list'}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        type='text'
        value={(columnFilterValue ?? '') as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
        className='amplify-input amplify-input--small'
        style={{ background: '#FFF' }}
        list={column.id + 'list'}
      />
      <div className='h-1' />
    </>
  )
}

// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return <input {...props} value={value} onChange={(e) => setValue(e.target.value)} />
}

export default InputTable
