import { Table, TableBody, TableCell, TableHead, TableRow } from '@aws-amplify/ui-react'
import { FunctionComponent } from 'react'
import { currencyFormatterShort, dateFormatterShort } from '../utils/utils'
import { ChartData } from '../components/Charts'

type FundingRoundsTableProps = {
  data: ChartData[]
  crunchbaseSource: React.ReactNode
}

const FundingRoundsTable: FunctionComponent<FundingRoundsTableProps> = ({ data, crunchbaseSource }) => {
  const tableBody = [...data]
    .sort((a, b) => b.announcedYear - a.announcedYear)
    .map((d, i) => {
      return (
        <TableRow key={`${d.fundingType} ${d?.announcedYear}, ${i}`}>
          <TableCell>{dateFormatterShort(d?.announcedDate) || 'N/A'}</TableCell>
          <TableCell>{d?.fundingType}</TableCell>
          <TableCell>{currencyFormatterShort(d.moneyRaised) || ''}</TableCell>
          <TableCell>{d?.leadInvestors || 'Unknown'}</TableCell>
        </TableRow>
      )
    })
  return (
    <>
      <Table caption='' highlightOnHover={true} size='small' className='outputTable'>
        <TableHead>
          <TableRow>
            <TableCell as='th' className='tooltip down' data-tooltip='Date that the funding round was publicly announced'>Date</TableCell>
            <TableCell as='th' className='tooltip down' data-tooltip='Name of the funding round where the investment was made'>Funding Round</TableCell>
            <TableCell as='th' className='tooltip down' data-tooltip='Amount of money raised in the funding round by all investors'>Money Raised</TableCell>
            <TableCell as='th' className='tooltip down' data-tooltip='Name of the investor(s) who led the investment in the funding round'>Key Investors</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{tableBody}</TableBody>
      </Table>
      {crunchbaseSource}
    </>
  )
}

export default FundingRoundsTable
