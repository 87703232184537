import { Button, Card, Flex, Heading, Link, Text, TextField, View } from '@aws-amplify/ui-react'
import { FunctionComponent, useState } from 'react'
import ProfileSearch from '../components/ProfileSearch'
import PublicCapTableUploader from '../components/PublicCapTableUploader'
import { restAPIRequest } from '../services/restApiRequest'
import { FileUploadRes } from '../services/uploadDataFile'
import { DocumentMetadata, DocumentQuery } from '../types'
import { parseRestError } from '../utils/utils'

const AdminPage: FunctionComponent = () => {
  const [file, setFile] = useState<Blob>()
  const [sending, setSending] = useState<boolean>(false)
  const [uploadError, setUploadError] = useState<string>('')
  const [warnings, setWarn] = useState<string[]>([])
  const [errors, setErrs] = useState<string[]>([])
  const [query, setSearch] = useState<string>()
  const [target, setTarget] = useState<string>()
  const [docs, setDocs] = useState<DocumentQuery[]>([])
  const [queryRes, setVectorQueryRes] = useState<string>()

  const submit = async () => {
    if (sending) return
    setSending(true)
    setWarn([])
    setErrs([])
    setUploadError('')
    try {
      const data = new FormData()
      if (!file) {
        return setUploadError('missing file or file')
      }
      data.append('file', file)
      const fileUploadRes = await restAPIRequest<FileUploadRes>({
        body: data,
        method: 'post',
        path: 'admin-table-update',
      })

      setWarn(fileUploadRes.warnings)
      setErrs(fileUploadRes.errors)
      alert(`SUCCESS!!!: You Created the following Tables\n * ${fileUploadRes.tablesCreated.join('\n* ')}`)
    } catch (err: any) {
      setUploadError(err?.message || err || 'UNKNOWN')
    }
  }

  const vectorSearch = async () => {
    if (!query) return
    try {
      const res = await restAPIRequest<{ docs: DocumentQuery[]; filtered: { context: string; metadata: DocumentMetadata } }>({
        path: `ai/${target}/websiteDbSearch`,
        query: { query: query || '' },
        method: 'get',
      })
      setDocs(res.docs)
      setVectorQueryRes(res.filtered.context)
    } catch (err: any) {
      alert(parseRestError(err))
    }
  }

  return (
    <View>
      <Heading level={1}>ADMIN: CAREFUL THERE BE DRAGONS</Heading>
      <ProfileSearch setSelected={(selected) => setTarget(selected?.id)} />
      <PublicCapTableUploader opportunityName={target} onUpload={() => {}} onError={(err) => alert(err)} />
      <TextField label='Vector DB Search' onChange={(e) => setSearch(e.target.value)} />
      <Button onClick={vectorSearch}>Search</Button>
      <Flex gap={0} direction='column'>
        {docs.map((doc, i) => {
          return (
            <View key={`doc${i}`} padding='.5rem' margin='.5rem' border='1px solid black'>
              <Text>{doc.pageContent}</Text>
              <Link href={doc.metadata.source}>Source: {doc.metadata.source}</Link>
            </View>
          )
        })}
        <Card>
          <Text>{queryRes || ''}</Text>
        </Card>
      </Flex>
      <Text>This allows you to update the config database with an excel file</Text>
      <Text>The table name will be the sheet name</Text>
      <Text>Headers need to be on row 4, Type on Row 3. Data needs to start at col B</Text>
      <Text>After the upload you might see errors and warnings</Text>
      <Text>Warnings are OK. Most of the time this just sets values to NULL or fixes duplicate headers</Text>
      <Text>
        Errors are BAD. This mean the table did not update. If you see the error about type not being defined be sure you DELETE all cells after your last header. I.e if your last
        col is F. make sure to select ALL cols after F (G, H, I, ...) and DELETE them.
      </Text>
      <Text>Enjoy Building!!! </Text>

      {uploadError ? <Text color='red'>Error uploading the file (This is not an issue with the excel file but an issue with the server): {uploadError}</Text> : null}
      <label htmlFor='fileUpload' className='fileUploader'>
        <TextField
          label='fileUpload'
          labelHidden
          type='file'
          id='fileUpload'
          onChange={(e) => {
            if (e.target && e.target.files) {
              setFile(e.target.files[0])
            }
          }}
        />
      </label>
      <Button disabled={sending} isLoading={sending} onClick={() => submit().finally(() => setSending(false))} size='small' variation='link'>
        {sending ? 'Uploading....' : 'Submit'}
      </Button>
      {errors.length > 0 ? (
        <View>
          <Heading level={4} color='red'>
            ERRORS (you NEED to fix these. Incorrect types, missing header or type def)
          </Heading>
          {errors.map((e, i) => {
            return (
              <Text key={`e${i}`} color='red'>
                {e}
              </Text>
            )
          })}
        </View>
      ) : null}
      {warnings.length > 0 ? (
        <View>
          <Heading level={2} color='orange'>
            Warnings (Null values or duplicate headings)
          </Heading>
          {warnings.map((e, i) => {
            return (
              <Text key={`w${i}`} color='orange'>
                {e}
              </Text>
            )
          })}
        </View>
      ) : null}
    </View>
  )
}

export default AdminPage
