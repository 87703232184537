import { Flex, Loader } from "@aws-amplify/ui-react"
import { FunctionComponent } from "react"


const PageLoader: FunctionComponent = () => {
  return (
    <Flex justifyContent='center' alignContent='center'>
    <Flex height='100vh' direction='column' justifyContent='center' alignContent='center'>
      <Loader />
    </Flex>
  </Flex>
  )
}

export default PageLoader;
