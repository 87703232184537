import { Alert, Button, Flex, Loader, Text, View } from '@aws-amplify/ui-react'
import { useQueries } from '@tanstack/react-query'
import { DataStore } from 'aws-amplify/datastore'
import { FunctionComponent, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import CompanyHeader from '../components/CompanyHeader'
import { SelectOpportunityText } from '../components/SelectOpportunityText'
import { useAmplifyUser } from '../contexts/amplifyUserContext'
import { useOpportunity } from '../contexts/opportunityContext'
import { useUser } from '../contexts/userContext'
import ChatView from '../figma-components/ChatView'
import { User } from '../models'
import { SOURCES, enabledForOrg } from '../services/featureFlags'
import { restAPIRequest } from '../services/restApiRequest'
import { DOC_AI, getPathTo } from '../sitemap'
import { DocumentQuery } from '../types'
import { StatusResponse } from '../utils/utils'

type QueryRes = {
  answer: string
  sources?: DocumentQuery[]
}

const DocChatPage: FunctionComponent = () => {
  const params = useParams()
  const chatId = params.chatId
  const opportunity = useOpportunity()
  const navigate = useNavigate()

  const user = useUser()
  const amplifyUser = useAmplifyUser()
  const orgGroups = amplifyUser?.groups
  const documents = opportunity?.files || []
  const [sources, setSources] = useState<DocumentQuery[]>([])
  const currentChatInfo = user?.docChats?.find((c) => c.id === chatId && c.target === opportunity?.id)
  const currentChat = currentChatInfo?.chat || []
  const selectedDocs = documents.filter((doc) => currentChatInfo?.selectedDocs.includes(doc.s3Key))

  const data = useQueries({
    queries: selectedDocs?.map(({ vectorDbId }) => {
      return {
        queryKey: ['doc', { vectorDbId }],
        staleTime: 1000 * 60 * 60 * 24 * 364,
        queryFn: async () =>
          await restAPIRequest<StatusResponse>({
            method: 'get',
            path: `doc/${vectorDbId}`,
          }),
      }
    }),
  })

  if (!opportunity) return <SelectOpportunityText />

  const notOk = data.some((d) => d.data?.status !== 'OK')

  if (!user) return <Text>Login again</Text>
  if (!currentChatInfo) return <Loader>Chat not found</Loader>

  const generateResponse = async (question: string) => {
    if (!user) return alert('login again')
    if (!question) return alert('Please enter a question')

    try {
      const documentIDs = selectedDocs.map(({ vectorDbId }) => vectorDbId)
      if (!documentIDs.length) return alert('no document selected')

      const sent = Math.floor(Date.now() / 1000)
      const response = await restAPIRequest<QueryRes>({
        body: {
          documentIDs,
          documentType: 'docs',
          input: question,
        },
        method: 'post',
        path: 'docs/query',
      })
      if (!response.answer) {
        throw new Error('empty response')
      }
      if (response?.sources) {
        setSources(response.sources || [])
      }

      const chat = [
        ...currentChat,
        {
          role: 'user',
          content: question,
          timestamp: sent,
        },
        {
          role: 'assistant',
          content: response.answer,
          timestamp: Math.floor(Date.now() / 1000),
          sources: response.sources?.map((s) => `${s?.metadata.source}: lines ${s?.metadata?.loc?.lines?.from} - ${s?.metadata?.loc?.lines?.to}`),
        },
      ]

      await DataStore.save(
        User.copyOf(user, (updated) => {
          const existing = updated?.docChats?.filter((c) => c.id !== chatId || c.target !== opportunity?.id) || []
          Object.assign(updated, {
            docChats: [...existing, { ...currentChatInfo, chat }],
          })
        })
      )
    } catch (error) {
      console.error('error updating docAI', error)
      throw error
    }
  }

  const clear = async () => {
    await DataStore.save(
      User.copyOf(user, (updated) => {
        Object.assign(updated, {
          docChats: updated.docChats?.map((u) => {
            if (u.id === chatId && u.target === opportunity?.id) return { ...u, chat: [] }
            return u
          }),
        })
      })
    )
  }

  const backButton = (
    <Button className='tertiary' size='small' onClick={() => navigate(getPathTo(DOC_AI, { opportunityId: opportunity.id }))}>
      Back
    </Button>
  )

  const debugSources = () => {
    if (!enabledForOrg(orgGroups, SOURCES)) return null
    return (
      <Flex gap={0} direction='column'>
        {sources.map((doc, i) => {
          return (
            <View key={`doc${i}`} padding='.5rem' margin='.5rem' border='1px solid black'>
              <Text>{doc.pageContent}</Text>
              <Text>{`pageNumber: ${doc.metadata?.loc?.pageNumber} lines: ${doc.metadata.loc?.lines?.from} - ${doc.metadata.loc?.lines?.to}`}</Text>
            </View>
          )
        })}
      </Flex>
    )
  }

  return (
    <>
      <CompanyHeader />
      <ChatView
        hideSource
        header='AI Document Chat'
        headerButtons={backButton}
        quickPrompts={[]}
        disabled={notOk}
        disabledMessage={
          <Alert variation='info'>
            <Loader /> Loading documents
          </Alert>
        }
        initIsExpanded={true}
        enabled={!!opportunity}
        placeholder={'chat with doc'}
        history={currentChat}
        postQuestion={generateResponse}
        clearClicked={clear}
        contentSources={
          selectedDocs.map((d) => ({
            type: 'doc',
            label: d.name,
          })) || []
        }
      />
      {debugSources()}
    </>
  )
}

export default DocChatPage
