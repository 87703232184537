import { TextField } from '@aws-amplify/ui-react'
import { FunctionComponent } from 'react'
import { NumericFormat } from 'react-number-format'
import { parseCurrency } from '../utils/utils'

interface NumberInputProps {
  label: string
  value: number
  labelHidden?: boolean
  className?: string
  variation?: 'quiet'
  isDisabled?: boolean
  onValueChange: (value: number) => void
}

const NumberInput: FunctionComponent<NumberInputProps> = ({ label, value, labelHidden = false, onValueChange, className, variation, isDisabled = false }) => {
  return (
    <NumericFormat
      disabled={isDisabled}
      className={className}
      variation={variation}
      label={label}
      labelHidden={labelHidden}
      type='text'
      inputMode='numeric'
      pattern='\d*'
      size='small'
      minWidth='10rem'
      thousandSeparator=','
      customInput={TextField}
      value={value}
      valueIsNumericString={true}
      onValueChange={(v) => {
        const value = parseCurrency(v.value || 0)
        if (isNaN(value)) return
        onValueChange(value)
      }}
    />
  )
}

export default NumberInput
