import { Card, Text } from '@aws-amplify/ui-react'
import { FunctionComponent } from 'react'
import { CartesianGrid, Customized, Label, Rectangle, ReferenceLine, ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis } from 'recharts'
import { currencyFormatterShort, multipleFormatter, percentFormatter } from '../utils/utils'

export type ChartData = {
  name: string
  value?: number
}

export type ValueTypes = 'percent' | 'currency' | 'multiple'
interface CandleStickViewProps {
  data: ChartData[]
  target: ChartData
  label: string
  valueType: ValueTypes
}

const CandleStickView: FunctionComponent<CandleStickViewProps> = ({ data, target, label, valueType }) => {
  const points = data.map((d) => ({ ...d, key: '' }))

  const targetPoint = {
    key: '',
    value: target.value,
    name: target.name,
  }
  let formatter = currencyFormatterShort
  if (valueType === 'percent') {
    formatter = percentFormatter
  } else if (valueType === 'multiple') {
    formatter = multipleFormatter
  }

  return (
    <ResponsiveContainer width='100%' height='100%'>
      <ScatterChart>
        <CartesianGrid />
        <XAxis name={label} label={label} dataKey='key' type='category' allowDuplicatedCategory={false} />
        <YAxis name={label} dataKey='value' type='number' allowDecimals interval='preserveStartEnd' tickFormatter={formatter} />
        {/*
        @ts-ignore */}
        <Tooltip content={<CustomTooltip info={{ label, formatter }} />} />
        <ReferenceLine y={target.value} stroke='orange' isFront={true} ifOverflow='extendDomain'>
          <Label value='Target' position='insideTopLeft' color='orange' fill='orange' />
        </ReferenceLine>
        <Customized component={CustomizedRectangle} />
        <Scatter data={points} fill='#8884d8' />
        <Scatter data={[targetPoint]} fill='orange' />
      </ScatterChart>
    </ResponsiveContainer>
  )
}

const CustomTooltip = ({ active, payload, info: { label, formatter } }: any) => {
  if (active && payload && payload.length) {
    const { value, name } = payload[0].payload

    return (
      <Card variation='elevated' z-index={500}>
        <Text>
          {label}: {formatter(value)}
        </Text>
        <Text>Company Name: {name}</Text>
      </Card>
    )
  }

  return null
}

const CustomizedRectangle = (props) => {
  const { formattedGraphicalItems } = props
  const points = formattedGraphicalItems[0].props.points

  if (points.length === 0) {
    return null
  }
  const { lowerWhisker, upperWhisker, median, lowerHinge, upperHinge } = calculateCandlestickData(points)
  const ratio = Math.abs((upperWhisker.cy - lowerWhisker.cy) / (upperWhisker.value - lowerWhisker.value))
  const base = lowerWhisker.cy

  const width = 100
  const height = upperWhisker.cy - lowerWhisker.cy
  const x = points[0].cx - width / 2
  return (
    <>
      {/* top line */}
      <Rectangle x={x} y={upperWhisker.cy - 1.5} width={width} height={3} fill='black' stroke='none' />
      {/* bottom line */}
      <Rectangle x={x} y={lowerWhisker.cy - 1.5} width={width} height={3} fill='black' stroke='none' />
      {/* vertical line */}
      <Rectangle x={x + width / 2 - 1.5} y={lowerWhisker.cy} width={3} height={height} fill='black' stroke='none' />
      {/* upper bar */}
      <Rectangle x={x} y={base - (median - lowerWhisker.value) * ratio} width={width} height={-((upperHinge - median) * ratio)} stroke='none' fill={'#e1e1e1'} fillOpacity={0.5} />
      {/* lower bar */}
      <Rectangle x={x} y={base - (median - lowerWhisker.value) * ratio} width={width} height={(median - lowerHinge) * ratio} stroke='none' fill={'#bdbdbd'} fillOpacity={0.5} />
    </>
  )
}

const calculateCandlestickData = (data) => {
  const sortedData = data.sort((a, b) => a.value - b.value)

  const lowerWhisker = sortedData[0]
  const upperWhisker = sortedData[sortedData.length - 1]

  const lowerHinge = calculateQuartile(sortedData, 0.25)
  const upperHinge = calculateQuartile(sortedData, 0.75)
  const median = calculateMedian(sortedData.map((i) => i.value))
  return {
    upperWhisker,
    upperHinge,
    median,
    lowerHinge,
    lowerWhisker,
  }
}

const calculateQuartile = (data, percentile) => {
  const index = (data.length - 1) * percentile
  const lowerIndex = Math.floor(index)
  const upperIndex = Math.ceil(index)

  const lowerValue = data[lowerIndex].value
  const upperValue = data[upperIndex].value

  const interpolation = index - lowerIndex

  return lowerValue + (upperValue - lowerValue) * interpolation
}

const calculateMedian = (sortedValues) => {
  const middleIndex = Math.floor(sortedValues.length / 2)

  if (sortedValues.length % 2 === 0) {
    return (sortedValues[middleIndex - 1] + sortedValues[middleIndex]) / 2
  } else {
    return sortedValues[middleIndex]
  }
}

export default CandleStickView
