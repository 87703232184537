import { Button, Flex, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@aws-amplify/ui-react'
import { FunctionComponent } from 'react'
import { AiOutlineTag, DeleteIcon, MarketMapIcon } from '../ui-components'
import { currencyFormatterShort, percentFormatter, dateFormatterLong } from '../utils/utils'
import IconList from './IconList'

export type ListInfoChartData = {
  target: string
  companyName: string
  fte: number
  fteGrowth1yr: number
  totalCapitalRaised: number
  keyInvestors: string
  timeSinceLastRaise: number
  location: string
  lastFundingType: string
  lastFundingDate: number
  lastFundingAmount: number
  lastEquityFundingType: string
  lastEquityValuation: number
  numFundingRounds: number
  revEstRange: string
  taxonomy: string[]
  gtm: string[]
} & {
  [k: string]: string | number
}

type CompsViewProps = {
  data: ListInfoChartData[]
  extraHeaders?: string[]
  cellChanged: (index: number, header: string, value: string) => void
  companyClicked: (targetCompany: string) => void
  headerChanged: (index: number, newHeader: string) => void
  deleteHeaderClicked: (header: string) => void
}

const ListInfoTable: FunctionComponent<CompsViewProps> = ({ extraHeaders, data, cellChanged, companyClicked, headerChanged, deleteHeaderClicked }) => {
  const tableBody = data.map((d, index) => {
    const totalCapitalRaised = d.totalCapitalRaised ? currencyFormatterShort(d.totalCapitalRaised) : 'N/A'
    // const revEstRange = d.revEstRange ? (typeof d.revEstRange === 'string' ? d.revEstRange : currencyFormatterShort(d.revEstRange)) : 'N/A'

    return (
      <TableRow key={d.target}>
        <TableCell minWidth='10rem'>
          <Button size='small' variation='link' onClick={() => companyClicked(d.companyName)}>
            {d.target}
          </Button>
        </TableCell>
        <TableCell minWidth='25rem'>{d.description || 'N/A'}</TableCell>
        <TableCell minWidth='8rem'>{d.fte || 'N/A'}</TableCell>
        <TableCell minWidth='8rem'>{percentFormatter(d.fteGrowth1yr)}</TableCell>
        <TableCell minWidth='8rem'>{totalCapitalRaised}</TableCell>
        <TableCell minWidth='15rem'>{d.keyInvestors || 'N/A'}</TableCell>
        <TableCell minWidth='8rem'>{d.timeSinceLastRaise || 'N/A'}</TableCell>
        <TableCell minWidth='10rem'>{d.location || 'N/A'}</TableCell>
        <TableCell minWidth='10rem'>{d.lastFundingType || 'N/A'}</TableCell>
        <TableCell minWidth='8rem'>{dateFormatterLong(d.lastFundingDate) || 'N/A'}</TableCell>
        <TableCell minWidth='8rem'>{currencyFormatterShort(d.lastFundingAmount) || 'N/A'}</TableCell>
        <TableCell minWidth='10rem'>{d.lastEquityFundingType || 'N/A'}</TableCell>
        <TableCell minWidth='8rem'>{currencyFormatterShort(d.lastEquityValuation) || 'N/A'}</TableCell>
        <TableCell minWidth='8rem'>{d.numFundingRounds || 'N/A'}</TableCell>
        {/* <TableCell minWidth='8rem'>{revEstRange}</TableCell>
        <TableCell minWidth='15rem'>
          <IconList list={d.taxonomy} icon={<AiOutlineTag />} />
        </TableCell>
        <TableCell minWidth='8rem'>
          <IconList list={d.gtm} icon={<MarketMapIcon />} />
        </TableCell> */}
        {extraHeaders?.map((h, i) => (
          <TableCell key={d.name + h + i}>
            <TextField
              variation='quiet'
              size='small'
              label={h}
              labelHidden
              autoComplete='off'
              type='text'
              value={d[h] || ''}
              onChange={(e) => cellChanged(index, h, e.target.value)}
            />
          </TableCell>
        ))}
      </TableRow>
    )
  })
  return (
    <Table id='ListInfoTable' caption='' size='small' variation='bordered' className='outputTable'>
      <TableHead top='0' style={{ zIndex: '2' }}>
        <TableRow>
          <TableCell as='th'>Company</TableCell>
          <TableCell as='th'>Description</TableCell>
          <TableCell as='th'>FTE</TableCell>
          <TableCell as='th'>FTE Growth (1yr)</TableCell>
          <TableCell as='th'>Total Funding Amount</TableCell>
          <TableCell as='th'>Key Investors</TableCell>
          <TableCell as='th'>Months Since Last Raise</TableCell>
          <TableCell as='th'>HQ Location</TableCell>
          <TableCell as='th'>Last Funding Type</TableCell>
          <TableCell as='th'>Last Funding Date</TableCell>
          <TableCell as='th'>Last Funding Amount</TableCell>
          <TableCell as='th'>Last Equity Funding Type</TableCell>
          <TableCell as='th'>Last Equity Valuation (Pre-Money)</TableCell>
          <TableCell as='th'>Number of Funding Rounds</TableCell>
          {/* <TableCell as='th'>Rev Est.</TableCell>
          <TableCell as='th'>Taxonomies</TableCell>
          <TableCell as='th'>Markets</TableCell> */}
          {extraHeaders?.map((h, i) => (
            <TableCell key={'extraheader' + i}>
              <Flex alignItems='center'>
                <TextField variation='quiet' size='small' type='text' fontWeight='600' label={h} labelHidden value={h || ''} onChange={(e) => headerChanged(i, e.target.value)} />
                <DeleteIcon className='deleteIcon' onClick={(e) => deleteHeaderClicked(h)} />
              </Flex>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>{tableBody}</TableBody>
    </Table>
  )
}

export default ListInfoTable
