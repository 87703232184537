import { AuthSession, AuthUser, FetchUserAttributesOutput, fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth'
import { FunctionComponent, PropsWithChildren, createContext, useContext } from 'react'

export const AmplifyUserContext = createContext<ExtendedAmplifyUser | undefined>(undefined)
export type ExtendedAmplifyUser = {
  org: string
  groups: string[]
  name: string
  email: string
  userId: string
  username: string
}

interface UserProviderProps extends PropsWithChildren {
  user: ExtendedAmplifyUser
}

export const AmplifyUserProvider: FunctionComponent<UserProviderProps> = ({ children, user, ...props }) => {
  return (
    <AmplifyUserContext.Provider {...props} value={user}>
      {children}
    </AmplifyUserContext.Provider>
  )
}

function getGroupsFromSession(session: AuthSession) {
  return (session.tokens?.idToken?.payload?.['cognito:groups'] || []) as string[]
}

function getOrgNameFromSession(session: AuthSession) {
  return ((session.tokens?.idToken?.payload?.['cognito:groups'] as string[]) || []).filter((u) => !u.includes('PLAYBOOK:'))[0] || 'NO_ORG'
}
function getNameFromAttr(attributes: FetchUserAttributesOutput) {
  return attributes?.name || 'Unknown Name'
}

function getEmailFromAttr(attributes: FetchUserAttributesOutput) {
  return attributes?.email || 'Unknown Email'
}
function getUserIdFromAttr(attributes: FetchUserAttributesOutput) {
  return attributes?.sub || 'Unknown ID'
}

export async function populateUser(user: AuthUser): Promise<ExtendedAmplifyUser> {
  const attributes = await fetchUserAttributes()
  const session = await fetchAuthSession()
  return {
    org: getOrgNameFromSession(session),
    groups: getGroupsFromSession(session),
    name: getNameFromAttr(attributes),
    email: getEmailFromAttr(attributes),
    userId: getUserIdFromAttr(attributes),
    username: user.username,
  }
}
export const useAmplifyUser = (): ExtendedAmplifyUser | undefined => useContext(AmplifyUserContext)
