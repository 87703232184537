import { Text } from '@aws-amplify/ui-react'
import { GetCell } from './components/InputTable'
import './styles/table.css'
import { DividendTypes, ExistingShareHolder, ShareHolderType } from './types'

export type TransactionModelRowTypes = SecondaryInvestmentDetail | PrimaryInvestmentDetail | NewInvestorDetail | ShareHolderType | ConvertibleNoteDetail

export const dividendTypeOptions = [
  {
    label: 'Not Declared',
    value: 'DIVIDEND_NOT_DECLARED',
  },
  {
    label: 'Non Cumulative',
    value: 'DIVIDEND_NON_CUMULATIVE',
  },
  {
    label: 'Cumulative',
    value: 'DIVIDEND_CUMULATIVE',
  },
]

export const participationTypeOptions = [
  {
    label: 'Non Participating',
    value: 'PARTICIPATION_RIGHTS_NON_PARTICIPATING',
  },
  {
    label: 'Full Participating',
    value: 'PARTICIPATION_RIGHTS_PARTICIPATING_FULL',
  },
  {
    label: 'Participating with Cap',
    value: 'PARTICIPATION_RIGHTS_PARTICIPATING_WITH_CAP',
  },
]

export const shareTypeOptions = [
  {
    label: 'Preferred',
    value: 'SHARE_HOLDER_TYPE_SERIES',
  },
  {
    label: 'Warrant',
    value: 'SHARE_HOLDER_TYPE_WARRANT',
  },
  {
    label: 'Common',
    value: 'SHARE_HOLDER_TYPE_COMMON',
  },
  {
    label: 'Option',
    value: 'SHARE_HOLDER_TYPE_OPTION',
  },
]

export type SecondaryInvestmentDetail = {
  existingShareholderKey: string
  existingShareholderName: string
  existingShareholderGroup: string
  shareValue: number
  numOfSharesSold: number
  pricePerShare: number
  totalProceeds: number
  buyerName: string
  buyerGroup: string
}

export const secondaryInvestmentColumns = [
  {
    header: 'Existing Shareholder Name',
    accessorKey: 'existingShareholderKey',
    meta: {
      type: 'select',
      optionType: 'ExistingShareholderNames',
    },
  },
  {
    header: 'Share Value',
    accessorKey: 'shareValue',
    meta: {
      type: 'viewOnlyMoney',
    },
  },
  {
    header: 'Total Proceeds',
    accessorKey: 'totalProceeds',
    meta: {
      type: 'inputMoney',
    },
  },
  {
    header: 'Buyer Name',
    accessorKey: 'buyerName',
    meta: {
      type: 'select',
      optionType: 'NewInvestors',
    },
  },
  {
    header: '',
    accessorKey: 'delete',
    enableSorting: false,
    enableGrouping: false,
    meta: {
      type: 'deleteButton',
    },
  },
]

export type NewInvestorDetail = {
  shareholderName: string
  group: string
}

export type ConvertibleNoteDetail = {
  shareholderName: string
  valuation: number
  maturityDate: string
  amountInvested: number
  convertDate: string
  investmentDate: string
  dividendType: DividendTypes
  interestRate: number
  convertTo: Omit<PrimaryInvestmentDetail, 'shareholderName' | 'group' | 'shareCount' | 'pricePerShare' | 'investmentDate' | 'totalContribution'>
}

export const convertibleNoteColumns = [
  {
    header: 'Convertible Note',
    enableSorting: false,
    enableGrouping: false,
    columns: [
      {
        header: 'Investor Name',
        accessorKey: 'shareholderName',
        meta: {
          type: 'select',
          optionType: 'NewInvestors',
        },
      },
      {
        header: 'Total Contribution ($)',
        accessorKey: 'amountInvested',
        meta: {
          type: 'inputMoney',
        },
      },
      {
        header: 'Investment Date',
        accessorKey: 'investmentDate',
        meta: {
          type: 'date',
        },
      },
      {
        header: 'Maturity Date',
        accessorKey: 'maturityDate',
        meta: {
          type: 'date',
        },
      },
      {
        header: 'Conversion Date',
        accessorKey: 'convertDate',
        meta: {
          type: 'date',
        },
      },
      {
        header: 'Valuation',
        accessorKey: 'valuation',
        meta: {
          type: 'inputMoney',
        },
      },
      {
        header: 'Dividend Type',
        accessorKey: 'dividendType',
        meta: {
          type: 'select',
          optionType: 'DividendTypes',
        },
      },
      {
        header: 'Dividend or Interest Rate',
        accessorKey: 'interestRate',
        meta: {
          type: 'inputPercentage',
        },
      },
    ],
  },
  {
    header: 'Converts To',
    enableSorting: false,
    enableGrouping: false,
    columns: [
      {
        header: 'Type',
        accessorKey: 'convertTo.type',
        meta: {
          type: 'select',
          optionType: 'ShareTypes',
        },
      },
      {
        header: 'Strike Price',
        accessorKey: 'convertTo.strikePrice',
        meta: {
          type: 'inputMoney',
        },
      },
      {
        header: 'Liquidation Multiple on Investments',
        accessorKey: 'convertTo.liquidationMultiple',
        meta: {
          type: 'inputMultiple',
        },
      },
      {
        header: 'Participation Rights',
        accessorKey: 'convertTo.participationType',
        meta: {
          type: 'select',
          optionType: 'ParticipationTypes',
        },
      },
      {
        header: 'Cap on Participation',
        accessorKey: 'convertTo.capOnParticipation',
        meta: {
          type: 'inputMultiple',
        },
      },
      {
        header: 'Dividend Type',
        accessorKey: 'convertTo.dividendType',
        meta: {
          type: 'select',
          optionType: 'DividendTypes',
        },
      },
      {
        header: 'Dividend or Interest Rate',
        accessorKey: 'convertTo.interestRate',
        meta: {
          type: 'inputPercentage',
        },
      },
    ],
  },
  {
    header: '',
    accessorKey: 'delete',
    enableSorting: false,
    enableGrouping: false,
    meta: {
      type: 'deleteButton',
    },
  },
]

export type PrimaryInvestmentDetail = {
  type: string
  shareholderName: string
  totalContribution: number
  liquidationMultiple: number
  participationType: string
  capOnParticipation: number
  investmentDate: string
  dividendType: string
  interestRate: number
  strikePrice?: number
  pricePerShare?: number
}

export const primaryInvestmentColumns = [
  {
    header: 'Participants',
    enableGrouping: false,
    columns: [
      {
        header: 'Investor Name',
        accessorKey: 'shareholderName',
        meta: {
          type: 'select',
          optionType: 'NewInvestors',
        },
      },
      {
        header: 'Total Contribution ($)',
        accessorKey: 'totalContribution',
        meta: {
          type: 'inputMoney',
        },
      },
      {
        header: 'Type',
        accessorKey: 'type',
        meta: {
          type: 'select',
          optionType: 'ShareTypes',
        },
      },
      {
        header: 'Investment Date',
        accessorKey: 'investmentDate',
        meta: {
          type: 'date',
        },
      },
      {
        header: 'Strike Price',
        accessorKey: 'strikePrice',
        meta: {
          type: 'inputMoney',
        },
      },
    ],
  },
  {
    header: 'Liquidation Preferences',
    enableGrouping: false,
    columns: [
      {
        header: 'Liquidation Multiple on Investments',
        accessorKey: 'liquidationMultiple',
        meta: {
          type: 'inputMultiple',
        },
      },
      {
        header: 'Participation Rights',
        accessorKey: 'participationType',
        meta: {
          type: 'select',
          optionType: 'ParticipationTypes',
        },
      },
      {
        header: 'Cap on Participation',
        accessorKey: 'capOnParticipation',
        meta: {
          type: 'inputMultiple',
        },
      },
    ],
  },
  {
    header: 'Dividends',
    enableGrouping: false,
    columns: [
      {
        header: 'Dividend Type',
        accessorKey: 'dividendType',
        meta: {
          type: 'select',
          optionType: 'DividendTypes',
        },
      },
      {
        header: 'Dividend or Interest Rate',
        accessorKey: 'interestRate',
        meta: {
          type: 'inputPercentage',
        },
      },
    ],
  },
  {
    header: 'Overrides',
    enableGrouping: false,
    columns: [
      {
        header: 'Price Per Share',
        accessorKey: 'pricePerShare',
        meta: {
          type: 'inputMoney',
        },
      },
    ],
  },
  {
    header: '',
    accessorKey: 'delete',
    enableSorting: false,
    enableGrouping: false,
    meta: {
      type: 'deleteButton',
    },
  },
]

export const existingCapTableColumns = [
  {
    header: 'General',
    enableGrouping: false,
    columns: [
      {
        header: 'Investor Name',
        accessorKey: 'shareholderName',
        meta: {
          type: 'inputString',
        },
      },
      {
        header: 'Group',
        accessorKey: 'group',
        meta: {
          type: 'inputString',
        },
      },
      {
        header: 'Type',
        accessorKey: 'type',
        meta: {
          type: 'select',
          optionType: 'ShareTypes',
        },
      },
      {
        header: 'Investment Date',
        accessorKey: 'investmentDate',
        meta: {
          type: 'date',
        },
      },
    ],
  },
  {
    header: 'Equity',
    enableGrouping: false,
    columns: [
      {
        header: 'Price Per Share',
        accessorKey: 'pricePerShare',
        meta: {
          type: 'inputMoney',
        },
      },
      {
        header: 'Share Count',
        accessorKey: 'shareCount',
        meta: {
          type: 'inputNumber',
        },
      },
      {
        header: 'Conversion Ratio',
        accessorKey: 'conversionRatio',
        meta: {
          type: 'inputMultiple',
        },
      },
      {
        header: 'Liquidation Preference',
        accessorKey: 'liquidationMultiple',
        meta: {
          type: 'inputMultiple',
        },
      },
      {
        header: 'Participation Type',
        accessorKey: 'participationType',
        meta: {
          type: 'select',
          optionType: 'ParticipationTypes',
        },
      },
      {
        header: 'Cap on Participation',
        accessorKey: 'capOnParticipation',
        meta: {
          type: 'inputMultiple',
        },
      },
    ],
  },
  {
    header: 'Option / Warrant',
    enableGrouping: false,
    columns: [
      {
        header: 'Strike Price',
        accessorKey: 'strikePrice',
        meta: {
          type: 'inputMoney',
        },
      },
      {
        header: 'Dividend Type',
        accessorKey: 'dividendType',
        meta: {
          type: 'select',
          optionType: 'DividendTypes',
        },
      },
      {
        header: 'Dividend or Interest Rate',
        accessorKey: 'interestRate',
        meta: {
          type: 'inputPercentage',
        },
      },
    ],
  },
  {
    header: 'Convertible Note',
    enableGrouping: false,
    columns: [
      {
        header: 'Total Contribution ($)',
        accessorKey: 'amountInvested',
        meta: {
          type: 'inputMoney',
        },
      },
      {
        header: 'Maturity Date',
        accessorKey: 'maturityDate',
        meta: {
          type: 'date',
        },
      },
      {
        header: 'Conversion Date',
        accessorKey: 'convertDate',
        meta: {
          type: 'date',
        },
      },
      {
        header: 'Valuation',
        accessorKey: 'valuation',
        meta: {
          type: 'inputMoney',
        },
      },
      {
        header: 'Dividend Type',
        accessorKey: 'dividendType',
        meta: {
          type: 'select',
          optionType: 'DividendTypes',
        },
      },
      {
        header: 'Dividend or Interest Rate',
        accessorKey: 'interestRate',
        meta: {
          type: 'inputPercentage',
        },
      },
    ],
  },
  {
    header: '',
    accessorKey: 'delete',
    enableSorting: false,
    enableGrouping: false,
    meta: {
      type: 'deleteButton',
    },
  },
]

export const getCapTableCells: GetCell<PrimaryInvestmentDetail | ExistingShareHolder> = ({ value, originalRow, colName }) => {
  if (!originalRow.type) return null
  const existingShareholder = originalRow
  if (['SHARE_HOLDER_TYPE_COMMON'].includes(existingShareholder.type)) {
    if (
      [
        'strikePrice',
        'pricePerShare',
        'liquidationMultiple',
        'participationType',
        'capOnParticipation',
        'investmentDate',
        'dividendType',
        'conversionRatio',
        'interestRate',
      ].includes(colName)
    ) {
      return <Text textAlign='center'>-</Text>
    }
  } else if (['SHARE_HOLDER_TYPE_SERIES'].includes(existingShareholder.type)) {
    if (['strikePrice'].includes(colName)) {
      return <Text textAlign='center'>-</Text>
    }
    if (['capOnParticipation'].includes(colName)) {
      // @ts-ignore
      if (existingShareholder?.participationType !== 'PARTICIPATION_RIGHTS_PARTICIPATING_WITH_CAP') {
        return <Text textAlign='center'>-</Text>
      }
    }
    if (['interestRate'].includes(colName)) {
      // @ts-ignore
      if (!['DIVIDEND_CUMULATIVE', 'DIVIDEND_NON_CUMULATIVE'].includes(existingShareholder?.dividendType)) {
        return <Text textAlign='center'>-</Text>
      }
    }
  } else if (['SHARE_HOLDER_TYPE_OPTION', 'SHARE_HOLDER_TYPE_WARRANT'].includes(existingShareholder.type)) {
    if (['liquidationMultiple', 'pricePerShare', 'participationType', 'capOnParticipation', 'dividendType', 'conversionRatio', 'interestRate'].includes(colName)) {
      return <Text textAlign='center'>-</Text>
    }
  }

  return null
}
