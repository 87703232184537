import { Tabs, View } from '@aws-amplify/ui-react'
import { FunctionComponent } from 'react'
import Dashboard from './Dashboard'

type DashboardListViewProps = {
  currentDashboardUrl: string
  setCurrentDashboard: Function
  dashboardUrls: string[]
}

const DashboardListView: FunctionComponent<DashboardListViewProps> = ({ dashboardUrls, currentDashboardUrl, setCurrentDashboard }) => {
  setCurrentDashboard(dashboardUrls[0]) // set initial dashboard url on page load

  return (
    <View>
      <Tabs.Container>
        <Tabs.List className='lightmodeTabs'>
          {dashboardUrls.map((url, i) => {
            const name = url?.split('/')[7]
            if (!name) console.error(`missing dashname in url ${url}`)
            return (
              <Tabs.Item value={`${i}`} key={url} title={name} onClick={() => setCurrentDashboard(url)}>
                {name}
              </Tabs.Item>
            )
          })}
        </Tabs.List>
      </Tabs.Container>
      <Dashboard url={currentDashboardUrl} />
    </View>
  )
}

export default DashboardListView
