import { useQueries } from '@tanstack/react-query'
import { CompanyInfoV3 } from '../API'
import { queryCompanyInfoV3 } from './useCompanyInfoV2'
import { Error404 } from '../types'

type ListCompanyRes = {
  targetCompanyName: String
  data: CompanyInfoV3 | undefined
  isLoading: boolean
  error: Error | undefined
}

export function useCompanyInfoV2List(targetCompanyNames: string[]): ListCompanyRes[] {
  targetCompanyNames.sort((a, b) => a.localeCompare(b))
  const q = useQueries({
    queries: targetCompanyNames.map((id) => {
      return {
        staleTime: 1000 * 60 * 5,
        queryKey: ['companyInfo', id],
        retry: (failureCount, error) => {
          if (error instanceof Error404 || error?.response?.status === 404) {
            return false
          }

          return true
        },
        queryFn: queryCompanyInfoV3(id),
      }
    }),
  })

  return q.map((res, i) => ({
    targetCompanyName: targetCompanyNames[i],
    data: res.data,
    isLoading: res.isLoading,
    error: res.error as Error | undefined,
  }))
}
