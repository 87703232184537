import { Button, Card, CheckboxField, Flex, Loader, ScrollView, Text, View } from '@aws-amplify/ui-react'
import { DataStore } from 'aws-amplify/datastore'
import { FunctionComponent, useState } from 'react'
import { Tooltip } from 'react-tooltip'
import { utils } from 'xlsx'
import { useOpportunity } from '../contexts/opportunityContext'
import { useSetUser } from '../contexts/userContext'
import GlassCard from '../figma-components/GlassCard'
import { ProductSimilaritySearchRes, useProductSimilaritySearchList } from '../hooks/useProductSimilaritySearchList'
import { Opportunity, ProductInfo } from '../models'
import { exportExcel } from '../services/excel'
import { BsBookmark } from '../ui-components'
import ExcelIcon from '../ui-components/ExcelIcon'
import { DELIM, p } from '../utils/utils'
import DashboardList from './DashboardList'
import InputTable from './InputTable'

type CompetitiveLandscapeProps = {
  products: ProductInfo[]
  companyName: string
  taxonomies: string[]
}

const BaseCols = [
  // {
  //   header: '',
  //   accessorKey: 'delete',
  //   meta: {
  //     type: 'viewOnlyString',
  //   },
  // },
  {
    header: 'Company Name',
    accessorKey: 'companyLabel',
    getGroupingValue: (row) => `${row.companyLabel}`,
    meta: {
      type: 'link',
      valueToLink: (i: string, row) => `/profiles/${row.companyName}`,
    },
  },
  {
    header: 'Product Name',
    accessorKey: 'productName',
    meta: {
      type: 'viewOnlyString',
    },
  },
  {
    header: 'Similarity Score',
    accessorKey: 'score',
    aggregationFn: 'mean',
    aggregatedCell: ({ getValue }) => p(getValue()),
    meta: {
      type: 'viewOnlyPercentage',
    },
  },
  {
    header: 'Product Description',
    accessorKey: 'description',
    meta: {
      type: 'viewOnlyString',
    },
  },
  // {
  //   header: 'Markets',
  //   accessorKey: 'markets',
  //   meta: {
  //     type: 'markets',
  //   },
  // },
]

const MetricCols = [
  {
    header: 'Summary',
    accessorKey: 'cb_description',
    meta: {
      type: 'viewOnlyString',
    },
  },
  {
    header: 'Entity Type',
    accessorKey: 'companyType',
    meta: {
      type: 'viewOnlyMoney',
    },
  },
  {
    header: 'Rev Est ($M)',
    accessorKey: 'cyberdb_revenues',
    meta: {
      type: 'viewOnlyMoney',
    },
  },
  {
    header: 'Total Funding ($M)',
    accessorKey: 'cb_total_funding_amount',
    meta: {
      type: 'viewOnlyMoney',
    },
  },
]

const CompetitiveLandscape: FunctionComponent<CompetitiveLandscapeProps> = ({ products, companyName, taxonomies }) => {
  const setUser = useSetUser()
  const opportunity = useOpportunity()
  const similarProductsList = useProductSimilaritySearchList(companyName, products)
  const [isOpen, setIsOpen] = useState(false)
  const [isSavingNewList, setIsSavingNewList] = useState(false)
  const simMap = similarProductsList.reduce((p, c) => {
    if (c.isLoading) return p
    if (!c.data) return p
    c.data.forEach((tResults) => {
      const tax = tResults.taxonomy
      if (!p[tax]) p[tax] = {}
      tResults.results.forEach((res, i) => {
        if (res.score > 0.3 && i !== 0) return
        const key = `${res.companyName}${DELIM}${res.productName}`
        if (!p[tax][key]) p[tax][key] = []
        // invert score score to be in range 0 - 1 where 1 is the best match
        p[tax][key].push({ ...res, score: 1 - res.score })
      })
    })
    return p
  }, {} as Record<string, Record<string, ProductSimilaritySearchRes['results']>>)

  const listsPerTaxonomy = Object.keys(simMap).map((taxonomy) => {
    const list = Object.keys(simMap[taxonomy]).reduce((p, cNameProduct) => {
      const first = simMap[taxonomy][cNameProduct][0]
      const avgScore = simMap[taxonomy][cNameProduct].reduce((p, c) => p + c.score, 0) / simMap[taxonomy][cNameProduct].length
      p.push({
        ...first,
        score: avgScore,
      })
      return p
    }, [] as ProductSimilaritySearchRes['results'])
    return { list, taxonomy }
  })

  listsPerTaxonomy.sort((a, b) => a.taxonomy.localeCompare(b.taxonomy))

  const fieldOptions = MetricCols.map((i) => i.header).filter((i) => i)

  const [selectedHeaders, setSelectedHeaders] = useState<string[]>(opportunity?.marketMapSelectedMetrics || fieldOptions)

  // TODO: uncomment this if we allow users to edit
  // const finalCols = [...BaseCols, ...selectedHeaders.map((i) => MetricCols.find((j) => j.header === i))]
  const finalCols = BaseCols
  const saveSelected = () => {
    if (!opportunity) return
    DataStore.save(
      Opportunity.copyOf(opportunity, (updated) => {
        updated.marketMapSelectedMetrics = selectedHeaders
      })
    )
  }

  const popOver = (
    <Card>
      <Flex direction='column'>
        <Text>+ Add Metric</Text>
        <Flex maxHeight='350px' overflow='scroll' direction='column'>
          {fieldOptions.map((i) => {
            const checked = selectedHeaders.includes(i)
            return (
              <CheckboxField
                key={i}
                label={i}
                name={i}
                value={i}
                checked={checked}
                onChange={(e) => {
                  if (checked) {
                    setSelectedHeaders(selectedHeaders.filter((i) => i !== e.target.value))
                  } else {
                    setSelectedHeaders([...selectedHeaders, e.target.value])
                  }
                }}
              />
            )
          })}
        </Flex>
        <Button
          onClick={() => {
            saveSelected()
            setIsOpen(false)
          }}
        >
          Save
        </Button>
      </Flex>
    </Card>
  )

  const addMetrics = (
    <View>
      <Button data-tooltip-id='my-tooltip' size='small' onClick={() => setIsOpen(true)}>
        + Add Metric
      </Button>
      <Tooltip id='my-tooltip' isOpen={isOpen} place='bottom' clickable={true}>
        {popOver}
      </Tooltip>
    </View>
  )

  const total = similarProductsList.length
  const remaining = similarProductsList.filter((m) => !m.isLoading).length
  const percentage = Math.round((remaining / total) * 100)

  const buttonRow = (
    <>
      <Button
        isLoading={isSavingNewList}
        loadingText='Saving...'
        size='small'
        marginRight='1rem'
        onClick={async () => {
          setIsSavingNewList(true)
          // get all companies across all taxonomies
          const companies = listsPerTaxonomy
            ?.map((t) => t.list)
            .flat()
            .filter((n) => n)
            .filter((n) => n?.companyName)
            .sort((a, b) => b.score - a.score)
            .map((n) => n.companyName)
          // filter down to unqiue companies and get top 30
          const groups = companies
            .filter((v, i) => companies.indexOf(v) === i)
            .slice(0, 30)
            .map((n) => ({ name: n, groupName: `${companyName} - Competitive Landscape` }))
          // save companies to user-level list
          await setUser({ groups })
            .catch((e) => console.error('setUser error', e))
            .finally(() => {
              setIsSavingNewList(false)
            })
        }}
      >
        <BsBookmark marginRight='0.5rem' /> Save to List
      </Button>
      <Button
        variation='primary'
        size='small'
        gap='0.5rem'
        alignSelf='flex-end'
        className='excel'
        onClick={() => {
          const data = listsPerTaxonomy
            ?.map((t) => t.list)
            .flat()
            .filter((n) => n)
            .filter((n) => n?.companyName)
            .map((n) => ({
              'Company name': n?.companyLabel || n?.companyName,
              'Company Profile Link': `https://app.playbookmna.com/profiles/${n?.companyName}`,
              Tags: n?.taxonomies?.join(', '),
              'Product Name': n?.productName,
              'Product Description': n?.description,
              'Similarity Score': p(n?.score),
            }))
          exportExcel({
            sheetName: 'Competitive Landscape',
            filename: `Competitive Landscape.xlsx`,
            data,
            format: (ws) => {
              for (let row = 0; row <= data.length; row++) {
                const cellPos = utils.encode_cell({
                  c: 1,
                  r: row,
                })
                ws[cellPos].l = {
                  Target: ws[cellPos].v,
                }
              }
              return ws
            },
          })
        }}
      >
        <ExcelIcon />
        Export to Excel
      </Button>
    </>
  )

  const headerButtons = percentage === 100 ? buttonRow : <Loader size='small' />
  const loaderBar =
    percentage < 100 ? (
      <Flex alignItems='center' gap='2rem' paddingBottom='1rem'>
        <Text variation='info'>Loading</Text>
        <Loader width='90%' variation='linear' isDeterminate percentage={percentage} size='small' filledColor='#1C70EE' />
      </Flex>
    ) : null

  return (
    <>
      <GlassCard header='Competitive Landscape' headerButtons={headerButtons}>
        {loaderBar}
        {listsPerTaxonomy.map((m) => {
          return (
            <GlassCard header={m.taxonomy} key={m.taxonomy} expandable className='overlayCard'>
              <ScrollView maxHeight='30rem'>
                <Flex direction='column' gap='2rem'>
                  <InputTable data={m.list} columns={finalCols as any} paginate enableGrouping={true} />
                </Flex>
              </ScrollView>
            </GlassCard>
          )
        })}
      </GlassCard>
      {opportunity?.competitiveLandscapeDashboards && (
        <GlassCard header='Dashboards' style={{ marginTop: '1rem' }}>
          <DashboardList dashboardUrls={opportunity.competitiveLandscapeDashboards.map((dashboard) => dashboard.url)} />
        </GlassCard>
      )}
    </>
  )
}

export default CompetitiveLandscape
