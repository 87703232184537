import { FunctionComponent } from 'react'
import ReactMarkdown from 'react-markdown'
import GlassCard from '../figma-components/GlassCard'

type InvestmentDetailsProps = {
  details: string
}

const InvestmentDetails: FunctionComponent<InvestmentDetailsProps> = ({ details }) => {
  return (
    <GlassCard header='Investment Details' className='lastCard'>
      <ReactMarkdown>{details}</ReactMarkdown>
    </GlassCard>
  )
}

export default InvestmentDetails
