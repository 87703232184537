import { createContext, FunctionComponent, PropsWithChildren, useContext } from 'react'
import { useUserState } from '../hooks/useUserState'
import { User } from '../models'

export function getGroupNames(user: User | undefined): string[] {
  const groups = user?.groups ?? []
  if (!groups) return []
  return groups.reduce((p, c) => {
    if (!p.includes(c.groupName)) {
      p.push(c.groupName)
    }
    return p
  }, [] as string[])
}

export const UserContext = createContext<User | undefined>(undefined)
export const UserSetContext = createContext<Function>(() => {})

export const UserProvider: FunctionComponent<PropsWithChildren> = ({ children, ...props }) => {
  const [user, updateUser] = useUserState()
  return (
    <UserContext.Provider {...props} value={user}>
      <UserSetContext.Provider {...props} value={updateUser}>
        {children}
      </UserSetContext.Provider>
    </UserContext.Provider>
  )
}

export const useUser = (): User | undefined => useContext(UserContext)
export const useSetUser = (): Function => useContext(UserSetContext)
