/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function DeleteIcon(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="20px"
      height="25px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 20, height: 25 }}
      paths={[
        {
          d: "M3.59501e-05 16.6667C-0.00246314 16.9952 0.125422 17.3112 0.355643 17.5456C0.585864 17.7799 0.899625 17.9134 1.22811 17.9167L15.4386 17.9167C15.7671 17.9134 16.0809 17.7799 16.3111 17.5456C16.5413 17.3112 16.6692 16.9952 16.6667 16.6667L16.6667 0L3.59501e-05 0L3.59501e-05 16.6667ZM1.6667 1.66667L15 1.66667L15 16.25L1.6667 16.25L1.6667 1.66667Z",
          fill: "rgba(48,53,69,1)",
          fillRule: "nonzero",
          style: { transform: "translate(8.33%, 28.33%)" },
        },
        {
          d: "M1.66667 0L0 0L0 10.4167L1.66667 10.4167L1.66667 0Z",
          fill: "rgba(48,53,69,1)",
          fillRule: "nonzero",
          style: { transform: "translate(27.08%, 41.67%)" },
        },
        {
          d: "M1.66667 0L0 0L0 10.4167L1.66667 10.4167L1.66667 0Z",
          fill: "rgba(48,53,69,1)",
          fillRule: "nonzero",
          style: { transform: "translate(45.83%, 41.67%)" },
        },
        {
          d: "M1.66667 0L0 0L0 10.4167L1.66667 10.4167L1.66667 0Z",
          fill: "rgba(48,53,69,1)",
          fillRule: "nonzero",
          style: { transform: "translate(64.58%, 41.67%)" },
        },
        {
          d: "M13.75 3.75L13.75 1.25C13.75 0.549062 13.2558 0 12.625 0L7.375 0C6.74417 0 6.25 0.549062 6.25 1.25L6.25 3.75L0 3.75L0 5.41667L20 5.41667L20 3.75L13.75 3.75ZM7.91667 1.66667L12.0833 1.66667L12.0833 3.75L7.91667 3.75L7.91667 1.66667Z",
          fill: "rgba(48,53,69,1)",
          fillRule: "nonzero",
          style: { transform: "translate(0%, 0%)" },
        },
      ]}
      {...getOverrideProps(overrides, "DeleteIcon")}
      {...rest}
    ></Icon>
  );
}
