import { Button, ComboBoxOption, Flex, SelectField, Text, View } from '@aws-amplify/ui-react'
import { DetailedHTMLProps, FunctionComponent, HTMLAttributes, useEffect, useRef, useState } from 'react'
import { restAPIRequest } from '../services/restApiRequest'
import GlassCard from '../figma-components/GlassCard'

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'tableau-viz': DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> & {
        src?: string
        width?: string
        height?: string
        toolbar?: string
        token?: string
        field?: string
        value?: string
        onFirstInteractive?: Function
      }
      'viz-filter': DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> & {
        src?: string
        width?: string
        height?: string
        toolbar?: string
        token?: string
        field?: string
        value?: string
        onFirstInteractive?: Function
      }
    }
  }
}

type DashboardProps = {
  url: string | undefined
  filters?: Filters[]
  filterValue?: string
  filterField?: string
  showRefresh?: boolean
  refreshKey?: string
  refreshText?: string
  [x: string]: any
}

type AuthRes = {
  jwt: string
}

type TableauViz = HTMLElement & {
  refreshDataAsync: Function
  workbook: {
    activeSheet: {
      applyFilterAsync: (a: string, b: string[], c: string) => Promise<void>
    }
  }
}

type Filters = {
  label: JSX.Element
  filterField: string
  options: ComboBoxOption[]
}

const Dashboard: FunctionComponent<DashboardProps> = ({ filters, refreshText, refreshKey, url, filterValue, filterField, showRefresh, ...props }) => {
  const [jwt, setJwt] = useState<string>()
  const [expired, setExpired] = useState<boolean>(true)
  const [refreshing, setRefreshing] = useState<boolean>(false)
  const ref = useRef<TableauViz>(null)

  const refresh = (force?: boolean) => {
    const shouldRefresh = refreshKey && localStorage.getItem(refreshKey) === 'refresh'
    if (!shouldRefresh && !force) return
    setRefreshing(true)
    ref?.current?.refreshDataAsync()?.finally(() => {
      localStorage.setItem(refreshKey || '', '')
      setRefreshing(false)
      ref?.current?.removeEventListener('firstinteractive', firstRefresh)
    })
  }

  const firstRefresh = () => {
    refresh()
  }
  useEffect(() => {
    async function getAuth(): Promise<void> {
      if (!expired) return

      const auth = await restAPIRequest<AuthRes>({
        method: 'get',
        path: 'tableau-auth',
      })

      if (!auth || !auth.jwt) {
        throw new Error(`did not get auth token from backend`)
      }
      setJwt(auth.jwt)
      setExpired(false)
    }

    let timeout: NodeJS.Timeout
    getAuth()
      .catch((error) => {
        console.error('failed to get jwt', error)
      })
      .then(() => {
        timeout = setTimeout(() => {
          setExpired(true)
        }, 300000)
      })

    ref?.current?.addEventListener('firstinteractive', firstRefresh)

    return () => {
      clearTimeout(timeout)
    }
  }, [url, expired])

  if (!url) return <Text>Loading... waiting for url</Text>
  if (!jwt) return <Text>Loading... getting auth</Text>
  return (
    <GlassCard header='' style={{ padding: '1rem' }}>
      <Flex>
        <Button
          isLoading={refreshing}
          display={showRefresh ? '' : 'none'}
          size='small'
          variation='link'
          onClick={() => {
            refresh(true)
          }}
        >
          {refreshText}
        </Button>
        {filters?.map((f) => {
          return (
            <SelectField
              key={f.filterField}
              defaultValue='All'
              size='small'
              label={f.label}
              options={f.options.map((o) => o.label)}
              onChange={(e) => {
                const o = f.options.find((o) => o.label === e.target.value)
                if (!o) return console.error('could not find option for dashboard filter', e.target.value, 'from', f.options.map((o) => o.label).join(','))
                if (o.label === 'All') return ref?.current?.workbook?.activeSheet?.applyFilterAsync(f.filterField, [''], 'all')
                ref?.current?.workbook?.activeSheet?.applyFilterAsync(f.filterField, o.id.split(','), 'replace')
              }}
            />
          )
        })}
      </Flex>
      <View {...props} key={filterValue}>
        <tableau-viz ref={ref} token={jwt} id='tableau-viz' src={url}>
          {filterField ? <viz-filter field={filterField} value={filterValue}></viz-filter> : null}
        </tableau-viz>
      </View>
    </GlassCard>
  )
}

export default Dashboard
