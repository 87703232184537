import {
  Alert,
  Button,
  CheckboxField,
  DropZone,
  Flex,
  Grid,
  Heading,
  Link,
  Loader,
  ScrollView,
  SelectField,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
  Text,
  TextField,
  View,
} from '@aws-amplify/ui-react'
import { DataStore } from 'aws-amplify/datastore'
import { ChangeEvent, Fragment, FunctionComponent, useEffect, useReducer, useRef, useState } from 'react'
import { NumericFormat } from 'react-number-format'
import { useNavigate, useParams } from 'react-router-dom'
import ReactSelect from 'react-select'
import CapTable, { updateProformaNames } from './CapTable'
import CapTableUpload, { formatDividend, formatParticipationType, formatShareholderType } from './components/CapTableUpload'
import CompanyHeader from './components/CompanyHeader'
import CurrencyInput from './components/CurrencyInput'
import DateInput from './components/DateInput'
import ExportButton from './components/ExportButton'
import InputTable from './components/InputTable'
import LoanDetailsInput, { LoanDetails } from './components/LoanDetailsInput'
import RoundDetails from './components/RoundDetailsInput'
import TransactionModelChart, { CHART_Y_TYPE_CURRENCY, CHART_Y_TYPE_MULTIPLE, CHART_Y_TYPE_PERCENT } from './components/TransactionModelChart'
import TransactionModelChart1PieChart from './components/TransactionModelChart1PieChart'
import TransactionModelMultiples from './components/TransactionModelMultiples'
import ValueAdjustment, { ValueAdjustmentType } from './components/ValueAdjustment'
import MissingDataCard from './components/missingDataCard'
import { useOpportunity } from './contexts/opportunityContext'
import GlassCard from './figma-components/GlassCard'
import GlassCardControlled from './figma-components/GlassCardControlled'
import NewInvestorView from './figma-components/NewInvestorsView'
import {
  ConvertibleNoteDetail,
  NewInvestorDetail,
  PrimaryInvestmentDetail,
  SecondaryInvestmentDetail,
  TransactionModelRowTypes,
  convertibleNoteColumns,
  dividendTypeOptions,
  getCapTableCells,
  participationTypeOptions,
  primaryInvestmentColumns,
  secondaryInvestmentColumns,
  shareTypeOptions,
} from './inputTableInfo'
import { Opportunity } from './models'
import FinancialPage from './pages/FinancialPage'
import { restAPIRequest } from './services/restApiRequest'
import { TRANSACTION_MODEL } from './sitemap'
import './styles/model.scss'
import './styles/table.css'
import {
  CONVERTIBLE_NOTE_KEY,
  CapTableRes,
  CapTableType,
  ConvertibleNoteInput,
  ExistingShareHolder,
  LOAN_KEY,
  MONITORING_KEY,
  NEW_INVESTOR_KEY,
  PRIMARY_INVESTMENT_KEY,
  PrimaryInvestmentInput,
  ProformaData,
  SECONDARY_INVESTMENT_KEY,
} from './types'
import { CkCheck, UploadIcon } from './ui-components'
import { DELIM, currencyFormatterLong, currencyFormatterShort, multipleFormatter, pFmt, parseCurrency, percentFormatter, removeDuplicateValues, uniqValue } from './utils/utils'
import WaterfallCharts, { getGrossProceedsChartValues, getGrossProceedsPerShareChartValues, getIrrChartValues, getMoicChartValues } from './components/WaterfallCharts'

type InputTableProps = {}

type ReducerState = {
  trxName: string
  transactionType: string
  preMoneyEquityValuation: number
  newOptionPoolSize: number
  methodForCreatingOptionPool: string
  exitDate: Date
  netDebtAtExit: number
  exitEVLowRange: number
  exitEVHighRange: number
  totalAggregateFees: number
  roundUp: boolean
  roundPlaces: number
  secondaryInvestmentData: SecondaryInvestmentDetail[]
  primaryInvestmentData: PrimaryInvestmentDetail[]
  convertibleNoteData: ConvertibleNoteDetail[]
  newInvestorsData: NewInvestorDetail[]
  valueAdjustments: ValueAdjustmentType[]
  totalPrimaryCapitalInvested: number
  totalSecondaryCapitalInvested: number
  seniorityMap: Record<string, number>
  userEv: number
  userTargetShareholder: string
  loan?: LoanDetails

  // not in trxModel
  capTableV1: ExistingShareHolder[]
  totalEquityRaised: number
  lastPreMoneyValuation: number
  latestDealDate: string
}

export type ModelType = Partial<Omit<ReducerState, 'capTableV1' | 'totalEquityRaised' | 'lastPreMoneyValuation' | 'latestDealDate'>>

type GroupCalcs = {
  group: string
  momReturns: number
  irr: number | undefined
  amountInvested: number
  proceeds: number
  proceedsPerShare: number
  shareCount: number
}

type ShareholderCalcs = Omit<GroupCalcs, 'group'> & {
  shareholderName: string
}

type CalcType = GroupCalcs & {
  shareholderName: string
  investmentDate: Date
  exitDate: Date
  loanAmount: number
  didConvert: boolean
  preferredDistribution: number
  commonDistribution: number
  participatingDistribution: number
}

export type ExitWaterfallCalcs = {
  salePrice: number
  calcs: [CalcType]
  groupCalcs: [GroupCalcs]
  shareholderCalcs: [ShareholderCalcs]
}

export type ExitWaterfallRes = {
  breakpoints: Record<string, number>
  calcs: ExitWaterfallCalcs[]
}

// SETUP: Step type
type Step = {
  name: string
  displayName: string
  isStepHeader: boolean
  exitCriteria: any[]
}

const defaultLoan: LoanDetails = {
  shareholderName: '',
  group: '',
  loanAmount: 1000000,
  termLength: 36,
  interestRate: 0.1,
  repaymentType: 'INTEREST_ONLY_BULLET_PRINCIPAL',
  interestOnlyLength: 36,
  interestPaymentFrequency: 'PAYMENT_FREQUENCY_MONTHLY',
  warrantPercent: 0.05,
}

const TODAY = new Date()
const ADD_NEW_INVESTOR = 'ADD_NEW_INVESTOR'
const ADD_NEW_PRIMARY_INVESTMENT_DETAIL = 'ADD_NEW_PRIMARY_INVESTMENT_DETAIL'
const CHANGE_SCENARIO = 'CHANGE_SCENARIO'
const ADD_NEW_CONVERTIBLE_NOTE_DETAIL = 'ADD_NEW_CONVERTIBLE_NOTE_DETAIL'

const PAGES = [
  { name: 'summary', displayName: 'Summary' },
  { name: 'capTable', displayName: 'Cap Table' },
  { name: 'waterfall', displayName: 'Waterfall & Returns' },
]

const TransactionModelInput: FunctionComponent<InputTableProps> = () => {
  const opportunity = useOpportunity()
  const [calcs, setCalcs] = useState<ExitWaterfallCalcs[]>([])
  const [finalCapTable, setFinalCapTable] = useState<CapTableType>([])
  const finalCapTableStateKey = JSON.stringify(
    finalCapTable.map((i) => {
      const out = { ...i } as any
      delete out.exitDate
      delete out.investmentDate
      return out
    })
  )

  const [pricePerShare, setPricePerShare] = useState<number>(0)
  const [proformaTableData, setProformaTableData] = useState<ProformaData>()
  const [userShareholderCalcs, setUserShareholderCalcs] = useState<ShareholderCalcs[]>([])
  const [breakpoints, setBreakpoints] = useState<Record<string, number>>({})
  const [currentPage, setCurrentPage] = useState<string>('summary')
  const [stepCounter, setStepCounter] = useState<number>(0) // SETUP: Counter for step flow
  const [stepDirection, setStepDirection] = useState<'none' | 'back' | 'next'>('none') // SETUP: Direction during step flow
  const [saving, setSaving] = useState(false)
  const [waterfallAlert, setWaterfallAlert] = useState<string>()
  const [trxAlert, setTrxAlert] = useState<string>()
  const [trxWarn, setTrxWarn] = useState<string>()
  const params = useParams()
  const navigate = useNavigate()
  const entryAssumptionsRef = useRef<HTMLDivElement>(null)
  const newInvestorsAssumptionsRef = useRef<HTMLDivElement>(null)
  const primaryInvestmentAssumptionsRef = useRef<HTMLDivElement>(null)
  const convertibleNoteRef = useRef<HTMLDivElement>(null)
  const secondarySaleAssumptionsRef = useRef<HTMLDivElement>(null)
  const exitAssumptionsRef = useRef<HTMLDivElement>(null)
  const seniorityAssumptionsRef = useRef<HTMLDivElement>(null)
  const [isCopying, setIsCopying] = useState<boolean>(false)

  const index = parseInt(params.scenarioId || '0', 10)
  const localStorageKey = `${TRANSACTION_MODEL}${DELIM}${opportunity?.id}${DELIM}${index}`

  const modelList = opportunity?.transactionModelsV1?.map((o, i) => {
    try {
      return JSON.parse(o || '{}')
    } catch (error) {
      return {}
    }
  }) || [{}]
  let model: ModelType = modelList[index] || {}
  model.exitDate = !model.exitDate ? undefined : new Date(model.exitDate)
  const localStorageRaw = JSON.parse(window.localStorage.getItem(localStorageKey) || '{}')
  const localStorage: ModelType = {}
  const lUpdated = new Date(localStorageRaw.updatedAt || 0).getTime()
  const oUpdated = new Date(opportunity?.updatedAt || 0).getTime()

  if (oUpdated < lUpdated) {
    Object.assign(localStorage, localStorageRaw)
    localStorage.exitDate = !localStorage.exitDate ? undefined : new Date(localStorage.exitDate)
  } else {
    window.localStorage.removeItem(localStorageKey)
  }

  const defaultPrimaryDetail = ({ shareholderName, primaryInvestmentData, totalPrimaryCapitalInvested }): PrimaryInvestmentDetail => ({
    type: 'SHARE_HOLDER_TYPE_SERIES',
    shareholderName: shareholderName,
    totalContribution: Math.max(1, primaryCapitalDiff(totalPrimaryCapitalInvested, primaryInvestmentData)),
    liquidationMultiple: 1,
    participationType: 'PARTICIPATION_RIGHTS_NON_PARTICIPATING',
    capOnParticipation: 0,
    investmentDate: TODAY.toISOString().split('T')[0],
    dividendType: 'DIVIDEND_CUMULATIVE',
    interestRate: 0.08,
  })

  const defaultCommon = ({ shareholderName }): PrimaryInvestmentDetail => ({
    type: 'SHARE_HOLDER_TYPE_COMMON',
    shareholderName: shareholderName,
    totalContribution: 0,
    liquidationMultiple: 1,
    participationType: 'PARTICIPATION_RIGHTS_NON_PARTICIPATING',
    capOnParticipation: 0,
    investmentDate: TODAY.toISOString().split('T')[0],
    dividendType: 'DIVIDEND_CUMULATIVE',
    interestRate: 0.08,
  })

  const defaultConvertibleDetail = ({ shareholderName, valuation }): ConvertibleNoteDetail => ({
    shareholderName: shareholderName,
    amountInvested: 10000,
    valuation,
    convertDate: TODAY.toISOString().split('T')[0],
    investmentDate: TODAY.toISOString().split('T')[0],
    // 2 - 5 years
    maturityDate: TODAY.toISOString().split('T')[0],
    // convert date
    // convertDate: TODAY.toISOString().split('T')[0],
    dividendType: 'DIVIDEND_CUMULATIVE',
    interestRate: 0.08,
    convertTo: {
      ...defaultCommon({ shareholderName }),
    },
  })

  const tasksReducer = (state: ReducerState, action: Partial<ReducerState> & { type?: string }): ReducerState => {
    const update = { ...state }
    if (action?.type === CHANGE_SCENARIO) {
      return {
        ...getModelState({ localStorage, model: modelList[index] || {}, opportunity, modelList }),
        capTableV1: update?.capTableV1,
        totalEquityRaised: update?.totalEquityRaised,
        lastPreMoneyValuation: update?.lastPreMoneyValuation,
        latestDealDate: update?.latestDealDate,
      }
    }
    if (action?.type === ADD_NEW_INVESTOR) {
      const newName = uniqValue(
        'New Investor',
        state.newInvestorsData.map((o) => o.shareholderName)
      )
      update.newInvestorsData = [
        ...state.newInvestorsData,
        {
          shareholderName: newName,
          group: state.trxName,
        },
      ]
      if (state.transactionType.includes(PRIMARY_INVESTMENT_KEY) && !state.transactionType.includes(SECONDARY_INVESTMENT_KEY)) {
        update.primaryInvestmentData = [
          ...state.primaryInvestmentData,
          defaultPrimaryDetail({
            shareholderName: newName,
            primaryInvestmentData: state.primaryInvestmentData,
            totalPrimaryCapitalInvested: state.totalPrimaryCapitalInvested,
          }),
        ]
      }

      return update
    }

    if (action?.type === ADD_NEW_PRIMARY_INVESTMENT_DETAIL) {
      update.primaryInvestmentData = [
        ...state.primaryInvestmentData,
        defaultPrimaryDetail({
          shareholderName: state.newInvestorsData[0].shareholderName,
          primaryInvestmentData: state.primaryInvestmentData,
          totalPrimaryCapitalInvested: state.totalPrimaryCapitalInvested,
        }),
      ]
      return update
    }

    if (action?.type === ADD_NEW_CONVERTIBLE_NOTE_DETAIL) {
      update.convertibleNoteData = [
        ...state.convertibleNoteData,
        defaultConvertibleDetail({
          shareholderName: state.newInvestorsData[0].shareholderName,
          valuation: state.preMoneyEquityValuation || 1000000,
        }),
      ]
      return update
    }

    Object.keys(action).forEach((key) => {
      if (!(key in state)) {
        throw new Error(`Unknown key ${key} in tasksReducer: ${JSON.stringify(action)}`)
      }
      const newValue = action[key]
      // TODO: update other tables when capTableV1 is updated
      update[key] = newValue
      if (key === 'exitDate') {
        update.capTableV1 = state.capTableV1.map((c) => {
          return {
            ...c,
            exitDate: newValue,
          }
        })
      }

      if (key === 'transactionType') {
        if (!newValue.includes(SECONDARY_INVESTMENT_KEY)) {
          update.secondaryInvestmentData = []
          update.totalSecondaryCapitalInvested = 0
        }
        if (!newValue.includes(PRIMARY_INVESTMENT_KEY)) {
          update.primaryInvestmentData = []
          update.totalPrimaryCapitalInvested = 0
        }
        if (newValue.includes(PRIMARY_INVESTMENT_KEY)) {
          let newName = state.newInvestorsData?.[0]?.shareholderName || action.newInvestorsData?.[0]?.shareholderName
          if (!newName) {
            newName = uniqValue(
              'New Investor',
              state.newInvestorsData.map((o) => o.shareholderName)
            )

            update.newInvestorsData = [
              {
                shareholderName: newName,
                group: state.trxName,
              },
            ]
          }

          if (!state.primaryInvestmentData.length && !action.primaryInvestmentData?.length) {
            update.totalPrimaryCapitalInvested = 10000000
            update.primaryInvestmentData = [
              defaultPrimaryDetail({
                shareholderName: newName,
                primaryInvestmentData: [],
                totalPrimaryCapitalInvested: update.totalPrimaryCapitalInvested,
              }),
            ]
          }
        }
        if (!newValue.includes(LOAN_KEY)) {
          update.loan = undefined
        }
        if (newValue.includes(LOAN_KEY) && !state.loan) {
          update.loan = defaultLoan
        }
      }

      if (key === 'capTableV1' && newValue.length !== 0) {
        if (newValue.length < state.capTableV1.length) {
          // remove secondary sales from that were removed from cap table
          update.secondaryInvestmentData = state.secondaryInvestmentData.filter((o) => {
            return newValue.find((n) => n.shareholderName === o.existingShareholderName && n.group === o.existingShareholderGroup)
          })
        } else {
          // rename secondary sales if cap table name changed
          update.secondaryInvestmentData = state.secondaryInvestmentData.map((o) => {
            const oldIndex = state.capTableV1.findIndex((n) => n.shareholderName === o.existingShareholderName && n.group === o.existingShareholderGroup)
            if (oldIndex < 0) {
              return o
            }

            if (state.capTableV1[oldIndex].shareholderName !== newValue[oldIndex].shareholderName || state.capTableV1[oldIndex].group !== newValue[oldIndex].group) {
              return {
                ...o,
                existingShareholderName: newValue[oldIndex].shareholderName,
                existingShareholderGroup: newValue[oldIndex].group,
              }
            }

            return o
          })
        }

        const capTable = (newValue as ExistingShareHolder[]).map((c) => {
          return {
            ...c,
            exitDate: state.exitDate || TODAY,
          }
        })
        update.capTableV1 = capTable
        const totalEquityRaised = capTable.reduce(sumSeries, 0)
        if (typeof totalEquityRaised === 'number') {
          update.totalEquityRaised = totalEquityRaised
        }

        const youngestInvestor = capTable.reduce((o, c) => {
          if (!o.investmentDate || o.type !== 'SHARE_HOLDER_TYPE_SERIES') return c
          if (!c.investmentDate || c.type !== 'SHARE_HOLDER_TYPE_SERIES') return o
          return o.investmentDate > c.investmentDate ? o : c
        })

        if (youngestInvestor.investmentDate) {
          update.latestDealDate = new Date(youngestInvestor.investmentDate).toISOString().split('T')[0]
          if (youngestInvestor.type === 'SHARE_HOLDER_TYPE_SERIES') {
            const existingShares = capTable.reduce((o, c) => o + c.shareCount, 0)
            const groupContribution = capTable.filter((o) => o.group === youngestInvestor.group).reduce(sumSeries, 0)
            const valuation = youngestInvestor.pricePerShare * existingShares
            update.lastPreMoneyValuation = valuation - groupContribution
          }
        }
      }
      if (key === 'pricePerShare') {
        update.secondaryInvestmentData = state.secondaryInvestmentData.map((o) => {
          const existingShareholder = state.capTableV1.find((e) => e.shareholderName === o.existingShareholderName && e.group === o.existingShareholderGroup)
          if (!existingShareholder) return o
          o.pricePerShare = newValue
          o.shareValue = newValue * existingShareholder.shareCount
          return o
        })
      }
    })

    return update
  }

  const defaultValues = getModelState({ localStorage, model, opportunity, modelList })
  const [liveState, dispatch] = useReducer(tasksReducer, defaultValues)
  useEffect(() => {
    if (modelList[index]) {
      dispatch({ type: CHANGE_SCENARIO })
      submit()
      return
    }

    if (index === 0) {
      addModel(opportunity, {}, modelList)
    }
  }, [index, modelList.length])

  const {
    capTableV1,
    trxName,
    transactionType,
    preMoneyEquityValuation,
    newOptionPoolSize,
    methodForCreatingOptionPool,
    exitDate,
    netDebtAtExit,
    exitEVLowRange,
    exitEVHighRange,
    totalAggregateFees,
    roundUp,
    roundPlaces,
    valueAdjustments,
    totalPrimaryCapitalInvested,
    totalSecondaryCapitalInvested,
    seniorityMap,
    userEv,
    secondaryInvestmentData,
    primaryInvestmentData,
    convertibleNoteData,
    newInvestorsData,
    userTargetShareholder,
    loan,
  } = liveState

  // handle visible state for each assumptions controls
  const [isEntryAssumptionsExpanded, setIsEntryAssumptionsExpanded] = useState<boolean>(false)
  const [isNewInvestorsAssumptionsExpanded, setIsNewInvestorsAssumptionsExpanded] = useState<boolean>(false)
  const [isPrimaryInvestmentAssumptionsExpanded, setIsPrimaryInvestmentAssumptionsExpanded] = useState<boolean>(false)
  const [isConvertibleNoteExpanded, setIsConvertibleNoteExpanded] = useState<boolean>(false)
  const [isSecondarySaleAssumptionsExpanded, setIsSecondarySaleAssumptionsExpanded] = useState<boolean>(false)
  const [isExitAssumptionsExpanded, setIsExitAssumptionsExpanded] = useState<boolean>(false)
  const [isSeniorityAssumptionsExpanded, setIsSeniorityAssumptionsExpanded] = useState<boolean>(false)
  const handleActiveInputSectionChange = (inputSection: string) => {
    setIsEntryAssumptionsExpanded(inputSection === 'Entry Assumptions')
    setIsNewInvestorsAssumptionsExpanded(inputSection === 'New Investors')
    setIsPrimaryInvestmentAssumptionsExpanded(inputSection === 'Primary Investment')
    setIsConvertibleNoteExpanded(inputSection === 'Convertible Notes')
    setIsSecondarySaleAssumptionsExpanded(inputSection === 'Secondary Sale')
    setIsExitAssumptionsExpanded(inputSection === 'Exit Assumptions')
    setIsSeniorityAssumptionsExpanded(inputSection === 'Seniority')
  }

  // handle clickout for each assumptions controls
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (entryAssumptionsRef.current && !entryAssumptionsRef.current?.contains(event.target as Node)) setIsEntryAssumptionsExpanded(false)
      if (newInvestorsAssumptionsRef.current && !newInvestorsAssumptionsRef.current?.contains(event.target as Node)) setIsNewInvestorsAssumptionsExpanded(false)
      if (primaryInvestmentAssumptionsRef.current && !primaryInvestmentAssumptionsRef.current?.contains(event.target as Node)) setIsPrimaryInvestmentAssumptionsExpanded(false)
      if (convertibleNoteRef.current && !convertibleNoteRef.current?.contains(event.target as Node)) setIsConvertibleNoteExpanded(false)
      if (secondarySaleAssumptionsRef.current && !secondarySaleAssumptionsRef.current?.contains(event.target as Node)) setIsSecondarySaleAssumptionsExpanded(false)
      if (exitAssumptionsRef.current && !exitAssumptionsRef.current?.contains(event.target as Node)) setIsExitAssumptionsExpanded(false)
      if (seniorityAssumptionsRef.current && !seniorityAssumptionsRef.current?.contains(event.target as Node)) setIsSeniorityAssumptionsExpanded(false)
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [entryAssumptionsRef, newInvestorsAssumptionsRef, primaryInvestmentAssumptionsRef, secondarySaleAssumptionsRef, exitAssumptionsRef, seniorityAssumptionsRef])

  const grossProceedsChartValues = getGrossProceedsChartValues(calcs)
  const grossProceedsPerShareChartValues = getGrossProceedsPerShareChartValues(calcs)
  const moicChartValues = getMoicChartValues(calcs)
  const irrChartValues = getIrrChartValues(calcs)

  const inputSpacingBuffer = // buffer used to space absolute positioned transaction inputs sections
    transactionType.includes(PRIMARY_INVESTMENT_KEY) && transactionType.includes(SECONDARY_INVESTMENT_KEY)
      ? 3
      : transactionType.includes(PRIMARY_INVESTMENT_KEY)
      ? 2
      : transactionType.includes(SECONDARY_INVESTMENT_KEY)
      ? 1
      : 0

  // SETUP: Steps and logic for showing step flow or not
  const [isSetupFlow, setIsSetupFlow] = useState<boolean>(capTableV1.length === 0)
  const STEPS: Step[] = [
    {
      name: 'capTable',
      displayName: 'Cap Table',
      isStepHeader: true,
      exitCriteria: [capTableV1],
    },
    {
      name: 'transactionType',
      displayName: 'Transaction Details',
      isStepHeader: true,
      exitCriteria: [transactionType, trxName],
    },
    {
      name: 'financials',
      displayName: 'Financials',
      isStepHeader: true,
      exitCriteria: [opportunity?.financialDataV2],
    },
    {
      name: 'entryAssumptions',
      displayName: 'Entry Assumptions',
      isStepHeader: true,
      exitCriteria: [preMoneyEquityValuation],
    },
    {
      name: 'investmentDetail',
      displayName: 'Investment Details',
      isStepHeader: true,
      exitCriteria: [],
    },
    {
      name: 'investmentDetail',
      displayName: 'New Investors',
      isStepHeader: false,
      exitCriteria: [newInvestorsData],
    },
    {
      name: 'investmentDetail',
      displayName: 'Primary Investment Details',
      isStepHeader: false,
      exitCriteria: [primaryInvestmentData],
    },
    {
      name: 'investmentDetail',
      displayName: 'Secondary Investment Sale',
      isStepHeader: false,
      exitCriteria: [secondaryInvestmentData],
    },
    {
      name: 'investmentDetail',
      displayName: 'Loan Details',
      isStepHeader: false,
      exitCriteria: [loan?.loanAmount, loan?.termLength, loan?.interestRate, loan?.interestOnlyLength, loan?.warrantPercent],
    },
    {
      name: 'exitAssumptions',
      displayName: 'Exit Assumptions',
      isStepHeader: true,
      exitCriteria: [exitDate, userEv],
    },
    {
      name: 'seniority',
      displayName: 'Seniority',
      isStepHeader: true,
      exitCriteria: [capTableV1],
    },
  ]

  const existingShares = capTableV1.reduce((o, c) => o + c.shareCount, 0)
  const totalPrimaryShares = primaryInvestmentData.reduce((o, c) => o + c.totalContribution / pricePerShare, 0)
  const totalShares = existingShares + totalPrimaryShares
  const existingOptionShares = capTableV1.filter((o) => o.type === 'SHARE_HOLDER_TYPE_OPTION').reduce((o, c) => o + c.shareCount, 0)
  const minOptionPoolSize = existingOptionShares / totalShares
  const shouldDisplayAllInputs = () => {
    return capTableV1.length !== 0 && transactionType !== '' && transactionType !== MONITORING_KEY
  }

  // Add anything you add here to above save method!
  const saveModel = async (overrides: Partial<Opportunity> = {}) => {
    if (!opportunity) return alert('select opportunity')
    if (!liveState.latestDealDate) return console.error('saveModel: latestDealDate is missing')
    if (!liveState.capTableV1) return console.error('saveModel: capTableV1 is missing')
    // if (isNaN(liveState.totalEquityRaised)) return console.error('saveModel: totalEquityRaised is missing')
    if (isNaN(liveState.lastPreMoneyValuation)) return console.error('saveModel: lastPreMoneyValuation is missing')
    if (!opportunity?.transactionModelsV1?.[index] && index !== 0) return console.error(`saveModel: model does not exit index=${index}`)
    try {
      await DataStore.save(
        Opportunity.copyOf(opportunity, (newOpp) => {
          Object.assign(newOpp, overrides)
          const trxUpdate: Partial<ReducerState> = { ...liveState }
          delete trxUpdate.capTableV1
          delete trxUpdate.totalEquityRaised
          delete trxUpdate.lastPreMoneyValuation
          delete trxUpdate.latestDealDate
          const transactionModelsV1 = [...(newOpp?.transactionModelsV1 || [])]
          transactionModelsV1[index] = JSON.stringify(trxUpdate)
          Object.assign(newOpp, {
            // TODO: make individual keys
            transactionModelsV1: removeDuplicateValues(transactionModelsV1),
            capTableV1: JSON.stringify(liveState.capTableV1),
            totalEquityRaised: liveState.totalEquityRaised,
            lastPreMoneyValuation: liveState.lastPreMoneyValuation,
            latestDealDate: liveState.latestDealDate,
          })
        })
      )
      window.localStorage.removeItem(localStorageKey)
    } catch (error: any) {
      console.error('saveModel: Error saving to opportunity', error, model)
    }
  }

  const updateData = (type: string, inputData: TransactionModelRowTypes[], setKey: string) => {
    return (rowIndex: number, columnId: string, value: unknown): void => {
      const out = inputData.map((row, i) => {
        if (i !== rowIndex) {
          return row
        }
        const o = {
          ...row,
          [columnId]: value,
        }
        if (type === SECONDARY_INVESTMENT_KEY) {
          const detail = row as SecondaryInvestmentDetail
          const update = o as SecondaryInvestmentDetail

          if (columnId === 'existingShareholderKey') {
            const parts = (value as string).split(DELIM)
            const shareholderName = parts[0]
            const group = parts[1]
            const eShareCount = capTableV1.find((s) => s.shareholderName === shareholderName && s.group === group)?.shareCount || 0
            if (!eShareCount) return setTrxAlert(`Did not find existing shareholder for ${shareholderName} | ${group}`)
            return {
              ...o,
              shareValue: pricePerShare * eShareCount,
              existingShareholderGroup: group,
              existingShareholderName: shareholderName,
            }
          } else if (columnId === 'totalProceeds') {
            const eShareCount = capTableV1.find((s) => s.shareholderName === detail.existingShareholderName && s.group === detail.existingShareholderGroup)?.shareCount || 0
            if (!eShareCount) return setTrxAlert(`Did not find existing shareholder for ${detail.existingShareholderName} | ${detail.existingShareholderGroup}`)

            const round = roundUp ? Math.ceil : Math.floor
            const v = parseCurrency(value as string)
            if (isNaN(v)) return row
            const numOfSharesSold = round(v / pricePerShare)
            return {
              ...o,
              numOfSharesSold,
              shareValue: pricePerShare * eShareCount,
            }
          } else if (columnId === 'buyerName') {
            const buyerDetails = newInvestorsData.find((s) => s.shareholderName === update.buyerName)
            if (!buyerDetails) return setTrxAlert(`Did not find existing shareholder for buyer ${update.buyerName}`)
            return {
              ...o,
              buyerName: buyerDetails.shareholderName,
              buyerGroup: buyerDetails.group,
            }
          }
        } else if (type === PRIMARY_INVESTMENT_KEY) {
          const detail = row as PrimaryInvestmentDetail

          if (columnId === 'participationType') {
            if (value === 'PARTICIPATION_RIGHTS_PARTICIPATING_WITH_CAP') {
              return {
                ...o,
                capOnParticipation: detail.capOnParticipation || 1,
              }
            }
            return {
              ...o,
              capOnParticipation: undefined,
            }
          } else if (columnId === 'dividendType') {
            if (value === 'DIVIDEND_CUMULATIVE' || value === 'DIVIDEND_NON_CUMULATIVE') {
              return {
                ...o,
                interestRate: detail.interestRate || 0,
              }
            }
            return {
              ...o,
              interestRate: undefined,
            }
          }
        } else if (type === CONVERTIBLE_NOTE_KEY) {
          const detail = row as ConvertibleNoteDetail
          if (columnId === 'dividendType') {
            if (value === 'DIVIDEND_CUMULATIVE' || value === 'DIVIDEND_NON_CUMULATIVE') {
              return {
                ...o,
                interestRate: detail.interestRate || 0,
              }
            }
            return {
              ...o,
              interestRate: undefined,
            }
          }
        } else if (type === NEW_INVESTOR_KEY) {
          const detail = row as NewInvestorDetail
          const update = o as NewInvestorDetail
          if (columnId === 'shareholderName') {
            dispatch({
              secondaryInvestmentData: secondaryInvestmentData.map((s) => {
                if (s.buyerName === detail.shareholderName && s.buyerGroup === detail.group) {
                  return {
                    ...s,
                    buyerName: update.shareholderName,
                  }
                }
                return s
              }),
              primaryInvestmentData: primaryInvestmentData.map((s) => {
                if (s.shareholderName === detail.shareholderName) {
                  return {
                    ...s,
                    shareholderName: update.shareholderName,
                  }
                }
                return s
              }),
            })
          } else if (columnId === 'group') {
            dispatch({
              secondaryInvestmentData: secondaryInvestmentData.map((s) => {
                if (s.buyerName === detail.shareholderName && s.buyerGroup === detail.group) {
                  return {
                    ...s,
                    buyerGroup: update.group,
                  }
                }
                return s
              }),
            })
            // TODO update primary with group
          }
          return update
        }

        return o
      })

      dispatch({
        [setKey]: out,
      })
    }
  }

  const getOptions = (type: string) => {
    switch (type) {
      case 'DividendTypes':
        return dividendTypeOptions
      case 'ParticipationTypes':
        return participationTypeOptions
      case 'ShareTypes':
        return shareTypeOptions
      case 'ExistingShareholderNames':
        return capTableV1.map((o, i) => {
          return {
            label: `${o.shareholderName} | ${o.group}`,
            value: `${o.shareholderName}${DELIM}${o.group}`,
          }
        })

      case 'NewInvestors':
        // TODO: support group for primary
        return newInvestorsData.map((o) => {
          return {
            label: `${o.shareholderName} | ${o.group}`,
            value: o.shareholderName,
          }
        })
    }
    return []
  }

  const valueAdjustment = valueAdjustments.reduce((p, c) => p + c.amount, 0) || 0
  const valuation = preMoneyEquityValuation + valueAdjustment

  async function submit() {
    try {
      if (newInvestorsData.length) {
        const dupe = findDuplicateInvestorNames(newInvestorsData)
        if (dupe.length) return setTrxAlert(`Duplicate investor names found: ${dupe.join(', ')}`)
      }
      if (!capTableV1.length) return setTrxAlert('Missing Existing Cap Table')
      const missingShareCount = capTableV1.find((c) => c.shareCount === 0)
      if (missingShareCount) return setTrxAlert(`Missing share count for ${missingShareCount.shareholderName} - ${missingShareCount.group} in Existing Cap Table`)

      const missingExitDate = capTableV1.find((c: any) => !c.exitDate)
      if (missingExitDate) return setTrxAlert(`Missing Exit Date for ${missingExitDate.shareholderName} - ${missingExitDate.group} in Existing Cap Table`)

      const missingStrikePrice = capTableV1.find((c: any) => ['SHARE_HOLDER_TYPE_OPTION', 'SHARE_HOLDER_TYPE_WARRANT'].includes(c.type) && !c.strikePrice)
      if (missingStrikePrice) return setTrxAlert(`Missing Strike Price for ${missingStrikePrice.shareholderName} - ${missingStrikePrice.group} in Existing Cap Table`)

      const missingConversionRatio = capTableV1.find((c: any) => c.type === 'SHARE_HOLDER_TYPE_SERIES' && !c.conversionRatio)
      if (missingConversionRatio)
        return setTrxAlert(`Conversion Ratio needs to be non-zero for ${missingConversionRatio.shareholderName} - ${missingConversionRatio.group} in Existing Cap Table`)

      const noSharesCanBeBought = primaryInvestmentData.find((c) => pricePerShare && c.totalContribution / pricePerShare < 1)
      if (noSharesCanBeBought && pricePerShare > 0)
        setTrxAlert(
          `Primary Investors "${noSharesCanBeBought.shareholderName}" investment of ${currencyFormatterLong(
            noSharesCanBeBought.totalContribution
          )} is too small to buy any shares. Current Price Per Share is ${currencyFormatterLong(pricePerShare)}.`
        )

      if (newOptionPoolSize !== 0 && minOptionPoolSize > newOptionPoolSize) return setTrxAlert(`Option pool is too small, minimum is ${pFmt(minOptionPoolSize)}`)
      const secondarySales: {
        existingShareholderName: string
        existingShareholderGroup: string
        numOfSharesSold: number
        buyerName: string
        buyerGroup: string
        pricePerShare: number
      }[] = []

      for (const o of secondaryInvestmentData) {
        const info = newInvestorsData.find((i) => i.shareholderName === o.buyerName && i.group === o.buyerGroup)
        if (!info) {
          return setTrxAlert(`Did not find new investor for secondary sales buyer for ${o.buyerName} | ${o.buyerGroup}`)
        }
        const { shareholderName, group } = info
        if (!pricePerShare) setTrxAlert(`Price per share is 0. Update inputs and try again`)
        if (o.shareValue < o.numOfSharesSold * pricePerShare) setTrxAlert(`Not enough shares to sell for ${o.buyerName} | ${o.buyerGroup}`)
        secondarySales.push({
          existingShareholderName: o.existingShareholderName,
          existingShareholderGroup: o.existingShareholderGroup,
          numOfSharesSold: o.numOfSharesSold,
          buyerName: shareholderName,
          buyerGroup: group,
          pricePerShare: o.pricePerShare || pricePerShare,
        })
      }

      const notInvested = primaryInvestmentData.filter((o) => exitDate < new Date(o.investmentDate)).map((o) => `${o.shareholderName}`)
      if (notInvested.length) {
        setTrxWarn(`Investment Date is after Exit Date for the following shareholders primay investments: ${notInvested.join(', ')}. They will not show up in current Cap Table.`)
      } else {
        setTrxWarn(undefined)
      }
      const primaryInvestments: PrimaryInvestmentInput[] = []
      for (const o of primaryInvestmentData) {
        const info = newInvestorsData.find((i) => i.shareholderName === o.shareholderName)
        if (!info) {
          return setTrxAlert(`Did not find new investor that matches primary: ${o.shareholderName}`)
        }
        const { shareholderName, group } = info
        const investmentDate = new Date(o.investmentDate)

        if (o.totalContribution <= 0) return setTrxAlert(`Total Contribution must be greater than 0 for ${shareholderName} - ${group}`)

        if (o.liquidationMultiple <= 0) return setTrxAlert(`Liquidation Multiple must be greater than 0 for ${shareholderName} - ${group}`)
        if (o.participationType === 'PARTICIPATION_RIGHTS_PARTICIPATING_WITH_CAP' && o.capOnParticipation <= 0)
          return setTrxAlert(`Cap on Participation must be greater than 0 for ${shareholderName} - ${group}`)
        if (o.dividendType === 'DIVIDEND_CUMULATIVE' || o.dividendType === 'DIVIDEND_NON_CUMULATIVE') {
          if (o.interestRate <= 0) return setTrxAlert(`Interest Rate must be greater than 0 for ${shareholderName} - ${group}`)
        }
        if (o.type === 'SHARE_HOLDER_TYPE_OPTION' || o.type === 'SHARE_HOLDER_TYPE_WARRANT') {
          if (o.strikePrice && o.strikePrice <= 0) return setTrxAlert(`Strike Price must be greater than 0 for ${shareholderName} - ${group}`)
        }

        primaryInvestments.push({
          type: formatShareholderType(o.type),
          shareholderName,
          group,
          totalContribution: o.totalContribution,
          liquidationMultiple: o.liquidationMultiple,
          participationType: formatParticipationType(o.participationType),
          capOnParticipation: o.capOnParticipation,
          exitDate,
          investmentDate,
          dividendType: formatDividend(o.dividendType),
          interestRate: o.interestRate,
          strikePrice: o.strikePrice,
          conversionRatio: 1,
          pricePerShare: o.pricePerShare || undefined,
        })
      }

      const convertibleNotes: ConvertibleNoteInput[] = []
      for (const note of convertibleNoteData) {
        const info = newInvestorsData.find((i) => i.shareholderName === note.shareholderName)
        if (!info) {
          return setTrxAlert(`Did not find new investor that matches convertible note: ${note.shareholderName}`)
        }

        const { shareholderName, group } = info
        const investmentDate = new Date(note.investmentDate)
        const maturityDate = new Date(note.maturityDate)
        const convertDate = new Date(note.convertDate)
        if (note.amountInvested <= 0) return setTrxAlert(`Amount Invested must be greater than 0 for ${shareholderName} - ${group}`)
        if (note.valuation <= 0) return setTrxAlert(`Valuation must be greater than 0 for ${shareholderName} - ${group}`)
        if (investmentDate > maturityDate) return setTrxAlert(`Investment Date must be before Maturity Date for ${shareholderName} - ${group}`)
        if (investmentDate > convertDate) return setTrxAlert(`Investment Date must be before Convert Date for ${shareholderName} - ${group}`)
        convertibleNotes.push({
          ...note,
          shareholderName,
          group,
          investmentDate,
          maturityDate,
          convertDate,
          convertTo: {
            ...note.convertTo,
            type: formatShareholderType(note.convertTo.type),
            exitDate,
          },
        })
      }

      const expectedOptionPool = newOptionPoolSize > minOptionPoolSize ? newOptionPoolSize : undefined
      const resCalc = await restAPIRequest<CapTableRes>({
        method: 'post',
        path: 'calculation/capTable',
        body: {
          roundPlaces: roundPlaces,
          roundUp: roundUp,
          valuation,
          secondarySales,
          primaryInvestments,
          convertibleNotes,
          existingCapTable: capTableV1.map((o) => {
            // TODO: remove this, was bug that had amountInvested on existing cap table
            // @ts-ignore
            delete o.amountInvested
            return o
          }),
          expectedOptionPool,
          investorFriendly: methodForCreatingOptionPool === 'investorFriendly',
          calcDate: exitDate,
        },
      }).catch((e) => {
        console.error('submit error', e.response?.data?.error || e.message)
        setTrxAlert(`Error during calculation: ${e?.response?.data?.error || e.message}`)
      })

      if (!resCalc) {
        setFinalCapTable([])
        return
      }

      const updateSeniority: Record<string, number> = {}
      const finalCapTable = resCalc.capTable.map((o) => {
        const key = `${o.shareholderName}${DELIM}${o.group}`
        const seniority = seniorityMap[key] || 1
        updateSeniority[key] = seniority
        return {
          ...o,
          seniority,
        }
      })

      if (resCalc.pricePerShare < 0) return setTrxAlert('Price per share is negative. Please check your input numbers.')
      setFinalCapTable(finalCapTable)
      setPricePerShare(resCalc.pricePerShare)
      setTrxAlert(undefined)
      setProformaTableData(resCalc.proformaTableData)
      dispatch({
        seniorityMap: updateSeniority,
      })
    } catch (e: any) {
      const eMsg = e.response?.data?.error || e.message
      console.error('submit error for ev', eMsg)
      setTrxAlert(`Error during calculation: ${eMsg}`)
    }
  }

  const saveChangesToBackend = async () => {
    await saveModel()
  }

  const saveChangesToLocal = async () => {
    window.localStorage.setItem(localStorageKey, JSON.stringify({ ...liveState, updatedAt: new Date(), capTableV1: JSON.stringify(capTableV1) }))
    if (saving) return
    setSaving(true)
    submit()
      .catch((e) => console.error('submit error', e))
      .finally(() => {
        setSaving(false)
      })
  }

  const loanStateKey = JSON.stringify(loan)

  const stateCheck = JSON.stringify({ ...liveState, finalCapTable: finalCapTableStateKey })

  useEffect(() => {
    const beforeUnload = async (e: BeforeUnloadEvent) => {
      try {
        await saveChangesToBackend()
        window.removeEventListener('beforeunload', beforeUnload)
      } catch {
        e.preventDefault()
        e.returnValue = ''
      }
    }
    const saveTimeout = setTimeout(() => {
      saveChangesToBackend().then(() => {
        window.removeEventListener('beforeunload', beforeUnload)
      })
    }, 32100)
    const localTimeout = setTimeout(() => {
      saveChangesToLocal()
    }, 250)
    window.addEventListener('beforeunload', beforeUnload)
    return () => {
      if (!window.location.pathname.includes('transaction_model')) saveChangesToBackend()
      clearTimeout(saveTimeout)
      clearTimeout(localTimeout)
      window.removeEventListener('beforeunload', beforeUnload)
    }
  }, [stateCheck])

  useEffect(() => {
    const submitExitWaterfall = async () => {
      if (typeof netDebtAtExit !== 'number') throw new Error('populate Net Debt At Exit')
      if (typeof exitEVLowRange !== 'number') throw new Error('populate Exit EV (Low Range)')
      if (typeof exitEVHighRange !== 'number') throw new Error('populate Exit EV (High Range)')
      if (exitEVHighRange < exitEVLowRange) throw new Error('Exit EV (High Range) must be greater than Exit EV (Low Range)')
      if (!finalCapTable.length) throw new Error('Issue with inputs')
      if (finalCapTable.some((c) => c.type === 'SHARE_HOLDER_TYPE_SERIES' && !c.seniority)) {
        throw new Error(`missing seniority for ${finalCapTable.find((c) => c.type === 'SHARE_HOLDER_TYPE_SERIES' && !c.seniority)?.shareholderName}`)
      }

      let loanInfo: LoanDetails | undefined = undefined
      if (loan) {
        const loanWarrant = primaryInvestmentData.find((p) => p.type === 'SHARE_HOLDER_TYPE_WARRANT')
        if (!loanWarrant) {
          throw new Error('There is no warrant for loan. Please create a warrant in Primary Investment Detail.')
        }
        const info = newInvestorsData.find((i) => i.shareholderName === loanWarrant.shareholderName)
        if (!info) {
          throw new Error(`Did not find new investor that matches warrant: ${loanWarrant.shareholderName}`)
        }
        const { shareholderName, group } = info
        loanInfo = {
          ...loan,
          shareholderName,
          group,
        }
      }

      const { calcs: resCalc, breakpoints } = await restAPIRequest<ExitWaterfallRes>({
        method: 'post',
        path: 'calculation/exitWaterfall',
        body: {
          capTable: finalCapTable,
          netDebtAtExit,
          exitEVLowRange,
          exitEVHighRange,
          fees: totalAggregateFees,
          loans: loanInfo ? [loanInfo] : undefined,
        },
      })

      const { calcs: singleCalc } = await restAPIRequest<ExitWaterfallRes>({
        method: 'post',
        path: 'calculation/exitWaterfall',
        body: {
          capTable: finalCapTable,
          netDebtAtExit,
          exitEVLowRange: userEv,
          exitEVHighRange: userEv,
          fees: totalAggregateFees,
          loans: loanInfo ? [loanInfo] : undefined,
        },
      })

      setCalcs(resCalc)
      setBreakpoints(breakpoints)
      setUserShareholderCalcs(singleCalc[0].shareholderCalcs)
      dispatch({
        userTargetShareholder: userTargetShareholder || singleCalc[0].calcs[0].shareholderName || '',
      })
    }

    if (valuation < existingShares * pricePerShare) setTrxAlert('Pre-Money Valuation is less than existing shares * price per share')

    const submitWaterfallTime = setTimeout(() => {
      if (trxAlert) console.error('trxAlert', trxAlert)
      submitExitWaterfall()
        .then(() => {
          setWaterfallAlert(undefined)
          setTrxAlert(undefined)
        })
        .catch((e) => {
          setCalcs([])
          setWaterfallAlert(`Error calculating waterfall. Check your inputs: ${e?.response?.data?.error || e.message}`)
          return setTrxAlert(`Error calculating waterfall. Check your inputs: ${e?.response?.data?.error || e.message}`)
        })
    }, 500)
    return () => {
      clearTimeout(submitWaterfallTime)
    }
  }, [exitEVHighRange, exitEVLowRange, finalCapTableStateKey, netDebtAtExit, totalAggregateFees, userEv, loanStateKey])

  const secondaryCapitalDiff = () => {
    return totalSecondaryCapitalInvested - secondaryInvestmentData.reduce((acc, p) => (p.totalProceeds ? acc + p.totalProceeds : acc), 0)
  }

  function renderExitDateInput(hideLabel) {
    return (
      <DateInput
        label='Exit date'
        labelHidden={hideLabel}
        value={exitDate}
        onChange={(d) => {
          dispatch({
            exitDate: d,
          })
        }}
      />
    )
  }

  function renderEvInput(opts: { className?: string; labelHidden?: boolean }) {
    return (
      <CurrencyInput
        label='Exit Enterprise Value'
        className={opts?.className}
        labelHidden={opts?.labelHidden}
        value={userEv}
        onValueChange={(v) => {
          dispatch({ userEv: v, exitEVHighRange: v })
        }}
      />
    )
  }
  function renderNetDebtAtExitInput(opts: { variation?: string; labelHidden?: boolean; className?: string }) {
    return (
      <CurrencyInput
        label='Net Debt & Trx. Fees'
        labelHidden={opts?.labelHidden}
        variation={opts?.variation as any}
        className={opts?.className}
        value={netDebtAtExit}
        onValueChange={(v) => {
          dispatch({
            netDebtAtExit: v,
          })
        }}
      />
    )
  }

  const renderEntryAssumptions = (): React.ReactNode => {
    const onTransactionTypeChange = (e) => {
      dispatch({
        transactionType: e,
      })
    }

    return (
      <View ref={entryAssumptionsRef}>
        <GlassCardControlled
          header='Entry Assumptions'
          isExpanded={isEntryAssumptionsExpanded}
          onExpand={handleActiveInputSectionChange}
          expandable={true}
          style={{ top: '0', borderTopLeftRadius: '0.5rem' }}
          className={`transactionModelCard ${isEntryAssumptionsExpanded && 'expanded'}`}
        >
          <Flex direction='column' className='transactionAssumptionsControls'>
            <Flex>
              <RoundDetails
                transactionType={transactionType}
                onTransactionTypeChange={onTransactionTypeChange}
                trxName={trxName}
                setTrxName={(i) => {
                  dispatch({ trxName: i })
                }}
              />
              <Flex className='assumptionsInput'>
                <Text className='amplify-label assumptionLabel'>Pre-Money Valuation</Text>
                <CurrencyInput
                  label='Pre-Money Valuation'
                  labelHidden={true}
                  className='assumptionValue'
                  value={preMoneyEquityValuation}
                  onValueChange={(value) => {
                    dispatch({
                      preMoneyEquityValuation: value,
                    })
                  }}
                />
              </Flex>
            </Flex>

            <Flex className='assumptionsInput' direction='column' alignItems='flex-start' gap='0'>
              <ValueAdjustment
                values={valueAdjustments || []}
                onChange={(x) => {
                  dispatch({
                    valueAdjustments: x,
                  })
                }}
              />
            </Flex>

            {/* TODO: Add 'Fees' input */}

            <Flex>
              <Flex className='assumptionsInput'>
                <Text className='amplify-label assumptionLabel'>Desired Option Pool</Text>
                <NumericFormat
                  label='Desired Option Pool'
                  labelHidden={true}
                  isRequired={true}
                  type='text'
                  inputMode='numeric'
                  pattern='\d*'
                  size='small'
                  className='assumptionValue'
                  decimalScale={2}
                  fixedDecimalScale
                  suffix='%'
                  customInput={TextField}
                  hasError={(newOptionPoolSize !== 0 && newOptionPoolSize <= minOptionPoolSize) || newOptionPoolSize > 1}
                  errorMessage={newOptionPoolSize > 1 ? 'Max size is 100%' : `Minimum option pool size is ${pFmt(minOptionPoolSize)}`}
                  valueIsNumericString={true}
                  value={newOptionPoolSize * 100}
                  onValueChange={(v) => {
                    dispatch({
                      newOptionPoolSize: Math.round(parseFloat(v.value) * 100) / 10000,
                    })
                  }}
                />
              </Flex>
              <Flex className='assumptionsInput'>
                <Text className='amplify-label assumptionLabel'>Option Pool Creation Method</Text>
                <SelectField
                  label='Option Pool Creation Method'
                  labelHidden={true}
                  size='small'
                  className='assumptionValue'
                  value={methodForCreatingOptionPool}
                  onChange={(e) => {
                    dispatch({
                      methodForCreatingOptionPool: e.target.value,
                    })
                  }}
                >
                  <option value='investorFriendly'>Investor Friendly</option>
                  <option value='founderFriendly'>Founder Friendly</option>
                </SelectField>
              </Flex>
            </Flex>
          </Flex>
        </GlassCardControlled>
      </View>
    )
  }

  function renderNewInvestor() {
    return (
      <NewInvestorView
        newInvestorsData={newInvestorsData}
        updateName={(i, newValue) => {
          const update = [...newInvestorsData]
          const oldValue = update[i].shareholderName
          update[i].shareholderName = newValue

          dispatch({
            newInvestorsData: update,
            // TODO: move this into reducer
            secondaryInvestmentData: secondaryInvestmentData.map((o) => {
              if (o.buyerName !== oldValue) return o
              return {
                ...o,
                buyerName: newValue,
              }
            }),
            primaryInvestmentData: primaryInvestmentData.map((o) => {
              if (o.shareholderName !== oldValue) return o
              return {
                ...o,
                shareholderName: newValue,
              }
            }),
          })
        }}
        updateGroup={(i, newValue) => {
          const update = [...newInvestorsData]
          const shareholderName = update[i].shareholderName
          update[i].group = newValue

          dispatch({
            newInvestorsData: update,
            // TODO move this into reducer
            secondaryInvestmentData: secondaryInvestmentData.map((o) => {
              if (o.buyerName !== shareholderName) return o
              return {
                ...o,
                group: newValue,
              }
            }),
            primaryInvestmentData: primaryInvestmentData.map((o) => {
              if (o.shareholderName !== shareholderName) return o
              return {
                ...o,
                group: newValue,
              }
            }),
          })
        }}
        addNewInvestor={() =>
          dispatch({
            type: ADD_NEW_INVESTOR,
          })
        }
        deleteInvestor={(iIndex) => {
          const shareholderName = newInvestorsData[iIndex].shareholderName
          dispatch({
            // TDOO: move this into reducer
            newInvestorsData: newInvestorsData.filter((_, i) => i !== iIndex),
            secondaryInvestmentData: secondaryInvestmentData.filter((o) => o.buyerName !== shareholderName),
            primaryInvestmentData: primaryInvestmentData.filter((o) => o.shareholderName !== shareholderName),
            convertibleNoteData: convertibleNoteData.filter((o) => o.shareholderName !== shareholderName),
          })
        }}
      />
    )
  }

  const renderNewInvestorInputs = (): React.ReactNode => {
    return (
      <View ref={newInvestorsAssumptionsRef}>
        <GlassCardControlled
          header='New Investors'
          isExpanded={isNewInvestorsAssumptionsExpanded}
          onExpand={handleActiveInputSectionChange}
          expandable={true}
          style={{ top: '50px' }}
          className={`transactionModelCard ${isNewInvestorsAssumptionsExpanded && 'expanded'}`}
        >
          <Flex direction='column' className='transactionAssumptionsControls'>
            {renderNewInvestor()}
          </Flex>
        </GlassCardControlled>
      </View>
    )
  }

  const renderPrimaryInvestmentInputs = (): React.ReactNode => {
    if (!transactionType.includes(PRIMARY_INVESTMENT_KEY)) return <></>

    if (newInvestorsData.length === 0) {
      return (
        <View ref={primaryInvestmentAssumptionsRef}>
          <GlassCardControlled
            header='Primary Investment'
            isExpanded={isPrimaryInvestmentAssumptionsExpanded}
            onExpand={handleActiveInputSectionChange}
            expandable={true}
            style={{ top: '100px' }}
            className={`transactionModelCard ${isPrimaryInvestmentAssumptionsExpanded && 'expanded'}`}
          >
            <Text className='amplify-label' padding='1rem'>
              Add 'New Investors' before selecting 'New Primary Investment Detail'
            </Text>
          </GlassCardControlled>
        </View>
      )
    }

    // const diff = primaryCapitalDiff(totalPrimaryCapitalInvested, primaryInvestmentData)
    return (
      <View ref={primaryInvestmentAssumptionsRef}>
        <GlassCardControlled
          header='Primary Investment'
          isExpanded={isPrimaryInvestmentAssumptionsExpanded}
          onExpand={handleActiveInputSectionChange}
          expandable={true}
          style={{ top: '100px' }}
          className={`transactionModelCard ${isPrimaryInvestmentAssumptionsExpanded && 'expanded'}`}
        >
          <Flex direction='column' className='transactionAssumptionsControls withTable'>
            {/* <Flex className='tableTitleInput' alignItems='center'>
              <CurrencyInput
                label='Total Primary Capital Invested'
                labelHidden={false}
                value={totalPrimaryCapitalInvested}
                onValueChange={(value) => {
                  dispatch({
                    totalPrimaryCapitalInvested: value,
                  })
                }}
              />

              {diff === 0 ? null : <Text variation='error'>Warning: Total Primary Capital Investment does not align with line items by ${diff}</Text>}
            </Flex> */}
            <InputTable<PrimaryInvestmentDetail>
              data={primaryInvestmentData}
              columns={primaryInvestmentColumns}
              enableGrouping
              updateData={updateData(PRIMARY_INVESTMENT_KEY, primaryInvestmentData, 'primaryInvestmentData')}
              deleteRow={(rowIndex: number) => {
                dispatch({
                  secondaryInvestmentData: secondaryInvestmentData.filter((o) => o.buyerName !== primaryInvestmentData[rowIndex].shareholderName),
                  primaryInvestmentData: primaryInvestmentData.filter((_, i) => i !== rowIndex),
                })
              }}
              getOptions={getOptions}
              getCell={getCapTableCells}
              sticky={true}
            />
            {/* TODO: use value that updates on primary investment table changes */}
            {/* <Flex marginLeft='0.5rem'>
              Total Primary Capital: <Text fontWeight='600' children={totalPrimaryCapitalInvested ? currencyFormatterLong(totalPrimaryCapitalInvested) : 'N/A'} />
            </Flex> */}
            <Button
              onClick={() => {
                dispatch({
                  type: ADD_NEW_PRIMARY_INVESTMENT_DETAIL,
                })
              }}
              size='small'
              alignSelf='flex-start'
              left='2rem'
              margin='1rem 0 1rem 0.5rem'
            >
              + Add Investment
            </Button>
          </Flex>
        </GlassCardControlled>
      </View>
    )
  }

  const renderConvertibleNotesInputs = (): React.ReactNode => {
    if (!transactionType.includes(PRIMARY_INVESTMENT_KEY)) return <></>

    if (newInvestorsData.length === 0) {
      return (
        <View ref={convertibleNoteRef}>
          <GlassCardControlled
            header='Convertible Notes'
            isExpanded={isConvertibleNoteExpanded}
            onExpand={handleActiveInputSectionChange}
            expandable={true}
            style={{ top: '150px' }}
            className={`transactionModelCard ${isConvertibleNoteExpanded && 'expanded'}`}
          >
            <Text className='amplify-label' padding='1rem'>
              Add 'New Investors' before selecting 'New Convertible Note Detail'
            </Text>
          </GlassCardControlled>
        </View>
      )
    }

    return (
      <View ref={convertibleNoteRef}>
        <GlassCardControlled
          header='Convertible Notes'
          isExpanded={isConvertibleNoteExpanded}
          onExpand={handleActiveInputSectionChange}
          expandable={true}
          style={{ top: '150px' }}
          className={`transactionModelCard ${isConvertibleNoteExpanded && 'expanded'}`}
        >
          <Flex direction='column' className='transactionAssumptionsControls withTable'>
            <InputTable<ConvertibleNoteDetail>
              data={convertibleNoteData}
              columns={convertibleNoteColumns}
              enableGrouping
              updateData={updateData(CONVERTIBLE_NOTE_KEY, convertibleNoteData, 'convertibleNoteData')}
              deleteRow={(rowIndex: number) => {
                dispatch({
                  convertibleNoteData: convertibleNoteData.filter((_, i) => i !== rowIndex),
                })
              }}
              getOptions={getOptions}
            />
            <Button
              onClick={() => {
                dispatch({
                  type: ADD_NEW_CONVERTIBLE_NOTE_DETAIL,
                })
              }}
              size='small'
              alignSelf='flex-start'
              margin='1rem 0 1rem 0.5rem'
            >
              + Add Note
            </Button>
          </Flex>
        </GlassCardControlled>
      </View>
    )
  }

  const renderSecondarySaleInputs = (): React.ReactNode => {
    if (!transactionType.includes(SECONDARY_INVESTMENT_KEY)) return <></>

    if (!valuation) return <Text>Pre-Money Valuation needs to be populated before adding secondary sales</Text>
    if (!pricePerShare) return <Text>Need to calculate Price Per share before adding secondary. Add details above.</Text>
    if (newInvestorsData.length === 0)
      return (
        <View ref={secondarySaleAssumptionsRef}>
          <GlassCardControlled
            header='Secondary Sale'
            isExpanded={isSecondarySaleAssumptionsExpanded}
            onExpand={handleActiveInputSectionChange}
            expandable={true}
            style={{ top: `${inputSpacingBuffer * 50 + 50}px` }}
            className={`transactionModelCard ${isSecondarySaleAssumptionsExpanded && 'expanded'}`}
          >
            <Text className='amplify-label' padding='1rem'>
              Add 'New Investors' before selecting 'Secondary Sale Assumptions'
            </Text>
          </GlassCardControlled>
        </View>
      )
    return (
      <View ref={secondarySaleAssumptionsRef}>
        <GlassCardControlled
          header='Secondary Sale'
          isExpanded={isSecondarySaleAssumptionsExpanded}
          onExpand={handleActiveInputSectionChange}
          expandable={true}
          style={{ top: `${inputSpacingBuffer * 50 + 50}px` }}
          className={`transactionModelCard ${isSecondarySaleAssumptionsExpanded && 'expanded'}`}
        >
          <Flex direction='column' className='transactionAssumptionsControls withTable'>
            {/* <Flex className='tableTitleInput' alignItems='center'>
              <View>
                <CurrencyInput
                  label='Total Secondary Capital Invested'
                  value={totalSecondaryCapitalInvested}
                  onValueChange={(value) => {
                    dispatch({
                      totalSecondaryCapitalInvested: value,
                    })
                  }}
                />
              </View>
              {secondaryCapitalDiff() === 0 ? null : (
                <Text variation='error'>Warning: Total Secondary Capital Investment does not align with line items by ${secondaryCapitalDiff()}</Text>
              )}
            </Flex> */}
            <InputTable<SecondaryInvestmentDetail>
              data={secondaryInvestmentData}
              columns={secondaryInvestmentColumns}
              enableGrouping
              updateData={updateData(SECONDARY_INVESTMENT_KEY, secondaryInvestmentData, 'secondaryInvestmentData')}
              deleteRow={(rowIndex: number) =>
                dispatch({
                  secondaryInvestmentData: secondaryInvestmentData.filter((_, i) => i !== rowIndex),
                })
              }
              getOptions={getOptions}
              getErrors={(eIndex, id, value, original) => {
                if (id === 'totalProceeds') {
                  const v = value as number
                  return { errorMessage: 'Not enough shares to sell.', hasError: original.shareValue < v }
                }
                return { errorMessage: '', hasError: false }
              }}
            />
            <Button
              onClick={() => {
                const newSi = {
                  existingShareholderKey: `${capTableV1[0].shareholderName}${DELIM}${capTableV1[0].group}`,
                  existingShareholderName: capTableV1[0].shareholderName,
                  existingShareholderGroup: capTableV1[0].group,
                  shareValue: pricePerShare * capTableV1[0].shareCount,
                  numOfSharesSold: pricePerShare ? secondaryCapitalDiff() / pricePerShare : 0,
                  pricePerShare: pricePerShare,
                  totalProceeds: secondaryCapitalDiff(),
                  buyerName: newInvestorsData[0].shareholderName,
                  buyerGroup: newInvestorsData[0].group,
                }
                dispatch({
                  secondaryInvestmentData: [...secondaryInvestmentData, newSi],
                })
              }}
              size='small'
              alignSelf='flex-start'
              margin='1rem 0 1rem 0.5rem'
            >
              + Add Sale
            </Button>
          </Flex>
        </GlassCardControlled>
      </View>
    )
  }

  function renderExitAssumptions() {
    return (
      <View ref={exitAssumptionsRef}>
        <GlassCardControlled
          header='Exit Assumptions'
          isExpanded={isExitAssumptionsExpanded}
          onExpand={handleActiveInputSectionChange}
          expandable={true}
          style={{ top: `${inputSpacingBuffer * 50 + 100}px` }}
          className={`transactionModelCard ${isExitAssumptionsExpanded && 'expanded'}`}
        >
          <Flex className='transactionAssumptionsControls'>
            <Flex>
              <Flex className='assumptionsInput'>
                <Text className='amplify-label assumptionLabel'>Investor</Text>
                <SelectField
                  label='Investor'
                  labelHidden={true}
                  size='small'
                  className='assumptionValue'
                  onChange={(e) => {
                    dispatch({
                      userTargetShareholder: e.target.value,
                    })
                  }}
                  options={userShareholderCalcs.map((i) => i.shareholderName)}
                />
              </Flex>
              <Flex className='assumptionsInput'>
                <Text className='amplify-label assumptionLabel'>Exit Date</Text>
                <DateInput
                  label='Close date'
                  labelHidden={true}
                  className='assumptionValue'
                  value={exitDate}
                  onChange={(d) => {
                    dispatch({
                      exitDate: d,
                    })
                  }}
                />
              </Flex>
            </Flex>

            <Flex>
              <Flex className='assumptionsInput'>
                <Text className='amplify-label assumptionLabel'>Exit EV (Low)</Text>
                <CurrencyInput
                  label='Exit EV (Low)'
                  labelHidden={true}
                  className='assumptionValue'
                  value={exitEVLowRange}
                  onValueChange={(v) => {
                    dispatch({
                      exitEVLowRange: v,
                    })
                  }}
                />
              </Flex>
              <Flex className='assumptionsInput'>
                <Text className='amplify-label assumptionLabel'>Exit EV (High)</Text>
                {/* TODO: update input to Exit EV */}
                {renderEvInput({ className: 'assumptionValue', labelHidden: true })}
              </Flex>
            </Flex>

            <Flex className='assumptionsInput'>
              <Text className='amplify-label assumptionLabel'>Net Debt & Trx. Fees</Text>
              {renderNetDebtAtExitInput({ labelHidden: true, className: 'assumptionValue' })}
            </Flex>
          </Flex>
        </GlassCardControlled>
      </View>
    )
  }

  const renderSeniorityInputs = (): React.ReactNode => {
    return (
      <View ref={seniorityAssumptionsRef}>
        <GlassCardControlled
          header='Seniority'
          isExpanded={isSeniorityAssumptionsExpanded}
          onExpand={handleActiveInputSectionChange}
          expandable={true}
          style={{ top: `${inputSpacingBuffer * 50 + 150}px`, borderBottomLeftRadius: '0.5rem' }}
          className={`transactionModelCard ${isSeniorityAssumptionsExpanded && 'expanded'}`}
        >
          <Flex className='transactionAssumptionsControls withTable'>
            <Table size='small'>
              <TableBody>
                {finalCapTable.filter((c) => c.type === 'SHARE_HOLDER_TYPE_SERIES').length ? (
                  <TableRow key='header-seniority'>
                    <TableCell colSpan={1} className='groupTableHeader' textAlign='left'>
                      Shareholder
                    </TableCell>
                    <TableCell colSpan={1} className='groupTableHeader' textAlign='left'>
                      Group
                    </TableCell>

                    <TableCell colSpan={1} className='groupTableHeader' textAlign='left'>
                      Seniority Rank
                    </TableCell>
                  </TableRow>
                ) : (
                  <Text className='amplify-label' padding='8px'>
                    None eligible
                  </Text>
                )}
                {finalCapTable
                  .filter((c) => c.type === 'SHARE_HOLDER_TYPE_SERIES')
                  .map((c) => {
                    const key = `${c.shareholderName}${DELIM}${c.group}`
                    return (
                      <TableRow key={`body-seniority ${key}`}>
                        <TableCell colSpan={1} className='tableCell tableCellLabel'>
                          {c.shareholderName}
                        </TableCell>
                        <TableCell colSpan={1} className='tableCell tableCellLabel'>
                          {c.group}
                        </TableCell>
                        <TableCell colSpan={1} className='tableCell'>
                          <NumericFormat
                            label={'Seniority Rank'}
                            labelHidden
                            type='text'
                            inputMode='numeric'
                            pattern='\d*'
                            size='small'
                            thousandSeparator=','
                            customInput={TextField}
                            value={seniorityMap[key] || 1}
                            valueIsNumericString={true}
                            onValueChange={(v) => {
                              const value = parseInt(v.value.replace(/[$,]/g, ''))
                              if (isNaN(value)) return
                              dispatch({
                                seniorityMap: {
                                  ...seniorityMap,
                                  [key]: value,
                                },
                              })
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </Flex>
        </GlassCardControlled>
      </View>
    )
  }

  function renderEntryMetrics(): any {
    return (
      <Flex>
        <Flex direction='column' minWidth='20rem'>
          <GlassCard header='' className='overlayCard metricCard small'>
            <Flex direction='column' gap='0'>
              <Text className='metricValue'>{`${currencyFormatterShort(preMoneyEquityValuation)}`}</Text>
              <Heading level={5} className='metricName'>
                Pre-Money Valuation
              </Heading>
            </Flex>
          </GlassCard>
          <GlassCard header='' className='overlayCard metricCard small'>
            <Flex direction='column' gap='0'>
              <Text className='metricValue'>{`${currencyFormatterLong(pricePerShare)}`}</Text>
              <Heading level={5} className='metricName'>
                Entry Price Per Share
              </Heading>
            </Flex>
          </GlassCard>
        </Flex>
        <TransactionModelMultiples preMoneyEquityValuation={preMoneyEquityValuation} />
      </Flex>
    )
  }

  function renderEntryExitPieChart(): any {
    if (!shouldDisplayAllInputs()) {
      return null
    }

    const sharesData = capTableV1.map((o) => {
      return {
        current: o.shareCount || 0,
        future: 0,
        name: o.shareholderName,
        group: o.group,
      }
    })

    finalCapTable.forEach((o) => {
      const shareData = sharesData.find((e) => e.name === o.shareholderName && e.group === o.group)
      if (shareData) {
        shareData.future = o.shareCount || 0
      } else {
        sharesData.push({
          current: 0,
          future: o.shareCount || 0,
          name: o.shareholderName,
          group: o.group,
        })
      }
    })

    return (
      <Flex justifyContent='space-around' gap='0.5rem'>
        <GlassCard header='' className='overlayCard'>
          <Heading level={4} textAlign='center'>
            Current
          </Heading>
          <View className='summaryTileContent summaryTileChart'>
            <TransactionModelChart1PieChart
              data={sharesData.map((d) => {
                return { name: d.name, value: d.current, group: d.group }
              })}
            />
          </View>
        </GlassCard>
        <GlassCard header='' className='overlayCard'>
          <Heading level={4} textAlign='center'>
            Pro Forma
          </Heading>
          <View className='summaryTileContent summaryTileChart'>
            <TransactionModelChart1PieChart
              data={sharesData.map((d) => {
                return { name: d.name, value: d.future, group: d.group }
              })}
            />
          </View>
        </GlassCard>
      </Flex>
    )
  }

  function renderExitMetrics() {
    const calc = userShareholderCalcs.find((i) => i.shareholderName === userTargetShareholder)
    const moicPrep = calc?.momReturns
    let moic = 'N/A'
    if (moicPrep) {
      moic = multipleFormatter(moicPrep)
    }
    const irrPrep = calc?.irr
    let irr = 'N/A'
    if (irrPrep) {
      irr = percentFormatter(irrPrep)
    }

    const proceedsPrep = calc?.proceeds
    let proceeds = 'N/A'
    if (proceedsPrep) {
      proceeds = currencyFormatterLong(proceedsPrep)
    }

    return (
      <Grid templateColumns='1fr 1fr 1fr' columnGap='1rem'>
        <GlassCard header='' className='overlayCard metricCard exitMetric'>
          <Flex direction='column' gap='0'>
            <Text className='metricValue'>{proceeds}</Text>
            <Heading level={5} className='metricName'>
              Total Exit Proceeds
            </Heading>
          </Flex>
        </GlassCard>
        <GlassCard header='' className='overlayCard metricCard exitMetric'>
          <Flex direction='column' gap='0'>
            <Text className='metricValue'>{moic}</Text>
            <Heading level={5} className='metricName'>
              MOIC
            </Heading>
          </Flex>
        </GlassCard>
        <GlassCard header='' className='overlayCard metricCard exitMetric'>
          <Flex direction='column' gap='0'>
            <Text className='metricValue'>{irr}</Text>
            <Heading level={5} className='metricName'>
              IRR
            </Heading>
          </Flex>
        </GlassCard>
      </Grid>
    )
  }

  const getCurrentPage = (page: string) => {
    if (pricePerShare === 0 && !trxAlert) return <Loader />
    const waterfallAlertBanner = waterfallAlert ? (
      <Alert hasIcon={true} variation='error' heading='Invalid Inputs for Cap Table'>
        {waterfallAlert}
      </Alert>
    ) : null
    const trxAlertBanner = trxAlert ? (
      <Alert hasIcon={true} variation='error' heading='Invalid Inputs for Waterfall'>
        {trxAlert}
      </Alert>
    ) : null
    const warnBanner = trxWarn ? (
      <Alert hasIcon={true} variation='warning' heading='Warning'>
        {trxWarn}
      </Alert>
    ) : null

    switch (page) {
      case 'capTable':
        if (finalCapTable.length === 0 && capTableV1.length === 0) {
          return (
            <MissingDataCard>
              <Text>Add Existing Cap Table to get started. Either upload an existing file or manually add them below.</Text>
              <CapTableUpload
                onUpload={(ct) =>
                  dispatch({
                    capTableV1: ct,
                  })
                }
                onChange={(ct) =>
                  dispatch({
                    capTableV1: ct,
                  })
                }
                data={capTableV1}
              />
            </MissingDataCard>
          )
        }

        return (
          <Flex direction='column' className='transactionModelSection'>
            {trxAlertBanner}
            {warnBanner}
            <GlassCard header='' className='transactionModelOutput'>
              <CapTable proformaTableData={proformaTableData} />
              <GlassCard header='Existing Cap Table' className='overlayCard'>
                <ScrollView width='100%'>
                  <CapTableUpload
                    onUpload={(ct) =>
                      dispatch({
                        capTableV1: ct,
                      })
                    }
                    onChange={(ct) =>
                      dispatch({
                        capTableV1: ct,
                      })
                    }
                    data={capTableV1}
                  />
                </ScrollView>
              </GlassCard>
            </GlassCard>
          </Flex>
        )
      case 'summary':
        return (
          <Flex direction='column' className='transactionModelSection'>
            {trxAlertBanner}
            {waterfallAlertBanner}
            {warnBanner}
            <GlassCard header='' className='transactionModelOutput'>
              <Heading level={4}>Entry Assumptions - {trxName}</Heading>
              {renderEntryMetrics()}
              {renderEntryExitPieChart()}
              <View>
                <Heading level={4} marginBottom='1rem'>
                  Exit Returns - {userTargetShareholder}
                </Heading>
                {renderExitMetrics()}
              </View>
            </GlassCard>
            {/* TODO: remove assumptionsSecondInputs() once E2E testing has passed */}
            {/* {assumptionsSecondInputs()} */}
          </Flex>
        )
      case 'waterfall':
        if (!finalCapTable.length) {
          return (
            <MissingDataCard>
              <Text>
                Please fill out{' '}
                <Link
                  textDecoration='underline'
                  onClick={() => {
                    setCurrentPage('summary')
                  }}
                >
                  Transaction Assumptions
                </Link>{' '}
                before calculating exit waterfall.
              </Text>
            </MissingDataCard>
          )
        }
        return (
          <Flex direction='column'>
            {waterfallAlertBanner}
            {warnBanner}
            <WaterfallCharts calcs={calcs} />
          </Flex>
        )
    }
  }

  // SETUP: render step flow
  const renderSetupFlow = (): React.ReactNode => {
    let stepNumber = 0
    return (
      <>
        <ul className='transactionModelSteps'>
          <div className='dividerContainer'>
            <div className='divider'></div>
          </div>
          {STEPS.map((step, stepIndex) => {
            if (!step.isStepHeader) return <Fragment key={stepIndex}></Fragment>

            stepNumber++
            const isActive = STEPS[stepCounter].name === step.name
            const isDone = !isActive && stepCounter > stepIndex
            return (
              <li key={stepIndex} className={`transactionModelStep ${isActive && 'active'} ${isDone ? 'done' : ''}`}>
                <Text className='stepNumber'>{isDone ? <CkCheck /> : stepNumber}</Text>
                <Text className='stepName'>{step.displayName}</Text>
              </li>
            )
          })}
        </ul>
        <Flex className='transactionModelStepContent'>
          {renderSetupPage(STEPS[stepCounter], stepDirection)}
          <Flex justifyContent='space-between' padding='0 1rem'>
            <Button size='small' className='tertiary' isDisabled={stepCounter - 1 < 0} onClick={() => updateStep(STEPS[stepCounter], 'back')}>
              ← Back
            </Button>
            <Button variation='primary' size='small' isDisabled={!validateExitCriteria(STEPS[stepCounter])} onClick={() => updateStep(STEPS[stepCounter], 'next')}>
              {stepCounter === STEPS.length - 1 ? 'Done →' : 'Next →'}
            </Button>
          </Flex>
        </Flex>
      </>
    )
  }

  // SETUP: step flow updater
  const updateStep = (currentStep: Step, direction: 'back' | 'next'): void => {
    if (direction === 'back') {
      setStepDirection('back')
      setStepCounter(stepCounter - 1)
    } else if (direction === 'next') {
      if (validateExitCriteria(currentStep)) {
        // handle step flow is done
        if (stepCounter === STEPS.length - 1) {
          setIsSetupFlow(false)
        } else {
          // handle moving to next step
          setStepDirection('next')
          setStepCounter(stepCounter + 1)
        }
      }
    }
  }

  // SETUP: validate exit criteria when moving through steps
  const validateExitCriteria = (step: Step): boolean => {
    let pass = true
    step.exitCriteria.forEach((requirement) => {
      if (typeof requirement === 'object' && requirement && requirement.length === 0) pass = false
      if (typeof requirement === 'string' && requirement && requirement.length === 0) pass = false
      if (typeof requirement === 'number' && requirement === 0) pass = false
    })
    return pass
  }

  // SETUP: render each page for step flow
  const renderSetupPage = (step: Step, direction: 'none' | 'back' | 'next'): React.ReactNode => {
    switch (step.name) {
      case 'capTable':
        switch (step.displayName) {
          case 'Existing Cap Table':
            return (
              <GlassCard header='' className='dropZoneContainer overlayCard'>
                <DropZone className='dropZone'>
                  <UploadIcon className='dropZoneIcon' />
                  <Text>
                    <strong>Upload existing cap table</strong> to get started
                  </Text>
                  <Text className='dropZoneSubtitle'>PDF, TXT, TEXT, LOG, MD, DOCX, or PPTX (max 200 KB per file, 1 MB total)</Text>
                </DropZone>
                <Text alignSelf='center'>
                  or <Link onClick={() => setStepCounter(stepCounter + 1)}>input values manually</Link>
                </Text>
              </GlassCard>
            )
          case 'Cap Table':
            return (
              <GlassCard header='' className='tableCard overlayCard'>
                <Heading className='transactionModelStepHeader'>Upload (or Input) Cap Table</Heading>
                <ScrollView width='100%'>
                  <CapTableUpload
                    onUpload={(ct) =>
                      dispatch({
                        capTableV1: ct,
                      })
                    }
                    onChange={(ct) =>
                      dispatch({
                        capTableV1: ct,
                      })
                    }
                    data={capTableV1}
                  />
                </ScrollView>
              </GlassCard>
            )
        }
        break
      case 'transactionType':
        const handleTransactionTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
          const transactionTypeArray = transactionType === '' ? [] : transactionType.split(',')
          if (e.target.checked) {
            transactionTypeArray.push(e.target.value)
            dispatch({
              transactionType: transactionTypeArray.join(','),
            })
          } else {
            dispatch({
              transactionType: transactionTypeArray.filter((type) => type !== e.target.value).join(','),
            })
          }
        }
        return (
          <GlassCard header='' className='transactionTypeContainer overlayCard'>
            <Flex className='transactionTypes'>
              <Heading className='transactionModelStepHeader'>Select Transaction Type(s)</Heading>
              <CheckboxField
                label='Portfolio Company Monitoring'
                className='transactionType'
                name={MONITORING_KEY}
                value={MONITORING_KEY}
                checked={transactionType.includes(MONITORING_KEY)}
                isDisabled={transactionType.includes(PRIMARY_INVESTMENT_KEY) || transactionType.includes(SECONDARY_INVESTMENT_KEY) || transactionType.includes(LOAN_KEY)}
                onChange={handleTransactionTypeChange}
              />
              <CheckboxField
                label='Primary Equity Capital Investment'
                className='transactionType'
                name={PRIMARY_INVESTMENT_KEY}
                value={PRIMARY_INVESTMENT_KEY}
                checked={transactionType.includes(PRIMARY_INVESTMENT_KEY)}
                isDisabled={transactionType.includes(MONITORING_KEY)}
                onChange={handleTransactionTypeChange}
              />
              <CheckboxField
                label='Secondary Equity Capital Investment'
                className='transactionType'
                name={SECONDARY_INVESTMENT_KEY}
                value={SECONDARY_INVESTMENT_KEY}
                checked={transactionType.includes(SECONDARY_INVESTMENT_KEY)}
                isDisabled={transactionType.includes(MONITORING_KEY)}
                onChange={handleTransactionTypeChange}
              />
              {/* <CheckboxField
                label='Venture Debt'
                className='transactionType'
                name={LOAN_KEY}
                value={LOAN_KEY}
                checked={transactionType.includes(LOAN_KEY)}
                isDisabled={transactionType.includes(MONITORING_KEY)}
                onChange={handleTransactionTypeChange}
              /> */}
            </Flex>
            <Flex className='roundName'>
              <Heading className='transactionModelStepHeader'>Enter Round Name</Heading>
              <TextField
                label='Round name'
                labelHidden={true}
                isRequired={true}
                type='text'
                placeholder={trxName}
                className='assumptionValue'
                value={trxName}
                onChange={(e) => {
                  dispatch({ trxName: e.target.value })
                }}
              />
            </Flex>
          </GlassCard>
        )
      case 'financials':
        return <FinancialPage />
      case 'entryAssumptions':
        return (
          <GlassCard header='' className='overlayCard'>
            <Heading className='transactionModelStepHeader'>Enter Entry Assumptions</Heading>
            <CurrencyInput
              label='Pre-Money Valuation'
              className='assumptionValue'
              value={preMoneyEquityValuation}
              onValueChange={(value) => {
                dispatch({
                  preMoneyEquityValuation: value,
                })
              }}
            />
            <ValueAdjustment
              values={valueAdjustments || []}
              onChange={(x) => {
                dispatch({
                  valueAdjustments: x,
                })
              }}
            />
            <NumericFormat
              label='Desired Option Pool'
              isRequired={true}
              type='text'
              inputMode='numeric'
              pattern='\d*'
              className='assumptionValue'
              decimalScale={2}
              fixedDecimalScale
              suffix='%'
              customInput={TextField}
              hasError={(newOptionPoolSize !== 0 && newOptionPoolSize <= minOptionPoolSize) || newOptionPoolSize > 1}
              errorMessage={newOptionPoolSize > 1 ? 'Max size is 100%' : `Minimum option pool size is ${pFmt(minOptionPoolSize)}`}
              valueIsNumericString={true}
              value={newOptionPoolSize * 100}
              onValueChange={(v) => {
                dispatch({
                  newOptionPoolSize: Math.round(parseFloat(v.value) * 100) / 10000,
                })
              }}
            />
            <SelectField
              label='Option Pool Creation Method'
              className='assumptionValue'
              value={methodForCreatingOptionPool}
              onChange={(e) => {
                dispatch({
                  methodForCreatingOptionPool: e.target.value,
                })
              }}
            >
              <option value='investorFriendly'>Investor Friendly</option>
              <option value='founderFriendly'>Founder Friendly</option>
            </SelectField>
          </GlassCard>
        )
      case 'investmentDetail':
        switch (step.displayName) {
          case 'Investment Details':
            // skip the Investment Detail step, only used for the step count header, has no content
            if (direction === 'back') {
              setStepCounter(stepCounter - 1)
            } else if (direction === 'next' || direction === 'none') {
              setStepCounter(stepCounter + 1)
            }
            return
          case 'New Investors':
            return (
              <GlassCard header='' className='overlayCard'>
                <Heading className='transactionModelStepHeader'>Add New Investors</Heading>
                {renderNewInvestor()}
              </GlassCard>
            )
          case 'Primary Investment Details':
            if (transactionType.includes(PRIMARY_INVESTMENT_KEY)) {
              return (
                <GlassCard header='' className='tableCard overlayCard'>
                  <Heading className='transactionModelStepHeader'>Enter Primary Investment Details</Heading>
                  <ScrollView width='100%'>
                    <InputTable<PrimaryInvestmentDetail>
                      data={primaryInvestmentData}
                      columns={primaryInvestmentColumns}
                      enableGrouping
                      updateData={updateData(PRIMARY_INVESTMENT_KEY, primaryInvestmentData, 'primaryInvestmentData')}
                      deleteRow={(rowIndex: number) => {
                        dispatch({
                          secondaryInvestmentData: secondaryInvestmentData.filter((o) => o.buyerName !== primaryInvestmentData[rowIndex].shareholderName),
                          primaryInvestmentData: primaryInvestmentData.filter((_, i) => i !== rowIndex),
                        })
                      }}
                      getOptions={getOptions}
                      getCell={getCapTableCells}
                    />
                    <Button
                      onClick={() => {
                        dispatch({
                          type: ADD_NEW_PRIMARY_INVESTMENT_DETAIL,
                        })
                      }}
                      size='small'
                      alignSelf='flex-start'
                      margin='1rem 0 1rem 0'
                    >
                      + Add Investment
                    </Button>
                  </ScrollView>
                </GlassCard>
              )
            } else {
              if (direction === 'back') {
                setStepCounter(stepCounter - 1)
              } else if (direction === 'next' || direction === 'none') {
                setStepCounter(stepCounter + 1)
              }
            }
            break
          case 'Secondary Investment Sale':
            if (transactionType.includes(SECONDARY_INVESTMENT_KEY)) {
              return (
                <GlassCard header='' className='tableCard overlayCard'>
                  <Heading className='transactionModelStepHeader'>Enter Secondary Sale Details</Heading>
                  <ScrollView width='100%'>
                    <InputTable<SecondaryInvestmentDetail>
                      data={secondaryInvestmentData}
                      columns={secondaryInvestmentColumns}
                      enableGrouping
                      updateData={updateData(SECONDARY_INVESTMENT_KEY, secondaryInvestmentData, 'secondaryInvestmentData')}
                      deleteRow={(rowIndex: number) =>
                        dispatch({
                          secondaryInvestmentData: secondaryInvestmentData.filter((_, i) => i !== rowIndex),
                        })
                      }
                      getOptions={getOptions}
                      getErrors={(eIndex, id, value, original) => {
                        if (id === 'totalProceeds') {
                          const v = value as number
                          return { errorMessage: 'Not enough shares to sell.', hasError: original.shareValue < v }
                        }
                        return { errorMessage: '', hasError: false }
                      }}
                    />
                    <Button
                      onClick={() => {
                        const newSi = {
                          existingShareholderKey: `${capTableV1[0].shareholderName}${DELIM}${capTableV1[0].group}`,
                          existingShareholderName: capTableV1[0].shareholderName,
                          existingShareholderGroup: capTableV1[0].group,
                          shareValue: pricePerShare * capTableV1[0].shareCount,
                          numOfSharesSold: pricePerShare ? secondaryCapitalDiff() / pricePerShare : 0,
                          pricePerShare: pricePerShare,
                          totalProceeds: secondaryCapitalDiff(),
                          buyerName: newInvestorsData[0].shareholderName,
                          buyerGroup: newInvestorsData[0].group,
                        }
                        dispatch({
                          secondaryInvestmentData: [...secondaryInvestmentData, newSi],
                        })
                      }}
                      size='small'
                      alignSelf='flex-start'
                      margin='1rem 0 1rem 0'
                    >
                      + Add Sale
                    </Button>
                  </ScrollView>
                </GlassCard>
              )
            } else {
              if (direction === 'back') {
                setStepCounter(stepCounter - 1)
              } else if (direction === 'next' || direction === 'none') {
                setStepCounter(stepCounter + 1)
              }
            }
            break
          case 'Loan Details':
            if (transactionType.includes(LOAN_KEY)) {
              return (
                <GlassCard header='' className='overlayCard'>
                  <Heading className='transactionModelStepHeader'>Enter Loan Details</Heading>
                  <LoanDetailsInput
                    state={liveState.loan}
                    dispatch={(update) => {
                      dispatch({
                        loan: {
                          ...liveState?.loan,
                          ...update,
                        } as LoanDetails,
                      })
                    }}
                  />
                </GlassCard>
              )
            } else {
              if (direction === 'back') {
                setStepCounter(stepCounter - 1)
              } else if (direction === 'next' || direction === 'none') {
                setStepCounter(stepCounter + 1)
              }
            }
            break
        }
        break
      case 'exitAssumptions':
        return (
          <GlassCard header='' className='overlayCard'>
            <Heading className='transactionModelStepHeader'>Enter Exit Assumptions</Heading>
            {renderExitDateInput(false)}
            {renderEvInput({ className: 'assumptionValue' })}
            {renderNetDebtAtExitInput({ className: 'assumptionValue' })}
          </GlassCard>
        )
      case 'seniority':
        return (
          <GlassCard header='' className='overlayCard'>
            <Heading className='transactionModelStepHeader'>Enter Seniority</Heading>
            <Table size='small'>
              <TableBody>
                {finalCapTable.filter((c) => c.type === 'SHARE_HOLDER_TYPE_SERIES').length ? (
                  <TableRow key='header-seniority'>
                    <TableCell colSpan={1} className='groupTableHeader' textAlign='left'>
                      Shareholder
                    </TableCell>
                    <TableCell colSpan={1} className='groupTableHeader' textAlign='left'>
                      Group
                    </TableCell>

                    <TableCell colSpan={1} className='groupTableHeader' textAlign='left'>
                      Seniority Rank
                    </TableCell>
                  </TableRow>
                ) : (
                  <Text className='amplify-label' padding='8px'>
                    None eligible
                  </Text>
                )}
                {finalCapTable
                  .filter((c) => c.type === 'SHARE_HOLDER_TYPE_SERIES')
                  .map((c) => {
                    const key = `${c.shareholderName}${DELIM}${c.group}`
                    return (
                      <TableRow key={`body-seniority ${key}`}>
                        <TableCell colSpan={1} className='tableCell tableCellLabel'>
                          {c.shareholderName}
                        </TableCell>
                        <TableCell colSpan={1} className='tableCell tableCellLabel'>
                          {c.group}
                        </TableCell>
                        <TableCell colSpan={1} className='tableCell'>
                          <NumericFormat
                            label={'Seniority Rank'}
                            labelHidden
                            type='text'
                            inputMode='numeric'
                            pattern='\d*'
                            size='small'
                            thousandSeparator=','
                            customInput={TextField}
                            value={seniorityMap[key] || 1}
                            valueIsNumericString={true}
                            onValueChange={(v) => {
                              const value = parseInt(v.value.replace(/[$,]/g, ''))
                              if (isNaN(value)) return
                              dispatch({
                                seniorityMap: {
                                  ...seniorityMap,
                                  [key]: value,
                                },
                              })
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </GlassCard>
        )
      default:
        return 'Please refresh the page, there was an error setting up your model.'
    }
  }

  const scenarioSelect = () => {
    let value
    if (modelList[index]) value = { label: trxName, value: index }
    return (
      <ReactSelect
        className='scenarioSelect'
        value={value}
        onChange={async (e) => {
          return navigate(`../${e?.value}`)
        }}
        options={modelList.map((m, i) => {
          return { label: m.trxName, value: i }
        })}
      />
    )
  }

  const renderModel = () => {
    return (
      <Flex direction='column' gap='0'>
        <Flex alignSelf='baseline' marginLeft='auto' marginBottom='0.75rem' alignItems='center'>
          <Text fontWeight='600'>Scenario: </Text>
          {scenarioSelect()}
          <Button
            size='small'
            isLoading={isCopying}
            onClick={async () => {
              if (isCopying) return
              setIsCopying(true)
              setTimeout(() => setIsCopying(false), 1000)
              const transactionModelsV1 = await addModel(
                opportunity,
                {
                  ...liveState,
                  trxName: uniqValue(
                    `${trxName} - Copy`,
                    modelList.map((m) => m.trxName)
                  ),
                },
                modelList
              )
              if (!transactionModelsV1) return console.error('Error creating copy model')
              await saveModel({
                transactionModelsV1,
              })
              navigate(`../${modelList.length}`)
            }}
          >
            Copy Scenario
          </Button>
          <Button
            variation='primary'
            size='small'
            onClick={async () => {
              const transactionModelsV1 = await addModel(
                opportunity,
                {
                  trxName: getDefaultTrxName(modelList),
                },
                modelList
              )
              if (!transactionModelsV1) return console.error('Error creating new model')
              await saveModel({
                transactionModelsV1,
              })
              setIsSetupFlow(true)
              setStepCounter(1)
              navigate(`../${modelList.length}`)
            }}
          >
            New Scenario
          </Button>
          <ExportButton
            filename={`trx_model-${opportunity?.name}.xlsx`}
            type={'trxModel'}
            data={{
              seniorityMap,
              calcs,
              breakpoints,
              netDebtAtExit,
              opportunityName: opportunity?.name,
              trxName,
              trxTypes: transactionType,
              exitDate,
              preMoneyEquityValuation,
              valueAdjustments,
              totalPrimaryCapitalInvested,
              // TODO: use round value
              totalPrimaryCapitalInvestedRounded: totalPrimaryCapitalInvested,
              totalSecondaryCapitalInvested,
              // TODO: use round value
              totalSecondaryCapitalInvestedRounded: totalSecondaryCapitalInvested,
              existingShares,
              newOptionsAddedCount:
                methodForCreatingOptionPool === 'investorFriendly' ? finalCapTable?.find((t) => t.shareholderName === 'New Option Pool')?.shareCount || undefined : undefined,
              finalCapTable,
              proformaDetailTable: updateProformaNames(proformaTableData?.detailData || []),
              irrChartValues,
              grossProceedsChartValues,
              grossProceedsPerShareChartValues,
              moicChartValues,
            }}
          />
        </Flex>
        <Flex marginLeft='15rem'>
          <Tabs.Container>
            <Tabs.List className='lightmodeTabs'>
              {PAGES.map((page, i) => {
                return (
                  <Tabs.Item value={`${i}`} key={page.name} title={page.displayName} fontSize='14px' onClick={() => setCurrentPage(page.name)}>
                    {page.displayName}
                  </Tabs.Item>
                )
              })}
            </Tabs.List>
          </Tabs.Container>
        </Flex>
        <Flex gap='0'>
          <View position='relative'>
            {renderEntryAssumptions()}
            {renderNewInvestorInputs()}
            {renderPrimaryInvestmentInputs()}
            {renderConvertibleNotesInputs()}
            {renderSecondarySaleInputs()}
            {renderExitAssumptions()}
            {renderSeniorityInputs()}
          </View>
          <ScrollView width='100%' className='shadow' marginLeft='15rem'>
            {getCurrentPage(currentPage)}
          </ScrollView>
        </Flex>
      </Flex>
    )
  }

  const renderNotFound = () => {
    return (
      <Flex>
        <Text>This transaction Not Found. Check the url or select existing transaction</Text>
        {scenarioSelect()}
      </Flex>
    )
  }
  return (
    <>
      <CompanyHeader />
      <GlassCard header='Transaction Model' level={0}>
        {!modelList[index] ? renderNotFound() : isSetupFlow ? renderSetupFlow() : renderModel()}
      </GlassCard>
    </>
  )
}

export default TransactionModelInput

const primaryCapitalDiff = (totalPrimaryCapitalInvestedState: number, primaryInvestmentDataState: PrimaryInvestmentDetail[]): number => {
  return totalPrimaryCapitalInvestedState - primaryInvestmentDataState.reduce((acc, p) => (p.totalContribution ? acc + p.totalContribution : acc), 0)
}

const sumSeries = (o: number, c: ExistingShareHolder): number => {
  if (c.type !== 'SHARE_HOLDER_TYPE_SERIES') return o
  return o + c.shareCount * c.pricePerShare
}

function findDuplicateInvestorNames(arr: (NewInvestorDetail | ExistingShareHolder)[]) {
  const investorNames = new Set()
  const duplicates: string[] = []

  for (let i = 0; i < arr.length; i++) {
    const investorName = arr[i].shareholderName
    if (investorNames.has(investorName)) {
      duplicates.push(investorName)
    } else {
      investorNames.add(investorName)
    }
  }

  return duplicates
}

function getDefaultTrxName(modelList: ReducerState[]) {
  const defaultName = 'Series X'
  const names = modelList.map((m) => m.trxName)
  return uniqValue(defaultName, names)
}

function getModelState({ localStorage, model, opportunity, modelList }): ReducerState {
  const modelValue = (key, defaultV) => localStorage[key] || model[key] || defaultV
  const defaultObjModelValue = (key, defaultV) => Object.assign({}, defaultV, model[key], localStorage[key])
  const oppValue = (key, defaultV) => localStorage[key] || opportunity?.[key] || defaultV

  const initTransactionType = modelValue('transactionType', MONITORING_KEY)
  const initLoan = initTransactionType.includes(LOAN_KEY) ? defaultObjModelValue('loan', defaultLoan) : undefined
  const initPrimaryCapitalInvested = initTransactionType.includes(PRIMARY_INVESTMENT_KEY) ? 10000000 : 0
  const initSecondaryCapitalInvested = initTransactionType.includes(SECONDARY_INVESTMENT_KEY) ? 10000000 : 0

  return {
    capTableV1: JSON.parse(oppValue('capTableV1', '[]')),
    trxName: modelValue('trxName', getDefaultTrxName(modelList)),
    transactionType: initTransactionType,
    preMoneyEquityValuation: modelValue('preMoneyEquityValuation', 10000000),
    newOptionPoolSize: modelValue('newOptionPoolSize', 0),
    methodForCreatingOptionPool: modelValue('methodForCreatingOptionPool', 'founderFriendly'),
    exitDate: modelValue('exitDate', TODAY),
    netDebtAtExit: modelValue('netDebtAtExit', 0),
    exitEVLowRange: modelValue('exitEVLowRange', 0),
    exitEVHighRange: modelValue('exitEVHighRange', 10000000),
    totalAggregateFees: modelValue('totalAggregateFees', 0),
    roundUp: modelValue('roundUp', false),
    roundPlaces: modelValue('roundPlaces', 4),
    valueAdjustments: modelValue('valueAdjustments', []),
    totalPrimaryCapitalInvested: modelValue('totalPrimaryCapitalInvested', initPrimaryCapitalInvested),
    totalSecondaryCapitalInvested: modelValue('totalSecondaryCapitalInvested', initSecondaryCapitalInvested),
    seniorityMap: modelValue('seniorityMap', {}),
    userEv: modelValue('userEv', 1000000000),
    secondaryInvestmentData: modelValue('secondaryInvestmentData', []),
    primaryInvestmentData: modelValue('primaryInvestmentData', []),
    convertibleNoteData: modelValue('convertibleNoteData', []),
    newInvestorsData: modelValue('newInvestorsData', []),
    userTargetShareholder: modelValue('userTargetShareholder', ''),
    loan: initLoan,
    totalEquityRaised: oppValue('totalEquityRaised', 0),
    lastPreMoneyValuation: oppValue('lastPreMoneyValuation', 0),
    latestDealDate: oppValue('latestDealDate', ''),
  }
}

const addModel = async (opportunity: Opportunity | undefined, modelData: Partial<ReducerState>, modelList) => {
  if (!opportunity) return console.error('No Opportunity')
  const existing = {
    ...modelData,
  }
  delete existing.capTableV1
  delete existing.totalEquityRaised
  delete existing.lastPreMoneyValuation
  delete existing.latestDealDate

  const transactionModelsV1 = [...(opportunity?.transactionModelsV1 || [])]
  const newModel = getModelState({ localStorage, model: existing, opportunity, modelList })
  transactionModelsV1.push(JSON.stringify(newModel || '{}'))
  return removeDuplicateValues(transactionModelsV1)
}
