/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import { Flex, Image } from "@aws-amplify/ui-react"
import { useNavigate } from 'react-router-dom'
import { getOverrideProps } from "../ui-components/utils"
export default function SideNavOld(props) {
  const {
    sideNavHeader = "laybook",
    sideNavBody,
    sideNavFooter,
    overrides,
    ...rest
  } = props;
  const navigate = useNavigate()
  return (
    <Flex
      gap="50px"
      direction="column"
      width="240px"
      height="1043px"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="50px 0px 10px 0px"
      backgroundColor="rgba(32,35,45,1)"
      {...getOverrideProps(overrides, "SideNavOld")}
      {...rest}
    >
      <Flex
        key='sideNavHeader'
        gap="10px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="1px 0px 1px 25px"
        {...getOverrideProps(overrides, "SideNavHeader")}
      >
        <Image src={window.location.pathname.includes('deal') ? '/logoSemiColored.svg' : '/logoSemiColoredBlack.svg'} alt='logo-colored' width='10rem' className='logo' onClick={() => navigate('/profiles')} />
      </Flex>
      <Flex
        key='sideNavHeader2'
        gap="5px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        children={sideNavBody}
        {...getOverrideProps(overrides, "SideNavBody")}
      ></Flex>
      <Flex
        key='sideNavHeader3'
        gap="10px"
        direction="column"
        width="240px"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="10px 0px 10px 0px"
        children={sideNavFooter}
        {...getOverrideProps(overrides, "SideNavFooter")}
      ></Flex>
    </Flex>
  );
}
