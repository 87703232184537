/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function BiData(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="24px"
      height="24px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 24, height: 24 }}
      paths={[
        {
          d: "M16 14L16 4C16 1.832 12.337 0 8 0C3.663 0 0 1.832 0 4L0 14C0 16.168 3.663 18 8 18C12.337 18 16 16.168 16 14ZM8 2C11.691 2 13.931 3.507 14 3.994C13.931 4.493 11.691 6 8 6C4.309 6 2.069 4.493 2 4.006C2.069 3.507 4.309 2 8 2ZM2 6.607C3.479 7.454 5.637 8 8 8C10.363 8 12.521 7.454 14 6.607L14 8.994C13.931 9.493 11.691 11 8 11C4.309 11 2.069 9.493 2 9L2 6.607ZM2 14L2 11.607C3.479 12.454 5.637 13 8 13C10.363 13 12.521 12.454 14 11.607L14 13.994C13.931 14.493 11.691 16 8 16C4.309 16 2.069 14.493 2 14Z",
          fill: "rgba(48,53,69,1)",
          fillRule: "nonzero",
          style: { transform: "translate(16.67%, 12.5%)" },
        },
      ]}
      {...getOverrideProps(overrides, "BiData")}
      {...rest}
    ></Icon>
  );
}
