import * as React from 'react'

import {
  Cell,
  CellTemplate,
  Compatible,
  Uncertain,
  UncertainCompatible,
  getCellProperty,
  getCharFromKey,
  isCharAllowedOnNumberInput,
  isNumpadNumericKey,
  keyCodes,
} from '@silevis/reactgrid'
import { longCurrencyFormat, parseCurrency } from '../utils/utils'
import { onKeyDown } from './MultipleCell'

export interface CurrencyCell extends Cell {
  type: 'currencyCell'
  value: number
  validator?: (value: number) => boolean
  nanToZero?: boolean
  hideZero?: boolean
  errorMessage?: string
}

export class CurrencyCellTemplate implements CellTemplate<CurrencyCell> {
  private wasEscKeyPressed = false

  getCompatibleCell(uncertainCell: Uncertain<CurrencyCell>): Compatible<CurrencyCell> {
    let value: number
    try {
      value = getCellProperty(uncertainCell, 'value', 'number')
    } catch (error) {
      value = NaN
    }
    const displayValue = uncertainCell.nanToZero && Number.isNaN(value) ? 0 : value
    const text = Number.isNaN(displayValue) || (uncertainCell.hideZero && displayValue === 0) ? '' : longCurrencyFormat.format(displayValue)
    return { ...uncertainCell, value: displayValue, text }
  }

  handleKeyDown(
    cell: Compatible<CurrencyCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean,
    key: string
  ): { cell: Compatible<CurrencyCell>; enableEditMode: boolean } {
    if (isNumpadNumericKey(keyCode)) keyCode -= 48
    const char = getCharFromKey(key)
    if (!ctrl && isCharAllowedOnNumberInput(char)) {
      const value = Number(char)

      if (Number.isNaN(value) && isCharAllowedOnNumberInput(char)) return { cell: { ...this.getCompatibleCell({ ...cell, value }), text: char }, enableEditMode: true }
      return { cell: this.getCompatibleCell({ ...cell, value }), enableEditMode: true }
    }
    return { cell, enableEditMode: keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER }
  }

  update(cell: Compatible<CurrencyCell>, cellToMerge: UncertainCompatible<CurrencyCell>): Compatible<CurrencyCell> {
    const value = cellToMerge.value || parseCurrency(cellToMerge.text)
    return this.getCompatibleCell({ ...cell, value })
  }

  private getTextFromCharCode = (cellText: string): string => {
    switch (cellText.charCodeAt(0)) {
      case keyCodes.DASH:
      case keyCodes.FIREFOX_DASH:
      case keyCodes.SUBTRACT:
        return '-'
      case keyCodes.COMMA:
        return ','
      case keyCodes.PERIOD:
      case keyCodes.DECIMAL:
        return '.'
      default:
        return cellText
    }
  }

  getClassName(cell: Compatible<CurrencyCell>, isInEditMode: boolean): string {
    const isValid = cell.validator?.(cell.value) ?? true
    const className = cell.className || ''
    return `${!isValid ? 'rg-invalid' : ''} ${className}`
  }

  render(cell: Compatible<CurrencyCell>, isInEditMode: boolean, onCellChanged: (cell: Compatible<CurrencyCell>, commit: boolean) => void): React.ReactNode {
    if (!isInEditMode) {
      const isValid = cell.validator?.(cell.value) ?? true
      const textToDisplay = !isValid && cell.errorMessage ? cell.errorMessage : cell.text
      return textToDisplay
    }
    const format = new Intl.NumberFormat(window.navigator.languages[0], { useGrouping: false, maximumFractionDigits: 20 })
    return (
      <input
        inputMode='decimal'
        ref={(input) => {
          if (input) {
            input.focus()
            input.setSelectionRange(input.value.length, input.value.length)
          }
        }}
        defaultValue={Number.isNaN(cell.value) ? this.getTextFromCharCode(cell.text) : format.format(cell.value)}
        onChange={(e) => onCellChanged(this.getCompatibleCell({ ...cell, value: parseCurrency(e.currentTarget.value) }), false)}
        onBlur={(e) => {
          onCellChanged(this.getCompatibleCell({ ...cell, value: parseCurrency(e.currentTarget.value) }), !this.wasEscKeyPressed)
          this.wasEscKeyPressed = false
        }}
        onKeyDown={(e) => {
          onKeyDown(e)
          if (e.keyCode === keyCodes.ESCAPE) this.wasEscKeyPressed = true
        }}
        onCopy={(e) => e.stopPropagation()}
        onCut={(e) => e.stopPropagation()}
        onPaste={(e) => e.stopPropagation()}
        onPointerDown={(e) => e.stopPropagation()}
      />
    )
  }
}
