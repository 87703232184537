import { Link } from '@aws-amplify/ui-react'
import MDEditor from '@uiw/react-md-editor'
import { FunctionComponent } from 'react'
import ReactMarkdown from 'react-markdown'

type Props = {
  value: string
  isEditing: boolean
  isLink?: boolean
  onChange: (v: string) => void
}

export const Editable: FunctionComponent<Props> = ({ value, onChange, isEditing, isLink }) => {
  let Type: any = ReactMarkdown
  if (isLink) {
    Type = Link
    Type.defaultProps = {
      isExternal: true,
      href: value,
    }
  }
  return (
    <>
      <Type>{value || 'N/A'}</Type>
      {isEditing && (
        <MDEditor
          preview='edit'
          value={value || 'N/A'}
          onChange={(e) => {
            onChange(e || 'N/A')
          }}
        />
      )}
    </>
  )
}
export default Editable
