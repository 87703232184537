import { Flex, Text, TextField } from '@aws-amplify/ui-react'
import { FunctionComponent } from 'react'
import Select from 'react-select'
import { LOAN_KEY, MONITORING_KEY, PRIMARY_INVESTMENT_KEY, SECONDARY_INVESTMENT_KEY } from '../types'

export const TransactionTypes = {
  [MONITORING_KEY]: 'Monitoring',
  [PRIMARY_INVESTMENT_KEY]: 'Primary',
  [SECONDARY_INVESTMENT_KEY]: 'Secondary',
  // [LOAN_KEY]: 'Venture Debt',
}

interface RoundDetailsProps {
  transactionType: string
  onTransactionTypeChange: (e: string) => void
  trxName: string
  setTrxName: (e: string) => void
}

const RoundDetails: FunctionComponent<RoundDetailsProps> = ({ transactionType, onTransactionTypeChange, trxName, setTrxName }) => {
  const transactionTypeOptions = Object.keys(TransactionTypes).map((i) => {
    return {
      value: i,
      label: TransactionTypes[i as keyof typeof TransactionTypes],
    }
  })

  const transactionTypeValue =
    transactionType === ''
      ? []
      : transactionType.split(',').map((i) => {
          return {
            value: i,
            label: TransactionTypes[i as keyof typeof TransactionTypes],
          }
        })

  return (
    <>
      <Flex className='assumptionsInput' alignItems='baseline' direction='column'>
        <Text className='amplify-label assumptionLabel'>Transaction Type(s)</Text>
        <Select
          isMulti={true}
          value={transactionTypeValue}
          onChange={(e) => onTransactionTypeChange(e.map((e) => e.value).join(','))}
          options={transactionTypeOptions}
          className='transactionTypeSelect'
        />
      </Flex>
      <Flex className='assumptionsInput'>
        <Text className='amplify-label assumptionLabel'>Round Name</Text>
        <TextField
          label='Round name'
          labelHidden={true}
          isRequired={true}
          type='text'
          size='small'
          className='assumptionValue'
          value={trxName}
          onChange={(e) => {
            setTrxName(e.target.value)
          }}
        />
      </Flex>
    </>
  )
}

export default RoundDetails
