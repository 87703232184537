/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function TransactionModelIcon(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="20px"
      height="16.33px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 20, height: 16 }}
      paths={[
        {
          d: "M12.4714 0L12.4714 1.33333L15.1953 1.33333L7.30475 9.22388L4.30475 6.22387L0 10.5286L0.942834 11.4714L4.30475 8.10946L7.30475 11.1095L16.1381 2.27617L16.1381 5L17.4714 5L17.4714 0L12.4714 0Z",
          fill: "rgba(48,53,69,1)",
          fillRule: "nonzero",
          style: { transform: "translate(12.64%, 14.29%)" },
        },
        {
          d: "M1.33333 0L0 0L0 16.3333L20 16.3333L20 15L1.33333 15L1.33333 0Z",
          fill: "rgba(48,53,69,1)",
          fillRule: "nonzero",
          style: { transform: "translate(0%, 0%)" },
        },
      ]}
      {...getOverrideProps(overrides, "TransactionModelIcon")}
      {...rest}
    ></Icon>
  );
}
