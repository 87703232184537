import { Button, Flex, ScrollView, Table, TableBody, TableCell, TableHead, TableRow, Text } from '@aws-amplify/ui-react'
import { FunctionComponent } from 'react'
import ReactMarkdown from 'react-markdown'
import GlassCard from '../figma-components/GlassCard'
import { ProductInfo } from '../models'
import { exportExcel } from '../services/excel'
import { AiOutlineTag, MarketMapIcon, ExcelIcon } from '../ui-components'

const COLUMNS = [
  {label: 'Product', key: 'name', meta: {minWidth: '15rem', isSticky: true, tooltip: 'Name of the product or service'}},
  {label: 'Description', key: 'shortDescription', meta: {minWidth: '25rem', tooltip: 'Details what the product or service is'}},
  {label: 'Target Customer Profile', key: 'targetCustomerProfile', meta: {minWidth: '15rem', removeHeaders: true, tooltip: 'Target audience for the product'}},
  {label: 'Features', key: 'features', meta: {minWidth: '25rem', removeHeaders: true, tooltip: 'Outlines all features and use-cases for the given product'}},
  {label: 'Revenue Model', key: 'revenueModel', meta: {minWidth: '15rem', removeHeaders: true, tooltip: 'Method(s) of generating revenue by the product'}},
  {label: 'Delivery Model', key: 'deliveryModel', meta: {minWidth: '15rem', removeHeaders: true, tooltip: 'Channels by which the product delivers value to customers'}},
]

interface ProductTableProps {
  products: ProductInfo[]
}

const ProductTable: FunctionComponent<ProductTableProps> = ({ products }) => {
  const cellTopLeftStyle = { verticalAlign: 'top', textAlign: 'left' } as any
  const tag = (key: string, text: string) => (
    <Flex alignItems='center' gap='4px' className='taxonomyTag' width='fit-content' key={text}>
      { key === 'taxonomiesV2' && <AiOutlineTag /> }
      { key === 'markets' && <MarketMapIcon /> }
     <Text>{text}</Text>
    </Flex>
  )
  const buttonRow = (
    <Button
      variation='primary'
      size='small'
      gap='0.5rem'
      className='excel'
      onClick={() => {
        exportExcel({
          sheetName: 'Products',
          filename: `Products.xlsx`,
          data: products.map((p) => ({
            Product: p.name,
            Taxonomies: p.taxonomiesV2?.join(', ') || 'N/A',
            Markets: p.markets?.join(', ') || 'N/A',
            Description: p.shortDescription || 'N/A',
            'Target Customer Profile': p.targetCustomerProfile || 'N/A',
            Features: p.featuresV2 || 'N/A',
            'Revenue Model': p.revenueModel || 'N/A',
            'Delivery Model': p.deliveryModel || 'N/A',
          })),
        })
      }}
    >
      <ExcelIcon />
      Export to Excel
    </Button>
  )

  return (
    <GlassCard header='Product Details' headerButtons={buttonRow}>
      <ScrollView width='100%'>
        <Table size='small' variation='bordered' className='outputTable'>
          <TableHead>
            <TableRow>
              {
                COLUMNS.map(({ label, key, meta }) => {
                  return <TableCell key={key} as='th' minWidth={meta.minWidth} className={`tooltip down ${meta.isSticky ? 'sticky' : ''}`} data-tooltip={meta.tooltip}>{label}</TableCell>
                })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              products.map((product, index) => {
                return (
                  <TableRow key={product.name + index}>
                    {
                      COLUMNS.map(({ label, key, meta }) => {
                        let value = product[key]
                        if (key === 'name') {
                          return (
                            <TableCell key={label + key + product.name} style={cellTopLeftStyle} className='sticky'>
                              <Flex direction='column' gap='2rem'>
                                <ReactMarkdown>{`**${value}**`}</ReactMarkdown>
                                {
                                  product.taxonomiesV2 &&
                                  <div>
                                    <Text className='amplify-label'>Taxonomies</Text>
                                    <Flex className='taxonomyTagContainer'>{product.taxonomiesV2.map((i) => tag('taxonomiesV2', i || 'N/A'))}</Flex>
                                  </div>
                                }
                                {
                                  product.markets &&
                                  <div>
                                    <Text className='amplify-label'>Markets</Text>
                                    <Flex className='taxonomyTagContainer'>{product.markets.map((i) => tag('markets', i || 'N/A'))}</Flex>
                                  </div>
                                }
                              </Flex>
                            </TableCell>
                          )
                        }
                        if (key === 'features') {
                          value = product.featuresV2 || product.features || 'N/A'
                        }
                        if (meta.removeHeaders) {
                          const v = value || 'N/A'
                          value =
                            v
                              .split('\n')
                              .filter((i) => /^[ \t]*[-*] .*(\n[ \t]+.*)*(?=\n\n|$)/gm.test(i))
                              .map((i) => i.replace(/\*\*/g, ''))
                              .join('\n') ||
                            v.replace(/\*\*/g, '') ||
                            v
                        }
                        return (
                          <TableCell key={label + key + product.name} style={cellTopLeftStyle}>
                            <ReactMarkdown>{value}</ReactMarkdown>
                          </TableCell>
                        )
                      })
                    }
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </ScrollView>
    </GlassCard>
  )
}

export default ProductTable
