import { Button, Flex, Loader, SelectField, Text, TextField, ScrollView } from '@aws-amplify/ui-react'
import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { utils } from 'xlsx'
import ListInfoTable from '../components/ListInfoTable'
import { getGroupNames, useSetUser, useUser } from '../contexts/userContext'
import { useCompanyInfoV2List } from '../hooks/useCompanyInfoV2List'
import { exportExcel } from '../services/excel'
import { ExcelIcon } from '../ui-components'
import { getMonthsBetweenDates, taxonomiesFromInfo } from '../utils/utils'

type ListInfoPageType = {
  handlePageUpdate: () => void
}
let saveTimeout: NodeJS.Timeout | undefined

const ListInfoPage: FunctionComponent<ListInfoPageType> = ({ handlePageUpdate }) => {
  const user = useUser()
  const setUser = useSetUser()
  const navigate = useNavigate()
  const groups = user?.groups ?? []
  const groupNames = getGroupNames(user)
  const [selectedGroup, setGroup] = useState<string>(groupNames[0])
  const [saving, setSaving] = useState<boolean>(false)
  const nameRef = useRef<HTMLInputElement>(null)
  const selectedGroupNames = groups.filter((g) => g.groupName === selectedGroup).map((i) => i.name)
  const infoList = useCompanyInfoV2List(selectedGroupNames)
  const [headerEdited, setEdited] = useState<boolean>(false)
  const [headers, setHeader] = useState<string[]>([])

  const initExtraDataMap = (user?.listInfo?.[0]?.data || []).reduce((p, c) => {
    try {
      p[c.name] = JSON.parse(c.jsonData || '{}')
    } catch (error) {
      console.error('error parsing json from list info', c.jsonData, error)
    }
    return p
  }, {})
  const [newExtraDataMap, setExtraDataMap] = useState<Record<string, Record<string, string | number>>>({})

  const extraDataMap = { ...initExtraDataMap, ...newExtraDataMap }
  const data = infoList
    .map(({ data }) => {
      if (!data) return null
      const name = data.companyLabel || 'Unknown'
      const monthsSinceLastDeal = getMonthsBetweenDates(new Date(data.cb_lastFundingDate || 0), new Date())
      const taxonomy = taxonomiesFromInfo(data)
      return {
        target: name,
        companyName: data.companyName,
        description: data.cb_fullDescription || 'N/A',
        fte: data.cb_numberOfEmployees || 0,
        fteGrowth1yr: data.fullTimeEmployeeCountGrowth1y || 0,
        totalCapitalRaised: data.cb_totalFundingAmount || 0,
        keyInvestors: data.cb_top5Investors ? data.cb_top5Investors.split(',').join(', ') : 'N/A',
        timeSinceLastRaise: data.cb_lastFundingDate ? monthsSinceLastDeal : 'N/A',
        location: data.headquartersLocation?.replace(/-? ?undefined,?/g, '') || 'N/A',
        lastFundingType: data.cb_lastFundingType || 'N/A',
        lastFundingDate: data.cb_lastFundingDate || 'N/A',
        lastFundingAmount: data.cb_lastFundingAmount || null,
        lastEquityFundingType: data.cb_lastEquityFundingType || 'N/A',
        lastEquityValuation: data.cb_lastEquityValuation || null,
        numFundingRounds: data.cb_numFundingRounds || 'N/A',
        revEstRange: data.cyberdb_revenues || data.cb_estimatedRevenueRange || 0,
        taxonomy: taxonomy || [],
        gtm: data?.ai_goToMarketChannels?.split(',') || [],
        // fteGrowth: data.fteGrowth || 'N/A',
        ...extraDataMap[name],
      }
    })
    .filter((i) => i)

  // hack to get around group names and headers being empty first
  useEffect(() => {
    if (!selectedGroup && groupNames.length) {
      setGroup(groupNames[groupNames.length - 1])
    }
    if (!headerEdited && !headers.length && user?.listInfo?.[0]?.headers) {
      setHeader(user.listInfo[0].headers)
    }
  }, [groupNames, headers.length, selectedGroup, user?.listInfo, headerEdited])

  if (!groups.length) {
    return (
      <Text>
        Create a company list to view their transaction metrics.{' '}
        <Link to='' className='amplify-link' onClick={() => handlePageUpdate()}>
          Click here to create a company list.
        </Link>
      </Text>
    )
  }

  if (!user) return <Loader />

  const saveClicked = async () => {
    clearTimeout(saveTimeout)
    setSaving(true)
    await setUser({
      listInfo: [
        {
          headers,
          data: Object.keys(extraDataMap).map((key) => {
            return {
              name: key,
              jsonData: JSON.stringify(extraDataMap[key]),
            }
          }),
        },
      ],
    })
      .catch((e) => console.error('setUser error', e))
      .finally(() => setSaving(false))
  }

  const backgroundSave = async () => {
    clearTimeout(saveTimeout)
    saveTimeout = setTimeout(() => {
      saveClicked()
    }, 5000)
  }

  const exportClicked = async () => {
    exportExcel({
      sheetName: 'Info',
      filename: 'Saved List.xlsx',
      data: data.map((d) => {
        return {
          'Company Profile': `https://app.playbookmna.com/profiles/${d.target}`,
          'Company': d.target,
          'Description': d.description,
          'FTE': d.fte,
          'FTE Growth (1yr)': d.fteGrowth1yr,
          'Total Funding Amount': d.totalCapitalRaised,
          'Key Investors': d.keyInvestors,
          'Months Since Last Raise': d.timeSinceLastRaise,
          'HQ Location': d.location,
          'Last Funding Type': d.lastFundingType,
          'Last Funding Date': d.lastFundingDate,
          'Last Funding Amount': d.lastFundingAmount,
          'Last Equity Funding Type': d.lastEquityFundingType,
          'Last Equity Valuation (Pre-Money)': d.lastEquityValuation,
          'Number of Funding Rounds': d.numFundingRounds,
        }
      }),
      format: (ws) => {
        for (let row = 0; row <= data.length; row++) {
          const cellPos = utils.encode_cell({
            c: 0,
            r: row,
          })
          ws[cellPos].l = {
            Target: ws[cellPos].v,
          }
        }
        return ws
      },
    })
  }

  const companyClicked = (targetCompany: string) => {
    navigate(`/profiles/${targetCompany}`)
  }

  const newColClicked = () => {
    const newColName = nameRef.current?.value
    if (!newColName) return alert('Missing metric name')
    if (headers.includes(newColName)) return alert(`Metric with name ${newColName} already exists. Please choose a different name`)
    setHeader([...headers, newColName])
    nameRef.current.value = ''
  }

  const cellChanged = (index, header, value) => {
    const name = data[index].target
    setExtraDataMap({
      ...extraDataMap,
      [name]: {
        ...extraDataMap[name],
        [header]: value,
      },
    })
    backgroundSave()
  }

  const headerChanged = (index, newHeader) => {
    const update = [...headers]
    const oldHeader = headers[index]
    update[index] = newHeader
    if (newHeader === oldHeader) return
    Object.keys(extraDataMap).forEach((key) => {
      extraDataMap[key][newHeader] = extraDataMap[key][oldHeader]
      delete extraDataMap[key][oldHeader]
    })
    setExtraDataMap({ ...extraDataMap })
    setHeader(update)
    setEdited(true)
    backgroundSave()
  }

  const headerDeleted = (header) => {
    setHeader(headers.filter((h) => h !== header))
    Object.keys(extraDataMap).forEach((key) => {
      delete extraDataMap[key][header]
    })
    setExtraDataMap({ ...extraDataMap })
    setEdited(true)
  }

  return (
    <Flex direction='column'>
      <Flex alignItems='center' gap='2rem'>
        <SelectField
          flex={1}
          placeholder='Select Company List'
          labelHidden
          size='small'
          maxWidth='20rem'
          label={'Select Company List'}
          options={groupNames}
          value={selectedGroup}
          onChange={(e) => {
            setGroup(e.target.value)
          }}
        />
        {selectedGroup && (
          <>
            <Flex border='1px solid #D4D4D4' padding='0.25rem' borderRadius='0.25rem'>
              <TextField placeholder='Enter new metric name' size='small' autoComplete='off' label={'New metric name'} labelHidden type='text' ref={nameRef} />
              <Button size='small' onClick={newColClicked}>
                {' '}
                + Add Metric
              </Button>
            </Flex>
            <Button variation='primary' size='small' className='excel' gap='0.5rem' onClick={exportClicked}>
              <ExcelIcon />
              Export to Excel
            </Button>
            <Button variation='primary' size='small' marginLeft='auto' alignSelf='center' className='save' onClick={saveClicked} isLoading={saving}>
              Save Changes
            </Button>
          </>
        )}
      </Flex>
      {selectedGroup && (
        <ScrollView width='100%'>
          <ListInfoTable
            data={data}
            extraHeaders={headers}
            companyClicked={companyClicked}
            cellChanged={cellChanged}
            headerChanged={headerChanged}
            deleteHeaderClicked={headerDeleted}
          />
        </ScrollView>
      )}
    </Flex>
  )
}

export default ListInfoPage
