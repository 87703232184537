import { Table, TableBody, TableCell, TableHead, TableRow } from '@aws-amplify/ui-react'
import { FunctionComponent } from 'react'
import { currencyFormatterShort } from '../utils/utils'

type ChartData = {
  announceDate?: Date
  name?: string
  type?: string
  leadInvestor?: string
  acquisitionTerms?: string
  price?: number
}
type CompsViewProps = {
  data: ChartData[]
}

const AcquisitionTrxTable: FunctionComponent<CompsViewProps> = ({ data }) => {
  const tableBody = data.map((d) => {
    return (
      <TableRow key={`${d.name} ${d?.announceDate}`}>
        <TableCell>{d?.announceDate?.toLocaleString('en-US', { year: 'numeric', month: 'long' }) || 'N/A'}</TableCell>
        <TableCell>{d?.name}</TableCell>
        <TableCell>{d?.type || 'Unknown'}</TableCell>
        <TableCell>{d?.leadInvestor || 'Unknown'}</TableCell>
        <TableCell>{d?.acquisitionTerms || 'Unknown'}</TableCell>
        <TableCell>{currencyFormatterShort(d.price) || ''}</TableCell>
      </TableRow>
    )
  })
  return (
    <Table caption='' highlightOnHover={true} variation='striped' size='small'>
      <TableHead>
        <TableRow>
          <TableCell as='th'>Year Announced</TableCell>
          <TableCell as='th'>Org Name</TableCell>
          <TableCell as='th'>Type</TableCell>
          <TableCell as='th'>Lead Investor</TableCell>
          <TableCell as='th'>Terms</TableCell>
          <TableCell as='th'>Price</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{tableBody}</TableBody>
    </Table>
  )
}

export default AcquisitionTrxTable
