import { Card, Flex, Heading, Link, Loader, ScrollView, Text } from '@aws-amplify/ui-react'
import { FunctionComponent } from 'react'
import ReactMarkdown from 'react-markdown'
import GlassCard from '../figma-components/GlassCard'
import MarketLandscape from './MarketLandscape'
import { useMarketInfoV1List } from '../hooks/useMarketInfoV1List'
import { MarketDrivers, MarketInfoV1, MarketRisks } from '../models'
import { currencyFormatterShort, percentFormatter } from '../utils/utils'
import FeatureRequest from './FeatureRequest'

interface Props {
  companyName: string
  marketDrivers: MarketDrivers[]
  marketRisks: MarketRisks[]
  marketNames: string[]
  marketLandscape?: string[]
}

const MarketSummary: FunctionComponent<Props> = ({ marketDrivers, marketRisks, marketNames, companyName, marketLandscape = [] }) => {
  const markets = useMarketInfoV1List(marketNames)

  if (marketDrivers.length === 0 && marketRisks.length === 0 && marketNames.length === 0) return <FeatureRequest featureName='marketInfo' info={{ companyName }} />

  markets.sort((a, b) => {
    if (!a.data || !b.data) return 0
    return (b.data.toValue || 0) - (a.data.toValue || 0)
  })

  const driver = (driver: MarketDrivers) => (
    <GlassCard key={driver.summary} expandable={true} level={2} header={driver.summary || 'N/A'} className='marketCard'>
      <ReactMarkdown>{driver.details || 'N/A'}</ReactMarkdown>
    </GlassCard>
  )
  const drivers = (
    <GlassCard flex={1} header='Market Drivers of Growth' className='overlayCard'>
      {marketDrivers.map(driver)}
    </GlassCard>
  )

  const risk = (risk: MarketRisks) => (
    <GlassCard key={risk.summary} expandable={true} level={2} header={risk.summary || 'N/A'} className='marketCard'>
      <ReactMarkdown>{risk.details || 'N/A'}</ReactMarkdown>
      <Card className='mitigantCard'>
        <Flex direction='column' gap={1}>
          <Text>Mitigant</Text>
          <ReactMarkdown>{risk.mitigation || 'N/A'}</ReactMarkdown>
        </Flex>
      </Card>
    </GlassCard>
  )

  const risks = (
    <GlassCard flex={1} header='Market Risks' className='overlayCard'>
      {marketRisks.map(risk)}
    </GlassCard>
  )

  const marketCard = (market: MarketInfoV1) => {
    let source = market.sources?.[0]
    if (!source) source = `https://www.marketsandmarkets.com/search.asp?search=${encodeURIComponent(market.marketName + ' Market')}`
    return (
      <GlassCard header={market.marketName} level={2} key={market.marketName} minWidth='258px' maxWidth='258px' className='marketSummaryCard overlayCard'>
        <Flex direction='column' gap='0.25rem'>
          <Heading className='marketSummaryHeading'>Market Size</Heading>
          <Text className='marketSummaryMetric'>{market.toValue ? currencyFormatterShort(market.toValue) : 'N/A'}</Text>
          <Text className='marketSummaryMetricDescription'>Expected size by {market.toYear}</Text>
          <Heading className='marketSummaryHeading' marginTop='1rem'>
            Market Growth
          </Heading>
          <Text className='marketSummaryMetric'>{market.cagr ? percentFormatter(parseFloat(market.cagr) / 100) : 'N/A'}</Text>
          <Text className='marketSummaryMetricDescription'>
            Growth from {market.fromYear} - {market.toYear}
          </Text>
        </Flex>
        <Flex className='marketSummarySource'>
          <Text>Source:</Text>
          <Link isExternal={true} href={source}>
            {source}
          </Link>
        </Flex>
      </GlassCard>
    )
  }
  const marketRow = markets.map((m, i) => {
    if (!m.data) return <Loader key={i} />
    return marketCard(m.data)
  })

  const renderDriversRisks = () => {
    if (marketDrivers.length === 0 && marketRisks.length === 0) return <FeatureRequest featureName='marketInfo' info={{ companyName }} />
    return (
      <Flex>
        {marketDrivers.length > 0 && drivers}
        {marketRisks.length > 0 && risks}
      </Flex>
    )
  }

  return (
    <Flex direction='column'>
      <GlassCard header='Market Summary'>
        <ScrollView width='100%'>
          <Flex overflow='scroll'>{marketRow.length > 0 && marketRow}</Flex>
        </ScrollView>
      </GlassCard>
      <GlassCard header='Market Drivers and Risks'>{renderDriversRisks()}</GlassCard>
      {marketLandscape && <MarketLandscape markets={marketLandscape} />}
    </Flex>
  )
}
export default MarketSummary
