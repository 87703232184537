/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function CloseIcon(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="20px"
      height="20px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 20, height: 20 }}
      paths={[
        {
          d: "M20 1.32073L18.6792 0L10 8.67915L1.32085 0L0 1.32073L8.67921 9.99994L0 18.6791L1.32085 19.9999L10 11.3207L18.6792 19.9999L20 18.6791L11.3208 9.99994L20 1.32073Z",
          fill: "rgba(48,53,69,1)",
          fillRule: "nonzero",
          style: { transform: "translate(0%, 0%)" },
        },
      ]}
      {...getOverrideProps(overrides, "CloseIcon")}
      {...rest}
    ></Icon>
  );
}
