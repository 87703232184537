import { Flex, Link, Text } from '@aws-amplify/ui-react'
import EquityCalculator from '../pages/EquityCalculator'
import MissingDataCard from './missingDataCard'

export default function PublicApp(): JSX.Element {
  // @ts-ignore
  window.pendo.initialize()

  let page = (
    <Flex justifyContent='center' alignItems='center' height='100vh'>
      <MissingDataCard>
        <Text>Invalid link.</Text>
        <Text>
          Go to back to <Link href='https://app.playbookmna.com'>homepage</Link>
        </Text>
      </MissingDataCard>
    </Flex>
  )

  // TODO: remove empty page handler, added because Invalid Link card would show for a few milliseconds if companyName was null
  if (window.location.pathname.includes('/equity-calculator')) {
    page = <></>
  }

  if (window.location.pathname.includes('/equity-calculator')) {
    const urlParams = new URLSearchParams(window.location.search)
    let companyName = window.location.pathname.split('/equity-calculator/')[1] || urlParams.get('companyName')
    if (!companyName) {
      // TODO: remove hardcoded default
      window.location.href = '/equity-calculator/attackiq'
    } else {
      page = <EquityCalculator companyName={companyName} />
    }
  }

  return page
}
