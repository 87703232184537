import { Image, View } from '@aws-amplify/ui-react'
import { Navigate, Outlet } from 'react-router-dom'
import OpportunityName from './components/OpportunityName'
import RootLayout from './layouts/RootLayout'
import AdminPage from './pages/AdminPage'
import CRMPage from './pages/CRMPage'
import CompanyProfilePageV2 from './pages/CompanyProfilePageV2'
import DealsListPage from './pages/DealsListPage'
import DocChatPage from './pages/DocChatPage'
import DocListPage from './pages/DocListPage'
import FinancialPage from './pages/FinancialPage'
import SearchPage from './pages/HomePage'
import ListsPage from './pages/ListsPage'
import ProfileReviewPage from './pages/ProfileReviewPage'
import TransactionModel from './pages/TransactionModel'
import UserProfile from './pages/UserProfile'
import ValuationPage from './pages/ValuationPage'
import { BsBookmark, CompanyIcon, FilesIcon, HomeIcon, MenuLabel, MetricsBenchmarkingIcon, TransactionModelIcon, ValuationCompsIcon, AdminIcon } from './ui-components'

export const ADMIN = 'Upload Admin'
export const AR_REVIEW = 'A/R Review'
export const BOOKING_ANALYSIS = 'Booking Analysis'
export const COMPANY_PROFILES = 'Company Profile'
export const COMPANY_PROFILES_REVIEW = 'Profile Review'
export const COMPANY_PROFILE_HOME = 'Company Profiles'
export const PROFILE_VIEW_DIVIDER = 'Profile View'
export const SAVED_LISTS = 'Saved Lists'
export const CURRENT_PIPELINE_SNAPSHOT = 'Current Pipeline Snapshot'
export const DOC_AI = 'Document AI'
export const FINANCIAL_STATEMENTS = 'Financial Statements'
export const HR_DIVERSITY_SCORECARD = 'HR Diversity Scorecard'
export const HR_REVIEW = 'HR Review'
export const MARKET_DIVIDER = 'Profile View'
export const METRICS_AND_BENCHMARKING = 'Metrics & Benchmarking'
export const CRM = 'CRM'
export const PIPELINE_ANALYSIS = 'Pipeline Analysis'
export const PRICING_REVIEW = 'Pricing Review'
export const RECURRING_REVENUE_ANALYSIS = 'Recurring Revenue Analysis'
export const TARGET_PUBLIC_BENCHMARK = 'Financials'
export const DEAL_DIVIDER = 'My Workspace'
export const TRANSACTION_MODEL = 'Transaction Model'
export const USER_PROFILE = 'User Profile'
export const VALUATION = 'Valuation'
export const DOCUMENTATION = 'Documentation'
export const DEAL = 'Profile'
export const DEALS_LIST = 'My Deals'
export const CURRENT_DEAL = 'deal'
export const NAV_TRIAL = 'Trial'
export const NAV_DEMO = 'Demo'
export const NAV_ALL = 'all'
export const DOC_CHAT = 'doc chat'
export type SiteMap = {
  name: string
  menuElement?: React.ReactNode
  invisible?: boolean
  enabled?: boolean
  icon?: React.ReactNode
  path: string
  children?: SiteMap[]
}

export function getPathTo(name: string, params: { targetCompany?: string; opportunityId?: string; chatId?: string } = {}) {
  const siteMap = getSiteMap(NAV_ALL)
  // iterate through siteMap to find name and print path to that name
  for (let i = 0; i < siteMap.length; i++) {
    const parent = siteMap[i]
    if (parent.name === name) {
      return populateURL(`/${parent.path}`, params)
    }
    const children = parent.children
    if (children) {
      for (let j = 0; j < children.length; j++) {
        const child = children[j]
        if (child?.name === name) {
          return populateURL(`/${parent.path}/${child.path}`, params)
        }
      }
    }
  }

  throw new Error(`Could not find path to ${name}`)
}

function populateURL(path: string, params: Record<string, string>) {
  let newPath = path
  for (const key in params) {
    newPath = newPath.replace(`:${key}`, params[key])
  }
  return newPath
}

export function getRoutes() {
  return [
    {
      path: '',
      element: <Navigate to='/profiles' />,
    },
    {
      path: '/',
      element: <RootLayout />,
      children: [
        {
          path: 'profiles',
          element: <Outlet />,
          children: [
            {
              path: '',
              element: <SearchPage />,
            },
            {
              path: ':targetCompany',
              element: <CompanyProfilePageV2 mode='public' />,
            },
          ],
        },
        {
          path: 'lists',
          element: <ListsPage />,
        },
        {
          path: 'deals',
          element: <DealsListPage />,
        },
        {
          path: 'deal',
          element: <Outlet />,
          children: [
            {
              path: ':opportunityId/target_profile',
              element: <CompanyProfilePageV2 mode='private' />,
            },
            {
              path: ':opportunityId/financials',
              element: <FinancialPage />,
            },
            {
              path: ':opportunityId/valuation',
              element: <ValuationPage />,
            },
            {
              path: ':opportunityId/transaction_model',
              element: <Outlet />,
              children: [
                {
                  path: '',
                  element: <Navigate to='0' />,
                },
                {
                  path: ':scenarioId',
                  element: <TransactionModel />,
                },
              ],
            },
            {
              path: ':opportunityId/document_chat',
              element: <Outlet />,
              children: [
                {
                  path: '',
                  element: <DocListPage />,
                },
                {
                  path: ':chatId',
                  element: <DocChatPage />,
                },
              ],
            },
          ],
        },
        {
          path: 'review/:targetCompany',
          element: <ProfileReviewPage />,
        },
        {
          path: 'admin',
          element: <AdminPage />,
        },
        {
          path: 'crm',
          element: <CRMPage />,
        },
        {
          path: 'user_profile',
          element: <UserProfile />,
        },
      ],
    },
  ]
}
export function getSiteMap(type: string): SiteMap[] {
  const site = [
    {
      name: PROFILE_VIEW_DIVIDER,
      enabled: true,
      menuElement: (
        <View key='PROFILE_VIEW_DIVIDER' className='menuitem divider'>
          <MenuLabel name={PROFILE_VIEW_DIVIDER} />
        </View>
      ),
      path: '',
    },
    {
      name: COMPANY_PROFILE_HOME,
      path: 'profiles',
      icon: <CompanyIcon />,
    },
    {
      name: COMPANY_PROFILES,
      invisible: true,
      path: 'profiles/:targetCompany',
      icon: <CompanyIcon />,
    },
    {
      name: SAVED_LISTS,
      path: 'lists',
      icon: <BsBookmark />,
    },
    {
      name: DEAL_DIVIDER,
      enabled: true,
      menuElement: (
        <View key='DEAL_DIVIDER' className='menuitem divider'>
          <MenuLabel name={DEAL_DIVIDER} />
        </View>
      ),
      path: '',
    },
    {
      name: DEALS_LIST,
      path: 'deals',
      icon: <HomeIcon />,
    },
    {
      name: CURRENT_DEAL,
      path: 'deal',
      menuElement: <OpportunityName />,
      children: [
        {
          name: DEAL,
          path: ':opportunityId/target_profile',
          icon: <CompanyIcon />,
        },
        {
          name: TARGET_PUBLIC_BENCHMARK,
          path: ':opportunityId/financials',
          icon: <TransactionModelIcon />,
        },
        {
          name: VALUATION,
          path: ':opportunityId/valuation',
          icon: <MetricsBenchmarkingIcon />,
        },
        {
          name: TRANSACTION_MODEL,
          path: ':opportunityId/transaction_model',
          icon: <ValuationCompsIcon />,
        },
        {
          name: DOC_AI,
          path: ':opportunityId/document_chat',
          icon: <FilesIcon />,
        },
        {
          name: DOC_CHAT,
          invisible: true,
          path: ':opportunityId/document_chat/:chatId',
        },
      ],
    },
    {
      name: DOCUMENTATION,
      path: 'documentation',
    },
    {
      name: ADMIN,
      path: 'admin',
    },
    {
      name: CRM,
      path: 'crm',
      icon: <AdminIcon />,
    },
    {
      name: METRICS_AND_BENCHMARKING,
      path: 'metrics_and_benchmarking',
      children: [
        {
          name: FINANCIAL_STATEMENTS,
          path: 'financial_statements',
        },
        {
          name: RECURRING_REVENUE_ANALYSIS,
          path: 'recurring_revenue_analysis',
        },
        {
          name: BOOKING_ANALYSIS,
          path: 'booking_analysis',
        },
        {
          name: PIPELINE_ANALYSIS,
          path: 'pipeline_analysis',
        },
        {
          name: CURRENT_PIPELINE_SNAPSHOT,
          path: 'current_pipeline_snapshot',
        },
        {
          name: PRICING_REVIEW,
          path: 'pricing_review',
        },
        {
          name: AR_REVIEW,
          path: 'ar_review',
        },
        {
          name: HR_REVIEW,
          path: 'hr_review',
        },
        {
          name: HR_DIVERSITY_SCORECARD,
          path: 'hr_diversity_scorecard',
        },
      ],
    },
    {
      name: USER_PROFILE,
      path: 'user_profile',
    },
  ]

  if (type === NAV_DEMO || type === NAV_ALL) {
    site.forEach((item: SiteMap) => {
      if ([ADMIN, USER_PROFILE].includes(item.name)) return
      item.enabled = true
      if (item.children) {
        item.children.forEach((child) => {
          child.enabled = true
        })
      }
    })
  } else if (type === NAV_TRIAL) {
    const enabled = [COMPANY_PROFILE_HOME, SAVED_LISTS, DEALS_LIST, CURRENT_DEAL, DEAL, DOC_AI, CRM]

    site.forEach((item: SiteMap) => {
      if (enabled.includes(item.name)) {
        item.enabled = true
      }
      if (item.children) {
        item.children.forEach((child) => {
          if (enabled.includes(child.name)) {
            child.enabled = true
          }
        })
      }
    })
  }

  return site
}
