import App from '../App'
import PublicApp from './PublicApp'

export default function AppSelect(): JSX.Element {
  return isPublic() ? <PublicApp /> : <App />
}

function isPublic() {
  return window.location.pathname.includes('/equity-calculator')
}
