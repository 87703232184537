/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function LogoutIcon(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="20px"
      height="20px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 20, height: 20 }}
      paths={[
        {
          d: "M2.5518 5.40224L13.9908 5.40224L13.9908 5.4022L13.9908 4.06919L13.9908 4.06914L2.5518 4.06914L5.67835 0.942593L5.67835 0.942552L4.73571 0L0 4.73567L4.1635e-05 4.73571L0 4.73571L4.73571 9.47142L5.67835 8.52883L5.67835 8.52879L2.5518 5.40224Z",
          fill: "rgba(48,53,69,1)",
          fillRule: "nonzero",
          style: { transform: "translate(0%, 26.32%)" },
        },
        {
          d: "M0 0L0 1.33305L12.664 1.33305L12.664 18.6627L0 18.6627L0 19.9958L13.9971 19.9958L13.9971 0L0 0Z",
          fill: "rgba(48,53,69,1)",
          fillRule: "nonzero",
          style: { transform: "translate(30.01%, 0%)" },
        },
      ]}
      {...getOverrideProps(overrides, "LogoutIcon")}
      {...rest}
    ></Icon>
  );
}
