import { FunctionComponent } from 'react'
import { useConfigTable } from '../hooks/useConfigTable'
import { Flex, Heading } from '@aws-amplify/ui-react'
import GenericTableView from '../components/GenericTableView'
import LoadingWaiter from '../components/LoadingWaiter'

const capitalRaiseHeaders = [
  { name: 'Announced Date', type: 'shortDate', key: 'announced_date' },
  { name: 'Transaction Name', type: 'string', key: 'transaction_name' },
  { name: 'Money Raised (USD)', type: 'currencyFormatterShort', key: 'money_raised_currency_in_usd' },
  { name: 'Pre-Money Valuation (USD)', type: 'currencyFormatterShort', key: 'pre_money_valuation_currency_in_usd' },
  { name: 'Organization Location', type: 'string', key: 'organization_location' },
  { name: 'Funding Type', type: 'string', key: 'funding_type' },
  { name: 'Organization Industries', type: 'string', key: 'organization_industries' },
  { name: 'Funding Stage', type: 'string', key: 'funding_stage' },
  { name: 'Lead Investors', type: 'string', key: 'lead_investors' },
  { name: 'Investors', type: 'string', key: 'investor_names' },
  { name: 'Number of Partner Investors', type: 'string', key: 'number_of_partner_investors' },
]

const acquisitionHeaders = [
  { name: 'Announced Date', type: 'shortDate', key: 'announced_date' },
  { name: 'Acquiree Name', type: 'string', key: 'acquiree_name' },
  { name: 'Acquisition Type', type: 'string', key: 'acquisition_type' },
  { name: 'Acquirer Name', type: 'string', key: 'acquirer_name' },
  { name: 'Acquisition Terms', type: 'string', key: 'acquisition_terms' },
  { name: 'Price (USD)', type: 'currencyFormatterShort', key: 'price_currency_in_usd' },
]
const today = new Date()
today.setMonth(today.getMonth() - 12)
const TwelveMonthsAgo = today.toISOString().split('T')[0]

const TransactionList: FunctionComponent = () => {
  const [cpTrx, loadingCP, errorCP] = useConfigTable('cyber_transaction_capitalraise', {
    op: '>',
    key: 'announced_date',
    value: TwelveMonthsAgo,
    sortKey: 'announced_date',
    sortOrder: 'desc',
    limit: 10,
  })
  const [aTrx, loadingATrx, errorA] = useConfigTable('cyber_transactions_acquisition', {
    op: '>',
    key: 'announced_date',
    value: TwelveMonthsAgo,
    sortKey: 'announced_date',
    sortOrder: 'desc',
    limit: 10,
  })

  return (
    <Flex direction='column' overflow='scroll'>
      <Heading level={4}>Latest Capital Raise Transactions</Heading>
      <LoadingWaiter isLoading={loadingCP.get} error={errorCP.get}>
        <GenericTableView headers={capitalRaiseHeaders} data={cpTrx} />
      </LoadingWaiter>
      <Heading level={4}>Latest Acquisitions</Heading>
      <LoadingWaiter isLoading={loadingATrx.get} error={errorA.get}>
        <GenericTableView headers={acquisitionHeaders} data={aTrx} />
      </LoadingWaiter>
    </Flex>
  )
}
export default TransactionList
