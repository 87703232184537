import { restAPIRequest } from './restApiRequest'

export async function sendSlack(message: string): Promise<void> {
  try {
    await restAPIRequest({
      path: 'slack',
      body: {
        message,
      },
      method: 'post',
    })
  } catch (error) {
    console.error(`error sending slack ${message}`, error)
  }
}
