import { Button, Flex } from '@aws-amplify/ui-react'
import { FunctionComponent, useMemo, useState } from 'react'
import Select from 'react-select'

import { ColumnDef } from '@tanstack/react-table'
import '../styles/comps.css'
import InputTable from './InputTable'

const SelectCol = [
  {
    header: 'Name',
    accessorKey: 'label',
    meta: {
      type: 'viewOnlyString',
    },
  },
]

type AutoCompleteOptionSelectProps = {
  initSelected: string[] | Option[]
  optionsLoading: boolean
  isSending: boolean
  suggestedOptions: string[] | Option[]
  otherOptions: string[] | Option[]
  placeHolder: string
  saveButtonText?: string
  submit: (items: Option[], extraData?: Record<string, Record<string, string>>) => void
  extraCols?: ColumnDef<Option, Option>[]
  initExtraData?: Record<string, Record<string, string>>
}

type GroupedOption = {
  readonly label: string
  readonly options: readonly Option[]
}

export type Option = {
  readonly value: string
  readonly label: string
}

function normalizeOptions(opts: string[] | Option[], selectedOptions: Option[]): Option[] {
  let out = opts

  if (typeof out[0] === 'string') {
    out = opts.map((v) => {
      return {
        value: v,
        label: v,
      }
    }) as Option[]
  }
  out = out as Option[]
  return out.filter((opt) => {
    return !selectedOptions.find((c) => {
      return c.value === opt.value
    })
  })
}

const AutoCompleteOptionSelect: FunctionComponent<AutoCompleteOptionSelectProps> = ({
  initSelected,
  initExtraData,
  optionsLoading,
  isSending,
  submit,
  extraCols,
  suggestedOptions,
  otherOptions,
  placeHolder,
  saveButtonText,
}) => {
  const cols = useMemo(() => {
    return [...SelectCol, ...(extraCols ? extraCols : [])]
  }, [extraCols])
  const [selectedOptions, setSelectedOptions] = useState<Option[]>(normalizeOptions(initSelected, []))
  const [extraData, setExtraData] = useState<Record<string, Record<string, string>>>(initExtraData || {})

  const data = selectedOptions.map((opt) => {
    return {
      ...opt,
      ...extraData[opt.value],
    }
  })

  const fmtOptions = [
    {
      label: 'Suggested',
      options: normalizeOptions(suggestedOptions, selectedOptions),
    },
    {
      label: 'All',
      options: normalizeOptions(otherOptions, selectedOptions),
    },
  ]

  const removeOption = (opt: Option) => {
    setSelectedOptions((opts) => {
      return [
        ...opts.filter((c) => {
          return opt.value !== c.value
        }),
      ]
    })
  }

  return (
    <Flex direction='column'>
      <Flex alignItems='flex-end'>
        <Select<Option, false, GroupedOption>
          onChange={(e) => {
            if (!e) return
            setSelectedOptions([{ value: e.value, label: e.label }, ...selectedOptions])
          }}
          isLoading={optionsLoading}
          options={fmtOptions}
          placeholder={placeHolder}
          className='compSearch'
        />
        <Button isLoading={isSending} variation='primary' size='small' marginLeft='auto' className='save' onClick={() => submit(selectedOptions, extraData)}>
          {isSending ? 'Sending' : saveButtonText || 'Save Changes'}
        </Button>
      </Flex>
      <InputTable
        data={data}
        columns={cols}
        updateData={(rowIndex, colIndex, value) => {
          const item = selectedOptions[rowIndex]

          setExtraData({
            ...extraData,
            [item.value]: {
              ...(extraData[item.value] || {}),
              [colIndex]: value as string,
            },
          })
        }}
        deleteRow={(rowIndex) => {
          const item = selectedOptions[rowIndex]
          removeOption(item)
        }}
      />
    </Flex>
  )
}

export default AutoCompleteOptionSelect
