import { Collection, Flex, Text, View } from '@aws-amplify/ui-react'
import { signOut } from 'aws-amplify/auth'
import { DataStore } from 'aws-amplify/datastore'
import { Fragment, FunctionComponent, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAmplifyUser } from '../contexts/amplifyUserContext'
import { useOpportunity } from '../contexts/opportunityContext'
import SideNavOld from '../figma-components/SideNavOld'
import { CURRENT_DEAL, DEAL, METRICS_AND_BENCHMARKING, NAV_DEMO, NAV_TRIAL, getPathTo, getSiteMap } from '../sitemap'
import '../styles/sidenav.css'
import { LogoutIcon, MenuLabel } from '../ui-components'

const SideNavContainer: FunctionComponent = () => {
  const user = useAmplifyUser()
  const org = user?.org
  const location = useLocation()
  const navigate = useNavigate()
  const [isSideNavExpanded, setIsSideNavExpanded] = useState(true)
  const [isMetricsSectionExpanded, setIsMetricsSectionExpanded] = useState(false)
  const isDealsExpanded = location.pathname.includes('deal/')
  const opportunity = useOpportunity()

  const isLight = !location.pathname.includes('deal')
  const LOCAL_NAV = getSiteMap(org === 'Demo' ? NAV_DEMO : NAV_TRIAL)
    .filter((page) => (page.invisible ? false : page.enabled))
    .map((page) => {
      return {
        ...page,
        children: page.children?.filter((child) => (child.invisible ? false : child.enabled)),
      }
    })

  // TODO: determine cleaner method to set state for different menu sections
  const navigateTo = (path: string) => {
    switch (path) {
      case 'metrics_and_benchmarking':
        setIsMetricsSectionExpanded(!isMetricsSectionExpanded)
        break
      case 'documentation':
        window.open('https://app.playbookmna.com/documentation', '_blank', 'noreferrer')
        break
      case 'admin':
      case 'user_profile':
        setIsMetricsSectionExpanded(false)
        navigate(path)
        break
      default:
        setIsMetricsSectionExpanded(false)
        navigate(path)
    }
  }

  async function logout() {
    try {
      localStorage.clear()
      await DataStore.clear()
      await signOut()
      navigate('/')
    } catch (error) {
      alert(`error signing out: ${JSON.stringify(error)}`)
    }
  }

  function isSectionExpanded(item: (typeof LOCAL_NAV)[0]) {
    return (isMetricsSectionExpanded && item.name === METRICS_AND_BENCHMARKING) || (item.name === CURRENT_DEAL && (isDealsExpanded || opportunity))
  }

  function collection() {
    return (
      <Collection items={LOCAL_NAV} type='list' gap='5px' className='menuitems'>
        {/*
          // @ts-ignore */}
        {(item, i) => {
          const isSectionSelected = item.path === location.pathname || item.path === location.pathname.split('/')[1] ? 'selected' : ''
          if (isSideNavExpanded && item.menuElement && item.path !== CURRENT_DEAL) return <Fragment key={'custom' + i}>{item.menuElement}</Fragment>
          if (isSideNavExpanded && item.path === CURRENT_DEAL && !opportunity) return <Fragment key={'fake' + i}></Fragment>
          return (
            <View key={'sidenav-' + item.name + i} className={`menuitemContainer ${isSectionSelected && 'selected'}`}>
              <Flex
                className={`menuitem  ${isSectionSelected && 'selected'}`}
                onClick={() => {
                  if (item.path === CURRENT_DEAL) {
                    navigateTo(getPathTo(DEAL, { opportunityId: opportunity?.id }))
                    return
                  }
                  navigateTo(item.path)
                }}
              >
                {item.menuElement && item.path === CURRENT_DEAL && item.menuElement}
                {isSideNavExpanded ? (
                  item.icon
                ) : (
                  <View className='tooltip'>
                    {item.icon}
                    <span className='tooltipText'>{item.name}</span>
                  </View>
                )}
                {isSideNavExpanded && item.path !== CURRENT_DEAL ? <MenuLabel name={item.name} className='menulabel' /> : null}
                {item?.children?.length ? <i className={`menuitemExpand ${isSectionExpanded(item) ? 'active' : ''}`} /> : null}
              </Flex>
              {item?.children?.length ? (
                <Flex
                  key={'sitenav-sub-title-' + i}
                  className={`submenuitems
                    ${isSectionExpanded(item) ? 'active' : ''}
                `}
                >
                  {isSectionExpanded(item) &&
                    item.children?.map((subItem, j) => {
                      return (
                        <Flex
                          key={'sitenav-sub-' + subItem.name + j}
                          className={`submenuitem ${location.pathname.includes(subItem.path) || location.pathname.includes(subItem.path.split('/')[1]) ? 'selected' : ''}`}
                          onClick={() => {
                            if (!opportunity) return alert('select deal first')
                            navigate(
                              getPathTo(subItem.name, {
                                opportunityId: opportunity?.id,
                              })
                            )
                          }}
                        >
                          {subItem.icon}
                          <MenuLabel name={subItem.name} className='submenulabel' />
                        </Flex>
                      )
                    })}
                </Flex>
              ) : null}
            </View>
          )
        }}
      </Collection>
    )
  }

  const toggleSideNav = () => {
    setIsSideNavExpanded(!isSideNavExpanded)
  }

  const footer = (
    <Flex className='footer'>
      <Flex direction='column' gap='0'>
        <Text key='footer'>{user?.name}</Text>
        <Text key='footer2'>{org}</Text>
      </Flex>
      <LogoutIcon className='actionButton' onClick={logout} />
    </Flex>
  )

  return (
    <SideNavOld
      sideNavHeader={isSideNavExpanded ? 'laybook' : ''}
      sideNavBody={collection()}
      sideNavFooter={footer}
      className={`sideNav ${isLight ? '' : 'darkmode'} ${isSideNavExpanded ? '' : 'collapsed'}`}
    />
  )
}

export default SideNavContainer
