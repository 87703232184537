import { DataStore } from '@aws-amplify/datastore'
import { useState, useEffect } from 'react'
import { Opportunity } from '../models'
import { useParams } from 'react-router-dom'

export function useOpportunityState(): [Opportunity | undefined, Function] {
  const [opportunity, setOpp] = useState<Opportunity>()
  const params = useParams()
  const [opId, setId] = useState<string>(params.opportunityId || localStorage.getItem('opportunityId') || '')

  useEffect(() => {
    if (!opId) {
      localStorage.setItem('opportunityId', '')
      setOpp(undefined)
      return
    }
    localStorage.setItem('opportunityId', opId)
    const sub = DataStore.observeQuery(Opportunity, (o) => o.id.eq(opId)).subscribe(({ items }) => {
      setOpp(items[0])
    })

    return () => {
      sub.unsubscribe()
    }
  }, [opId])

  return [opportunity, setId]
}
