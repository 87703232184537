import { Button, Flex, Heading, Link, Loader, Table, TableBody, TableCell, TableHead, TableRow, Text } from '@aws-amplify/ui-react'
import { FunctionComponent } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetOpportunity } from '../contexts/opportunityContext'
import GlassCard from '../figma-components/GlassCard'
import MyDealsTable from '../figma-components/MyDealsTable'
import { useDealLogList } from '../hooks/useDealLog'
import { useOpportunities } from '../hooks/useOpportunities'
import { DEAL, getPathTo } from '../sitemap'
import { dateFormatterLong } from '../utils/utils'
import TransactionsList from './TransactionsList'

interface DealsListPageProps {}

const DealsListPage: FunctionComponent<DealsListPageProps> = () => {
  const navigate = useNavigate()
  const setOpportunity = useSetOpportunity()
  const [opportunitiesList, isLoading] = useOpportunities()
  const [dealLog, isDealLogLoading, dealLogError] = useDealLogList({ companyNames: opportunitiesList.map((o) => o.name) })

  const opportunities = opportunitiesList
    .map((o) => {
      return {
        ...(dealLog[o.name] || {}),
        id: o.id,
        label: `${o.name} - ${o.transactionName}`,
        createdAt: o.createdAt,
        updatedAt: o.updatedAt,
      }
    })
    .sort((a, b) => a.label.localeCompare(b.label))

  const handleOpportunityClick = (opportunityId: string) => {
    setOpportunity(opportunityId)
    navigate(getPathTo(DEAL, { opportunityId: opportunityId }))
  }

  const DefaultTable = () => {
    return (
      <Table variation='bordered' size='small' className='outputTable'>
        <TableHead>
          <TableRow>
            <TableCell as='th'>Deal Name</TableCell>
            <TableCell as='th'>Date Created</TableCell>
            <TableCell as='th'>Date Last Modified</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {opportunities.map((opportunity) => {
            return (
              <TableRow key={opportunity.id}>
                <TableCell>
                  <Button size='small' variation='link' padding='0 0.5rem' onClick={() => handleOpportunityClick(opportunity.id)}>
                    {opportunity.label}
                  </Button>
                </TableCell>
                <TableCell>{dateFormatterLong(opportunity.createdAt)}</TableCell>
                <TableCell>{dateFormatterLong(opportunity.updatedAt)}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    )
  }
  const EmptyDealsView = () => (
    <Flex direction='column' textAlign='center'>
      <Heading level={4}>No deals found</Heading>
      <Text>
        Go to <Link onClick={() => navigate('/profiles')}>Company Profiles</Link> to search for and start a new deal for a prospective company
      </Text>
    </Flex>
  )
  let table = <DefaultTable />

  if (dealLog && Object.keys(dealLog).length > 0) table = <MyDealsTable deals={opportunities} />

  const list = opportunities.length === 0 ? <EmptyDealsView /> : table

  return (
    <GlassCard header='My Deals' level={0}>
      <Flex direction='column'>
        {isLoading ? <Loader /> : list}
        <TransactionsList />
      </Flex>
    </GlassCard>
  )
}

export default DealsListPage
