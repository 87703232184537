/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function UploadIcon(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="20px"
      height="20px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 20, height: 20 }}
      paths={[
        {
          d: "M7.516 3.758L3.758 0L0 3.758L0.942792 4.70079L3.09133 2.55225L3.09133 12.5602L4.42467 12.5602L4.42467 2.55225L6.57321 4.70079L7.516 3.758Z",
          fill: "rgba(48,53,69,1)",
          fillRule: "nonzero",
          style: { transform: "translate(31.22%, 37.2%)" },
        },
        {
          d: "M16 6.06054L16 6C16 2.69167 13.3083 0 10 0C6.69167 0 4 2.69167 4 6L4 6.10379C3.01255 6.29014 2.10225 6.76431 1.38358 7.46667C0.946238 7.89114 0.598456 8.39902 0.360808 8.96024C0.12316 9.52147 0.000472165 10.1247 0 10.7341C5.92119e-16 12.0308 0.532833 13.2659 1.50042 14.212C2.44583 15.1365 3.72242 15.6667 5.00292 15.6667L7.66667 15.6667L7.66667 14.3333L5.00292 14.3333C3.04808 14.3333 1.33333 12.6515 1.33333 10.7341C1.33333 8.97296 2.81833 7.48763 4.71404 7.35267L5.33333 7.30854L5.33333 6C5.33333 4.76232 5.825 3.57534 6.70017 2.70017C7.57534 1.825 8.76232 1.33333 10 1.33333C11.2377 1.33333 12.4247 1.825 13.2998 2.70017C14.175 3.57534 14.6667 4.76232 14.6667 6L14.6667 7.335L15.325 7.34333C17.2613 7.36783 18.6667 8.79387 18.6667 10.7341C18.6667 12.7524 17.1811 14.3333 15.2846 14.3333L12.3333 14.3333L12.3333 15.6667L15.2846 15.6667C15.9198 15.6688 16.5485 15.5385 17.1306 15.2841C17.7127 15.0298 18.2354 14.657 18.6654 14.1894C19.526 13.2641 20 12.037 20 10.7341C20 8.29925 18.3215 6.39133 16 6.06054Z",
          fill: "rgba(48,53,69,1)",
          fillRule: "nonzero",
          style: { transform: "translate(0%, 0%)" },
        },
      ]}
      {...getOverrideProps(overrides, "UploadIcon")}
      {...rest}
    ></Icon>
  );
}
