import { Flex, Heading, View } from '@aws-amplify/ui-react'
import { signOut } from 'aws-amplify/auth'
import { DataStore } from 'aws-amplify/datastore'

import { FunctionComponent } from 'react'
import { useNavigate } from 'react-router-dom'
import { USER_PROFILE } from '../sitemap'
import { LogoutIcon } from '../ui-components'

const UserProfile: FunctionComponent = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate()
  async function logOut() {
    try {
      await DataStore.clear()
      await signOut()
      navigate('/')
    } catch (error) {
      alert(`error signing out: ${JSON.stringify(error)}`)
    }
  }

  return (
    <Flex direction='column' gap='2rem'>
      <Heading level={5}>{USER_PROFILE}</Heading>
      <View onClick={logOut} className='logout'>
        <LogoutIcon /> Logout
      </View>
    </Flex>
  )
  // return <Dashboard url='https://10az.online.tableau.com/t/playbookmatrial/views/UserProfile_Unlinked/Sheet1' />
}

export default UserProfile
