/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function ExcelIcon(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="20px"
      height="20px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 20, height: 20 }}
      paths={[
        {
          d: "M17.7167 10.451L14.5359 10.451L14.5359 8.86061L17.7167 8.86061L17.7167 10.451ZM17.7167 11.3598L14.5359 11.3598L14.5359 12.9501L17.7167 12.9501L17.7167 11.3598ZM17.7167 3.86232L14.5359 3.86232L14.5359 5.45268L17.7167 5.45268L17.7167 3.86232ZM17.7167 6.36146L14.5359 6.36146L14.5359 7.95183L17.7167 7.95183L17.7167 6.36146ZM17.7167 13.8589L14.5359 13.8589L14.5359 15.4493L17.7167 15.4493L17.7167 13.8589ZM19.9137 16.9942C19.8228 17.4668 19.2548 17.4781 18.8754 17.494L11.8096 17.494L11.8096 19.5388L10.3987 19.5388L0 17.7212L0 1.81983L10.4601 0L11.8096 0L11.8096 1.81076L18.6323 1.81074C19.0162 1.82665 19.4388 1.79939 19.7728 2.02885C20.0068 2.3651 19.9841 2.79223 20 3.17846L19.9909 15.0062C19.9795 15.6674 20.0522 16.3421 19.9137 16.9942ZM8.33124 13.4295C7.70419 12.1572 7.06577 10.894 6.44098 9.62171C7.05895 8.3835 7.66783 7.14074 8.27445 5.89798C7.75871 5.92298 7.24298 5.95478 6.72952 5.99113C6.34556 6.92491 5.89798 7.83369 5.59809 8.79927C5.31864 7.88821 4.94831 7.01124 4.60979 6.12291C4.10996 6.15017 3.61013 6.17971 3.1103 6.20924C3.63739 7.37248 4.19857 8.51982 4.70975 9.68987C4.10769 10.8258 3.54424 11.9777 2.96035 13.1205C3.45791 13.141 3.95547 13.1614 4.45302 13.1682C4.80745 12.264 5.24821 11.3938 5.55719 10.4714C5.83437 11.462 6.30466 12.3776 6.6909 13.325C7.23844 13.3636 7.7837 13.3977 8.33124 13.4295ZM18.9164 2.88977L11.8096 2.88977L11.8096 3.86232L13.6272 3.86232L13.6272 5.45268L11.8096 5.45268L11.8096 6.36146L13.6272 6.36146L13.6272 7.95183L11.8096 7.95183L11.8096 8.86061L13.6272 8.86061L13.6272 10.451L11.8096 10.451L11.8096 11.3598L13.6272 11.3598L13.6272 12.9501L11.8096 12.9501L11.8096 13.8589L13.6272 13.8589L13.6272 15.4493L11.8096 15.4493L11.8096 16.4999L18.9164 16.4999L18.9164 2.88977Z",
          fill: "rgba(48,53,69,1)",
          fillRule: "nonzero",
          style: { transform: "translate(0%, 1.15%)" },
        },
      ]}
      {...getOverrideProps(overrides, "ExcelIcon")}
      {...rest}
    ></Icon>
  );
}
