import { Button, Flex, Loader, ScrollView, Text } from '@aws-amplify/ui-react'
import { FunctionComponent } from 'react'
import { utils } from 'xlsx'
import GlassCard from '../figma-components/GlassCard'
import { useMarketMapList } from '../hooks/useMarketMapList'
import { exportExcel } from '../services/excel'
import ExcelIcon from '../ui-components/ExcelIcon'
import { removeDupByKey } from '../utils/utils'
import InputTable from './InputTable'

type MarketLandscapeProps = {
  markets: string[]
}

const BaseCols = [
  {
    header: 'Company Name',
    accessorKey: 'companyName',
    meta: {
      type: 'link',
      valueToLink: (i: string, row) => `/profiles/${row.companyName}`,
    },
  },
  {
    header: 'Product Name',
    accessorKey: 'productName',
    meta: {
      type: 'viewOnlyString',
    },
  },
  {
    header: 'Product Description',
    accessorKey: 'productDescription',
    meta: {
      type: 'viewOnlyString',
    },
  },
  {
    header: 'Tags',
    accessorKey: 'markets',
    meta: {
      type: 'markets',
    },
  },
]

const MarketLandscape: FunctionComponent<MarketLandscapeProps> = ({ markets }) => {
  const marketMap = useMarketMapList(markets)

  const finalCols = BaseCols
  const total = marketMap.length
  const remaining = marketMap.filter((m) => !m.isLoading).length
  const percentage = Math.round((remaining / total) * 100)

  const buttonRow = (
    <Button
      variation='primary'
      size='small'
      gap='0.5rem'
      alignSelf='flex-end'
      className='excel'
      onClick={() => {
        const data = marketMap
          ?.map((t) => t.data)
          .flat()
          .filter((n) => n)
          .map((n) => ({
            'Company name': n?.company_name,
            'Company Profile Link': `https://app.playbookmna.com/profiles/${n?.company_name}`,
            'Product Name': n?.product_name,
            'Product Description': n?.short_description,
            Markets: n?.markets,
            Tags: n?.taxonomies,
          }))

        exportExcel({
          sheetName: 'Market Landscape',
          filename: `Market Landscape.xlsx`,
          data,
          format: (ws) => {
            for (let row = 0; row <= data.length; row++) {
              const cellPos = utils.encode_cell({
                c: 1,
                r: row,
              })
              ws[cellPos].l = {
                Target: ws[cellPos].v,
              }
            }
            return ws
          },
        })
      }}
    >
      <ExcelIcon />
      Export to Excel
    </Button>
  )

  const headerButtons =
    percentage === 100 ? (
      buttonRow
    ) : (
      <Flex>
        <Text>Loading</Text>
        <Loader width='100%' variation='linear' isDeterminate percentage={percentage} size='small' filledColor='#1C70EE' />
      </Flex>
    )
  return (
    <GlassCard header='Market Landscape' headerButtons={headerButtons}>
      {marketMap.map((m) => {
        const names = removeDupByKey(
          m.data?.map((n) => ({
            companyLabel: n.company_label,
            companyName: n.company_name,
            markets: n.markets,
            productName: n.product_name,
            productDescription: n.short_description,
          })) || [],
          'companyLabel'
        )
        names.sort((a, b) => a.companyName.localeCompare(b.companyName))
        return (
          <GlassCard header={m.targetMarketName} key={m.targetMarketName} expandable className='overlayCard'>
            <ScrollView maxHeight='30rem'>
              <Flex direction='column' gap='2rem'>
                <InputTable data={names} columns={finalCols as any} paginate />
              </Flex>
            </ScrollView>
          </GlassCard>
        )
      })}
    </GlassCard>
  )
}

export default MarketLandscape
