/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function CkLock(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="24px"
      height="24px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 24, height: 24 }}
      paths={[
        {
          d: "M14.3158 8.3125L13.6842 8.3125L13.6842 5.90625C13.6842 4.33982 13.0853 2.83754 12.0193 1.7299C10.9533 0.622264 9.50755 7.77156e-16 8 0C6.49245 9.22873e-16 5.04665 0.622264 3.98066 1.7299C2.91466 2.83754 2.31579 4.33982 2.31579 5.90625L2.31579 8.3125L1.68421 8.3125C1.23753 8.3125 0.809144 8.49687 0.493294 8.82506C0.177443 9.15325 3.7397e-16 9.59837 0 10.0625L0 19.25C3.7397e-16 19.7141 0.177443 20.1592 0.493294 20.4874C0.809144 20.8156 1.23753 21 1.68421 21L14.3158 21C14.7625 21 15.1909 20.8156 15.5067 20.4874C15.8226 20.1592 16 19.7141 16 19.25L16 10.0625C16 9.59837 15.8226 9.15325 15.5067 8.82506C15.1909 8.49687 14.7625 8.3125 14.3158 8.3125ZM6.31579 13.5625C6.31445 13.255 6.39113 12.9525 6.53809 12.6856C6.68506 12.4187 6.89713 12.1967 7.15293 12.042C7.40874 11.8874 7.69926 11.8055 7.99522 11.8046C8.29118 11.8037 8.58214 11.8839 8.83878 12.0371C9.09543 12.1902 9.3087 12.411 9.45712 12.677C9.60553 12.9431 9.68386 13.2451 9.68419 13.5526C9.68453 13.8601 9.60687 14.1623 9.45904 14.4287C9.3112 14.6951 9.09841 14.9164 8.8421 15.0701L8.8421 17.0625C8.8421 17.2946 8.75338 17.5171 8.59546 17.6812C8.43753 17.8453 8.22334 17.9375 8 17.9375C7.77666 17.9375 7.56247 17.8453 7.40454 17.6812C7.24662 17.5171 7.15789 17.2946 7.15789 17.0625L7.15789 15.0701C6.90264 14.9176 6.69046 14.698 6.54266 14.4334C6.39485 14.1688 6.31661 13.8685 6.31579 13.5625L6.31579 13.5625ZM4.42105 5.90625C4.42105 4.91998 4.79812 3.9741 5.4693 3.2767C6.14048 2.5793 7.0508 2.1875 8 2.1875C8.9492 2.1875 9.85952 2.5793 10.5307 3.2767C11.2019 3.9741 11.5789 4.91998 11.5789 5.90625L11.5789 7.875C11.5789 7.99103 11.5346 8.10231 11.4556 8.18436C11.3767 8.26641 11.2696 8.3125 11.1579 8.3125L4.84211 8.3125C4.73044 8.3125 4.62334 8.26641 4.54438 8.18436C4.46541 8.10231 4.42105 7.99103 4.42105 7.875L4.42105 5.90625Z",
          fill: "rgba(61,67,87,1)",
          fillRule: "nonzero",
          style: {
            transform:
              "translate(calc(50% - 8px - 0px), calc(50% - 10.5px - 0.5px))",
          },
        },
      ]}
      {...getOverrideProps(overrides, "CkLock")}
      {...rest}
    ></Icon>
  );
}
