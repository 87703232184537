import { GraphQLQuery, generateClient } from '@aws-amplify/api'
import { graphqlOperation } from '@aws-amplify/api-graphql'
import { useQueries } from '@tanstack/react-query'
import { GetMarketInfoV1Query } from '../API'
import { getMarketInfoV1 } from '../graphql/queries'
import { MarketInfoV1 } from '../models'
import { Error404 } from '../types'
import { removeDuplicateValues, removeNonGraphqlKeys } from '../utils/utils'
const API = generateClient()

type ListCompanyRes = {
  marketName: String
  data: MarketInfoV1 | undefined
  isLoading: boolean
  error: Error | undefined
}

export function useMarketInfoV1List(marketNames: string[]): ListCompanyRes[] {
  const markets = removeDuplicateValues(marketNames)
  markets.sort((a, b) => a.localeCompare(b))
  const q = useQueries({
    queries: markets.map((id) => {
      return {
        queryKey: ['companyInfo', id],
        queryFn: queryMarketInfo(id),
        staleTime: 1000 * 60 * 60 * 24,
      }
    }),
  })

  return q.map((res, i) => ({
    marketName: markets[i],
    data: res.data,
    isLoading: res.isLoading,
    error: res.error as Error | undefined,
  }))
}

export function queryMarketInfo(marketName: string | undefined): () => Promise<MarketInfoV1> {
  return async () => {
    const info = await API.graphql<GraphQLQuery<GetMarketInfoV1Query>>(graphqlOperation(getMarketInfoV1, { marketName }))
    if (!info?.data?.getMarketInfoV1) throw new Error404(`${marketName} not found in MarketInfoV1 DB`)
    return new MarketInfoV1(removeNonGraphqlKeys(info.data.getMarketInfoV1))
  }
}
