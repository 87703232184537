import { createContext, FunctionComponent, PropsWithChildren, useContext } from 'react'
import { GetCyberRes, useCyberNames } from '../hooks/useCyberNames'

export const CyberContext = createContext<[cyber: GetCyberRes, loading: boolean]>([{}, false])

export const CyberProvider: FunctionComponent<PropsWithChildren> = ({ children, ...props }) => {
  const [cyber, loading] = useCyberNames()
  return (
    <CyberContext.Provider {...props} value={[cyber, loading]}>
      {children}
    </CyberContext.Provider>
  )
}

export const useCyber = (): [cyber: GetCyberRes, loading: boolean] => useContext(CyberContext)
