import { Table, TableBody, TableCell, TableHead, TableRow } from '@aws-amplify/ui-react'
import React from 'react'
import { currencyFormatterShort, dateFormatterShort } from '../utils/utils'

interface Header {
  name: string
  key: string
  type: string
}

interface Data {
  [key: string]: string | number | Date
}

interface Props {
  headers: Header[]
  data: Data[]
}

const GenericTableView: React.FC<Props> = ({ headers, data }) => {
  return (
    <Table highlightOnHover={true} variation='bordered' size='small' className='outputTable'>
      <TableHead>
        <TableRow>
          {headers.map((header, index) => (
            <TableCell as='th' key={index}>
              {header.name}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item, index) => (
          <TableRow key={index}>
            {headers.map((header, index) => (
              <TableCell key={index}>{format(item[header.key], headers[index].type)}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

function format(value, type) {
  if (value === null || value === undefined) return 'N/A'

  if (type === 'string') {
    return value
  }
  if (type === 'year') {
    return value.toLocaleString('en-US', { year: 'numeric', month: 'long' }) || 'N/A'
  }
  if (type === 'shortDate') {
    return dateFormatterShort(value) || 'N/A'
  }
  if (type === 'currencyFormatterShort') {
    return currencyFormatterShort(value)
  }
  return value
}
export default GenericTableView
