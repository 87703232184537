import { DataStore } from 'aws-amplify/datastore'
import { list, remove, uploadData } from 'aws-amplify/storage'

import { Opportunity } from '../models'

export const OpportunityBasePath = 'user-files/opportunities'

type FileUploadOpts = {
  contentType?: string
  path?: string
}

export async function uploadFileForOpportunity(op: Opportunity, file: File, opts: FileUploadOpts = {}) {
  const prefix = opts.path ? `${opts.path}/` : ''
  try {
    const { key } = await uploadData({
      key: `${getPathForOpportunity(op)}${prefix}${file.name}`,
      data: file,
      options: {
        accessLevel: 'private',
        contentType: opts.contentType || file.type,
      },
    }).result
    return key
  } catch (error) {
    console.error('Error uploading file: ', error)
    throw error
  }
}

export async function listFilesForOpportunity(op: Opportunity, path?: string) {
  const prefix = path ? `${path}/` : ''
  const out = await list({
    prefix: `${getPathForOpportunity(op)}${prefix}`,
    options: { accessLevel: 'private', pageSize: 1000 },
  })
  return out.items
}

export function getPathForOpportunity(opportunity: Opportunity) {
  return `${OpportunityBasePath}/${opportunity.id}/`
}

export async function deleteFile(key: string, accessLevel: 'guest' | 'protected' | 'private') {
  return await remove({ key, options: { accessLevel } })
}

export async function deleteFileFromOpportunity(opportunity: Opportunity, s3Key: string) {
  try {
    deleteFile(s3Key, 'private')
    await DataStore.save(
      Opportunity.copyOf(opportunity, (updated) => {
        Object.assign(updated, {
          files: updated.files?.filter((u) => {
            return u.s3Key !== s3Key
          }),
        })
      })
    )
  } catch (error) {
    console.error('Error deleting file: ', error)
  }
}
