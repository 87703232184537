import { restAPIRequest } from './restApiRequest'

type CleanNotesRes = {
  text: string
}
export async function cleanNotes(text) {
  if (!text) throw new Error('no text to clean')
  const out = await restAPIRequest<CleanNotesRes>({
    path: `ai/run`,
    method: 'post',
    body: {
      key: 'cleanNotes',
      input: { notes: text },
    },
  })
  if (!out?.text) throw new Error('failed to clean notes')
  return out.text
}
