import { useQuery } from '@tanstack/react-query'
import { restAPIRequest } from '../services/restApiRequest'
import { defaultRetry } from '../utils/utils'

type GoogleSheetDataProps = {
  companyName: string
  companyLabel: string
  enabled?: boolean
}

export type MeetingNotesHeaders = {
  PlaybookID: string
  'Company Name': string
  'Meeting Date': string
  'Meeting Notes': string
  'Playbook:Meeting Notes': string
  index: number
}

type MeetingNotesFns = {
  refetch: () => void
}

export function useMeetingNotes({ companyName, companyLabel, enabled = true }: GoogleSheetDataProps): [MeetingNotesHeaders[], boolean, Error | undefined, MeetingNotesFns] {
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['meetingNotes', companyName],
    enabled,
    queryFn: queryMeetingNotes(companyName, companyLabel),
    retry: defaultRetry,
  })

  return [
    data || ([] as any),
    isLoading,
    error as Error | undefined,
    {
      refetch,
    },
  ]
}

export function queryMeetingNotes(companyName: string, companyLabel: string) {
  return async () =>
    restAPIRequest<MeetingNotesHeaders>({
      path: `companyProfile/${companyName}/notes`,
      query: {
        companyLabel,
      },
      method: 'get',
    })
}
