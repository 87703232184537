/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function BsBookmark(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="20px"
      height="20px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 20, height: 20 }}
      paths={[
        {
          d: "M0 2.5C-2.77556e-16 1.83696 0.263392 1.20107 0.732233 0.732233C1.20107 0.263392 1.83696 7.63278e-16 2.5 1.38778e-16L12.5 0C13.163 3.46945e-16 13.7989 0.263392 14.2678 0.732233C14.7366 1.20107 15 1.83696 15 2.5L15 19.375C14.9999 19.4881 14.9692 19.599 14.9111 19.6959C14.853 19.7929 14.7697 19.8723 14.67 19.9257C14.5703 19.979 14.4581 20.0044 14.3451 19.999C14.2322 19.9936 14.1229 19.9576 14.0287 19.895L7.5 16.3762L0.97125 19.895C0.877132 19.9576 0.767788 19.9936 0.654864 19.999C0.541941 20.0044 0.429666 19.979 0.329999 19.9257C0.230332 19.8723 0.147005 19.7929 0.0888926 19.6959C0.03078 19.599 5.84005e-05 19.4881 0 19.375L0 2.5ZM2.5 1.25C2.16848 1.25 1.85054 1.3817 1.61612 1.61612C1.3817 1.85054 1.25 2.16848 1.25 2.5L1.25 18.2075L7.15375 15.105C7.25633 15.0367 7.37679 15.0003 7.5 15.0003C7.62321 15.0003 7.74367 15.0367 7.84625 15.105L13.75 18.2075L13.75 2.5C13.75 2.16848 13.6183 1.85054 13.3839 1.61612C13.1495 1.3817 12.8315 1.25 12.5 1.25L2.5 1.25Z",
          fill: "rgba(48,53,69,1)",
          fillRule: "nonzero",
          style: { transform: "translate(12.5%, 0%)" },
        },
      ]}
      {...getOverrideProps(overrides, "BsBookmark")}
      {...rest}
    ></Icon>
  );
}
