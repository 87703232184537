/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createOpportunity = /* GraphQL */ `mutation CreateOpportunity(
  $input: CreateOpportunityInput!
  $condition: ModelOpportunityConditionInput
) {
  createOpportunity(input: $input, condition: $condition) {
    id
    orgName
    name
    transactionName
    transactionTypes
    uploadDate
    marketMapCompSet
    marketMapSelectedMetrics
    publicCompsCompSetV2 {
      name
      groupName
      __typename
    }
    owner
    transactionAcquisitionCompSet
    transactionCapitalRaiseCompSet
    transactionModelsV1
    competitiveLandscapeDashboards {
      url
      name
      __typename
    }
    capTableV1
    totalEquityRaised
    lastPreMoneyValuation
    latestDealDate
    files {
      name
      s3Key
      group
      dateUploaded
      vectorDbId
      docType
      tags
      __typename
    }
    financialData {
      public_company_2020_revenue
      public_company_2021_revenue
      public_company_2022_revenue
      public_company_2023_analyst_revenue_estimates
      public_company_ltm_revenue_m
      public_company_ntm_revenue_m
      public_company_arr_m
      public_company_ga_percent
      public_company_gross_margin
      public_company_gross_profit
      public_company_ebitda
      public_company_ebitda_percent
      public_company_ltm_ebitda
      public_company_ltm_ebitda_margin
      public_company_ntm_ebitda
      public_company_ltm_fcf
      public_company_ltm_fcf_percent
      public_company_rd_percent
      public_company_sm_percent
      public_company_total_cash
      public_company_ltm_revenue_growth
      public_company_ntm_revenue_growth
      public_company_arr_growth
      public_company_arr_per_customer_k
      public_company_gross_retention
      public_company_net_dollar_retention
      public_company_ltm_free_cash_flow_growth_percent
      public_company_growth_persistence
      public_company_net_new_arr
      public_company_net_new_arr_growth_percent
      public_company_arr_employee_k
      public_company_annualized_opex_employee
      public_company_burn_multiple
      public_company_ltm_magic_number
      public_company_operating_income_percent
      public_company_rule_of_40_ltm_fcf
      public_company_rule_of_40_op_in_margin
      public_company_rule_of_40_quarter_fcf
      public_company_sales_and_marketing_yield
      public_company_implied_5yr_ltv_cac
      __typename
    }
    financialDataV2 {
      type
      year
      quarter
      month
      totalRevenue
      totalRevGrowthPercent
      totalARR
      arrGrowthPercent
      totalGrossProfit
      grossMarginPercent
      totalEBITDA
      eBITDAMarginPercent
      totalFCF
      fcfMarginPercent
      gnaAsPercentOfRev
      snmAsPercentOfRev
      rndAsPercentOfRev
      grossRetentionPercent
      netRetentionPercent
      salesEfficiencyMultiple
      netNewARR
      netNewARRGrowthPercent
      arrPerEmployee
      annualizedOpexPerEmployee
      burnMultiple
      operatingIncomePercent
      ruleOf40LTMFCF
      ruleOf40LTMEbitda
      salesAndMarketingYield
      implied5yrLTVPerCAC
      __typename
    }
    overrides {
      sourceTypes {
        overview
        products
        customerDetail
        marketSummary
        competitiveLandscape
        investmentDetails
        __typename
      }
      products {
        name
        description
        shortDescription
        type
        featuresV2
        features
        useCases
        revenueModel
        deliveryModel
        targetCustomerProfile
        sources
        markets
        taxonomiesV2
        mcKinseyMarketCategory
        classification
        objectStateJson
        __typename
      }
      productRanks
      caseStudies {
        customerName
        backgroundOnProblem
        howProductHelpedCustomer
        howCustomerJustifiedROI
        sourceLink
        __typename
      }
      marketRisks {
        summary
        details
        mitigation
        __typename
      }
      marketDrivers {
        summary
        details
        __typename
      }
      objectStateJson
      __typename
    }
    tasks {
      id
      name
      status
      args
      amplifyUserId
      orgName
      error
      startedAt
      finishedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOpportunityMutationVariables,
  APITypes.CreateOpportunityMutation
>;
export const updateOpportunity = /* GraphQL */ `mutation UpdateOpportunity(
  $input: UpdateOpportunityInput!
  $condition: ModelOpportunityConditionInput
) {
  updateOpportunity(input: $input, condition: $condition) {
    id
    orgName
    name
    transactionName
    transactionTypes
    uploadDate
    marketMapCompSet
    marketMapSelectedMetrics
    publicCompsCompSetV2 {
      name
      groupName
      __typename
    }
    owner
    transactionAcquisitionCompSet
    transactionCapitalRaiseCompSet
    transactionModelsV1
    competitiveLandscapeDashboards {
      url
      name
      __typename
    }
    capTableV1
    totalEquityRaised
    lastPreMoneyValuation
    latestDealDate
    files {
      name
      s3Key
      group
      dateUploaded
      vectorDbId
      docType
      tags
      __typename
    }
    financialData {
      public_company_2020_revenue
      public_company_2021_revenue
      public_company_2022_revenue
      public_company_2023_analyst_revenue_estimates
      public_company_ltm_revenue_m
      public_company_ntm_revenue_m
      public_company_arr_m
      public_company_ga_percent
      public_company_gross_margin
      public_company_gross_profit
      public_company_ebitda
      public_company_ebitda_percent
      public_company_ltm_ebitda
      public_company_ltm_ebitda_margin
      public_company_ntm_ebitda
      public_company_ltm_fcf
      public_company_ltm_fcf_percent
      public_company_rd_percent
      public_company_sm_percent
      public_company_total_cash
      public_company_ltm_revenue_growth
      public_company_ntm_revenue_growth
      public_company_arr_growth
      public_company_arr_per_customer_k
      public_company_gross_retention
      public_company_net_dollar_retention
      public_company_ltm_free_cash_flow_growth_percent
      public_company_growth_persistence
      public_company_net_new_arr
      public_company_net_new_arr_growth_percent
      public_company_arr_employee_k
      public_company_annualized_opex_employee
      public_company_burn_multiple
      public_company_ltm_magic_number
      public_company_operating_income_percent
      public_company_rule_of_40_ltm_fcf
      public_company_rule_of_40_op_in_margin
      public_company_rule_of_40_quarter_fcf
      public_company_sales_and_marketing_yield
      public_company_implied_5yr_ltv_cac
      __typename
    }
    financialDataV2 {
      type
      year
      quarter
      month
      totalRevenue
      totalRevGrowthPercent
      totalARR
      arrGrowthPercent
      totalGrossProfit
      grossMarginPercent
      totalEBITDA
      eBITDAMarginPercent
      totalFCF
      fcfMarginPercent
      gnaAsPercentOfRev
      snmAsPercentOfRev
      rndAsPercentOfRev
      grossRetentionPercent
      netRetentionPercent
      salesEfficiencyMultiple
      netNewARR
      netNewARRGrowthPercent
      arrPerEmployee
      annualizedOpexPerEmployee
      burnMultiple
      operatingIncomePercent
      ruleOf40LTMFCF
      ruleOf40LTMEbitda
      salesAndMarketingYield
      implied5yrLTVPerCAC
      __typename
    }
    overrides {
      sourceTypes {
        overview
        products
        customerDetail
        marketSummary
        competitiveLandscape
        investmentDetails
        __typename
      }
      products {
        name
        description
        shortDescription
        type
        featuresV2
        features
        useCases
        revenueModel
        deliveryModel
        targetCustomerProfile
        sources
        markets
        taxonomiesV2
        mcKinseyMarketCategory
        classification
        objectStateJson
        __typename
      }
      productRanks
      caseStudies {
        customerName
        backgroundOnProblem
        howProductHelpedCustomer
        howCustomerJustifiedROI
        sourceLink
        __typename
      }
      marketRisks {
        summary
        details
        mitigation
        __typename
      }
      marketDrivers {
        summary
        details
        __typename
      }
      objectStateJson
      __typename
    }
    tasks {
      id
      name
      status
      args
      amplifyUserId
      orgName
      error
      startedAt
      finishedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOpportunityMutationVariables,
  APITypes.UpdateOpportunityMutation
>;
export const deleteOpportunity = /* GraphQL */ `mutation DeleteOpportunity(
  $input: DeleteOpportunityInput!
  $condition: ModelOpportunityConditionInput
) {
  deleteOpportunity(input: $input, condition: $condition) {
    id
    orgName
    name
    transactionName
    transactionTypes
    uploadDate
    marketMapCompSet
    marketMapSelectedMetrics
    publicCompsCompSetV2 {
      name
      groupName
      __typename
    }
    owner
    transactionAcquisitionCompSet
    transactionCapitalRaiseCompSet
    transactionModelsV1
    competitiveLandscapeDashboards {
      url
      name
      __typename
    }
    capTableV1
    totalEquityRaised
    lastPreMoneyValuation
    latestDealDate
    files {
      name
      s3Key
      group
      dateUploaded
      vectorDbId
      docType
      tags
      __typename
    }
    financialData {
      public_company_2020_revenue
      public_company_2021_revenue
      public_company_2022_revenue
      public_company_2023_analyst_revenue_estimates
      public_company_ltm_revenue_m
      public_company_ntm_revenue_m
      public_company_arr_m
      public_company_ga_percent
      public_company_gross_margin
      public_company_gross_profit
      public_company_ebitda
      public_company_ebitda_percent
      public_company_ltm_ebitda
      public_company_ltm_ebitda_margin
      public_company_ntm_ebitda
      public_company_ltm_fcf
      public_company_ltm_fcf_percent
      public_company_rd_percent
      public_company_sm_percent
      public_company_total_cash
      public_company_ltm_revenue_growth
      public_company_ntm_revenue_growth
      public_company_arr_growth
      public_company_arr_per_customer_k
      public_company_gross_retention
      public_company_net_dollar_retention
      public_company_ltm_free_cash_flow_growth_percent
      public_company_growth_persistence
      public_company_net_new_arr
      public_company_net_new_arr_growth_percent
      public_company_arr_employee_k
      public_company_annualized_opex_employee
      public_company_burn_multiple
      public_company_ltm_magic_number
      public_company_operating_income_percent
      public_company_rule_of_40_ltm_fcf
      public_company_rule_of_40_op_in_margin
      public_company_rule_of_40_quarter_fcf
      public_company_sales_and_marketing_yield
      public_company_implied_5yr_ltv_cac
      __typename
    }
    financialDataV2 {
      type
      year
      quarter
      month
      totalRevenue
      totalRevGrowthPercent
      totalARR
      arrGrowthPercent
      totalGrossProfit
      grossMarginPercent
      totalEBITDA
      eBITDAMarginPercent
      totalFCF
      fcfMarginPercent
      gnaAsPercentOfRev
      snmAsPercentOfRev
      rndAsPercentOfRev
      grossRetentionPercent
      netRetentionPercent
      salesEfficiencyMultiple
      netNewARR
      netNewARRGrowthPercent
      arrPerEmployee
      annualizedOpexPerEmployee
      burnMultiple
      operatingIncomePercent
      ruleOf40LTMFCF
      ruleOf40LTMEbitda
      salesAndMarketingYield
      implied5yrLTVPerCAC
      __typename
    }
    overrides {
      sourceTypes {
        overview
        products
        customerDetail
        marketSummary
        competitiveLandscape
        investmentDetails
        __typename
      }
      products {
        name
        description
        shortDescription
        type
        featuresV2
        features
        useCases
        revenueModel
        deliveryModel
        targetCustomerProfile
        sources
        markets
        taxonomiesV2
        mcKinseyMarketCategory
        classification
        objectStateJson
        __typename
      }
      productRanks
      caseStudies {
        customerName
        backgroundOnProblem
        howProductHelpedCustomer
        howCustomerJustifiedROI
        sourceLink
        __typename
      }
      marketRisks {
        summary
        details
        mitigation
        __typename
      }
      marketDrivers {
        summary
        details
        __typename
      }
      objectStateJson
      __typename
    }
    tasks {
      id
      name
      status
      args
      amplifyUserId
      orgName
      error
      startedAt
      finishedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOpportunityMutationVariables,
  APITypes.DeleteOpportunityMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    amplifyUserId
    owner
    groups {
      name
      groupName
      __typename
    }
    listInfo {
      headers
      data {
        name
        jsonData
        __typename
      }
      __typename
    }
    websiteChatsV2 {
      target
      chat {
        role
        content
        timestamp
        sources
        __typename
      }
      __typename
    }
    docChats {
      target
      id
      selectedDocs
      chat {
        role
        content
        timestamp
        sources
        __typename
      }
      __typename
    }
    marketMapSelectedMetrics
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    amplifyUserId
    owner
    groups {
      name
      groupName
      __typename
    }
    listInfo {
      headers
      data {
        name
        jsonData
        __typename
      }
      __typename
    }
    websiteChatsV2 {
      target
      chat {
        role
        content
        timestamp
        sources
        __typename
      }
      __typename
    }
    docChats {
      target
      id
      selectedDocs
      chat {
        role
        content
        timestamp
        sources
        __typename
      }
      __typename
    }
    marketMapSelectedMetrics
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    amplifyUserId
    owner
    groups {
      name
      groupName
      __typename
    }
    listInfo {
      headers
      data {
        name
        jsonData
        __typename
      }
      __typename
    }
    websiteChatsV2 {
      target
      chat {
        role
        content
        timestamp
        sources
        __typename
      }
      __typename
    }
    docChats {
      target
      id
      selectedDocs
      chat {
        role
        content
        timestamp
        sources
        __typename
      }
      __typename
    }
    marketMapSelectedMetrics
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createOrg = /* GraphQL */ `mutation CreateOrg(
  $input: CreateOrgInput!
  $condition: ModelOrgConditionInput
) {
  createOrg(input: $input, condition: $condition) {
    orgName
    owner
    dashboards {
      url
      companyName
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrgMutationVariables,
  APITypes.CreateOrgMutation
>;
export const updateOrg = /* GraphQL */ `mutation UpdateOrg(
  $input: UpdateOrgInput!
  $condition: ModelOrgConditionInput
) {
  updateOrg(input: $input, condition: $condition) {
    orgName
    owner
    dashboards {
      url
      companyName
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrgMutationVariables,
  APITypes.UpdateOrgMutation
>;
export const deleteOrg = /* GraphQL */ `mutation DeleteOrg(
  $input: DeleteOrgInput!
  $condition: ModelOrgConditionInput
) {
  deleteOrg(input: $input, condition: $condition) {
    orgName
    owner
    dashboards {
      url
      companyName
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrgMutationVariables,
  APITypes.DeleteOrgMutation
>;
export const createCompanyInfoV3 = /* GraphQL */ `mutation CreateCompanyInfoV3(
  $input: CreateCompanyInfoV3Input!
  $condition: ModelCompanyInfoV3ConditionInput
) {
  createCompanyInfoV3(input: $input, condition: $condition) {
    companyName
    companyLabel
    master_market_map_company_name
    isApproved
    linkedinUrl
    iconUrl
    headquartersLocation
    headquartersRegion
    ticker
    fullTimeEmployeeCount
    fullTimeEmployeeCountGrowth6m
    fullTimeEmployeeCountGrowth1y
    fullTimeEmployeeCountGrowth2y
    companyType
    marketComps
    ai_public_comps {
      name
      groupName
      __typename
    }
    cb_organizationPermalink
    cb_acquiredByLabel
    cb_acquiredByPermalink
    cb_acquisitionStatus
    cb_acquisitionTerms
    cb_acquisitionType
    cb_description
    cb_fullDescription
    cb_numberOfEmployees
    cb_fteGrowth
    cb_top5Investors
    cb_website
    cb_foundedDate
    cb_totalFundingAmount
    cb_lastFundingDate
    cb_estimatedRevenueRange
    cb_numFundingRounds
    cb_lastFundingAmount
    cb_lastFundingType
    cb_lastEquityValuation
    cb_lastEquityFundingType
    cyberdb_revenues
    cyberdbId
    ai_goToMarketChannels
    ai_investmentHighlights
    publicFinancialData {
      yearlyData {
        year
        evRevenue
        evEbitda
        revenue
        ebitda
        analystRevenueEstimates
        __typename
      }
      enterpriseValue
      price
      ruleOf40OpInMargin
      smPercent
      salesAndMarketingYield
      implied5yrLtvCac
      close52WeeksAgo
      close52WeekHigh
      close52WeekLow
      arr
      lastYearArr
      ntmRevenueGrowth
      ltmRevenue
      ltmEbitda
      ltmRevenuePrevious
      ltmEbitdaMargin
      netNewArrGrowthPercent
      netDollarRetention
      grossRetention
      grossMargin
      __typename
    }
    products {
      name
      description
      shortDescription
      type
      featuresV2
      features
      useCases
      revenueModel
      deliveryModel
      targetCustomerProfile
      sources
      markets
      taxonomiesV2
      mcKinseyMarketCategory
      classification
      objectStateJson
      __typename
    }
    productRanks
    caseStudies {
      customerName
      backgroundOnProblem
      howProductHelpedCustomer
      howCustomerJustifiedROI
      sourceLink
      __typename
    }
    marketRisks {
      summary
      details
      mitigation
      __typename
    }
    marketDrivers {
      summary
      details
      __typename
    }
    objectStateJson
    tasks {
      id
      name
      status
      args
      amplifyUserId
      orgName
      error
      startedAt
      finishedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCompanyInfoV3MutationVariables,
  APITypes.CreateCompanyInfoV3Mutation
>;
export const updateCompanyInfoV3 = /* GraphQL */ `mutation UpdateCompanyInfoV3(
  $input: UpdateCompanyInfoV3Input!
  $condition: ModelCompanyInfoV3ConditionInput
) {
  updateCompanyInfoV3(input: $input, condition: $condition) {
    companyName
    companyLabel
    master_market_map_company_name
    isApproved
    linkedinUrl
    iconUrl
    headquartersLocation
    headquartersRegion
    ticker
    fullTimeEmployeeCount
    fullTimeEmployeeCountGrowth6m
    fullTimeEmployeeCountGrowth1y
    fullTimeEmployeeCountGrowth2y
    companyType
    marketComps
    ai_public_comps {
      name
      groupName
      __typename
    }
    cb_organizationPermalink
    cb_acquiredByLabel
    cb_acquiredByPermalink
    cb_acquisitionStatus
    cb_acquisitionTerms
    cb_acquisitionType
    cb_description
    cb_fullDescription
    cb_numberOfEmployees
    cb_fteGrowth
    cb_top5Investors
    cb_website
    cb_foundedDate
    cb_totalFundingAmount
    cb_lastFundingDate
    cb_estimatedRevenueRange
    cb_numFundingRounds
    cb_lastFundingAmount
    cb_lastFundingType
    cb_lastEquityValuation
    cb_lastEquityFundingType
    cyberdb_revenues
    cyberdbId
    ai_goToMarketChannels
    ai_investmentHighlights
    publicFinancialData {
      yearlyData {
        year
        evRevenue
        evEbitda
        revenue
        ebitda
        analystRevenueEstimates
        __typename
      }
      enterpriseValue
      price
      ruleOf40OpInMargin
      smPercent
      salesAndMarketingYield
      implied5yrLtvCac
      close52WeeksAgo
      close52WeekHigh
      close52WeekLow
      arr
      lastYearArr
      ntmRevenueGrowth
      ltmRevenue
      ltmEbitda
      ltmRevenuePrevious
      ltmEbitdaMargin
      netNewArrGrowthPercent
      netDollarRetention
      grossRetention
      grossMargin
      __typename
    }
    products {
      name
      description
      shortDescription
      type
      featuresV2
      features
      useCases
      revenueModel
      deliveryModel
      targetCustomerProfile
      sources
      markets
      taxonomiesV2
      mcKinseyMarketCategory
      classification
      objectStateJson
      __typename
    }
    productRanks
    caseStudies {
      customerName
      backgroundOnProblem
      howProductHelpedCustomer
      howCustomerJustifiedROI
      sourceLink
      __typename
    }
    marketRisks {
      summary
      details
      mitigation
      __typename
    }
    marketDrivers {
      summary
      details
      __typename
    }
    objectStateJson
    tasks {
      id
      name
      status
      args
      amplifyUserId
      orgName
      error
      startedAt
      finishedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCompanyInfoV3MutationVariables,
  APITypes.UpdateCompanyInfoV3Mutation
>;
export const deleteCompanyInfoV3 = /* GraphQL */ `mutation DeleteCompanyInfoV3(
  $input: DeleteCompanyInfoV3Input!
  $condition: ModelCompanyInfoV3ConditionInput
) {
  deleteCompanyInfoV3(input: $input, condition: $condition) {
    companyName
    companyLabel
    master_market_map_company_name
    isApproved
    linkedinUrl
    iconUrl
    headquartersLocation
    headquartersRegion
    ticker
    fullTimeEmployeeCount
    fullTimeEmployeeCountGrowth6m
    fullTimeEmployeeCountGrowth1y
    fullTimeEmployeeCountGrowth2y
    companyType
    marketComps
    ai_public_comps {
      name
      groupName
      __typename
    }
    cb_organizationPermalink
    cb_acquiredByLabel
    cb_acquiredByPermalink
    cb_acquisitionStatus
    cb_acquisitionTerms
    cb_acquisitionType
    cb_description
    cb_fullDescription
    cb_numberOfEmployees
    cb_fteGrowth
    cb_top5Investors
    cb_website
    cb_foundedDate
    cb_totalFundingAmount
    cb_lastFundingDate
    cb_estimatedRevenueRange
    cb_numFundingRounds
    cb_lastFundingAmount
    cb_lastFundingType
    cb_lastEquityValuation
    cb_lastEquityFundingType
    cyberdb_revenues
    cyberdbId
    ai_goToMarketChannels
    ai_investmentHighlights
    publicFinancialData {
      yearlyData {
        year
        evRevenue
        evEbitda
        revenue
        ebitda
        analystRevenueEstimates
        __typename
      }
      enterpriseValue
      price
      ruleOf40OpInMargin
      smPercent
      salesAndMarketingYield
      implied5yrLtvCac
      close52WeeksAgo
      close52WeekHigh
      close52WeekLow
      arr
      lastYearArr
      ntmRevenueGrowth
      ltmRevenue
      ltmEbitda
      ltmRevenuePrevious
      ltmEbitdaMargin
      netNewArrGrowthPercent
      netDollarRetention
      grossRetention
      grossMargin
      __typename
    }
    products {
      name
      description
      shortDescription
      type
      featuresV2
      features
      useCases
      revenueModel
      deliveryModel
      targetCustomerProfile
      sources
      markets
      taxonomiesV2
      mcKinseyMarketCategory
      classification
      objectStateJson
      __typename
    }
    productRanks
    caseStudies {
      customerName
      backgroundOnProblem
      howProductHelpedCustomer
      howCustomerJustifiedROI
      sourceLink
      __typename
    }
    marketRisks {
      summary
      details
      mitigation
      __typename
    }
    marketDrivers {
      summary
      details
      __typename
    }
    objectStateJson
    tasks {
      id
      name
      status
      args
      amplifyUserId
      orgName
      error
      startedAt
      finishedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCompanyInfoV3MutationVariables,
  APITypes.DeleteCompanyInfoV3Mutation
>;
export const createMarketInfoV1 = /* GraphQL */ `mutation CreateMarketInfoV1(
  $input: CreateMarketInfoV1Input!
  $condition: ModelMarketInfoV1ConditionInput
) {
  createMarketInfoV1(input: $input, condition: $condition) {
    marketName
    description
    drivers
    restraints
    opportunities
    challenge
    cagr
    fromYear
    toYear
    fromValue
    toValue
    publishedDate
    sources
    objectStateJson
    tasks {
      id
      name
      status
      args
      amplifyUserId
      orgName
      error
      startedAt
      finishedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMarketInfoV1MutationVariables,
  APITypes.CreateMarketInfoV1Mutation
>;
export const updateMarketInfoV1 = /* GraphQL */ `mutation UpdateMarketInfoV1(
  $input: UpdateMarketInfoV1Input!
  $condition: ModelMarketInfoV1ConditionInput
) {
  updateMarketInfoV1(input: $input, condition: $condition) {
    marketName
    description
    drivers
    restraints
    opportunities
    challenge
    cagr
    fromYear
    toYear
    fromValue
    toValue
    publishedDate
    sources
    objectStateJson
    tasks {
      id
      name
      status
      args
      amplifyUserId
      orgName
      error
      startedAt
      finishedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMarketInfoV1MutationVariables,
  APITypes.UpdateMarketInfoV1Mutation
>;
export const deleteMarketInfoV1 = /* GraphQL */ `mutation DeleteMarketInfoV1(
  $input: DeleteMarketInfoV1Input!
  $condition: ModelMarketInfoV1ConditionInput
) {
  deleteMarketInfoV1(input: $input, condition: $condition) {
    marketName
    description
    drivers
    restraints
    opportunities
    challenge
    cagr
    fromYear
    toYear
    fromValue
    toValue
    publishedDate
    sources
    objectStateJson
    tasks {
      id
      name
      status
      args
      amplifyUserId
      orgName
      error
      startedAt
      finishedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMarketInfoV1MutationVariables,
  APITypes.DeleteMarketInfoV1Mutation
>;
