import { Card, Text } from '@aws-amplify/ui-react'
import { FunctionComponent } from 'react'
import { Area, AreaChart, Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import GlassCard from '../figma-components/GlassCard'
import { c, currencyFormatterShort } from '../utils/utils'

export type ChartData = {
  moneyRaised: number
  announcedYear: number
  announcedDate?: string
  leadInvestors: string
  fundingType: string
  preMoneyValuation: number
  description: string
}

interface ChartsProps {
  raiseData: ChartData[]
  crunchbaseSource: React.ReactNode
}

const Charts: FunctionComponent<ChartsProps> = ({ raiseData, crunchbaseSource }) => {
  const thisYear = new Date().getFullYear()
  const chartData: ChartData[] = []
  const fundingTypes = {}
  const oldRaiseData = raiseData.filter((predicate) => predicate.announcedYear < thisYear - 5)
  const oldMoneyRaised = oldRaiseData.reduce((p, c) => p + (c.moneyRaised || 0), 0)
  for (let i = thisYear - 5; i < thisYear + 1; i++) {
    chartData.push({ moneyRaised: 0, announcedYear: i, leadInvestors: '', fundingType: '', preMoneyValuation: 0, description: '' })
  }
  for (const raise of raiseData) {
    const chartDataItem = chartData.find((p) => p.announcedYear === raise.announcedYear)
    if (chartDataItem) {
      fundingTypes[raise.fundingType] = true
      Object.assign(chartDataItem, raise)
      if (!chartDataItem[raise.fundingType] && raise.moneyRaised) chartDataItem[raise.fundingType] = 0
      chartDataItem[raise.fundingType] += raise.moneyRaised
      continue
    }
  }

  const cumulativeMoneyRaisedAtAnnouncedDate = chartData.reduce((a, b, i) => {
    let moneyRaised = Object.keys(fundingTypes).reduce((p, c) => p + (b[c] || 0), 0)
    // add old money raised to first year
    if (i === 0) moneyRaised += oldMoneyRaised
    return [...a, { ...b, moneyRaised: (a[a.length - 1]?.moneyRaised || 0) + moneyRaised }]
  }, [] as any[])

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const round = payload[0].name
      const { fundingType, announcedYear, leadInvestors, preMoneyValuation, description } = payload[0].payload
      const moneyRaised = payload[0].payload[round]
      return (
        <Card variation='elevated' z-index={500}>
          <Text>Money raised: {currencyFormatterShort(moneyRaised)} </Text>
          {round ? <Text>Round: {round} </Text> : null}
          {fundingType ? <Text>Funding Type: {fundingType} </Text> : null}
          {announcedYear ? <Text>Year Announced: {announcedYear} </Text> : null}
          {leadInvestors ? <Text>Lead Investors: {leadInvestors} </Text> : null}
          {preMoneyValuation ? <Text>Pre-Money Valuation: {currencyFormatterShort(preMoneyValuation)} </Text> : null}
          {description ? <Text>Description: {description} </Text> : null}
        </Card>
      )
    }

    return null
  }

  const renderColorfulLegendText = (value: string, entry: any) => {
    return <span style={{ color: 'black' }}>{value}</span>
  }

  return (
    <>
      <GlassCard header='Capital Raised by Round' className='overlayCard'>
        <ResponsiveContainer height={300}>
          <BarChart
            data={chartData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='announcedYear' />
            <YAxis tickFormatter={currencyFormatterShort} />
            <Tooltip wrapperStyle={{ zIndex: 1000 }} content={<CustomTooltip />} />
            <Legend verticalAlign='top' align='center' formatter={renderColorfulLegendText} />
            {Object.keys(fundingTypes).map((key, i) => (
              <Bar key={i} dataKey={key} stackId='a' fill={`hsl(${(210 + i * 50) % 360}, 80%, 75%)`} />
            ))}
          </BarChart>
        </ResponsiveContainer>
        {crunchbaseSource}
      </GlassCard>
      <GlassCard header='Cumulative Capital Raised' className='overlayCard'>
        <ResponsiveContainer height={300}>
          <AreaChart
            data={cumulativeMoneyRaisedAtAnnouncedDate}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='announcedYear' />
            <YAxis tickFormatter={currencyFormatterShort} />
            <Tooltip formatter={currencyFormatterShort} />
            <Area type='monotone' dataKey='moneyRaised' stroke='#8884d8' fill='#8884d8' />
          </AreaChart>
        </ResponsiveContainer>
        {crunchbaseSource}
      </GlassCard>
    </>
  )
}

export default Charts
