import { createContext, FunctionComponent, PropsWithChildren, useContext } from 'react'
import { useOrgState } from '../hooks/useOrgState'
import { Org } from '../models'

export const OrgContext = createContext<Org | undefined>(undefined)
export const OrgSetContext = createContext<Function>(() => {})

export const OrgProvider: FunctionComponent<PropsWithChildren> = ({ children, ...props }) => {
  const [org, updateOrg] = useOrgState()
  return (
    <OrgContext.Provider {...props} value={org}>
      <OrgSetContext.Provider {...props} value={updateOrg}>
        {children}
      </OrgSetContext.Provider>
    </OrgContext.Provider>
  )
}

export const useOrg = (): Org | undefined => useContext(OrgContext)
export const useSetOrg = (): Function => useContext(OrgSetContext)
