import { useQuery } from '@tanstack/react-query'
import { restAPIRequest } from '../services/restApiRequest'

export type GetCyberRes = Record<
  string,
  {
    companyName: string
    companyLabel: string
    img: string
    shortDescription: string
    cb_organizationPermalink: string
    ticker?: string
  }
>

export function useCyberNames(): [GetCyberRes, boolean] {
  const { isLoading, data } = useQuery({
    queryKey: ['cyberNames'],
    staleTime: 1000 * 60 * 60 * 24,
    queryFn: () =>
      restAPIRequest<GetCyberRes>({
        path: 'cyber',
        method: 'get',
      }),
  })

  return [data || {}, isLoading]
}
