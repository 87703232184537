/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function MetricsBenchmarkingIcon(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="20px"
      height="20px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 20, height: 20 }}
      paths={[
        {
          d: "M1 10.6667L4.66667 10.6667C4.93179 10.6664 5.18596 10.5609 5.37343 10.3734C5.5609 10.186 5.66636 9.93179 5.66667 9.66667L5.66667 1C5.66636 0.734878 5.5609 0.480704 5.37343 0.293235C5.18596 0.105765 4.93179 0.000308769 4.66667 0L1 0C0.734878 0.000308769 0.480704 0.105765 0.293234 0.293235C0.105765 0.480704 0.000308769 0.734878 0 1L0 9.66667C0.000308769 9.93179 0.105765 10.186 0.293234 10.3734C0.480704 10.5609 0.734878 10.6664 1 10.6667ZM1.33333 1.33333L4.33333 1.33333L4.33333 9.33333L1.33333 9.33333L1.33333 1.33333Z",
          fill: "rgba(48,53,69,1)",
          fillRule: "nonzero",
          style: { transform: "translate(20%, 30%)" },
        },
        {
          d: "M4.66667 0L1 0C0.734878 0.000308769 0.480704 0.105765 0.293234 0.293234C0.105765 0.480704 0.000308769 0.734878 0 1L0 15.6667C0.000308769 15.9318 0.105765 16.186 0.293234 16.3734C0.480704 16.5609 0.734878 16.6664 1 16.6667L4.66667 16.6667C4.93179 16.6664 5.18596 16.5609 5.37343 16.3734C5.5609 16.186 5.66636 15.9318 5.66667 15.6667L5.66667 1C5.66636 0.734878 5.5609 0.480704 5.37343 0.293234C5.18596 0.105765 4.93179 0.000308769 4.66667 0ZM4.33333 15.3333L1.33333 15.3333L1.33333 1.33333L4.33333 1.33333L4.33333 15.3333Z",
          fill: "rgba(48,53,69,1)",
          fillRule: "nonzero",
          style: { transform: "translate(61.67%, 0%)" },
        },
        {
          d: "M1.33333 0L0 0L0 20L20 20L20 18.6667L1.33333 18.6667L1.33333 0Z",
          fill: "rgba(48,53,69,1)",
          fillRule: "nonzero",
          style: { transform: "translate(0%, 0%)" },
        },
      ]}
      {...getOverrideProps(overrides, "MetricsBenchmarkingIcon")}
      {...rest}
    ></Icon>
  );
}
