import { GraphQLQuery, GraphQLSubscription, generateClient } from '@aws-amplify/api'
import { graphqlOperation } from '@aws-amplify/api-graphql'
import { useQuery } from '@tanstack/react-query'
import { fetchAuthSession } from 'aws-amplify/auth'
import { useEffect, useState } from 'react'
import { CompanyInfoV3, GetCompanyInfoV3Query, OnUpdateCompanyInfoV3Subscription, OnUpdateCompanyInfoV3SubscriptionVariables } from '../API'
import { getCompanyInfoV3 } from '../graphql/queries'
import { onUpdateCompanyInfoV3 } from '../graphql/subscriptions'
import { Error404 } from '../types'

const API = generateClient()

const MAX_RETRIES = 5

export function useCompanyInfoV2(targetCompanyName?: string): [CompanyInfoV3 | undefined, boolean, Error | undefined] {
  const [subError, setError] = useState<Error>()
  const [data, setData] = useState<CompanyInfoV3>()
  const {
    isLoading,
    data: initData,
    error,
  } = useQuery({
    enabled: !!targetCompanyName,
    queryKey: ['companyInfo', targetCompanyName],
    retry: (failureCount, error) => {
      if (failureCount > MAX_RETRIES) {
        return false
      }
      if (error instanceof Error404) {
        return false
      }

      return true
    },
    staleTime: 1000 * 60 * 60 * 24,
    queryFn: queryCompanyInfoV3(targetCompanyName),
  })

  useEffect(() => {
    if (!targetCompanyName) return

    const vars: OnUpdateCompanyInfoV3SubscriptionVariables = {
      filter: {
        companyName: { eq: targetCompanyName },
      },
    }
    const sub = API.graphql<GraphQLSubscription<OnUpdateCompanyInfoV3Subscription>>(graphqlOperation(onUpdateCompanyInfoV3, vars)).subscribe({
      next: (value) => {
        const newData = value?.data?.onUpdateCompanyInfoV3 as CompanyInfoV3
        setData(newData)
        setError(undefined)
      },
      error: (e) => {
        setError(e)
      },
    })
    // Stop receiving data updates from the subscription
    return () => {
      sub.unsubscribe()
      setData(undefined)
    }
  }, [targetCompanyName])

  return [data || initData, isLoading, subError || (error as Error)]
}

export function queryCompanyInfoV3(targetCompanyName: string | undefined) {
  return async () => {
    try {
      const { idToken } = (await fetchAuthSession()).tokens ?? {}
      const args = graphqlOperation(getCompanyInfoV3, { companyName: targetCompanyName })
      if (!idToken) (args as any).authMode = 'iam'
      const info = await API.graphql<GraphQLQuery<GetCompanyInfoV3Query>>(args)
      if (!info?.data?.getCompanyInfoV3) throw new Error404(`${targetCompanyName} not found in Company Info DB`)
      return info?.data?.getCompanyInfoV3
    } catch (error) {
      console.error('error queryCompanyInfoV3', error)
      throw error
    }
  }
}
