import '../styles/equityCalculator.scss'

import { Alert, Button, Flex, Heading, Image, Link, Loader, SelectField, Table, TableBody, TableCell, TableHead, TableRow, Text, View } from '@aws-amplify/ui-react'
import { FunctionComponent, useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import { ExitWaterfallCalcs, ExitWaterfallRes } from '../TransactionModelInput'
import CurrencyInput from '../components/CurrencyInput'
import DateInput from '../components/DateInput'
import EquityChart from '../components/EquityChart'
import FeatureRequest from '../components/FeatureRequest'
import MultipleInput from '../components/MultipleInput'
import NumberInput from '../components/NumberInput'
import { getGrossProceedsChartValues } from '../components/WaterfallCharts'
import GlassCard from '../figma-components/GlassCard'
import { useCompanyInfoV2 } from '../hooks/useCompanyInfoV2'
import { useConfigTable } from '../hooks/useConfigTable'
import { CompMarketSize, CompetitiveProducts, useOneShot } from '../hooks/useOneShot'
import { useProductSimilaritySearchList } from '../hooks/useProductSimilaritySearchList'
import { restAPIRequest } from '../services/restApiRequest'
import { sendSlack } from '../services/slack'
import { ExistingShareHolder } from '../types'
import { CloseIcon } from '../ui-components'
import PageLoader from '../ui-components/PageLoader'
import { currencyFormatterShort, longCurrencyFormat, shortCurrencyFormat } from '../utils/utils'
import Select from 'react-select'

type ShareTypes = 'RSU' | 'Stock Options'
type OutputTypes = 'Chart' | 'Table'

type BaseProps = {
  companyName: string
}

const INTERVAL = 2
const CAGRMap: [number[], number[], number][] = [
  // fte range, cagr, fte to rev
  [[0, 50], [1.5, 1.2, 1.0, 0.8, 0.4, 0.15], 100000],
  [[50, 200], [1.3, 1.1, 0.9, 0.7, 0.55, 0.1], 125000],
  [[200, 400], [1.2, 1.0, 0.8, 0.6, 0.45, 0.5], 125000],
  [[400, 800], [1.1, 0.9, 0.7, 0.5, 0.35, 0.25], 150000],
  [[800, 1500], [1.0, 0.8, 0.6, 0.4, 0.25, 0.0], 150000],
  [[1500, 2500], [0.9, 0.7, 0.5, 0.3, 0.15, 0.0], 175000],
  [[2500, Infinity], [0.5, 0.4, 0.3, 0.1, 0.5, 0.0], 200000],
]
export const longPercentFormat = new Intl.NumberFormat('en-US', {
  notation: 'compact',
  compactDisplay: 'short',
  style: 'percent',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
})

type Overrides = {
  revMultiples: number[]
  cagr?: number[]
  revenue?: number
  fte?: number
}

function getExitValues(fte: number, exitDate: Date, overrides: Overrides) {
  const { CAGR, revenue } = getInfo(overrides.fte || fte)
  const revMultiples = overrides.revMultiples
  let years = new Date(exitDate).getFullYear() - new Date().getFullYear()
  if (years < 0) years = 1
  return (overrides.cagr || CAGR).map((cagr, i) => {
    return Math.min(Math.round((overrides.revenue || revenue) * Math.pow(1 + cagr, years) * revMultiples[i]), Number.MAX_SAFE_INTEGER - 1)
  })
}

function getInfo(fte) {
  const out = CAGRMap.find((c) => c[0][0] <= fte && fte <= c[0][1])
  if (!out) throw new Error('No range')
  const [range, CAGR, ftrToRef] = out
  return { range, CAGR, revenue: ftrToRef * fte }
}

const CompanyHeader: FunctionComponent<
  BaseProps & {
    overrides: Overrides
    fte?: number
    compMarketSize?: CompMarketSize
    compsChartModalHandler: (boolean) => void
    competitorsModalHandler: (boolean) => void
    welcomeMessageModalHandler: (boolean) => void
    setIsOverridesModalVisible: (boolean) => void
  }
> = ({ companyName, fte = 0, compMarketSize, overrides, welcomeMessageModalHandler, competitorsModalHandler, compsChartModalHandler, setIsOverridesModalVisible }) => {
  const [companyInfo] = useCompanyInfoV2(companyName)
  const [cms, isLoading] = useOneShot(companyName, 'compMarketSize', undefined, !compMarketSize)
  const data = compMarketSize || cms
  const companyLogoImg = companyInfo?.iconUrl ? <Image alt='company image' src={companyInfo?.iconUrl} maxHeight={80} borderRadius='0.5rem' /> : null
  if (!companyInfo || (isLoading && !compMarketSize)) return <Loader filledColor='#1C4E98' alignSelf='center' />
  const fteF = overrides.fte || fte || 1
  const revenue = overrides.revenue || getInfo(fteF).revenue || 0
  return (
    <Flex direction='column' gap='0.5rem'>
      <Flex className='equityCalculatorHeader'>
        <Flex alignItems='center'>
          {companyLogoImg}
          <Flex direction='column' gap='0'>
            <Flex gap='0.5rem'>
              <Heading level={4}>{companyInfo?.companyLabel}</Heading>
            </Flex>
            <Heading level={5} marginBottom='0'>
              Equity Calculator
            </Heading>
          </Flex>
        </Flex>
        <Flex alignItems='center'>
          <Flex direction='column' gap='0.25rem' textAlign='center'>
            <Text fontWeight='600'>Est. Revenue</Text>
            <Text>~{currencyFormatterShort(revenue)}</Text>
          </Flex>
          <Flex direction='column' gap='0.25rem' textAlign='center'>
            <Text fontWeight='600'>Market Size</Text>
            <View backgroundColor='#EFF0F1' borderRadius='0.25rem'>
              <Text>{data?.market_size}</Text>
            </View>
          </Flex>
        </Flex>
        <Flex className='learnMoreButtons'>
          <Button size='small' onClick={() => welcomeMessageModalHandler(true)}>
            How it Works
          </Button>
          <Button size='small' onClick={() => competitorsModalHandler(true)}>
            Top Competitors
          </Button>
          <Button size='small' onClick={() => compsChartModalHandler(true)}>
            Similar Companies vs Market
          </Button>
          <Button size='small' variation='destructive' onClick={() => setIsOverridesModalVisible(true)}>
            Edit Variables
          </Button>
        </Flex>
      </Flex>
      <Text>
        {companyInfo?.companyLabel} sells to {data?.customer_types?.join(', ') || 'N/A'}.
      </Text>
    </Flex>
  )
}

type CompInfoProps = BaseProps & { cachedCompetitiveProducts?: CompetitiveProducts[] }
const CompInfo: FunctionComponent<CompInfoProps> = ({ companyName, cachedCompetitiveProducts }) => {
  const [companyInfo, isLoading] = useCompanyInfoV2(companyName)
  const similarProductsList = useProductSimilaritySearchList(companyName, companyInfo?.products || [])
  const isListLoading = similarProductsList.some((s) => s.isLoading)
  const uniqMap: Record<
    string,
    {
      score: number
      productName: string
      competitorName: string
      competitorProduct: string
      competitorDescription: string
    }
  > = {} as any
  if (!isListLoading) {
    similarProductsList.forEach((s) =>
      s.data?.forEach((d) =>
        d.results.forEach((r) => {
          if (!uniqMap[r.companyName] || uniqMap[r.companyName].score > r.score) {
            uniqMap[r.companyName] = {
              score: r.score,
              productName: s.targetProductName,
              competitorName: r.companyLabel,
              competitorProduct: r.productName,
              competitorDescription: r.description,
            }
          }
        })
      )
    )
  }

  const info = Object.keys(uniqMap)
    .sort((a, b) => uniqMap[a].score - uniqMap[b].score)
    .slice(0, 10)
    .map((l) => {
      const info = uniqMap[l]
      const myProduct = companyInfo?.products?.find((p) => p.name === info.productName)
      return {
        productName: info.productName,
        description: myProduct?.description,
        competitorName: info.competitorName,
        competitorProduct: info.competitorProduct,
        competitorDescription: info.competitorDescription,
      }
    })
  const [compProducts, isLoadingComp] = useOneShot(companyName, 'competitiveProducts', info, !isListLoading && !cachedCompetitiveProducts)
  const loader = <Loader filledColor='#1C4E98' alignSelf='center' />
  return (
    <Flex direction='column'>
      {isLoading ? (
        loader
      ) : (
        <Text>
          {companyInfo?.companyLabel} has ~{isListLoading ? loader : Object.keys(uniqMap).length} close competitors.
        </Text>
      )}
      {isLoadingComp && !cachedCompetitiveProducts ? (
        loader
      ) : (
        <Flex direction='column' gap='0.5rem'>
          <Text>The following are its top competitors:</Text>
          <Table caption='' highlightOnHover={true} variation='striped' size='small'>
            <TableHead backgroundColor='#E4EDFF'>
              <TableRow>
                <TableCell as='th'>Competitor</TableCell>
                <TableCell as='th'>Differentiation</TableCell>
                <TableCell as='th'>Gaps</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(cachedCompetitiveProducts || compProducts || []).map((d) => {
                return (
                  <TableRow key={`${d.competitorName}`}>
                    <TableCell>{d?.competitorName}</TableCell>
                    <TableCell>{d?.differentiation}</TableCell>
                    <TableCell>{d?.gaps}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          <Text color='darkslategray'>
            We dynamically identify competitors in real-time based on the competition faced by the current products and services offered by {companyInfo?.companyLabel}
          </Text>
        </Flex>
      )}
    </Flex>
  )
}

type OutputProps = { calculation: ExitWaterfallRes | undefined; added: ExitWaterfallCalcs | undefined; exitDate: Date; shareCount?: number; sharePrice: number; outputType: string }
const EquityCalculatorOutput: FunctionComponent<OutputProps> = ({ calculation, added, exitDate, shareCount, sharePrice, outputType }) => {
  if (!calculation || !shareCount) {
    return (
      <Text marginTop='8rem' fontWeight='500' color='#595D6D'>
        Enter <strong>Your Equity</strong> details to view your returns
      </Text>
    )
  }

  const fullData = [added, ...calculation.calcs]
  const yearsDiff = exitDate.getFullYear() - new Date().getFullYear()
  const totalNumberOfShares = calculation.calcs[0].groupCalcs.reduce((p, c) => p + c.shareCount, 0) || 0
  const totalCost = shareCount * sharePrice

  function getProceeds(i: number) {
    if (!calculation) return longCurrencyFormat.format(0)
    const p = calculation.calcs[i].groupCalcs.find((i) => i.group === 'Playbook')?.proceeds
    if (!p || p === 0 || p - totalCost <= 0) return longCurrencyFormat.format(0)
    const ret = p - totalCost
    if (ret >= 1000000) return shortCurrencyFormat.format(ret)
    return longCurrencyFormat.format(p - totalCost || 0)
  }

  function getProceedsPerShare(i: number) {
    if (!calculation) return longCurrencyFormat.format(0)
    return longCurrencyFormat.format(calculation.calcs[i].groupCalcs.find((i) => i.group === 'Playbook')?.proceedsPerShare || 0)
  }

  return (
    <Flex direction='column' gap='2rem' marginTop='2rem'>
      <Flex direction='column' textAlign='center' gap='0.5rem'>
        <Heading level={4} color='#1C4E98'>
          {`In ${yearsDiff} years, you will make ~${getProceeds(3)} to ${getProceeds(0)}`}
        </Heading>
        <Text>
          Your equity ownership is <strong>~{longPercentFormat.format(shareCount / totalNumberOfShares)}</strong>
        </Text>
      </Flex>
      <View className='equityCalculatorOutput'>
        {outputType === 'Chart' && (
          <EquityChart
            interval={INTERVAL}
            data={getGrossProceedsChartValues(fullData)}
            chartYType='currency'
            highExit={calculation.calcs[1].salePrice}
            lowExit={calculation.calcs[4].salePrice}
          />
        )}
        {outputType === 'Table' && (
          <Table size='small' margin='0 auto'>
            <TableHead>
              <TableRow>
                <TableCell as='th' colSpan={1} className='groupTableHeader'>
                  Scenario
                </TableCell>
                <TableCell as='th' colSpan={1} className='groupTableHeader'>
                  Returns
                </TableCell>
                <TableCell as='th' colSpan={1} className='groupTableHeader'>
                  Price per Share
                </TableCell>
                <TableCell as='th' colSpan={1} className='groupTableHeader'>
                  Valuation
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className='tableCell' textAlign='center' padding='0.75rem' fontWeight='600' color='#43A854'>
                  Best-Case <small>(15% chance)</small>
                </TableCell>
                <TableCell className='tableCell' textAlign='center' padding='0.75rem'>
                  <Text color='#43A854' display='inline' fontWeight='600'>
                    {getProceeds(1)}
                  </Text>{' '}
                  to{' '}
                  <Text color='#43A854' display='inline' fontWeight='600'>
                    {getProceeds(0)}
                  </Text>
                </TableCell>
                <TableCell className='tableCell' textAlign='center' padding='0.75rem'>
                  <Text color='#43A854' display='inline' fontWeight='600'>
                    {getProceedsPerShare(1)}
                  </Text>{' '}
                  to{' '}
                  <Text color='#43A854' display='inline' fontWeight='600'>
                    {getProceedsPerShare(0)}
                  </Text>
                </TableCell>
                <TableCell className='tableCell' textAlign='center' padding='0.75rem'>
                  <Text color='#43A854' display='inline' fontWeight='600'>
                    {shortCurrencyFormat.format(calculation.calcs[1].salePrice || 0)}
                  </Text>{' '}
                  to{' '}
                  <Text color='#43A854' display='inline' fontWeight='600'>
                    {shortCurrencyFormat.format(calculation.calcs[0].salePrice || 0)}
                  </Text>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className='tableCell' textAlign='center' padding='0.75rem' fontWeight='600' color='#1C4E98'>
                  Expected <small>(70% chance)</small>
                </TableCell>
                <TableCell className='tableCell' textAlign='center' padding='0.75rem'>
                  <Text color='#1C4E98' display='inline' fontWeight='600'>
                    {getProceeds(3)}
                  </Text>{' '}
                  to{' '}
                  <Text color='#1C4E98' display='inline' fontWeight='600'>
                    {getProceeds(2)}
                  </Text>
                </TableCell>
                <TableCell className='tableCell' textAlign='center' padding='0.75rem'>
                  <Text color='#1C4E98' display='inline' fontWeight='600'>
                    {getProceedsPerShare(3)}
                  </Text>{' '}
                  to{' '}
                  <Text color='#1C4E98' display='inline' fontWeight='600'>
                    {getProceedsPerShare(2)}
                  </Text>
                </TableCell>
                <TableCell className='tableCell' textAlign='center' padding='0.75rem'>
                  <Text color='#1C4E98' display='inline' fontWeight='600'>
                    {shortCurrencyFormat.format(calculation.calcs[3].salePrice || 0)}
                  </Text>{' '}
                  to{' '}
                  <Text color='#1C4E98' display='inline' fontWeight='600'>
                    {shortCurrencyFormat.format(calculation.calcs[2].salePrice || 0)}
                  </Text>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className='tableCell' textAlign='center' padding='0.75rem' fontWeight='600' color='#BF4040'>
                  Worst-Case <small>(15% chance)</small>
                </TableCell>
                <TableCell className='tableCell' textAlign='center' padding='0.75rem'>
                  <Text color='#BF4040' display='inline' fontWeight='600'>
                    {getProceeds(5)}
                  </Text>{' '}
                  to{' '}
                  <Text color='#BF4040' display='inline' fontWeight='600'>
                    {getProceeds(4)}
                  </Text>
                </TableCell>
                <TableCell className='tableCell' textAlign='center' padding='0.75rem'>
                  <Text color='#BF4040' display='inline' fontWeight='600'>
                    {getProceedsPerShare(5)}
                  </Text>{' '}
                  to{' '}
                  <Text color='#BF4040' display='inline' fontWeight='600'>
                    {getProceedsPerShare(4)}
                  </Text>
                </TableCell>
                <TableCell className='tableCell' textAlign='center' padding='0.75rem'>
                  <Text color='#BF4040' display='inline' fontWeight='600'>
                    {shortCurrencyFormat.format(calculation.calcs[5].salePrice || 0)}
                  </Text>{' '}
                  to{' '}
                  <Text color='#BF4040' display='inline' fontWeight='600'>
                    {shortCurrencyFormat.format(calculation.calcs[4].salePrice || 0)}
                  </Text>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </View>
    </Flex>
  )
}

type OverridesInputProps = { overrides: Overrides; setOverrides: (o: Overrides) => void; fte: number }
const OverridesInput: FunctionComponent<OverridesInputProps> = ({ fte, overrides, setOverrides }) => {
  const cases = ['Best', 'Expected', 'Worst']
  const { CAGR, revenue } = getInfo(overrides.fte || fte)
  return (
    <Flex direction='row' gap='1rem'>
      <NumberInput
        key={'fte'}
        label={`FTE`}
        value={overrides.fte || fte || 1}
        onValueChange={(v) => {
          setOverrides({ ...overrides, fte: v })
        }}
      />
      <NumberInput
        key={'revenue'}
        label={`Revenue`}
        value={overrides.revenue || revenue || 1}
        onValueChange={(v) => {
          setOverrides({ ...overrides, revenue: v })
        }}
      />
      <Flex direction='column' gap='0'>
        {cases.map((c, i) => {
          return (
            <NumberInput
              key={c}
              label={`Revenue Multiple for the ${c} Case.`}
              value={overrides.revMultiples?.[i * 2] || 0}
              onValueChange={(v) => {
                const revMultiples = [...overrides.revMultiples]
                revMultiples[i * 2] = v
                setOverrides({ ...overrides, revMultiples })
              }}
            />
          )
        })}
      </Flex>
      <Flex direction='column' gap='0'>
        {cases.map((c, i) => {
          return (
            <MultipleInput
              key={c}
              label={`CAGR Multiple for the ${c} Case.`}
              value={overrides.cagr?.[i * 2] || CAGR[i * 2] || 0}
              onValueChange={(v) => {
                const cagr = [...(overrides.cagr || CAGR)]
                cagr[i * 2] = v
                setOverrides({ ...overrides, cagr })
              }}
            />
          )
        })}
      </Flex>
    </Flex>
  )
}
const EquityCalculator: FunctionComponent<BaseProps> = ({ companyName }) => {
  const [exitDate, setExitDate] = useState<Date>(new Date(new Date().setFullYear(new Date().getFullYear() + 4)))
  const [overrides, setOverrides] = useState<Overrides>({
    revMultiples: [8, 7, 5, 4, 3, 2],
  })
  const [type, setType] = useState<ShareTypes>('Stock Options')
  const [shareCount, setShareCount] = useState<number>()
  const [sharePrice, setSharePrice] = useState<number>(1)
  const [outputType, setOutputType] = useState<OutputTypes>('Chart')
  const [error, setError] = useState<string>()
  const [added, setAdded] = useState<ExitWaterfallCalcs>()
  const [isCompsChartModalVisible, setIsCompsChartModalVisible] = useState<boolean>(false)
  const [isCompetitorsModalVisible, setIsCompetitorsModalVisible] = useState<boolean>(false)
  const [capTable, capTableLoading, capTableError] = useConfigTable('public_cap_tables', {
    findKey: 'company_name',
    value: companyName,
  })
  const publicInfo = capTable[0] || {}
  const cap_table_json = publicInfo.cap_table_json
  const fte = publicInfo.fte
  const competitiveProducts = publicInfo.competitive_products ? JSON.parse(publicInfo.competitive_products).output : undefined
  const compMarketSizeRaw = publicInfo.comp_market_size ? JSON.parse(publicInfo.comp_market_size).output : undefined
  let compMarketSize = compMarketSizeRaw
  if (compMarketSizeRaw?.customer_types?.type === 'array') {
    compMarketSize = {
      customer_types: compMarketSizeRaw?.customer_types?.items?.value || [],
      market_size: compMarketSizeRaw?.market_size?.value || 'n/a',
    }
  }
  const [calculation, setCalculation] = useState<ExitWaterfallRes>()
  const isWelcomeMessageDismissedKey = 'welcomeMessageDismissed'
  const isWelcomeMessageDismissed = JSON.parse(window.localStorage.getItem(isWelcomeMessageDismissedKey) || 'false')
  const [isWelcomeMessageModalVisible, setIsWelcomeMessageModalVisible] = useState<boolean>(!isWelcomeMessageDismissed)
  const handleWelcomeMessageDismissed = () => {
    window.localStorage.setItem(isWelcomeMessageDismissedKey, JSON.stringify('true'))
    setIsWelcomeMessageModalVisible(false)
  }
  const [isOverridesModalVisible, setIsOverridesModalVisible] = useState<boolean>(false)

  const preferredValue = capTable?.[0]?.cap_table_json?.reduce((p, c) => {
    if (c.type !== 'SHARE_HOLDER_TYPE_SERIES') {
      return p
    }
    return p + c.shareCount * c.liquidationMultiple * c.pricePerShare
  }, 0)
  useEffect(() => {
    if (!capTable || capTableLoading?.get || shareCount === undefined) {
      return
    }
    if (capTableError?.get) {
      return setError(capTableError?.get?.message)
    }
    if (isNaN(exitDate.getTime())) return setError('Invalid Exit Date, Select another date.')
    if (exitDate.getTime() < new Date().getTime()) return setError('Exit Date must be in the future')
    if (shareCount <= 0) return setError('Share count must be greater than 0')
    if (type === 'Stock Options' && sharePrice <= 0) return setError('Share price must be greater than 0')

    const getWaterfall = async () => {
      const addedCapTable = cap_table_json.map((c) => ({
        ...c,
        exitDate: exitDate.toISOString(),
      }))

      const newShareholder: Partial<ExistingShareHolder> = {
        exitDate: exitDate,
        group: 'Playbook',
        shareholderName: 'User',
        shareCount,
        investmentDate: new Date(exitDate.getTime() - 1000 * 60 * 60 * 24 * 365 * 3),
      }

      if (type === 'RSU') {
        newShareholder.type = 'SHARE_HOLDER_TYPE_COMMON'
      } else {
        newShareholder.type = 'SHARE_HOLDER_TYPE_OPTION'
        // @ts-ignore
        newShareholder.strikePrice = sharePrice
      }

      // @ts-ignore
      addedCapTable.push(newShareholder)

      const finFte = overrides.fte || fte || 1
      try {
        const range = getExitValues(finFte, exitDate, overrides)
        const len = range.length
        for (let i = 1; i < len; i++) {
          range.push(Math.round((range[i] + range[i - 1]) / 2))
        }

        const res = await restAPIRequest<ExitWaterfallRes>({
          method: 'post',
          path: 'calculation/exitWaterfall',
          body: {
            capTable: addedCapTable,
            netDebtAtExit: 0,
            exitEVLowRange: 0,
            exitEVHighRange: 1,
            range,
          },
        })
        let bp = res.breakpoints['User|-*-*-|Playbook']
        if (!bp) {
          bp = addedCapTable.reduce((p, c) => {
            if (c.type !== 'SHARE_HOLDER_TYPE_SERIES') return p
            return p + c.shareCount * c.liquidationMultiple * c.pricePerShare
          }, 0)
        }

        setAdded({
          groupCalcs: [
            {
              group: 'Playbook',
              proceeds: 0,
              proceedsPerShare: 0,
              shareCount,
              momReturns: 0,
              irr: 0,
              amountInvested: 0,
            },
          ],
          salePrice: bp,
          calcs: [
            {
              group: 'Playbook',
              shareholderName: '',
              investmentDate: new Date(),
              exitDate: new Date(),
              loanAmount: 0,
              didConvert: false,
              preferredDistribution: 0,
              commonDistribution: 0,
              participatingDistribution: 0,
              momReturns: 0,
              irr: 0,
              amountInvested: 0,
              proceeds: 0,
              proceedsPerShare: 0,
              shareCount,
            },
          ],
          shareholderCalcs: [
            {
              shareholderName: 'User',
              shareCount,
              momReturns: 0,
              irr: 0,
              amountInvested: 0,
              proceeds: 0,
              proceedsPerShare: 0,
            },
          ],
        })
        setCalculation(res)
        setError(undefined)
      } catch (error: any) {
        console.error('waterfallError', error)
        setError(JSON.parse(error.response?.body || '{}')?.error || error.message)
        setCalculation(undefined)
      }
    }
    getWaterfall().catch((e) => console.error(e))
  }, [JSON.stringify(capTable), exitDate, type, shareCount, sharePrice, JSON.stringify(overrides)])

  if (!capTable || capTableLoading?.get) return <PageLoader />
  if (!capTableLoading?.get && capTable.length === 0)
    return (
      <Flex direction='column' height='100vh' justifyContent='center' alignContent='center'>
        <Flex justifyContent='center' alignContent='center'>
          <FeatureRequest
            featureArg={companyName}
            info={{ companyName }}
            header={`Request Equity Calculator for ${companyName}`}
            requestMsg={`We currently do not have ${companyName} available, click to request!`}
            featureName={'publicCapTable'}
          />
        </Flex>
      </Flex>
    )

  const returnsHeaderButtons = (
    <Flex alignItems='center'>
      <Link onClick={() => setOutputType('Chart')}>Chart</Link>
      <Link onClick={() => setOutputType('Table')}>Table</Link>
    </Flex>
  )

  function shareCountUpdate(v) {
    if (!window.localStorage.getItem('hasInputShares')) {
      sendSlack(`Equity Calculator used: ${companyName}`)
      window.localStorage.setItem('hasInputShares', 'true')
    }
    setShareCount(v)
  }

  return (
    <Flex direction='column' gap='0'>
      <Flex padding='1.25rem 0 0.25rem 1.25rem' alignItems='center' width='20rem'>
        <Image src='/logoSemiColoredBlack.svg' alt='logo-colored' className='logo' />
        <Select
          placeholder={'Search companies...'}
          openMenuOnClick={true}
          escapeClearsValue={false}
          // isClearable
          options={currentList}
          value={currentList.filter((company) => {
            return company.value === companyName
          })}
          onChange={(newValue) => {
            window.location.assign(`/equity-calculator/${newValue?.value}`)
          }}
        />
      </Flex>
      <GlassCard header='' style={{ margin: '1rem auto', width: '98vw' }}>
        <CompanyHeader
          companyName={companyName}
          overrides={overrides}
          fte={fte}
          compMarketSize={compMarketSize}
          welcomeMessageModalHandler={setIsWelcomeMessageModalVisible}
          compsChartModalHandler={setIsCompsChartModalVisible}
          competitorsModalHandler={setIsCompetitorsModalVisible}
          setIsOverridesModalVisible={setIsOverridesModalVisible}
        />
        <Alert hasIcon={true} variation='error' display={error || capTableError?.get ? '' : 'none'}>
          {capTableError?.get?.message || error}
        </Alert>
        <Flex justifyContent='center' wrap='wrap' gap='1.5rem' marginTop='0.5rem'>
          <GlassCard header='Your Equity' className='overlayCard' style={{ width: '21rem', height: '27rem' }}>
            <Flex direction='column'>
              <Flex direction='column' gap='0'>
                <Text className='amplify-label'>Type of Shares</Text>
                <small>Type of shares you received: Stock Options or RSU</small>
                <SelectField
                  label='Type of Shares'
                  labelHidden
                  size='small'
                  options={['Stock Options', 'RSU']}
                  value={type}
                  onChange={(e) => setType(e.target.value as ShareTypes)}
                />
              </Flex>
              <Flex direction='column' gap='0'>
                <Text className='amplify-label'>Number of Shares</Text>
                <small>Amount of shares you received / will receive</small>
                <NumberInput label='Number of Shares' labelHidden value={shareCount || 0} onValueChange={shareCountUpdate} />
              </Flex>
              {type !== 'Stock Options' ? null : (
                <>
                  <Flex direction='column' gap='0'>
                    <Text className='amplify-label'>Option Price per Share (Strike Price)</Text>
                    <small>Price to exercise a single stock option</small>
                    <CurrencyInput
                      decimalDigits={6}
                      fixedDecimalScale={false}
                      label='Option Price per Share (Strike Price)'
                      labelHidden
                      value={sharePrice}
                      onValueChange={(v) => setSharePrice(v)}
                    />
                  </Flex>
                </>
              )}
              <Flex direction='column' gap='0'>
                <Text className='amplify-label'>Exit Date</Text>
                <small>Date your company is expected to exit</small>
                <DateInput
                  label='Exit Date'
                  labelHidden
                  value={exitDate}
                  onChange={(d) => {
                    if (isNaN(d.getTime())) return
                    setExitDate(d)
                  }}
                />
              </Flex>
            </Flex>
          </GlassCard>
          <GlassCard header='Your Returns' className='overlayCard overflow' headerButtons={returnsHeaderButtons}>
            <Flex>
              <EquityCalculatorOutput calculation={calculation} added={added} exitDate={exitDate} shareCount={shareCount} sharePrice={sharePrice} outputType={outputType} />
            </Flex>
          </GlassCard>
        </Flex>
      </GlassCard>
      <ReactModal appElement={document.getElementById('app')} isOpen={isWelcomeMessageModalVisible} onRequestClose={() => handleWelcomeMessageDismissed()}>
        <GlassCard
          header='How it Works'
          className='smallModal'
          headerButtons={<CloseIcon width='0.75rem' className='actionButton' onClick={() => handleWelcomeMessageDismissed()} />}
        >
          <Text>Welcome! Let's break it down in three simple steps:</Text>
          <ul className='processList'>
            <li className='processListItem'>
              First, we use a data-driven approach to value your company, using your company's estimated revenue, revenue growth rate, and industry multiples
            </li>
            <li className='processListItem'>
              Next, we benchmark this value against similar companies in your industry to determine a range of potential sale prices (valuation) from least probable (green or red)
              to most probable (blue)
            </li>
            <li className='processListItem'>
              Last, we use your most recently filed cap table and inputs in <strong>Your Equity </strong>
              to show what you would make if your company were to sell, across each potential sale price (sell meaning a liquidation event such as an IPO, merger or acquisition)
            </li>
          </ul>
          <Flex direction='column' gap='0.5rem'>
            <Text color='darkslategray'>All valuations and returns are estimates of what we expect using investor-grade math</Text>
            <Text color='darkslategray'>All input data is encrypted end-to-end and can never be viewed by anyone expect you</Text>
          </Flex>
        </GlassCard>
      </ReactModal>
      <ReactModal appElement={document.getElementById('app')} isOpen={isCompetitorsModalVisible} onRequestClose={() => setIsCompetitorsModalVisible(false)}>
        <GlassCard
          header='Top Competitors'
          className='largeModal'
          headerButtons={<CloseIcon width='0.75rem' className='actionButton' onClick={() => setIsCompetitorsModalVisible(false)} />}
        >
          <CompInfo companyName={companyName} cachedCompetitiveProducts={competitiveProducts} />
        </GlassCard>
      </ReactModal>
      <ReactModal appElement={document.getElementById('app')} isOpen={isCompsChartModalVisible} onRequestClose={() => setIsCompsChartModalVisible(false)}>
        <GlassCard
          header='Similar Companies vs Market'
          className='largeModal'
          headerButtons={<CloseIcon width='0.75rem' className='actionButton' onClick={() => setIsCompsChartModalVisible(false)} />}
        >
          <Image src='/images/CompsChart.png' alt='Comps Chart' />
        </GlassCard>
      </ReactModal>
      <ReactModal appElement={document.getElementById('app')} isOpen={isOverridesModalVisible} onRequestClose={() => setIsOverridesModalVisible(false)}>
        <GlassCard header='Override Assumptions' headerButtons={<CloseIcon width='0.75rem' className='actionButton' onClick={() => setIsOverridesModalVisible(false)} />}>
          <Text>To estimate revenue we multiply # full time employees (FTE) by an industry standard multiple</Text>
          <Text>You can also input revenue directly ignoring FTE input</Text>
          <Text>Revenue is then multiplied by CAGR and Revenue multiple and number of years from now until exit.</Text>
          <Text>We calculate a range of CAGR and Revenue multiple for Best, Expected and Worst cases. </Text>
          <Text>All of the values we use are based on industry standards, but feel free to override for higher accuracy</Text>
          <OverridesInput fte={fte || 1} overrides={overrides} setOverrides={setOverrides} />
        </GlassCard>
      </ReactModal>
    </Flex>
  )
}

export default EquityCalculator

const currentList = [
  { value: 'readme-io', label: 'ReadMe' },
  { value: 'motion-f1df', label: 'Motion' },
  { value: 'blackcloak', label: 'BlackCloak' },
  { value: 'pyramid-analytics', label: 'Pyramid Analytics' },
  { value: 'finix-payments', label: 'Finix' },
  { value: 'reserved-ai', label: 'Archera' },
  { value: 'eclypsium', label: 'Eclypsium' },
  { value: 'sight-machine', label: 'Sight Machine' },
  { value: 'modsy-2', label: 'Modsy' },
  { value: 'facetofficial', label: 'Facet' },
  { value: 'mosaic-tech', label: 'Mosaic.tech' },
  { value: 'cowbell-cyber-inc', label: 'Cowbell Cyber' },
  { value: 'Cowbell Cyber', label: 'Cowbell Cyber' },
  { value: 'georama', label: 'QualSights' },
  { value: 'homethrive', label: 'Homethrive' },
  { value: 'cresicor' },
  { value: 'alkira', label: 'Alkira' },
  { value: 'figma', label: 'Figma' },
  { value: 'very-good-security', label: 'VGS' },
  { value: 'impossible-foods', label: 'Impossible Foods' },
  { value: 'nomad-health', label: 'Nomad Health' },
  { value: 'corelight', label: 'Corelight' },
  { value: 'spycloud-inc', label: 'SpyCloud' },
  { value: 'o-prezent-ai', label: 'prezent.ai' },
  { value: 'signifyd', label: 'Signifyd' },
  { value: 'zip-f492', label: 'Zip' },
  { value: 'seekout', label: 'SeekOut' },
  { value: 'axle', label: 'Denim' },
  { value: 'domino-data-lab', label: 'Domino Data Lab' },
  { value: 'clumio', label: 'Clumio' },
  { value: 'anvilogic', label: 'Anvilogic' },
  { value: 'front-app', label: 'Front' },
  { value: 'lacework', label: 'Lacework' },
  { value: 'axonius', label: 'Axonius' },
  { value: 'bishop-fox', label: 'Bishop Fox' },
  { value: 'CoreStack Inc', label: 'CoreStack Inc' },
  { value: 'fortanix', label: 'Fortanix' },
  { value: 'collaborative-robotics', label: 'Collaborative Robotics' },
  { value: 'seeq', label: 'Seeq' },
  { value: 'attackiq', label: 'AttackIQ' },
  { value: 'tulip-interfaces', label: 'Tulip Interfaces' },
  { value: 'qloo', label: 'Qloo' },
  { value: 'semperis', label: 'Semperis' },
  { value: 'immersive-labs-2', label: 'Immersive Labs' },
  { value: 'tailor-brands', label: 'Tailor Brands' },
  { value: 'reversinglabs', label: 'ReversingLabs' },
  { value: 'persona', label: 'PERSONA' },
  { value: 'uptycs', label: 'Uptycs' },
  { value: 'kandji', label: 'Kandji' },
  { value: 'jupiterone', label: 'JupiterOne' },
  { value: 'sentra', label: 'Sentra' },
  { value: 'sysdig', label: 'Sysdig' },
  { value: 'traceable', label: 'Traceable' },
  { value: 'intigriti', label: 'intigriti' },
]
