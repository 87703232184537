import { DataStore } from 'aws-amplify/datastore'
import { FunctionComponent } from 'react'
import FinancialInput from '../components/FinancialInput'
import { useOpportunity } from '../contexts/opportunityContext'
import { FinancialDataV2, Opportunity } from '../models'

const FinancialPage: FunctionComponent = () => {
  const opportunity = useOpportunity()
  // TODO: remove this check
  if (!opportunity) return null

  async function submit(data: FinancialDataV2[]) {
    if (!opportunity) return alert('Select a valid target company')

    try {
      await DataStore.save(
        Opportunity.copyOf(opportunity, (updated) => {
          Object.assign(updated, {
            financialDataV2: data,
          })
        })
      )
    } catch (error: any) {
      console.error('marketMapCompSet: Error saving to datastore', error.message, error)
      alert('Error saving, check inputs and try again')
    }
  }

  return <FinancialInput submit={submit} initState={(opportunity.financialDataV2 || []).map((e) => ({ ...e }))} />
}

export default FinancialPage
