/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function HomeIcon(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="20px"
      height="21.04px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 20, height: 21 }}
      paths={[
        {
          d: "M19.5387 8.55712L10.6731 0.400829C10.3849 0.135674 10.0054 -0.00782393 9.61384 0.000329346C9.2223 0.00848263 8.84906 0.167656 8.57214 0.444579L0.418438 8.59833L0 9.01672L0 21.037L7.85714 21.037L7.85714 13.537L12.1429 13.537L12.1429 21.037L20 21.037L20 8.98159L19.5387 8.55712ZM9.64455 1.4286C9.65714 1.4286 9.64937 1.43132 9.64397 1.43663C9.63839 1.43132 9.63196 1.4286 9.64455 1.4286ZM18.5714 19.6085L13.5714 19.6085L13.5714 13.537C13.5714 13.1582 13.4209 12.7948 13.153 12.5269C12.8851 12.259 12.5217 12.1085 12.1429 12.1085L7.85714 12.1085C7.47826 12.1085 7.1149 12.259 6.84699 12.5269C6.57908 12.7948 6.42857 13.1582 6.42857 13.537L6.42857 19.6085L1.42857 19.6085L1.42857 9.60846L9.64455 1.45471C9.64513 1.45418 9.64518 1.45369 9.64563 1.45315L18.5714 9.60846L18.5714 19.6085Z",
          fill: "rgba(48,53,69,1)",
          fillRule: "nonzero",
          style: { transform: "translate(0%, 0%)" },
        },
      ]}
      {...getOverrideProps(overrides, "HomeIcon")}
      {...rest}
    ></Icon>
  );
}
