import { Button, Flex, Heading, Text } from '@aws-amplify/ui-react'
import { FunctionComponent, useState } from 'react'
import GlassCard from '../figma-components/GlassCard'
import { MarketDrivers, MarketRisks } from '../models'
import Editable from './Editable'
import MarketSummary from './MarketSummary'

interface ProductsTableProps {
  marketRisks: MarketRisks[]
  marketDrivers: MarketDrivers[]
  marketNames: string[]
  isEditing: boolean
  companyName: string
  onSave: (marketRisks: MarketRisks[], marketDrivers: MarketDrivers[]) => Promise<void>
}

const MarketInfoReview: FunctionComponent<ProductsTableProps> = ({
  marketRisks: initMarketRisks,
  marketDrivers: initMarketDrivers,
  isEditing,
  marketNames,
  onSave,
  companyName,
}) => {
  const [marketDrivers, setMarketDrivers] = useState<MarketDrivers[]>(initMarketDrivers)
  const [marketRisks, setMarketRisks] = useState<MarketRisks[]>(initMarketRisks)
  const [saving, setSaving] = useState<boolean>(false)

  function section(label: string, key: string, market: MarketRisks | MarketDrivers, list: MarketRisks[] | MarketDrivers[], fn: Function) {
    const value = market[key] || 'N/A'
    return (
      <Flex direction='column'>
        <Heading level={5}>{label}</Heading>
        <Editable
          value={value || 'N/A'}
          isEditing={isEditing}
          onChange={function (e: string): void {
            const update = list.map((i) => {
              if (i.summary === market.summary) {
                return { ...i, [key]: e || '' }
              }
              return i
            })
            fn(update)
          }}
        />
      </Flex>
    )
  }

  const driverTable = marketDrivers ? (
    marketDrivers.map((market, i) => {
      return (
        <GlassCard
          key={i}
          header={`driver number ${i}`}
          headerIcon={
            <Button
              isLoading={saving}
              variation='destructive'
              onClick={() => {
                const newMarketDriver = [...marketDrivers]
                newMarketDriver.splice(i, 1)
                setMarketDrivers(newMarketDriver)
              }}
            >
              Delete
            </Button>
          }
        >
          <Flex direction='column'>
            {section('Heading', 'summary', market, marketDrivers, setMarketDrivers)}
            {section('Details', 'details', market, marketDrivers, setMarketDrivers)}
          </Flex>
        </GlassCard>
      )
    })
  ) : (
    <Text>N/A</Text>
  )
  const risks = marketRisks ? (
    marketRisks.map((market, i) => {
      return (
        <GlassCard
          key={i}
          header={`risk number ${i}`}
          headerIcon={
            <Button
              isLoading={saving}
              variation='destructive'
              onClick={() => {
                const newMarketRisks = [...marketRisks]
                newMarketRisks.splice(i, 1)
                setMarketRisks(newMarketRisks)
              }}
            >
              Delete
            </Button>
          }
        >
          <Flex direction='column'>
            {section('Heading', 'summary', market, marketRisks, setMarketRisks)}
            {section('Details', 'details', market, marketRisks, setMarketRisks)}
            {section('Mitigate', 'mitigation', market, marketRisks, setMarketRisks)}
          </Flex>
        </GlassCard>
      )
    })
  ) : (
    <Text>N/A</Text>
  )

  if (!isEditing) return <MarketSummary marketDrivers={marketDrivers} marketRisks={marketRisks} marketNames={marketNames} companyName={companyName} />

  return (
    <Flex direction='column'>
      <Button
        isLoading={saving}
        variation='primary'
        onClick={() => {
          setSaving(true)
          return onSave(marketRisks, marketDrivers)
            .catch((e) => console.error('onSave error', e))
            .finally(() => setSaving(false))
        }}
      >
        SAVE
      </Button>
      <Flex direction='row'>
        <GlassCard header='Market Drivers of Growth' flex={1}>
          <Flex direction='column'>{driverTable}</Flex>
          <Button
            isLoading={saving}
            onClick={() => {
              setMarketDrivers([
                ...marketDrivers,
                {
                  summary: 'New Summary',
                  details: '',
                },
              ])
            }}
          >
            Add new driver
          </Button>
        </GlassCard>

        <GlassCard header='Market Risks' flex={1}>
          <Flex direction='column'>{risks}</Flex>
          <Button
            isLoading={saving}
            onClick={() => {
              setMarketRisks([
                ...marketRisks,
                {
                  summary: 'New Summary',
                  details: '',
                  mitigation: '',
                },
              ])
            }}
          >
            Add new risk
          </Button>
        </GlassCard>
      </Flex>
    </Flex>
  )
}

export default MarketInfoReview
