import { GraphQLQuery, generateClient } from '@aws-amplify/api'
import { graphqlOperation } from '@aws-amplify/api-graphql'
import { DataStore } from '@aws-amplify/datastore'
import { useEffect, useState } from 'react'
import { GetOrgQuery, GetOrgQueryVariables } from '../API'
import { useAmplifyUser } from '../contexts/amplifyUserContext'
import { getOrg } from '../graphql/queries'
import { Org } from '../models'
import { restAPIRequest } from '../services/restApiRequest'
import { sendSlack } from '../services/slack'

const API = generateClient()

export function useOrgState(): [Org | undefined, Function] {
  const amplifyUser = useAmplifyUser()
  const [org, setOrg] = useState<Org>()
  const orgName = amplifyUser?.org

  useEffect(() => {
    if (!orgName) return
    const sub = DataStore.observeQuery(Org, (o) => o.orgName.eq(orgName)).subscribe(async ({ items, isSynced }) => {
      if (items.length === 0) {
        try {
          const vars: GetOrgQueryVariables = {
            orgName,
          }
          const info = await API.graphql<GraphQLQuery<GetOrgQuery>>(graphqlOperation(getOrg, vars))
          if (info?.data?.getOrg) {
            // @ts-ignore
            delete info.data.getOrg.createdAt
            // @ts-ignore
            delete info.data.getOrg.updatedAt
            return setOrg(new Org(info.data.getOrg))
          }
        } catch (e: any) {
          console.error(e)
          await sendSlack(`:alert: org error: ${e.message}. info ${JSON.stringify(e)}`)
          return
        }

        if (!isSynced) return
        await restAPIRequest({
          method: 'post',
          path: 'org',
        })
        await DataStore.save(new Org({ orgName }))
      } else {
        setOrg(items[0])
      }
    })

    return () => {
      sub.unsubscribe()
    }
  }, [orgName])

  const updateOrg = async (newOrg: Org) => {
    if (!org) return console.error('No org trying to update org')
    await DataStore.save(
      Org.copyOf(org, (updated) => {
        Object.assign(updated, {
          ...newOrg,
        })
      })
    )
  }

  return [org, updateOrg]
}
