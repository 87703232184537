import { Alert, Image, Button, Card, Flex, Link, Text, TextField, View, useTheme } from '@aws-amplify/ui-react'
import { FunctionComponent, ReactNode, useEffect, useRef, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { AIMessageV2 } from '../models'
import { IoSparklesSharp, BiData, MdAccountCircle, CkLock, Trash } from '../ui-components'
import GlassCard from './GlassCard'
import '../styles/chatgpt.css'

interface WebsiteChatProps {
  enabled: boolean
  disabled: boolean
  history: AIMessageV2[]
  postQuestion: (question: string) => Promise<void>
  clearClicked: () => Promise<void>
  quickPrompts?: QuickPrompts[]
  contentSources?: ContentSources[]
  header: string
  placeholder: string
  disabledMessage?: ReactNode
  hideSource?: boolean
  headerButtons?: React.ReactNode
  initIsExpanded?: boolean
  websiteSources?: string[]
}

export type QuickPrompts = {
  label: string
  prompt: string
  icon?: ReactNode
}

export type ContentSources = {
  type: 'doc'
  label: string
  icon?: ReactNode
}

const ChatView: FunctionComponent<WebsiteChatProps> = ({
  header,
  contentSources,
  quickPrompts,
  disabled,
  enabled,
  placeholder,
  history,
  postQuestion,
  clearClicked,
  disabledMessage,
  hideSource,
  headerButtons = <></>,
  initIsExpanded = false,
  websiteSources = [],
}) => {
  const [question, setQuestion] = useState('')
  const [sending, isSending] = useState(false)
  const chatHistoryRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight
    }
  }, [history?.length])

  const theme = useTheme()
  const userChat = (message: AIMessageV2) => (
    <Flex key={message.timestamp} className='chatMessage'>
      <MdAccountCircle width='35px' height='35px' />
      <Text>{message.content}</Text>
    </Flex>
  )

  const aiRes = (message: AIMessageV2) => (
    <Flex key={message.timestamp} className='chatMessage'>
      <Image alt='ai-chat-icon' src='/aiIcon.svg' width='35px' height='35px' />
      <Flex gap='0px' direction='column' flex={1}>
        <ReactMarkdown>{message.content}</ReactMarkdown>
        {!hideSource && message?.sources?.[0] && (
          <Text color={theme.tokens.colors.font.disabled} fontSize={theme.tokens.fontSizes.xs} marginTop='0.5rem'>
            source:{' '}
            <Link isExternal={true} href={message.sources[0]}>
              {message.sources[0]}
            </Link>
          </Text>
        )}
      </Flex>
    </Flex>
  )

  const send = async (q?: string) => {
    isSending(true)
    return postQuestion(q || question)
      .then(() => setQuestion(''))
      .catch((e) => console.error('postQuestion error', e))
      .finally(() => isSending(false))
  }

  const messages = (
    <Flex direction='column' maxHeight='400px' overflow='scroll' gap='0.75rem' ref={chatHistoryRef}>
      {history.map((message) => {
        if (message.role === 'user') return userChat(message)
        return aiRes(message)
      })}
    </Flex>
  )

  const disableMessageView = disabledMessage || (
    <Alert variation='error'>Sorry, we encountered an error connecting to the company's website. AI Website Chat will not be available for this company.</Alert>
  )

  const contentSourcesBar = (
    <Flex direction='row'>
      {contentSources?.map((source, i) => (
        <Card className='docCard' key={source.label}>
          <Flex key={i} alignItems='center' gap='4px' padding='4px'>
            {source.icon || <BiData maxWidth='14px' maxHeight='14px' />}
            <Text>{source.label}</Text>
            {/* <Button size='small' variation='link'>
              X
            </Button> */}
          </Flex>
        </Card>
      ))}
    </Flex>
  )
  const promptTag = (prompt: QuickPrompts, i: number) => (
    <Button
      key={i}
      isDisabled={sending}
      variation='primary'
      size='small'
      className='aiPromptButton'
      onClick={() => {
        setQuestion(prompt.prompt)
        send(prompt.prompt)
      }}
    >
      <Text>{prompt.label}</Text>
    </Button>
  )
  const quickPromptsView = () => {
    if (disabled) return null
    return <Flex>{quickPrompts?.map((prompt, i) => promptTag(prompt, i))}</Flex>
  }
  const headerIcon = <Image alt='ai-icon' src='/aiIcon.svg' width='45px' height='45px' />
  return (
    <GlassCard header={header} headerIcon={headerIcon} headerButtons={headerButtons} headerProps={{ height: '30px' }} expandable={true} className='aiCard overlayCard' initIsExpanded={initIsExpanded}>
      <Flex direction='row' alignItems='center' margin='8px 0 0 60px'>
        <Flex direction='column' width='100%' gap='0'>
          { websiteSources.length > 0 && <Text>You are chatting with <strong>{websiteSources[0]}</strong></Text> }
          <Flex>
            <Flex className='aiDisclaimer'>
              <CkLock /><Text>AI interactions are 100% confidential and secure, always. You are using your own private instance of an AI model.</Text>
            </Flex>
            <Button className='tertiary clearChatButton' alignSelf='center' marginLeft='auto' onClick={clearClicked} size='small'>
              <Trash /> Clear chat
            </Button>
          </Flex>
          {contentSources?.length ? contentSourcesBar : null}
          {disabled ? (enabled ? disableMessageView : null) : messages}
          <Flex direction='column' className='chatPrompt'>
            {quickPromptsView()}
            <Flex>
              <TextField
                flex={1}
                value={question}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    send()
                  }
                }}
                isDisabled={sending || disabled || !enabled}
                onChange={(e) => setQuestion(e.target.value)}
                labelHidden
                size='small'
                label={header}
                placeholder={placeholder}
                autoComplete='off'
                autoCapitalize='off'
                autoCorrect='off'
              />
              <Button isDisabled={question.length < 1 || disabled} variation='primary' alignSelf='end' className='aiButton' isLoading={sending} size='small' onClick={() => send()}>
                <Text>→</Text>
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </GlassCard>
  )
}

export default ChatView
