/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function SectionCollapseIcon(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="20px"
      height="20px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 20, height: 20 }}
      paths={[
        {
          d: "M10 20C8.02219 20 6.0888 19.4135 4.4443 18.3147C2.79981 17.2159 1.51809 15.6541 0.761209 13.8268C0.00433197 11.9996 -0.193701 9.98889 0.192153 8.04909C0.578007 6.10928 1.53042 4.32745 2.92894 2.92893C4.80431 1.05357 7.34784 -1.97602e-08 10 2.22044e-16C12.6522 1.97602e-08 15.1957 1.05357 17.0711 2.92893C18.9464 4.80429 20 7.34783 20 9.99999C20 12.6522 18.9464 15.1957 17.0711 17.0711C16.1446 18.0025 15.0427 18.741 13.8289 19.2438C12.6152 19.7465 11.3138 20.0036 10 20ZM10 1.33335C5.22123 1.33335 1.33337 5.22121 1.33337 9.99999C1.33337 14.7788 5.22123 18.6666 10 18.6666C14.7788 18.6666 18.6666 14.7788 18.6666 9.99999C18.6666 5.22121 14.7788 1.33335 10 1.33335Z",
          fill: "rgba(48,53,69,1)",
          fillRule: "nonzero",
          style: { transform: "translate(0%, 0%)" },
        },
        {
          d: "M0.942748 10.2761L0 9.33331L4.1952 5.13803L0 0.942748L0.942748 0L6.08082 5.13803L0.942748 10.2761Z",
          fill: "rgba(48,53,69,1)",
          fillRule: "nonzero",
          style: { transform: "translate(39.31%, 24.31%)" },
        },
      ]}
      {...getOverrideProps(overrides, "SectionCollapseIcon")}
      {...rest}
    ></Icon>
  );
}
