import { Flex, Text } from '@aws-amplify/ui-react'
import React from 'react'

type Props = {
  list: string[]
  icon: React.ReactNode
}

const IconList: React.FC<Props> = ({ list, icon }) => {
  return (
    <Flex className='taxonomyTagContainer'>
      {list?.length < 1
        ? 'N/A'
        : list.map((bucket) => {
            if (!bucket) return 'N/A'
            return (
              <Flex alignItems='center' gap='4px' className='taxonomyTag' width='fit-content' key={bucket}>
                {icon}
                <Text>{bucket}</Text>
              </Flex>
            )
          })}
    </Flex>
  )
}

export default IconList
