import { Grid, Flex, View, Text } from '@aws-amplify/ui-react'
import { Outlet, useLocation } from 'react-router-dom'
import { CkInfoOutline } from '../ui-components'
// import ChatGPT from '../pages/ChatGPT'
import SideNav from '../pages/SideNav'

export default function RootLayout() {
  const location = useLocation()
  const isLight = !location.pathname.includes('deal')

  const renderPrivateModeHeader = () => {
    return (
      <Flex className='privateModeHeader tooltip down large' data-tooltip='You are in Private Mode. All information leveraging AI will remain 100% confidential. You are using your own instance of a generative AI model only accessible by you.'>
        <Text>Private Workspace Mode</Text>
        <CkInfoOutline />
      </Flex>
    )
  }

  return (
    <Grid id='app' className={`app ${isLight ? '' : 'darkmode'}`}>
      <SideNav />
      <View className={`view ${isLight ? '' : 'darkmode'}`}>
        { !isLight && renderPrivateModeHeader() }
        <Outlet />
      </View>
      {/* <ChatGPT /> */}
    </Grid>
  )
}
