import { DataStore } from '@aws-amplify/datastore'
import { Flex } from '@aws-amplify/ui-react'
import { FunctionComponent, useState } from 'react'
import AutoCompleteOptionSelect from '../components/AutoCompleteOptionSelect'
import BenchmarkingDashboard from '../components/BenchmarkingDashboard'
import { CompPicker } from '../components/CompPicker'
import MultiplesSummaryView from '../components/MultiplesSummaryView'
import { PublicCompsTable, PublicCompsYearlyData, useConfigTable } from '../hooks/useConfigTable'
import { FinancialDataV2, Opportunity } from '../models'
import { Option } from '../types'
import { alphaCompare } from '../utils/utils'

interface PublicCompsProps {
  opportunity: Opportunity
}

const extraCols = [
  {
    header: 'Bucket',
    accessorKey: 'bucket',
    meta: {
      type: 'inputString',
    },
  },
  {
    header: '',
    accessorKey: 'delete',
    enableSorting: false,
    enableGrouping: false,
    meta: {
      type: 'deleteButton',
    },
  },
]

const PublicComps: FunctionComponent<PublicCompsProps> = ({ opportunity }) => {
  const [isSending, setIsSending] = useState<boolean>(false)
  const [adding, setAdding] = useState<boolean>(false)
  const publicCompsCompSet = opportunity?.publicCompsCompSetV2 || []
  const initBucket = publicCompsCompSet.reduce((acc, curr) => {
    acc[curr.name] = { bucket: curr.groupName }
    return acc
  }, {})
  const compNames = Object.keys(initBucket)

  const [profiles, tableLoaders] = useConfigTable('public_comps')
  profiles.sort((a, b) => a.name.localeCompare(b.name))

  const data = {}

  for (const profile of profiles) {
    const compInfo = publicCompsCompSet?.find((i) => alphaCompare(i.name, profile.name))
    if (!compInfo) continue
    const info = parseChartData(profile) as any
    info.company = profile.name
    if (!data[compInfo.groupName]) data[compInfo.groupName] = []
    data[compInfo.groupName].push(info)
  }

  const options = profiles.map((profile) => ({ value: profile.name, label: profile.name }))
  async function updateComps(selectedComps: string[], extraData?: Record<string, Record<string, string>>) {
    try {
      await DataStore.save(
        Opportunity.copyOf(opportunity, (updated) => {
          Object.assign(updated, {
            publicCompsCompSetV2: selectedComps.map((c) => ({
              name: c,
              groupName: extraData?.[c]?.bucket || initBucket?.[c]?.bucket || 'General',
            })),
          })
        })
      )
    } catch (error: any) {
      console.error('publicCompsCompSetV2: Error saving to datastore', error, error.message)
    }
  }

  const submit = (opts: Option[], extraData?: Record<string, Record<string, string>>) => {
    if (isSending) return
    const selectedComps = opts.map((o) => o.value)
    setIsSending(true)
    updateComps(selectedComps, extraData)
      .catch((err) => {
        alert(err)
      })
      .finally(() => {
        setIsSending(false)
        setAdding(false)
      })
  }

  if (!adding) {
    const target = parseChartDataFromFinanceData(opportunity?.financialDataV2 || [], opportunity.name)
    return (
      <Flex direction='column' gap='2rem'>
        <CompPicker
          compSet={compNames}
          onEditClick={() => {
            setAdding(true)
          }}
        />
        <MultiplesSummaryView data={data} />
        <BenchmarkingDashboard data={data} targetData={target} />
      </Flex>
    )
  }

  return (
    <Flex direction='column'>
      <AutoCompleteOptionSelect
        placeHolder='Search comp set...'
        suggestedOptions={[]}
        otherOptions={options}
        extraCols={extraCols}
        optionsLoading={tableLoaders.get}
        isSending={isSending}
        submit={submit}
        initExtraData={initBucket}
        initSelected={compNames}
      />
    </Flex>
  )
}

function parseChartData(row: PublicCompsTable | undefined | null) {
  if (!row) row = {} as PublicCompsTable
  const info2022 = row.yearly_data?.find((i) => i.year === 2022) || ({} as PublicCompsYearlyData)
  const info2023 = row.yearly_data?.find((i) => i.year === 2023) || ({} as PublicCompsYearlyData)
  const info2024 = row.yearly_data?.find((i) => i.year === 2024) || ({} as PublicCompsYearlyData)
  const EV = row.enterprise_value || undefined
  const mostRecentClose = row.price || undefined
  const week52AgoClose = row.close_52_weeks_ago || undefined
  const week52High = row.close_52_week_high || undefined
  const week52Low = row.close_52_week_low || undefined
  const sumOfRevenueLastFourQuarters = row.ltm_revenue || undefined
  const sumOfEbitdaLastFourQuarters = row.ltm_ebitda || undefined
  const year2023Ebitda = info2023.ebitda || undefined
  const year2024Ebitda = info2024.ebitda || undefined
  const sumOfRevenuePreviousFourQuarters = row.ltm_revenue_previous || undefined
  const arr = row.arr || undefined
  const pastArr = row.last_year_arr || undefined
  const e2023 = info2023.analystRevenueEstimates || undefined
  const e2024 = info2024.analystRevenueEstimates || undefined

  const EV_LTMRev = EV && sumOfRevenueLastFourQuarters && EV / sumOfRevenueLastFourQuarters
  const EV_2022Rev = EV && info2022.revenue ? EV / info2022.revenue : undefined
  const EV_2023Rev = e2023 && EV ? EV / e2023 : undefined
  const EV_2024Rev = e2024 && EV ? EV / e2024 : undefined
  const EV_LTM_EBITDA = EV && sumOfEbitdaLastFourQuarters && EV / sumOfEbitdaLastFourQuarters
  const EV_2023EBITDA = EV && year2023Ebitda && EV / year2023Ebitda
  const EV_2024EBITDA = EV && year2024Ebitda && EV / year2024Ebitda

  const LTMChange = mostRecentClose && week52AgoClose && mostRecentClose / week52AgoClose - 1
  const pOf52WeekHigh = mostRecentClose && week52High && mostRecentClose / week52High
  const pOf52WeekLow = mostRecentClose && week52Low && mostRecentClose / week52Low
  const ltmRevGrowth = sumOfRevenueLastFourQuarters && sumOfRevenuePreviousFourQuarters && sumOfRevenueLastFourQuarters / sumOfRevenuePreviousFourQuarters - 1
  const ltmARRGrowth = arr && pastArr && arr / pastArr - 1

  return {
    EV,
    LTMChange,
    pOf52WeekHigh,
    pOf52WeekLow,
    EV_LTMRev,
    EV_2022Rev,
    EV_2023Rev,
    EV_2024Rev,
    EV_LTM_EBITDA,
    EV_2023EBITDA,
    EV_2024EBITDA,
    ntmRevGrowth: row.ntm_revenue_growth || undefined,
    ltmRevGrowth,
    ltmARRGrowth,
    smAsPofRev: row.sm_percent || undefined,
    smYield: row.sales_and_marketing_yield || undefined,
    ltv_CAC: row.implied_5yr_ltv_cac || undefined,
    netNewARRGrowth: row.net_new_arr_growth_percent || undefined,
    netDollarRetention: row.net_dollar_retention || undefined,
    grossDollarRetention: row.gross_retention || undefined,
    grossMargin: row.gross_margin || undefined,
    ebitdaMargin: row.ltm_ebitda_margin || undefined,
    ruleOf40OpInMargin: row.rule_of_40_op_in_margin || undefined,
  }
}

function parseChartDataFromFinanceData(data: FinancialDataV2[], company: string) {
  const ntm = data?.find((f) => f.type === 'ntm') || {}
  const ltm = data?.find((f) => f.type === 'ltm') || {}

  return {
    company,
    ntmRevGrowth: ntm.totalRevGrowthPercent || undefined,
    ltmRevGrowth: ltm.totalRevGrowthPercent || undefined,
    ltmARRGrowth: ltm.arrGrowthPercent || undefined,
    smAsPofRev: ltm.snmAsPercentOfRev || undefined,
    smYield: ltm.salesAndMarketingYield || undefined,
    ltv_CAC: ltm.implied5yrLTVPerCAC || undefined,
    netNewARRGrowth: ltm.netNewARRGrowthPercent || undefined,
    netDollarRetention: ltm.netRetentionPercent || undefined,
    grossDollarRetention: ltm.grossRetentionPercent || undefined,
    grossMargin: ltm.grossMarginPercent || undefined,
    ebitdaMargin: ltm.eBITDAMarginPercent || undefined,
    ruleOf40OpInMargin: undefined,
  }
}

export default PublicComps
