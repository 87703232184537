/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function FilesIcon(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="20px"
      height="18px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 20, height: 18 }}
      paths={[
        {
          d: "M19 18L1 18C0.734878 17.9997 0.480704 17.8942 0.293234 17.7068C0.105765 17.5193 0.000308769 17.2651 0 17L0 1C0.000308769 0.73488 0.105765 0.480705 0.293234 0.293236C0.480704 0.105766 0.734878 0.000310393 1 1.62474e-06L8.77779 1.62474e-06C8.9753 -0.000354815 9.16847 0.0579439 9.33281 0.167506C9.49714 0.277069 9.62525 0.432961 9.70088 0.615418L10.5555 2.66667L19 2.66667C19.2651 2.66698 19.5193 2.77243 19.7068 2.9599C19.8942 3.14737 19.9997 3.40155 20 3.66667L20 17C19.9997 17.2651 19.8942 17.5193 19.7068 17.7068C19.5193 17.8942 19.2651 17.9997 19 18ZM1.33333 16.6667L18.6667 16.6667L18.6667 4L9.66667 4L8.55554 1.33333L1.33333 1.33333L1.33333 16.6667Z",
          fill: "rgba(48,53,69,1)",
          fillRule: "nonzero",
          style: { transform: "translate(0%, 0%)" },
        },
      ]}
      {...getOverrideProps(overrides, "FilesIcon")}
      {...rest}
    ></Icon>
  );
}
