import { Table, TableBody, TableCell, TableHead, TableRow } from '@aws-amplify/ui-react'
import { FunctionComponent } from 'react'
import '../styles/table.css'
import { c, m, median, p } from '../utils/utils'

interface PublicCompsProps {
  data: {
    [bucket: string]: ChartData[]
  }
}

type ChartData = {
  company: string
  EV: number
  LTMChange: number
  pOf52WeekHigh: number
  pOf52WeekLow: number
  EV_LTMRev: number
  EV_2022Rev: number
  EV_2023Rev: number
  EV_2024Rev: number
  EV_LTM_EBITDA?: number
  EV_2023EBITDA?: number
  EV_2024EBITDA?: number
}

const negativeOk = {
  EV: true,
  LTMChange: true,
  pOf52WeekHigh: true,
  pOf52WeekLow: true,
}
const MultiplesSummaryView: FunctionComponent<PublicCompsProps> = ({ data }) => {
  const tableBody = Object.keys(data)
    .sort((a, b) => (a || '').localeCompare(b || ''))
    .map((bucket, j) => {
      const total: Omit<ChartData, 'company'> = {
        EV: 0,
        LTMChange: 0,
        pOf52WeekHigh: 0,
        pOf52WeekLow: 0,
        EV_LTMRev: 0,
        EV_2022Rev: 0,
        EV_2023Rev: 0,
        EV_2024Rev: 0,
        EV_LTM_EBITDA: 0,
        EV_2023EBITDA: 0,
        EV_2024EBITDA: 0,
      }

      const mean = { ...total }
      const med = { ...total }
      const high = { ...total }
      const low = {
        EV: Infinity,
        LTMChange: Infinity,
        pOf52WeekHigh: Infinity,
        pOf52WeekLow: Infinity,
        EV_LTMRev: Infinity,
        EV_2022Rev: Infinity,
        EV_2023Rev: Infinity,
        EV_2024Rev: Infinity,
        EV_LTM_EBITDA: Infinity,
        EV_2023EBITDA: Infinity,
        EV_2024EBITDA: Infinity,
      }
      const counts = { ...total }
      const tableRows = data[bucket]
        .sort((a, b) => (a?.company || '').localeCompare(b?.company || ''))
        .map((chartData, i) => {
          Object.keys(total).forEach((key) => {
            const v = chartData[key]
            if (typeof v !== 'number') return
            if (v === Infinity) return
            if (!negativeOk[key] && v < 0) return
            counts[key]++
            total[key] += v
            if (v > high[key]) high[key] = v
            if (v < low[key]) low[key] = v
          })

          return formatRowForBucket(chartData, data[bucket].length + 4, bucket, i)
        })

      for (const key in total) {
        mean[key] = total[key] / counts[key]
      }

      tableRows.push(createRow('Mean', bucket, mean))

      for (const key in total) {
        med[key] = median(data[bucket].map((i) => i[key]).filter((i) => typeof i === 'number' && i !== Infinity))
      }

      tableRows.push(createRow('Median', bucket, med))
      tableRows.push(createRow('Low', bucket, low))
      tableRows.push(createRow('High', bucket, high))

      return tableRows
    })
    .flat()

  return (
    <Table caption='' highlightOnHover={true} size='small' className='outputTable'>
      <TableHead>
        <TableRow>
          <TableCell as='th'>Bucket</TableCell>
          <TableCell as='th'>Company</TableCell>
          <TableCell as='th'>EV</TableCell>
          <TableCell as='th'>LTM Change</TableCell>
          <TableCell as='th'>% of 52 Week High</TableCell>
          <TableCell as='th'>% of 52 Week Low</TableCell>
          <TableCell as='th'>EV / LTM Rev</TableCell>
          <TableCell as='th'>EV / 2022 Rev</TableCell>
          <TableCell as='th'>EV / 2023 Rev</TableCell>
          <TableCell as='th'>EV / 2024 Rev</TableCell>
          <TableCell as='th'>EV / LTM EBITDA</TableCell>
          <TableCell as='th'>EV / 2023 EBITDA</TableCell>
          <TableCell as='th'>EV / 2024 EBITDA</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{tableBody}</TableBody>
    </Table>
  )
}

function createRow(label: string, bucket: string, row: Omit<ChartData, 'company'>) {
  return (
    <TableRow className='totalRow' key={`${label}-${bucket}`}>
      <TableCell className='totalRowCell'>{label}</TableCell>
      <TableCell className='totalRowCell'>{c(row.EV)}</TableCell>
      <TableCell className='totalRowCell'>{p(row.LTMChange)}</TableCell>
      <TableCell className='totalRowCell'>{p(row.pOf52WeekHigh)}</TableCell>
      <TableCell className='totalRowCell'>{p(row.pOf52WeekLow)}</TableCell>
      <TableCell className='totalRowCell'>{m(row.EV_LTMRev || undefined)}</TableCell>
      <TableCell className='totalRowCell'>{m(row.EV_2022Rev || undefined)}</TableCell>
      <TableCell className='totalRowCell'>{m(row.EV_2023Rev || undefined)}</TableCell>
      <TableCell className='totalRowCell'>{m(row.EV_2024Rev || undefined)}</TableCell>
      <TableCell className='totalRowCell'>{m(row.EV_LTM_EBITDA || undefined)}</TableCell>
      <TableCell className='totalRowCell'>{m(row.EV_2023EBITDA || undefined)}</TableCell>
      <TableCell className='totalRowCell'>{m(row.EV_2024EBITDA || undefined)}</TableCell>
    </TableRow>
  )
}

function formatRowForBucket(chartData: ChartData, rowCount: number, bucket: string, i: number) {
  const groupedCell = (
    // @ts-ignore
    <TableCell rowspan={rowCount} className='multiplesBucketCell'>
      {bucket}
    </TableCell>
  )
  return (
    <TableRow key={chartData.company + i}>
      {i === 0 ? groupedCell : null}
      <TableCell>{chartData.company}</TableCell>
      <TableCell>{c(chartData.EV)}</TableCell>
      <TableCell>{p(chartData.LTMChange)}</TableCell>
      <TableCell>{p(chartData.pOf52WeekHigh)}</TableCell>
      <TableCell>{p(chartData.pOf52WeekLow)}</TableCell>
      <TableCell>{m(chartData.EV_LTMRev)}</TableCell>
      <TableCell>{m(chartData.EV_2022Rev)}</TableCell>
      <TableCell>{m(chartData.EV_2023Rev)}</TableCell>
      <TableCell>{m(chartData.EV_2024Rev)}</TableCell>
      <TableCell>{m(chartData.EV_LTM_EBITDA)}</TableCell>
      <TableCell>{m(chartData.EV_2023EBITDA)}</TableCell>
      <TableCell>{m(chartData.EV_2024EBITDA)}</TableCell>
    </TableRow>
  )
}

export default MultiplesSummaryView
