import { Button, Flex, Text } from '@aws-amplify/ui-react'
import { FunctionComponent, useEffect, useState } from 'react'
import { File } from '../models'
import GlassCard from './GlassCard'
import SelectableDocListView from './SelectableDocListView'
import FileUploader from '../components/FileUploader'

interface MyDocumentsViewProps {
  docs: File[]
  sending?: boolean
  updateClicked: (selectedS3Keys: string[]) => Promise<void>
  deleteClicked: (s3Key: string) => void
  [x: string]: any
}

const MyDocumentsView: FunctionComponent<MyDocumentsViewProps> = ({ docs, selectedDocs, updateClicked, deleteClicked, sending, ...rest }) => {
  const [selectedS3Keys, setSelected] = useState<string[]>(selectedDocs || [])
  const [updating, setUploading] = useState(sending)
  useEffect(() => {
    setUploading(sending)
  }, [sending])
  return (
    <GlassCard header='My Documents' {...rest} className='overlayCard'>
      <Flex direction='column'>
        <Text>Select from uploaded transaction documents to update information on current profile page:</Text>
        <SelectableDocListView isDisabled={updating} docs={docs} selectedDocs={selectedDocs} deleteClicked={deleteClicked} updateSelectedDocs={setSelected} maxHeight='500px' />
        <Flex>
          {docs.length > 0 && (
            <Button
              isLoading={updating || sending}
              isFullWidth
              size='small'
              className='save'
              loadingText='Updating Page...'
              onClick={() => {
                if (updating) return
                setUploading(true)
                updateClicked(selectedS3Keys).catch(() => setUploading(false))
              }}
            >
              Update Page
            </Button>
          )}
          <FileUploader width='50%' />
        </Flex>
      </Flex>
    </GlassCard>
  )
}

export default MyDocumentsView
