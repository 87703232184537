import { Button, Flex, Text, SwitchField, TextAreaField } from '@aws-amplify/ui-react'
import { FunctionComponent, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import GlassCard from '../figma-components/GlassCard'
import { MeetingNotesHeaders } from '../hooks/useMeetingNotes'
import { cleanNotes } from '../services/aiRuns'
import { restAPIRequest } from '../services/restApiRequest'
import { MdAdd } from '../ui-components'
import { Accordion } from '@aws-amplify/ui-react'

type DealHistoryProps = {
  companyName: string
  meetingNotes: MeetingNotesHeaders[]
}

type MeetingNotesProperties = 'Meeting Date' | 'Playbook:Meeting Notes' | 'Meeting Notes'

const MeetingNotes: FunctionComponent<DealHistoryProps> = ({ meetingNotes, companyName }) => {
  const [isEditing, setIsEditable] = useState<Record<number, boolean>>({})
  const [updates, setUpdate] = useState<Record<number, MeetingNotesHeaders>>({})
  const [isSaving, setSaving] = useState<Record<number, boolean>>({})
  const [showRawNotes, setShowRawNotes] = useState<Record<number, boolean>>({})
  const [value, setValue] = useState<string[] | undefined>([])
  const adding = updates[-1]
  const data = meetingNotes.map((meetingNote) => ({ ...meetingNote, ...updates[meetingNote.index] }))
  Object.keys(updates).forEach((key) => {
    if (!data.find((d) => d.index === parseInt(key))) data.push(updates[parseInt(key)])
  })
  data.sort((a, b) => {
    if (a.index === -1) return -1
    return new Date(b['Meeting Date']).getTime() - new Date(a['Meeting Date']).getTime()
  })
  const hasChanges = (index: number) => Object.keys(updates[index] || {}).length > 0

  async function save(index) {
    const body = {
      ...updates[index],
    }
    if (!body) return
    if (isSaving[index]) return
    setSaving({
      ...isSaving,
      [index]: true,
    })

    try {
      if (body['Meeting Notes']) {
        const cleaned = await cleanNotes(body['Meeting Notes'])
        body['Playbook:Meeting Notes'] = cleaned
      }
      const res = await restAPIRequest<{ index: number }>({
        path: `companyProfile/${companyName}/notes`,
        method: 'post',
        body,
      })
      const newUpdates = { ...updates }
      newUpdates[res.index] = { ...body, index: res.index }
      delete newUpdates[-1]
      setUpdate(newUpdates)
    } catch (error) {
      console.error('Failed to save notes', error)
      alert('Failed to save notes, please try again in a moment')
    } finally {
      setSaving({
        ...isSaving,
        [index]: false,
      })
    }
  }

  const meetingNotesTypeToggle = (item) => {
    if (!item['Playbook:Meeting Notes']) return null
    if (!item['Meeting Notes']) return null

    return (
      <SwitchField
        label='AI-generated'
        size='small'
        thumbColor='#2E64E5'
        trackColor='lightgrey'
        trackCheckedColor='#2E64E5'
        isChecked={!showRawNotes[item.index]}
        onChange={(e) => {
          setShowRawNotes({
            ...showRawNotes,
            [item.index]: !showRawNotes[item.index],
          })
        }}
      />
    )
  }

  const editButton = (index) => {
    return (
      <Button
        size='small'
        isLoading={isSaving[index]}
        onClick={async (e) => {
          e.preventDefault()
          e.stopPropagation()
          try {
            if (isEditing[index]) {
              if (hasChanges(index)) await save(index)
            }
            setIsEditable({
              ...isEditing,
              [index]: !isEditing[index],
            })
          } catch (e) {
            console.error(e)
            alert('Failed to save, please try again in a moment')
          }
        }}
      >
        {isEditing[index] ? (hasChanges(index) ? 'Save' : 'Back') : 'Edit'}
      </Button>
    )
  }
  const updateValue = (index, key) => (v) => {
    setUpdate({
      ...updates,
      [index]: {
        ...updates[index],
        index: index,
        [key]: v.target.value,
      },
    })
  }

  const renderNoteProperty = (key: MeetingNotesProperties, item: MeetingNotesHeaders) => {
    if (!isEditing[item.index]) {
      return <ReactMarkdown>{`${item[key] || ''}`}</ReactMarkdown>
    }

    const rowsToShow = ['Playbook:Meeting Notes', 'Meeting Notes'].includes(key) ? 10 : 1
    return <TextAreaField flex={1} label='' labelHidden size='small' rows={rowsToShow} value={item[key]} onChange={updateValue(item.index, key)} />
  }

  const selectNotes = (item) => {
    if (!item['Meeting Notes']) return 'Meeting Notes'
    if (!item['Playbook:Meeting Notes']) return 'Meeting Notes'
    return showRawNotes[item.index] ? 'Meeting Notes' : 'Playbook:Meeting Notes'
  }

  const noteView = (item) => (
    <Accordion.Item value={`${item.index}`}>
      <Accordion.Trigger>
        <Text className='header3'>
          {item['Meeting Date']} — {(item['Meeting Notes'] || '').substring(0, 20)}...
        </Text>
        <Accordion.Icon />
      </Accordion.Trigger>
      <Accordion.Content style={{ maxHeight: '27rem', overflow: 'scroll' }}>
        <Flex direction='column' key={item.index}>
          {editButton(item.index)}
          <Flex alignItems='center'>
            <Text fontWeight='600'>Date</Text>
            {renderNoteProperty('Meeting Date', item)}
          </Flex>
          <Flex direction='column'>
            <Flex alignItems='center' justifyContent='space-between'>
              <Text fontWeight='600'>Notes</Text>
              {meetingNotesTypeToggle(item)}
            </Flex>
            {renderNoteProperty(selectNotes(item), item)}
          </Flex>
        </Flex>
      </Accordion.Content>
    </Accordion.Item>
  )

  const meetingAddr = () => {
    const newMeeting = async () => {
      if (adding) return
      const index = -1

      setUpdate({
        ...updates,
        [index]: {
          index: -1,
          PlaybookID: companyName,
          'Company Name': companyName,
          'Meeting Date': new Date().toLocaleDateString('en-EN'),
        } as MeetingNotesHeaders,
      })

      setIsEditable({
        ...isEditing,
        [index]: true,
      })
      setValue([`${index}`])
    }

    if (!adding) {
      return (
        <Button variation='primary' size='small' alignSelf='flex-end' isLoading={adding} onClick={newMeeting}>
          <MdAdd /> Add Meeting Note
        </Button>
      )
    }

    return null
  }
  return (
    <GlassCard header='Meeting Notes' className='overflow overlayCard' style={{ maxHeight: '100vh' }} headerButtons={meetingAddr()}>
      <Accordion.Container value={value} onValueChange={setValue} allowMultiple={true}>
        {data.map((meetingNote) => noteView(meetingNote))}
      </Accordion.Container>
    </GlassCard>
  )
}

export default MeetingNotes
