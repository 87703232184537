import { useQueries } from '@tanstack/react-query'
import { ProductInfo } from '../models'
import { restAPIRequest } from '../services/restApiRequest'

type ProductSimilaritySearchListRes = {
  targetProductName: string
  data: ProductSimilaritySearchRes[] | undefined
  isLoading: boolean
  error: Error | undefined
}

export type ProductSimilaritySearchRes = {
  taxonomy: string
  results: {
    companyName: string
    companyLabel: string
    productName: string
    description: string
    taxonomies: string[]
    score: number
  }[]
}
type Opts = {
  k?: number
}

export function useProductSimilaritySearchList(companyName: string, products: ProductInfo[], opts: Opts = {}): ProductSimilaritySearchListRes[] {
  const q = useQueries({
    queries: products.map((product) => {
      return {
        enabled: products.length > 0,
        queryKey: [companyName, 'products', 'similaritySearch', product.name],
        queryFn: async () =>
          restAPIRequest<ProductSimilaritySearchRes[]>({
            path: `companyProfile/${companyName}/products/similaritySearch`,
            method: 'post',
            body: {
              productName: product.name,
              description: product.description || 'n/a',
              features: product.featuresV2 || 'n/a',
              taxonomies: product.taxonomiesV2,
              maxScore: 0.3,
              k: opts.k,
            },
          }),
        staleTime: 1000 * 60 * 60 * 24,
      }
    }),
  })

  return q.map((res, i) => ({
    targetProductName: products[i].name,
    data: res.data,
    isLoading: res.isLoading,
    error: res.error as Error | undefined,
  }))
}
