import { FunctionComponent, useState } from 'react'
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { currencyFormatterLong, currencyFormatterShort, multipleFormatter, percentFormatter } from '../utils/utils'

const STOKE_COLORS: string[] = ['#8884d8', '#82ca9d', '#ffc658', '#0088fe', '#de548b', '#4dc2f0', '#a37926', '#508f52', '#f59de3', '#a88982']

export type TrxModelChartData = {
  xAxis: number | string
  yAxis: Record<string, number>
}
export const CHART_Y_TYPE_CURRENCY = 'currency'
export const CHART_Y_TYPE_MULTIPLE = 'multiple'
export const CHART_Y_TYPE_PERCENT = 'percent'

type TransactionModelChartParams = {
  data: TrxModelChartData[]
  chartYType: string
}

const TransactionModelChart: FunctionComponent<TransactionModelChartParams> = ({ data, chartYType }) => {
  const yAxis = Object.keys(data?.[0]?.yAxis || {})
  yAxis.sort((a, b) => {
    if (a.toLowerCase().includes('common')) return 1
    if (a.toLowerCase().includes('option')) return 1
    if (a.toLowerCase().includes('warrant')) return 1
    return -1
  })
  const [barProps, setBarProps] = useState(
    yAxis.reduce(
      (a, key) => {
        a[key] = false
        return a
      },
      { hover: null } as Record<string, boolean | string | null>
    )
  )

  const handleLegendMouseEnter = (e: { dataKey: string }) => {
    if (!barProps[e.dataKey]) {
      setBarProps({ ...barProps, hover: e.dataKey })
    }
  }

  const handleLegendMouseLeave = () => {
    setBarProps({ ...barProps, hover: null })
  }

  const selectBar = (e: any) => {
    setBarProps({
      ...barProps,
      [e.dataKey]: !barProps[e.dataKey],
      hover: null,
    })
  }

  if (!data[0]) return null
  const values = data.map((r) => {
    return {
      xAxis: r.xAxis,
      ...r.yAxis,
    }
  })
  let formatter = currencyFormatterShort
  if (chartYType === CHART_Y_TYPE_MULTIPLE) formatter = multipleFormatter
  if (chartYType === CHART_Y_TYPE_PERCENT) formatter = percentFormatter

  return (
    <ResponsiveContainer width='100%' height='100%'>
      <LineChart
        width={300}
        height={300}
        data={values}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='xAxis' allowDataOverflow={true} tickFormatter={currencyFormatterShort} />
        <YAxis width={50} domain={['auto', 'auto']} type='number' tickFormatter={formatter} />
        <Tooltip formatter={formatter as any} labelFormatter={currencyFormatterLong} />
        <Legend onClick={selectBar} onMouseOver={handleLegendMouseEnter} onMouseOut={handleLegendMouseLeave} />
        {yAxis.map((headerName, i) => {
          if (headerName === 'xAxis') return null
          return (
            <Line
              hide={barProps[headerName] === true}
              key={headerName}
              type='monotone'
              dataKey={headerName}
              stroke={`${STOKE_COLORS[i % STOKE_COLORS.length]}`}
              activeDot={{ r: 8 }}
              strokeWidth={getStrokeWidth()}
            />
          )

          function getStrokeWidth(): string | number | undefined {
            if (barProps.hover === null) return 2
            return Number(barProps.hover === headerName || !barProps.hover ? 5 : 0.5)
          }
        })}
      </LineChart>
    </ResponsiveContainer>
  )
}
export default TransactionModelChart
