import { AutocompleteOption } from '../types'
import { restAPIRequest } from './restApiRequest'

export async function listCompanies(search: string): Promise<AutocompleteOption[]> {
  try {
    return await restAPIRequest<AutocompleteOption[]>({
      path: `companies/autocomplete/${search}`,
      method: 'get',
    })
  } catch (error) {
    console.error(`error getting companies ${search}`, error)
    throw error
  }
}
