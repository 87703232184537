import { useQuery } from '@tanstack/react-query'
import { restAPIRequest } from '../services/restApiRequest'

export type CompMarketSize = {
  customer_types: string[]
  market_size: string
}
export type CompetitiveProducts = {
  competitorName: string
  differentiation: string
  gaps: string
}
type OutputMap = {
  compMarketSize: CompMarketSize
  competitiveProducts: CompetitiveProducts[]
}

type OneShotKey = keyof OutputMap
type Output<T extends OneShotKey> = OutputMap[T]

const MAX_RETRIES = 3
export function useOneShot<T extends OneShotKey>(companyName: string, key: T, input: Object = {}, enabled: boolean = true): [Output<T> | undefined, boolean, Error | undefined] {
  const { data, isLoading, error } = useQuery({
    queryKey: ['oneShot', key, companyName],
    enabled,
    queryFn: queryOneShot(companyName, key, input),
    retry: (failureCount, error) => {
      if (failureCount > MAX_RETRIES) {
        return false
      }

      return true
    },
    staleTime: 1000 * 60 * 60 * 24 * 7,
    cacheTime: 1000 * 60 * 60 * 24 * 7,
  })

  return [data, isLoading, error as Error | undefined]
}

export function queryOneShot<T extends OneShotKey>(companyName: string, key: T, input: Object) {
  return async () =>
    restAPIRequest<Output<T>>({
      path: `ai/oneShot`,
      method: 'post',
      body: {
        companyName,
        key,
        data: input,
      },
    })
}
