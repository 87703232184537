import { Tabs } from '@aws-amplify/ui-react'
import { FunctionComponent } from 'react'

interface ProfileNavBarProps {
  setPage: (page: string) => void
  pages: string[]
  currentPage: string
}

const ProfileNavBar: FunctionComponent<ProfileNavBarProps> = ({ currentPage, pages, setPage }) => {
  return (
    <Tabs.Container defaultValue='0'>
      <Tabs.List spacing='relative'>
        {pages.map((page, i) => {
          return (
            <Tabs.Item value={`${i}`} key={page} title={page} onClick={() => setPage(page)}>
              {page}
            </Tabs.Item>
          )
        })}
      </Tabs.List>
    </Tabs.Container>
  )
}

export default ProfileNavBar
