import * as React from 'react'

import { Cell, CellTemplate, Compatible, Span, Uncertain, getCellProperty } from '@silevis/reactgrid'

export interface HeaderCell extends Cell, Span {
  type: 'headerCell'
  text: string
}

export class HeaderCellTemplate implements CellTemplate<HeaderCell> {
  getCompatibleCell(uncertainCell: Uncertain<HeaderCell>): Compatible<HeaderCell> {
    const text = getCellProperty(uncertainCell, 'text', 'string')
    const value = parseFloat(text)
    return { ...uncertainCell, text, value }
  }

  render(cell: Compatible<HeaderCell>, isInEditMode: boolean, onCellChanged: (cell: Compatible<HeaderCell>, commit: boolean) => void): React.ReactNode {
    return cell.text
  }

  isFocusable = (cell: Compatible<HeaderCell>): boolean => false

  getClassName(cell: Compatible<HeaderCell>, isInEditMode: boolean): string {
    return cell.className ? cell.className : ''
  }
}
