import { GraphQLQuery, generateClient } from '@aws-amplify/api'
import { graphqlOperation } from '@aws-amplify/api-graphql'
import { DataStore } from '@aws-amplify/datastore'
import { useEffect, useState } from 'react'
import { GetUserQuery, GetUserQueryVariables } from '../API'
import { useAmplifyUser } from '../contexts/amplifyUserContext'
import { getUser } from '../graphql/queries'
import { User } from '../models'
import { sendSlack } from '../services/slack'

const API = generateClient()

export function useUserState(): [User | undefined, Function] {
  const amplifyUser = useAmplifyUser()
  const [user, setUser] = useState<User>()
  const userID = amplifyUser?.userId

  useEffect(() => {
    if (!userID) return

    const sub = DataStore.observeQuery(User, (o) => o.amplifyUserId.eq(userID)).subscribe(async ({ items, isSynced }) => {
      if (items.length === 0) {
        try {
          const vars: GetUserQueryVariables = {
            amplifyUserId: userID,
          }
          const info = await API.graphql<GraphQLQuery<GetUserQuery>>(graphqlOperation(getUser, vars))
          if (info?.data?.getUser) {
            // @ts-ignore
            delete info.data.getUser.createdAt
            // @ts-ignore
            delete info.data.getUser.updatedAt
            return setUser(new User(info.data.getUser))
          }
        } catch (e: any) {
          console.error(e)
          await sendSlack(`:alert: user error: ${e.message}. info ${JSON.stringify(e)}`)
          return
        }

        if (!isSynced) return
        await DataStore.save(new User({ amplifyUserId: userID }))
        await sendSlack(`:party-dino: :party-dino: :party-dino: New USER!!!: ${userID}`)
      } else {
        setUser(items[0])
      }
    })

    return () => {
      sub.unsubscribe()
    }
  }, [userID])

  const updateUser = async (newUser: User) => {
    if (!user) return console.error('No user trying to update user')
    await DataStore.save(
      User.copyOf(user, (updated) => {
        Object.assign(updated, {
          ...newUser,
        })
      })
    )
  }

  return [user, updateUser]
}
