import { Flex, Text, TextField, Image } from '@aws-amplify/ui-react'
import { FunctionComponent } from 'react'
import { NewInvestorDetail } from '../inputTableInfo'

interface NewInvestorViewProps {
  newInvestorsData: NewInvestorDetail[]
  updateName: (index: number, value: string) => void
  updateGroup: (index: number, value: string) => void
  deleteInvestor: (index: number) => void
  addNewInvestor: () => void
}

const NewInvestorView: FunctionComponent<NewInvestorViewProps> = ({ newInvestorsData, updateName, updateGroup, addNewInvestor, deleteInvestor }) => {
  return (
    <Flex className='assumptionsInput' direction='column' alignItems='flex-start' gap='0'>
      <Flex alignItems='center'>
        <Flex className='assumptionLabel'>
          <Text className='amplify-label assumptionLabel'>Investors</Text>
          <Image src='/images/CkAdd.svg' alt='add' className='imageButton' onClick={() => addNewInvestor()} />
        </Flex>
      </Flex>
      <Flex direction='column' gap='1rem'>
        {newInvestorsData.map((newInvestor, index) => {
          return (
            <Flex key={index} className='subAssumptionContainer'>
              <Flex direction='column' gap='0' width='15rem'>
                <Text className='assumptionSubLabel'>Name</Text>
                <TextField
                  label='Name'
                  labelHidden={true}
                  type='text'
                  size='small'
                  className='assumptionValue'
                  value={newInvestor.shareholderName}
                  onChange={(e) => updateName(index, e.target.value)}
                />
              </Flex>
              <Flex direction='column' gap='0' width='15rem'>
                <Text className='assumptionSubLabel'>Group</Text>
                <TextField
                  label='Group'
                  labelHidden={true}
                  type='text'
                  size='small'
                  className='assumptionValue'
                  value={newInvestor.group}
                  onChange={(e) => updateGroup(index, e.target.value)}
                />
              </Flex>
              <Image
                src='/images/CkClose.svg'
                alt='remove'
                className='imageButton'
                minWidth='1.5rem'
                alignSelf='flex-end'
                marginLeft='-0.5rem'
                marginBottom='0.25rem'
                onClick={() => deleteInvestor(index)}
              />
            </Flex>
          )
        })}
      </Flex>
    </Flex>
  )
}

export default NewInvestorView
