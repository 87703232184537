import { Tasks } from '../models'

export const PROFILE_UPDATE = 'profile.update'
export const OPPORTUNITY_OVERRIDE_UPDATE = 'opportunity.override.update'
export const STATUS_RUNNING = 'running'
export const STATUS_ERROR = 'error'
export const STATUS_DONE = 'done'

export function getRunningTasks(taskName: string, tasks: Tasks[] | null | undefined, { timeout = 1000 * 60 * 30 } = {}): Tasks | undefined {
  const task = tasks?.find((t) => t.name === taskName && t.status === STATUS_RUNNING)
  if (!task) return undefined
  if (!task.startedAt) return undefined
  if (new Date().getTime() - new Date(task.startedAt).getTime() >= timeout) return undefined
  return task
}
