import { FunctionComponent, ReactNode, useState } from 'react'
import { View } from '@aws-amplify/ui-react'
import { SectionOpenIcon, SectionCollapseIcon } from '../ui-components'

type InputTableViewProps = {
  tableTitle: string
  isVisible?: boolean
  display?: string
  marginTop?: string
  children: ReactNode
  overflow?: string
  size?: 'small' | 'large'
  isControlled?: boolean
  handleClick?: () => void
}
const InputTableView: FunctionComponent<InputTableViewProps> = ({
  tableTitle,
  isVisible = false,
  isControlled = false,
  display = 'inherit',
  marginTop = '',
  children,
  overflow = 'scroll',
  size = 'small',
  handleClick = () => {},
}) => {
  const [isTableBodyVisible, setIsTableBodyVisible] = useState<boolean>(isVisible)
  const className = size === 'small' ? 'tableTitle' : 'tableTitle alternate'
  const visible = isControlled ? isVisible : isTableBodyVisible

  return (
    <View className='table' display={display} marginTop={marginTop} backgroundColor={'rgba(255, 255, 255, .9)'}>
      <View
        className={className}
        onClick={() => {
          setIsTableBodyVisible(!visible)
          handleClick()
        }}
      >
        {tableTitle}
        {visible ? <SectionOpenIcon /> : <SectionCollapseIcon />}
      </View>
      <View className={`tableBody ${visible ? '' : 'collapse'}`}>{children}</View>
    </View>
  )
}
export default InputTableView
